<template>
    <div>
        <div class="flex-page-content flex-container body-gray">
            <div class="flex-body body-scroll">

                <div class="container mx-auto">

                    <div class="py-4">
                        <h1 class="mb-2">My Employee Dashboard</h1>

                        <employee-scoreboard :show-onboarding-modal="true" />

                        <div class="row">
                            <div class="col-12 col-lg-9">
                                <h4 class="mb-2">Account Features</h4>
                                <div class="form-row">

                                    <div class="col-12 col-ss-6 col-sm-4">
                                        <router-link :to="{name: 'employee-paychecks'}" class="module-tile">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">

                                                    <span class="module-tile-icon">
                                                        <i class="fal fa-fw fa-money-check-alt"></i>
                                                    </span>

                                                    <div class="module-tile-label">
                                                        Pay Stubs
                                                    </div>

                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4">
                                        <router-link :to="{name: 'employee-tax-forms'}" class="module-tile">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">

                                                    <span class="module-tile-icon">
                                                        <i class="fal fa-fw fa-file-contract"></i>
                                                    </span>

                                                    <div class="module-tile-label">
                                                        Tax Documents
                                                    </div>

                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4">
                                        <!-- TODO: only show if their companies/clients have has_paperless_onboarding -->
                                        <router-link :to="{name: 'employee-hr-docs'}" class="module-tile">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">

                                                    <span class="module-tile-icon">
                                                        <i class="fal fa-fw fa-copy"></i>
                                                    </span>

                                                    <div class="module-tile-label">
                                                        HR Documents
                                                    </div>

                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12 col-lg-3">

                                <div class="pt-2 pt-lg-0">
                                    <!-- Companies List -->
                                    <companies-list :companies="companies" :is-client="false" />
                                    <!-- Promo Tile -->
                                    <div class="hide-in-native">
                                        <promo-tile class="promo-tile-tall-lg"></promo-tile>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import EmployeeScoreboard from '@/components/EmployeeScoreboard'
import CompaniesList from '@/components/CompaniesList'
import PromoTile from '@/components/PromoTile'

export default {
    props: ['company'],
    components: {EmployeeScoreboard, CompaniesList, PromoTile,},
    computed: {
        state() {return this.$store.state},
        warningCount() {
            if (!this.$store.state.user) {
                return null
            }
            let result = []
            result = result.concat(this.$store.state.user.employee_onboarding_companies)
            result = result.concat(this.$store.state.user.employee_pending_link_companies)
            return result.length
        },
        companies() {
            let result = []
            result = result.concat(this.$store.state.user.employee_companies)
            result = result.concat(this.$store.state.user.employee_onboarding_companies)
            result = result.concat(this.$store.state.user.employee_pending_approval_companies)
            result = result.concat(this.$store.state.user.employee_pending_link_companies)

            let seen = new Set()
            result = result.filter((c) => {
                if (seen.has(c.id)) {
                    return false
                }

                seen.add(c.id)
                return true
            })
            result.sort((a, b) => a.name > b.name ? 1 : -1)
            return result
        },
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Home')
        this.$store.dispatch('GET_USER')
        this.$api.post('/me/last-viewed', {ess: true})
    },
}
</script>
