<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered-s py-4">

                        <h5>{{ onboardingApplication.company.name }}</h5>
                        {{ mode }}
                        <form @submit.prevent="onSubmit">
                            <form-errors :errors="formErrors"/>

                            <div v-show="mode == 'survey' && currentQuestion">
                                <survey v-show="currentQuestion == 'q1'" :yaml="surveys.q1" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q1" />
                                <survey v-show="currentQuestion == 'q2'" :yaml="surveys.q2" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q2" />
                                <survey v-show="currentQuestion == 'q3'" :yaml="surveys.q3" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q3" />
                                <survey v-show="currentQuestion == 'q4'" :yaml="surveys.q4" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q4" />
                                <survey v-show="currentQuestion == 'q5'" :yaml="surveys.q5" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q5" />
                                <survey v-show="currentQuestion == 'q6'" :yaml="surveys.q6" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q6" />
                                <survey v-show="currentQuestion == 'q7'" :yaml="surveys.q7" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q7" />
                                <survey v-show="currentQuestion == 'q8'" :yaml="surveys.q8" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q8" />
                                <survey v-show="currentQuestion == 'q9'" :yaml="surveys.q9" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q9" />
                                <survey v-show="currentQuestion == 'q10'" :yaml="surveys.q10" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q10" />
                                <survey v-show="currentQuestion == 'q11'" :yaml="surveys.q11" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q11" />
                                <survey v-show="currentQuestion == 'q12'" :yaml="surveys.q12" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q12" />
                                <survey v-show="currentQuestion == 'q13'" :yaml="surveys.q13" :knowndata="knownData" :hidesubmit="true" @error="onWOTCFormError" ref="q13" />

                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <a href @click.prevent="previousQuestion">Back</a>
                                    </div>

                                    <div class="col text-right">
                                        <button class="btn btn-primary">Continue</button>
                                    </div>
                                </div>
                            </div>

                            <div v-show="mode == 'signature'">
                                <h3>WOTC Benefits Questionnaire</h3>
                                <hr/>

                                <p>
                                    Under penalties of perjury, I declare that I gave the above information to the employer on or before the day I was offered a job, and it is, to the best of my knowledge, true, correct, and complete.
                                </p>

                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <a href @click.prevent="mode = 'survey'; previousQuestion()">Back</a>
                                    </div>

                                    <div class="col text-right">
                                        <button class="btn btn-primary" @click.prevent="mode = 'complete'">Sign</button>
                                    </div>
                                </div>
                            </div>

                            <div v-show="mode == 'complete'">
                                <h3>WOTC Benefits Questionnaire</h3>
                                <hr/>

                                <div class="text-center pt-2 pb-4">

                                    <p><i class="fas fa-check text-primary"></i>&nbsp; Questionnaire complete &mdash; thank you for your responses.</p>

                                    <p>Next, we'll set up your HR information.</p>

                                </div>

                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <a href @click.prevent="previousQuestion">Back</a>
                                    </div>

                                    <div class="col text-right">
                                        <button class="btn btn-primary" @click.prevent="onSubmit">Continue</button>
                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import Survey from '@/components/PDFForm/Survey'
import WizardMixin from './WizardMixin'
import moment from 'moment'

const SURVEYS = {
    q1: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      slug: wotc_q1_is_worked_before
      text: "Have you worked for this employer before?"
      options:
        - text: "No"
          slug: no

        - text: "Yes"
          slug: yes
        `,
    q2: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      slug: wotc_q2_are_you_veteran
      text: "Are you a Veteran of the U.S. Armed Forces?"
      options:

        - text: "No"
          slug: no

        - text: "Yes"
          slug: yes
        `,
    q3: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      slug: wotc_q21_disabled_veteran
      text: "Are you a veteran entitled to compensation for a service-connected disability?"
      options:

        - text: "No"
          slug: no

        - text: "Yes"
          slug: yes
          questions:

            - type: select-one
              slug: wotc_q211_discharged_past_year
              text: "Were you discharged or released from active duty within a year before you were hired?"
              options:

                - text: "No"
                  slug: no

                - text: "Yes"
                  slug: yes
        `,
    q4: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      slug: wotc_q22_received_food_stamps
      text: "Are you a member of a family that received Supplemental Nutrition Assistance Program (SNAP) benefits (Food Stamps) for at least 3 months during the 15 months before you were hired?"
      options:

        - text: "No"
          slug: no

        - text: "Yes"
          slug: yes
          questions:

            - type: text
              slug: wotc_q22_name_of_primary_recipient_first_name
              text: "What was the first name of the primary recipient?"

            - type: text
              slug: wotc_q22_name_of_primary_recipient_last_name
              text: "What was the last name of the primary recipient?"

            - type: text
              slug: wotc_q22_City
              text: "What is the primary city the benefits were received in?"

            - type: select-one
              slug: wotc_q22_State
              text: "What is the primary state these benefits were received in?"
              preset_options: 'states'
              widget: select
        `,
    q5: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      slug: wotc_q6_food_stamps_6_months
      text: "Are you a member of a family that received Supplemental Nutrition Assistance Program (SNAP) benefits (formerly Food Stamps) within the past 6 months?"
      options:

        - text: "No"
          slug: no

        - text: "Yes"
          slug: yes

    `,
    q6: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      text: "Were SNAP benefits received:"
      slug: snap_timeperiod
      options:
        - slug: wotc_q6_food_stamps_6_months
          text: "For the 6 months before you were hired?"

        - slug: wotc_q6_food_stamps_3_of_5_months
          text: "Have you received food stamps for 3 of the past 5 months?"

        - slug: neither
          text: "Neither of these"

      questions:
        - type: text
          slug: wotc_q6_name_of_primary_recipient_first_name
          text: "What was the primary recipient's first name?"
          condition: any
          condition_options: ["wotc_q6_food_stamps_6_months", "wotc_q6_food_stamps_3_of_5_months"]

        - type: text
          slug: wotc_q6_name_of_primary_recipient_last_name
          text: "What was the primary recipient's last name?"
          condition: any
          condition_options: ["wotc_q6_food_stamps_6_months", "wotc_q6_food_stamps_3_of_5_months"]

        - type: text
          slug: wotc_q6_City
          text: "What is the primary city the benefits were received in"
          condition: any
          condition_options: ["wotc_q6_food_stamps_6_months", "wotc_q6_food_stamps_3_of_5_months"]

        - type: select-one
          slug: wotc_q6_State
          text: "What is the primary state these benefits were received in?"
          widget: select
          preset_options: 'states'
          condition: any
          condition_options: ["wotc_q6_food_stamps_6_months", "wotc_q6_food_stamps_3_of_5_months"]
    `,
    q7: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      slug: wotc_q3_referred_to_employer
      text: "Were you referred to an employer by ANY of the following:"
      options:
        - text: "Vocational Rehabilitation Agency approved by a State"
          slug: voc_rehab
          output_value: "1"

        - text: "An Employment Network under the Ticket to Work Program"
          slug: emp_network
          output_value: "2"

        - text: "The Department of Veterans Affairs"
          slug: va
          output_value: "3"

        - text: "I was not referred by any of these agencies"
          slug: none
          output_value: "4"

    - type: text
      slug: wotc_q3_referred_to_employer_voc_rehab
      template: "{{#if wotc_q3_referred_to_employer__voc_rehab}}X{{/if}}"

    - type: text
      slug: wotc_q3_referred_to_employer_emp_network
      template: "{{#if wotc_q3_referred_to_employer__emp_network}}X{{/if}}"

    - type: text
      slug: wotc_q3_referred_to_employer_va
      template: "{{#if wotc_q3_referred_to_employer__va}}X{{/if}}"

    - type: text
      slug: wotc_q3_referred_to_employer_none
      template: "{{#if wotc_q3_referred_to_employer__none}}X{{/if}}"

    `,
    q8: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      slug: wotc_q7_received_TANF
      text: "Are you a member of a family that received TANF assistance in the past?"
      options:
        - text: "No"
          slug: no

        - text: "Yes"
          slug: yes
    `,
    q9: `
title: WOTC Benefits Questionnaire
questions:
    - text: "Did your family:"
      type: select-one
      slug: tanf_details
      options:
        - slug: eighteen_months
          text: "Receive TANF assistance for at least the last 18 months before you were hired?"

        - slug: two_years_since_1997
          text: "Receive TANF assistance for ANY 18-month period beginning after August 5, 1997 and this period ended sometime during the 2 years before you were hired?"

        - slug: two_years
          text: "Stop being eligible for TANF assistance within 2 years before you were hired because a Federal or state law limited the maximum time those payments could be made?"

        - slug: nine_months
          text: "Receive TANF assistance for any 9 months during the 18-month period before you were hired?"

        - slug: none
          text: "None of the above"

      questions:
        - type: text
          slug: wotc_q7_name_of_primary_recipient_first_name
          text: "What was the first name of the primary recipient?"
          condition: any
          condition_options: ["eighteen_months", "two_years_since_1997", "two_years", "nine_months"]

        - type: text
          slug: wotc_q7_name_of_primary_recipient_last_name
          text: "What was the last name of the primary recipient?"
          condition: any
          condition_options: ["eighteen_months", "two_years_since_1997", "two_years", "nine_months"]

        - type: text
          slug: wotc_q7_City
          text: "What is the primary city the benefits were received in"
          condition: any
          condition_options: ["eighteen_months", "two_years_since_1997", "two_years", "nine_months"]

        - type: select-one
          slug: wotc_q7_State
          text: "What is the primary state these benefits were received in?"
          condition: any
          condition_options: ["eighteen_months", "two_years_since_1997", "two_years", "nine_months"]
          widget: select
          preset_options: 'states'
    `,
    q10: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      slug: wotc_q4_are_you_exfelon
      text: "Have you been convicted of a felony?"
      options:
        - text: "No"
          slug: no

        - text: "Yes"
          slug: yes
          questions:

            - type: select-one
              slug: wotc_q41_federal_or_state
              text: "What type of conviction was it?"
              options:
                - slug: fed
                  text: "Federal"

                - slug: state
                  text: "State"

            - type: date
              slug: wotc_q41_conviction_date
              text: "What was the date of conviction?"

            - type: select-one
              slug: wotc_q41_released_last_year
              text: "Were you released from prison after a felony conviction during the year before you were hired?"
              options:
                - slug: no
                  text: "No"

                - slug: yes
                  text: "Yes"
                  questions:

                    - type: date
                      slug: wotc_q41_release_date
                      text: "What was your date of release?"
    `,
    q11: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      slug: wotc_q51_60_days_before_hire
      text: "Did you receive Supplemental Security Income (SSI) benefits for any month ending within 60 days before you were hired?"
      options:

        - text: "No"
          slug: no

        - text: "Yes"
          slug: yes
    `,
    q12: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      slug: wotc_q9_LTU_27_weeks
      text: "Are you an individual who is or was in a period of unemployment that is at least 27 consecutive weeks and for all or part of that period you received unemployment compensation?"
      options:

        - text: "No"
          slug: no

        - text: "Yes"
          slug: yes
          questions:

            - type: select-one
              slug: wotc_q9_LTU_state
              text: "What state did you receive unemployment compensation in?"
              preset_options: 'states'
              widget: select

            - type: date
              slug: wotc_q9_LTU_startdate
              text: "Enter the last date of employment"
    `,
    q13: `
title: WOTC Benefits Questionnaire
questions:
    - type: select-one
      slug: wotc_q23_unemployed_veteran
      text: "Were you unemployed at any time during the year before you were hired?"
      options:

        - text: "No"
          slug: no

        - text: "Yes"
          slug: yes
          questions:
            - type: select-one
              slug: veteran_unemployment_length
              text: "Were you unemployed:"
              options:
                - text: "For a combined period of at least 6 months (whether or not consecutive) during the year before you were hired"
                  slug: six_months

                - text: "For a combined period of at least 4 weeks but less than 6 months (whether or not consecutive) during the year before you were hired"
                  slug: four_weeks

                - text: "Neither of these"
                  slug: neither
    `,
}

export default {
    mixins: [FormMixin, WizardMixin],
    components: {
        FormInput,
        FormErrors,
        Survey,
    },
    props: ['onboardingApplication'],
    computed: {
        currentYAML() {
            return SURVEYS[this.currentQuestion]
        },
        surveys() {
            return SURVEYS
        },
        knownData() {
            // no known data for WOTC for now - will be filled in w/Python later before submitting to API
            const app = this.onboardingApplication
            return {
                'system': {
                },
                'employee': {
                },
                'employer': {
                },
            }
        },
    },
    data() {
        return {
            mode: 'survey',  // Can be 'survey', 'signature', or 'complete'
            formRules: {
            },
            formData: {
                action: 'answer-wotc',
            },
            currentQuestion: 'q1',
            completedQuestions: [],
        }
    },
    methods: {
        onWOTCFormError(error) {
            this.$bus.showError(error)
        },
        makeWOTCData() {
            const data = {}
            Object.assign(data, this.knownData)
            this.completedQuestions.forEach(q => {
                try {
                    Object.assign(data, this.$refs[q].getFormValues())
                } catch (ex) {
                    this.onWOTCFormError(ex)
                    return
                }
            })

            return data
        },
        onSubmit() {
            if (this.currentQuestion) {
                this.completedQuestions.push(this.currentQuestion)
            }

            const data = {
                action: 'answer-wotc',
                date: moment().format('MM/DD/YYYY'),
                datetime: moment().utc().format('YYYY-MM-DD HH:mm:ss') + ' Z',
            }
            Object.assign(data, this.makeWOTCData())
            this.formData = data

            if (this.currentQuestion) {
                this.nextQuestion()
            }
            else {
                this.$store.dispatch('START_LOADING')
                const wotcFormId = this.onboardingApplication.wotc_form.id ? this.onboardingApplication.wotc_form.id : this.onboardingApplication.wotc_form
                this.$api.post(`/me/companies/${this.onboardingApplication.id}/onboarding-application/wotc/save/${wotcFormId}`, data).then((resp) => {
                    this.$emit('updated', resp)
                    this.$emit('next')
                    this.$store.dispatch('STOP_LOADING')
                }).catch((errors) => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    this.$store.dispatch('STOP_LOADING')
                })
            }
        },
        reset: function() {
            this.errors = {}
            this.formErrors = []
        },
        nextQuestion() {
            const routing = {
                q1: {
                    wotc_q1_is_worked_before__no: 'q2',
                    wotc_q1_is_worked_before__yes: null,  // This means exit the survey
                },
                q2: {
                    wotc_q2_are_you_veteran__yes: 'q3',
                    wotc_q2_are_you_veteran__no: 'q5',
                },
                q3: {
                    wotc_q21_disabled_veteran__yes: 'q4',
                    wotc_q21_disabled_veteran__no: 'q4',
                },
                q4: {
                    wotc_q22_received_food_stamps__yes: 'q7',
                    wotc_q22_received_food_stamps__no: 'q7',
                },
                q5: {
                    wotc_q6_food_stamps_6_months__yes: 'q6',
                    wotc_q6_food_stamps_6_months__no: 'q7',
                },
                q6: {
                    snap_timeperiod__wotc_q6_food_stamps_6_months: 'q7',
                    snap_timeperiod__wotc_q6_food_stamps_3_of_5_months: 'q7',
                    snap_timeperiod__neither: 'q7',
                },
                q7: {
                    wotc_q3_referred_to_employer__voc_rehab: 'q8',
                    wotc_q3_referred_to_employer__emp_network: 'q8',
                    wotc_q3_referred_to_employer__va: 'q8',
                    wotc_q3_referred_to_employer__none: 'q8',
                },
                q8: {
                    wotc_q7_received_TANF__yes: 'q9',
                    wotc_q7_received_TANF__no: 'q10',
                },
                q9: {
                    tanf_details__eighteen_months: 'q10',
                    tanf_details__two_years_since_1997: 'q10',
                    tanf_details__two_years: 'q10',
                    tanf_details__nine_months: 'q10',
                    tanf_details__none: 'q10',
                },
                q10: {
                    wotc_q4_are_you_exfelon__yes: 'q11',
                    wotc_q4_are_you_exfelon__no: 'q11',
                },
                q11: {
                    wotc_q2_are_you_veteran__yes: 'q13',
                    wotc_q2_are_you_veteran__no: 'q12',
                },
                q12: {
                    wotc_q9_LTU_27_weeks__yes: null,
                    wotc_q9_LTU_27_weeks__no: null,
                },
                q13: {
                    wotc_q23_unemployed_veteran__yes: null,
                    wotc_q23_unemployed_veteran__no: null,
                },
            }

            const path = routing[this.currentQuestion]
            Object.keys(path).forEach(slug => {
                if (this.formData[slug]) {
                    this.currentQuestion = path[slug]
                    return false
                }
            })
            if (!this.currentQuestion && this.mode == 'survey') {
                if (this.makeWOTCData()['wotc_q1_is_worked_before__no']) {
                    this.mode = 'signature'
                }
                else {
                    this.mode = 'complete'
                }
            }
        },
        previousQuestion() {
            if (this.mode == 'complete') {
                this.mode = 'survey'
            }

            if (this.completedQuestions.length) {
                this.currentQuestion = this.completedQuestions.pop()
            }
            else {
                this.$router.push(this.previousPage)
            }
        },
    },
}


</script>
