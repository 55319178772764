<!--
    This component lives in 2 places:
    - Inside .page-actions-activefilters, within FastTable
    - In the Filters modal window
-->
<template>
    <div class="activefilters-list-wrapper" ref="filtersWrapper">
        <div class="activefilters-list activefilters-list--h">
            <span class="activefilters-list__main">

                <button type="button" class="btn filter-pill" v-for="pill in visiblePills" :key="pill.id" :class="{'filter-pill--inactive': filterValueCounts && filterValueCounts[pill.filter.id][pill.value] < 1}">
                    <span class="filter-pill__text">
                        {{ pill.filter.name }}: {{ pill.label }}
                    </span>
                    <span class="filter-pill__close">
                        <button type="button" class="close" aria-label="Close" @click.prevent="removeFilter(pill.filter.id, pill.value)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </span>
                </button>

                <span class="dropdown filter-pill-dropdown" v-if="hiddenPills.length">
                    <button type="button" class="btn filter-pill filter-pill--menu dropdown-toggle" id="filtersOverflow" data-toggle="dropdown" ref="overflowTrigger" @click.prevent="" aria-haspopup="true" aria-expanded="false">
                        <span class="filter-pill__text">{{ hiddenPills.length }}</span>
                    </button>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="filtersOverflow" ref="overflowMenu">
                        <div class="dropdown-item" v-for="pill in hiddenPills" :key="pill.id" :class="{'filter-pill--inactive': filterValueCounts && filterValueCounts[pill.filter.id][pill.value] < 1}">
                            <span class="flex-grow-1 flex-shrink-1 pr-2 text-wrap">
                                {{ pill.filter.name }}: {{ pill.label }}
                            </span>
                            <span class="flex-0">
                                <button type="button" class="close" aria-label="Close" @click.prevent="removeFilter(pill.filter.id, pill.value)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </span>
                        </div>

                    </div>
                </span>
            </span>

            <span class="activefilters-list__suffix">
                <button class="btn btn-outline-primary" v-if="pills.length" @click.prevent="clearFilters">
                    <span v-if="inModal">Deselect All</span>
                    <span v-else>Turn Off Filters</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    props: ['possibleFilters', 'filterValues', 'quickFilterOn', 'filterValueCounts', 'inModal'],
    computed: {
        pills() {
            if (!this.possibleFilters) {return []}

            const pills = []
            this.possibleFilters.forEach(f => {
                if (this.quickFilterOn && f.type == 'warning') {return}

                if (this.filterValues[f.id] && this.filterValues[f.id].length) {
                    this.filterValues[f.id].forEach(fv => {
                        pills.push({
                            id: `${f.id}--${fv.value}`,
                            filter: f,
                            value: fv.value,
                            label: fv.label,
                        })
                    })
                }
            })

            return pills
        },
        visiblePills() {
            return this.pills.filter(p => this.visiblePillIds.find(id => id == p.id))
        },
        hiddenPills() {
            return this.pills.filter(p => !this.visiblePillIds.find(id => id == p.id))
        },
    },
    mounted() {
        $(window).on('resize', this.onResize)
        this.$bus.$on('resize', this.onResize)

        $(this.$refs.overflowTrigger).dropdown({
            boundary: 'viewport', // Otherwise defaults to scrollParent which may not work.
        }).on('show.bs.dropdown', () => {
            // anything?
        }).on('hide.bs.dropdown', () => {
            // anything?
        })
    },
    destroyed() {
        $(this.$refs.overflowTrigger).dropdown('dispose')
    },
    data() {
        return {
            visiblePillIds: [],
            resizeTimer: null,
            resizeTimeout: 100,
        }
    },
    watch: {
        possibleFilters: {
            handler() {
                this.showPills()
            },
            deep: true,
            immediate: true,
        },
        filterValues: {
            handler() {
                this.showPills()
            },
            deep: true,
        },
    },
    methods: {
        showPills() {
            this.$set(this, 'visiblePillIds', [])
            if (this.pills.length < 1) {return}

            $(this.$refs.filtersWrapper).addClass('mid-calculation')
            this.$nextTick(() => {
                const allPills = Array.from(this.pills)  // Mutable array which we can use as our work queue

                const tryPill = () => {
                    const pill = allPills.pop()

                    if (pill) {
                        this.visiblePillIds.push(pill.id)  // Make this pill visible

                        this.$nextTick().then(() => {  // Wait for it to render
                            // Calculate the available and used widths
                            const availableWidth = $(this.$refs.filtersWrapper).width()
                            const $child = $(this.$refs.filtersWrapper).children().first()
                            const usedWidth = $child[0].scrollWidth

                            if (availableWidth < usedWidth) {
                                // If we don't have room, remove this pill and stop iteration
                                this.visiblePillIds.pop()
                                $(this.$refs.filtersWrapper).removeClass('mid-calculation')
                                return
                            }

                            tryPill()  // Schedule another pill to try
                        })
                    }
                    else {
                        $(this.$refs.filtersWrapper).removeClass('mid-calculation')
                        return // We ran out of pills
                    }
                }

                tryPill()
            })
        },
        onResize() {
            if (this.resizedTimer) {
                clearTimeout(this.resizedTimeout)
            }

            $(this.$refs.filtersWrapper).addClass('overflow-hidden')
            this.resizeTimer = setTimeout(() => {
                $(this.$refs.filtersWrapper).removeClass('overflow-hidden')
                this.$nextTick(() => {
                    this.showPills()
                })
            }, this.resizeTimeout)
        },
        removeFilter(filterId, filterValue) {
            this.$emit('remove', {filterId, filterValue})
        },
        clearFilters() {
            this.$emit('clear')
        },
    },
}
</script>

<style scoped>

.mid-calculation .filter-pill {
    opacity: 0.01;
}

</style>
