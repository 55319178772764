<template>
    <div id="company">
        <transition name="fade" mode="out-in">
            <router-view class="flex-page-wrapper flex-container flex-body view" v-if="dataLoaded" :company="company" />
        </transition>
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'

export default {
    data() {
        return {
            dataLoaded: false,
            company: null,
        }
    },
    mounted() {
        this.$store.dispatch('GET_USER')
        this.getCompany()
    },
    methods: {
        getCompany() {
            this.dataLoaded = false
            const appId = this.$route.params.appId
            this.$store.dispatch('START_LOADING')
            api.get(`/companies/${appId}`).then((resp) => {
                this.company = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        },
    }
}
</script>
