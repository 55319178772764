<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered-s py-4">

                        <h5>{{ onboardingApplication.company.name }}</h5>
                        <h3 v-if="!activeForm">Required Documents</h3>

                        <div v-show="!activeForm">
                            <p class="mt-4">Complete the {{ onboardingApplication.forms.length + onboardingApplication.hr_documents.length }} documents below.</p>

                            <div class="card doc-item mb-2" v-for="form in forms" :key="form.id">
                                <div class="card-body">
                                    <div class="todo-item" :class="{'todo-item-completed': form.filled_on}">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ form.yaml_form.yaml|yamlTitle }}</h6>

                                            <div class="doc-action-primary mb-1">

                                                <a href class="btn btn-outline-primary" target="_blank" @click.prevent="setActiveForm(form)" v-if="!form.filled_on">
                                                    <i class="far fa-fw fa-pencil mr-1"></i>Fill Out Document
                                                </a>

                                                <a href v-if="form.filled_on" class="btn btn-outline-success" @click.prevent="previewPDFForm(form)">
                                                    <i class="far fa-fw fa-check mr-1"></i>View Signed Document
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card doc-item mb-2" v-for="hr_doc in onboardingApplication.hr_documents" :key="hr_doc.id">
                                <div class="card-body">
                                    <div class="todo-item" :class="{'todo-item-completed': hr_doc.signed_on}">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ hr_doc.hr_document.name }}</h6>

                                            <div class="doc-action-primary mb-1">

                                                <a v-if="!hr_doc.signed_on" href class="btn btn-outline-primary" @click.prevent="showHRDocument(hr_doc)">
                                                    <i class="far fa-fw fa-external-link mr-1"></i>Sign Document
                                                </a>

                                                <a href v-if="hr_doc.signed_on" class="btn btn-outline-success" @click.prevent="showHRDocument(hr_doc)">
                                                    <i class="far fa-fw fa-check mr-1"></i>View Signed Document
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <form @submit.prevent="onSubmit">
                                <form-errors :errors="formErrors"/>

                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <router-link v-if="previousPage" :to="previousPage">Back</router-link>
                                    </div>

                                    <div class="col text-right">
                                        <button class="btn btn-primary" :disabled="continueDisabled">Continue</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-if="activeForm" class="mb-4">
                            <survey :yaml="activeForm.yaml_form.yaml" :knowndata="knownData" @error="onPDFFormError" @submit="onPDFFormSubmit" ref="activeFormSurvey" :hidesubmit="true" :pdf-url="activeForm.yaml_form.instructions_pdf_file_url || activeForm.yaml_form.pdf_file_url"/>

                            <div class="row mb-2 d-flex align-items-center">
                                <div class="col">
                                    <a href @click.prevent="setActiveForm(null)">Back</a>
                                </div>

                                <div class="col text-right">
                                    <button class="btn btn-primary" @click.prevent="onPDFFormSubmit">Continue</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <pdf-modal :url="currentPDF.url" :title="currentPDF.title" :has-accept="true" v-if="currentPDF" @close="currentPDF = null" ref="pdfModal">

            <template slot="footer" v-if="(activeForm && !activeForm.filled_on) || (activeHRDocument && !activeHRDocument.signed_on)">
                <div class="form-row">
                    <div class="col-12" v-if="activeForm">
                        <p>{{ activeForm.yaml_form.acceptance_text }}</p>
                    </div>

                    <div class="col-12" v-if="activeHRDocument">
                        <p>I have read and agree with this document.</p>
                    </div>

                    <div class="col-12 col-rs-6 col-sm-4 offset-sm-2">
                        <button type="button" class="btn btn-block btn-primary mb-1" @click.prevent="signForm()">Sign Document</button>
                    </div>

                    <div class="col-12 col-rs-6 col-sm-4">
                        <button v-if="activeForm" type="button" class="btn btn-block btn-outline-primary mb-1" @click.prevent="$refs.pdfModal.close()">Edit Form</button>
                        <button v-if="activeHRDocument" type="button" class="btn btn-block btn-outline-primary mb-1" @click.prevent="$refs.pdfModal.close()">Close</button>
                    </div>
                </div>
            </template>

        </pdf-modal>

    </div>
</template>


<script>
import FormMixin from '@/mixins/Form'
import Survey from '@/components/PDFForm/Survey'
import api from '@/api'
import bus from '@/bus'
import WizardMixin from './WizardMixin'
import PdfModal from '@/components/PdfModal'
import moment from 'moment'
import YAML from 'js-yaml'

export default {
    mixins: [FormMixin, WizardMixin, ],
    components: {Survey, PdfModal, },
    props: ['onboardingApplication'],
    mounted() {
        this.$store.dispatch('GET_USER')
    },
    computed: {
        API_BASE() {
            return api.API_BASE
        },
        forms() {
            const forms = this.onboardingApplication.forms
            forms.sort((a, b) => {
                if (a.yaml_form.priority_order == b.yaml_form.priority_order) {
                    return a.id > b.id ? 1 : -1
                }
                return (a.yaml_form.priority_order > b.yaml_form.priority_order) ? 1 : -1
            })
            return forms
        },
        continueDisabled() {
            let c = this.forms.filter((f) => !f.filled_on).length
            c += this.onboardingApplication.hr_documents.filter(d => !d.signed_on).length
            return c > 0
        },
        knownData() {
            const app = this.onboardingApplication
            return {
                'system': {
                    'date': moment().format('MM/DD/YYYY'),
                    'datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),
                },
                'employee': {
                    'first_name': app.first_name,
                    'middle_initial': app.middle_initial,
                    'last_name': app.last_name,
                    'full_name': app.full_name,
                    'email': app.email,
                    'suffix': app.suffix,
                    'address_line_1': app.address_1,
                    'address_line_2': app.address_2,
                    'city': app.city,
                    'state': app.state,
                    'state_long': app.state_long,
                    'postcode': app.postal_code,
                    'phone_number': app.phone_number,
                    'dob': moment(app.dob).format('MM/DD/YYYY'),
                    'date_hired': moment(app.date_hired).format('MM/DD/YYYY'),
                    'school_district_id': app.school_district_id,
                    'school_district_name': app.school_district_name,
                },
                'employer': {
                    'name': app.company.name,
                    'fein': app.company.EIN,
                    'address_line_1': app.company.address_1,
                    'address_line_2': app.company.address_2,
                    'city': app.company.city,
                    'state': app.company.state,
                    'state_long': app.company.state_long,
                    'postcode': app.company.postal_code,
                    'phone_number': app.company.phone_number,
                },
            }
        }
    },
    data() {
        return {
            formRules: {
            },
            formData: this.makeFormData(),
            activeForm: null,
            activeHRDocument: null,
            currentPDF: null,
        }
    },
    methods: {
        makeFormData() {
            return {
                action: 'complete-forms',
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.patch(`/me/companies/${this.onboardingApplication.id}/onboarding-application`, this.formData).then((resp) => {
                this.$emit('updated', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        close() {
            this.activeForm = null
        },
        setActiveForm(form) {
            this.activeForm = null
            this.$nextTick(() => {
                this.activeForm = form
            })
        },
        onPDFFormError(errors) {
            if (errors.message) {
                bus.showError(errors.message)
            }
            else if (errors.__all__) {
                bus.showError(errors.__all__)
            }
            else {
                bus.showError(errors)
            }
        },
        onPDFFormSubmit() {
            let data
            try {
                data = this.$refs.activeFormSurvey.getFormValues()
            }
            catch (ex) {
                this.onPDFFormError(ex)
                return
            }

            this.$store.dispatch('START_LOADING')

            delete data['signature']
            if (data['translator_signature']) {delete data['translator_signature']}
            if (data['translator_signature_2']) {delete data['translator_signature_2']}
            if (data['translator_signature_3']) {delete data['translator_signature_3']}
            if (data['translator_signature_4']) {delete data['translator_signature_4']}
            if (data['translator_signature_5']) {delete data['translator_signature_5']}

            api.post(`/me/companies/${this.onboardingApplication.id}/onboarding-application/forms/${this.activeForm.id}`, {'form_data': data}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.previewPDFForm(this.activeForm)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        },
        showHRDocument(doc) {
            this.activeHRDocument = doc
            if (!doc) {
                this.currentPDF = null
                return
            }

            let url = ''
            if (doc.signed_on) {
                url = `${this.API_BASE}/me/companies/${this.onboardingApplication.id}/onboarding-application/hr-documents/${doc.id}/signed`
            }
            else {
                url = `${this.API_BASE}/hr-documents/${doc.hr_document.id}/pdf-file`
            }

            this.currentPDF = {
                title: doc.hr_document.name,
                url: {
                    url: url,
                    withCredentials: true,
                }
            }
        },
        previewPDFForm(form) {
            this.currentPDF = {
                title: this.parseYAML(form.yaml_form.yaml).title,
                url: {
                    url: `${this.API_BASE}/me/companies/${this.onboardingApplication.id}/onboarding-application/forms/${form.id}/pdf`,
                    withCredentials: true,
                }
            }
        },
        signForm() {
            let data

            if (this.activeForm) {
                try {
                    data = this.$refs.activeFormSurvey.getFormValues()
                }
                catch (ex) {
                    this.onPDFFormError(ex)
                    return
                }

                this.$store.dispatch('START_LOADING')
                const formData = {
                    form_data: data,
                    signatures: {
                        'signature': {
                            'first_name': this.knownData.employee.first_name,
                            'last_name': this.knownData.employee.last_name,
                            'full_name': this.knownData.employee.full_name,
                            'date': this.knownData.system.date
                        },
                    },
                }

                if (data['translator_count'] && (1 * data['translator_count']) >= 1) {
                    formData.signatures['translator_signature'] = {
                        'first_name': data['translator_first_name'],
                        'last_name': data['translator_last_name'],
                        'full_name': `${data['translator_first_name']} ${data['translator_last_name']}`,
                        'date': this.knownData.system.date
                    }
                }

                if (data['translator_count'] && (1 * data['translator_count']) >= 2) {
                    formData.signatures['translator_signature_2'] = {
                        'first_name': data['translator_first_name_2'],
                        'last_name': data['translator_last_name_2'],
                        'full_name': `${data['translator_first_name_2']} ${data['translator_last_name_2']}`,
                        'date': this.knownData.system.date
                    }
                }

                if (data['translator_count'] && (1 * data['translator_count']) >= 3) {
                    formData.signatures['translator_signature_3'] = {
                        'first_name': data['translator_first_name_3'],
                        'last_name': data['translator_last_name_3'],
                        'full_name': `${data['translator_first_name_3']} ${data['translator_last_name_3']}`,
                        'date': this.knownData.system.date
                    }
                }

                if (data['translator_count'] && (1 * data['translator_count']) >= 4) {
                    formData.signatures['translator_signature_4'] = {
                        'first_name': data['translator_first_name_4'],
                        'last_name': data['translator_last_name_4'],
                        'full_name': `${data['translator_first_name_4']} ${data['translator_last_name_4']}`,
                        'date': this.knownData.system.date
                    }
                }

                if (data['translator_count'] && (1 * data['translator_count']) >= 5) {
                    formData.signatures['translator_signature_5'] = {
                        'first_name': data['translator_first_name_5'],
                        'last_name': data['translator_last_name_5'],
                        'full_name': `${data['translator_first_name_5']} ${data['translator_last_name_5']}`,
                        'date': this.knownData.system.date
                    }
                }

                api.post(`/me/companies/${this.onboardingApplication.id}/onboarding-application/forms/${this.activeForm.id}?sign=1`, formData).then(() => {
                    this.$emit('updated')
                    this.$refs.pdfModal.close()
                    this.activeForm = null
                    this.$store.dispatch('STOP_LOADING')
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    bus.showError(errors.__all__)
                })
            }
            else if (this.activeHRDocument) {
                const signatureData = {
                    'date': moment().format('MM/DD/YYYY'),
                    'datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),
                }

                this.$store.dispatch('START_LOADING')
                api.post(`/me/companies/${this.onboardingApplication.id}/onboarding-application/hr-documents/${this.activeHRDocument.id}`, signatureData).then(() => {
                    this.$emit('updated')
                    this.$refs.pdfModal.close()
                    this.activeHRDocument = null
                    this.$store.dispatch('STOP_LOADING')
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    bus.showError(errors.__all__)
                })
            }

        },
        parseYAML(yaml) {
            return YAML.safeLoad(yaml)
        },
    },
    filters: {
        yamlTitle(yaml) {
            return YAML.safeLoad(yaml).title
        },
    },
}
</script>
