<template>
    <div class="promo-tile promo-tile-get-the-app" :class="">
        <div class="promo-tile-inner">
            <div class="promo-tile-messaging">
                <div class="promo-tile-icon">
                    <img src="@/assets/img/app-icon-rounded.svg">
                </div>
                <h1 class="promo-tile-headline">
                    Get the <span class="text-nowrap">mobile app</span>
                </h1>
                <p class="promo-tile-subhead">
                    Fast &amp; Convenient Access
                </p>
            </div>

            <div class="promo-tile-cta">
                <div class="promo-tile-cta-primary">
                    <p>
                        <a href="https://www.getmypaystub.com/download-app/android">
                            <img src="@/assets/img/google-play-badge.svg">
                        </a>
                    </p>
                    <p>
                        <a href="https://www.getmypaystub.com/download-app/ios">
                            <img src="@/assets/img/apple-appstore-badge.svg">
                        </a>
                    </p>
                </div>
                <div class="promo-tile-cta-secondary">
                    <p>
                        <a class="d-inline-block py-hf" href @click.prevent="showSendLinkModal()">Send a link to my phone</a>
                    </p>
                </div>
            </div>
        </div>
        
        <send-link-modal ref="sendLinkModal" />
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'

import SendLinkModal from '@/components/SendLinkModal'

export default {
    components: {SendLinkModal,},
    methods: {
        showSendLinkModal() {
            this.$refs.sendLinkModal.open()
        },
    },
}
</script>
