<template>
    <div class="card card-body bg-150 m-0 py-1">
        <div class="text-small text-muted">

            <div class="form-row mb-1">
                <div class="col">
                    <span>{{ changelog.created_on|date('M/D/YYYY LT') }}</span>
                </div>
                <div class="col text-right">
                    <span>{{ changelog.created_from_ip }}</span>
                </div>
            </div>

            <div v-if="changelog.company_security_profile">
                <div class="text-semibold text-700 mb-hf">
                    Company
                </div>
                <div class="pl-2 mb-1">
                    {{ cspCompany.name }}
                </div>
            </div>

            <div v-if="changelog.change_description">
                {{ changelog.change_description }}
            </div>

            <div v-for="change in changedValues">
                <div class="text-semibold text-700 mb-hf">
                    {{ change.label }}
                </div>
                <div class="pl-2 mb-1">
                    <div class="form-row mb-hf">
                        <div class="col-3">
                            <span>New value:</span>
                        </div>
                        <div class="col-9">
                            <span>{{ change.new_value }}</span>
                        </div>
                    </div>
                    <div class="form-row mb-hf" v-if="change.old_value !== null && change.old_value !== ''">
                        <div class="col-3">
                            <span>Old value:</span>
                        </div>
                        <div class="col-9">
                            <span>{{ change.old_value }}</span>
                        </div>
                    </div>
                </div>

            </div>

            <div v-for="iterable in changedIterables">
                <div class="text-semibold text-700 mb-hf">{{ iterable.label }}</div>
                <div class="pl-2 mb-1">

                    <div class="form-row mb-hf" v-if="iterable.added.length">
                        <div class="col-3">
                            <span>Added:</span>
                        </div>
                        <div class="col-9">
                            <ul class="list-unstyled m-0">
                                <li v-for="(change, $index) in iterable.added" :class="{'border-top mt-1 pt-1': $index > 0}">
                                    <div class="form-row mb-hf" v-for="item in change" v-if="item.value !== '' && item.value !== null">
                                        <div class="col-4">
                                            <span>{{ item.label }}:</span>
                                        </div>
                                        <div class="col-8">
                                            <span>{{ item.value }}</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="form-row mb-hf mt-2" v-if="iterable.removed.length">
                        <div class="col-3">
                            <span>Removed:</span>
                        </div>
                        <div class="col-9">
                            <ul class="list-unstyled m-0">
                                <li v-for="(change, $index) in iterable.removed" :class="{'border-top mt-1 pt-1': $index > 0}">
                                    <div class="form-row mb-hf" v-for="item in change" v-if="item.value !== '' && item.value !== null">
                                        <div class="col-4">
                                            <span>{{ item.label }}:</span>
                                        </div>
                                        <div class="col-8">
                                            <span>{{ item.value }}</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>


<script>
import validate from 'validate.js'
import moment from 'moment'

export default {
    props: ['changelog', 'client', ],
    computed: {
        cspCompany() {
            if (!this.changelog.company_security_profile) {return null}

            return this.client.companies.find(c => c.id == this.changelog.company_security_profile.company)
        },
        changedValues() {
            const result = []
            const valuesChanged = this.changelog.diff.values_changed || {}
            const addedItems = this.changelog.diff.dictionary_item_added || {}
            const removedItems = this.changelog.diff.dictionary_item_removed || {}

            Object.keys(valuesChanged).forEach(key => {
                const val = valuesChanged[key]
                const label = this.makeLabel(key.match(/^root\['([^']*)'\]$/)[1])

                result.push({
                    label: label,
                    old_value: this.formatIfDate(val.old_value),
                    new_value: this.formatIfDate(val.new_value),
                })
            })

            Object.keys(addedItems).forEach(key => {
                const realKey = key.match(/^root\['([^']*)'\]$/)[1]
                if (realKey == 'is_active') {
                    return
                }

                const val = addedItems[key]
                const label = this.makeLabel(realKey)
                if (val instanceof Array) {return}

                if (!val) {return}

                result.push({
                    label: label,
                    old_value: '',
                    new_value: this.formatIfDate(val),
                })
            })

            Object.keys(removedItems).forEach(key => {
                const val = removedItems[key]
                const label = this.makeLabel(key.match(/^root\['([^']*)'\]$/)[1])
                if (val instanceof Array) {
                    return
                }

                result.push({
                    label: label,
                    old_value: this.formatIfDate(val),
                    new_value: '',
                })
            })

            return result
        },
        changedIterables() {
            const result = []
            const changes = {}
            const addedItems = this.changelog.diff.iterable_item_added || {}
            const removedItems = this.changelog.diff.iterable_item_removed || {}
            const addedLists = this.changelog.diff.dictionary_item_added || {}
            const removedLists = this.changelog.diff.dictionary_item_removed || {}

            const initKey = (realKey, label) => {
                changes[realKey] = {
                    label: label,
                    added: [],
                    removed: [],
                }
            }

            Object.keys(addedItems).forEach(key => {
                const val = addedItems[key]

                const realKey = key.match(/^root\['([^']*)'\]/)[1]
                const label = this.makeLabel(realKey)

                if (!changes[realKey]) {
                    initKey(realKey, label)
                }

                changes[realKey].added.push(this.makeSubset(realKey, val))
            })

            Object.keys(removedItems).forEach(key => {
                const val = removedItems[key]

                const realKey = key.match(/^root\['([^']*)'\]/)[1]
                const label = this.makeLabel(realKey)

                if (!changes[realKey]) {
                    initKey(realKey, label)
                }

                changes[realKey].removed.push(this.makeSubset(realKey, val))
            })

            Object.keys(addedLists).forEach(key => {
                const val = addedLists[key]
                if (!(val instanceof Array)) {return}
                const realKey = key.match(/^root\['([^']*)'\]$/)[1]
                const label = this.makeLabel(realKey)

                if (!changes[realKey]) {
                    initKey(realKey, label)
                }

                val.forEach(item => {
                    changes[realKey].added.push(this.makeSubset(realKey, item))
                })
            })

            Object.keys(removedLists).forEach(key => {
                const val = removedLists[key]
                if (!(val instanceof Array)) {return}
                const realKey = key.match(/^root\['([^']*)'\]$/)[1]
                const label = this.makeLabel(realKey)

                if (!changes[realKey]) {
                    initKey(realKey, label)
                }

                val.forEach(item => {
                    changes[realKey].removed.push(this.makeSubset(realKey, item))
                })
            })

            Object.keys(changes).forEach(key => {
                const val = changes[key]

                if (val.added.length || val.removed.length) {
                    result.push({
                        label: this.makeLabel(key),
                        added: val.added,
                        removed: val.removed,
                    })
                }
            })

            return result
        },
    },
    methods: {
        formatIfDate(val) {
            if ((typeof val == 'string') && (val.match(/^\d{4}-[01]\d-[0-3]\d$/))) {
                return moment(val).format('M/D/YYYY')
            }

            return val
        },
        makeLabel(label) {
            const KNOWN_LABELS = {
                aca_category: 'ACA Category',
                user_status_pretty: 'User Account',
                date_hired: 'Hire Date',
                pdf_file: 'PDF File',
                hr_documents: 'HR Documents',
                status_pretty: 'Status',
                updated_by_pretty: 'Updated By',
            }

            if (KNOWN_LABELS[label]) {
                return KNOWN_LABELS[label]
            }
            else {
                return validate.capitalize(validate.prettify(label))
            }
        },
        makeSubset(key, obj) {
            const item = []
            if (key == 'jobs') {
                item.push({label: 'Company', value: obj.company})
                item.push({label: 'Location', value: obj.location})
                item.push({label: 'Position', value: obj.position})
                item.push({label: 'Hourly rate', value: '$' + obj.hourly_rate})
                item.push({label: 'Default hours', value: obj.default_hours || ''})
                item.push({label: 'Pay type', value: obj.pay_type_pretty})
            }
            else {
                Object.keys(obj).forEach(k => {
                    item.push({
                        label: this.makeLabel(k),
                        value: this.formatIfDate(obj[k]),
                    })
                })
            }

            return item
        },
    },
}
</script>
