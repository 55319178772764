<template>
    <sidebar @close="$emit('close')">
        <template slot="title">
            {{ template.name }}
        </template>

        <template slot="header">
            <div class="row mb-1">
                <div class="col">
                    <span class="text-muted">
                        Updated on: {{ template.updated_on|date }}
                    </span>
                </div>
            </div>

            <!-- Tabs -->
            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'main'}" href="#tabContentMain1" id="tabLinkMain1" data-toggle="tab" role="tab" aria-controls="tabContentMain" :aria-selected="activeTab == 'main'">Main</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'changelogs'}" href="#tabContentHistory1" id="tabLinkHistory1" data-toggle="tab" role="tab" aria-controls="tabContentHistory" :aria-selected="activeTab == 'changelogs'">History</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot>
            <!-- Tabs Content Container -->
            <div class="tab-content">
                <!-- Tab Pane 1 -->
                <div class="tab-pane" :class="{'show active': activeTab == 'main'}" id="tabContentMain1" role="tabpanel" aria-labelledby="tabLinkMain1">

                    <!-- Documents Widget -->
                    <info-widget :collapsible="false">
                        <template slot="title">HR Documents</template>

                        <template slot="headerbutton">
                            <button type="button" class="btn btn-outline-primary" @click.prevent="$refs.editModal.open()">
                                Edit
                            </button>
                        </template>

                        <slot>
                            <div class="p-2">
                                <div class="info-widget-empty" v-if="!template.hr_documents">
                                    This template has no HR Documents.
                                </div>
                                <div v-if="template.hr_documents">
                                    <p class="mb-1">
                                        This template includes the following HR Documents:
                                    </p>

                                    <ul class="list-unstyled mb-0">
                                        <li :class="{'border-top': $index > 0}" v-for="(document, $index) in template.hr_documents" :key="document.id">
                                            <div class="form-row align-items-baseline py-3q">
                                                <div class="col">
                                                    <span>
                                                        <i class="far fa-fw fa-file-pdf mr-hf"></i>
                                                        {{ document.name }}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </slot>
                    </info-widget>

                    <!-- Assignment Widget -->
                    <info-widget :collapsible="false">
                        <template slot="title">Assignment</template>

                        <template slot="headerbutton">
                            <button type="button" class="btn btn-outline-primary" @click.prevent="$refs.editModal.open()">
                                Edit
                            </button>
                        </template>

                        <slot>
                            <div class="p-2">
                                <div class="info-widget-empty" v-if="!visiblePositionCompanies.length">
                                    This template is not assigned to anything.
                                </div>
                                <div v-if="visiblePositionCompanies.length">
                                    <p class="mb-1">
                                        This template is assigned to the following:
                                    </p>

                                    <ul class="list-unstyled mb-0" v-if="visiblePositionCompanies.length">
                                        <!-- Header row -->
                                        <li>
                                            <div class="form-row align-items-baseline py-1">
                                                <div class="col">
                                                    <h5 class="m-0">Position</h5>
                                                </div>
                                            </div>
                                        </li>

                                        <li v-for="cp in visiblePositionCompanies" :key="cp.company.id">
                                            <!-- Company heading -->
                                            <div class="text-small text-muted pt-1 pb-qt border-bottom mb-hf">
                                                <span>{{ cp.company.name }}</span>
                                            </div>

                                            <!-- Position rows -->
                                            <div class="form-row align-items-baseline py-hf" v-for="position in cp.positions" :key="position.id">
                                                <div class="col">
                                                    <span>{{ position.terminal_value }}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                    <div class="mt-1">
                                        <button v-if="!showAllPositions && !allPositionsVisible" type="button" class="btn btn-link btn-block" @click.prevent="showAllPositions = true">See all</button>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </info-widget>

                    <button class="btn btn-outline-primary btn-block my-2" @click.prevent="inactivate()" v-if="template.is_active">Deactivate Template</button>
                    <button class="btn btn-outline-primary btn-block my-2" @click.prevent="reactivate()" v-if="!template.is_active">Reactivate Template</button>
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'changelogs'}" id="tabContentHistory1" role="tabpanel" aria-labelledby="tabLinkHistory1">
                    <change-history :client="client" :changelogs="changelogs" :active-changelog-id="activeChangelogId"/>
                </div>
            </div>
        </slot>

        <edit-modal ref="editModal" :template="template" :client="client" @updated="$emit('updated', $event)" v-if="template" />
    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import InfoWidget from '@/components/InfoWidget'
import ChangeHistory from '@/components/ChangeHistory'
import EditModal from './HRTemplateModal'

export default {
    props: ['template', 'client', 'changelogs', 'tab', 'section', 'itemId', ],
    components: {Sidebar, InfoWidget, ChangeHistory, EditModal},
    computed: {
        positionCompanies() {
            const result = []
            const companies = {}
            this.template.positions.forEach(p => {
                if (!companies[p.company.id]) {
                    companies[p.company.id] = {
                        company: p.company,
                        positions: [],
                    }
                }

                companies[p.company.id].positions.push(p)
            })

            Object.keys(companies).forEach(key => {
                result.push(companies[key])
            })

            return result
        },
        visiblePositionCompanies() {
            if (this.showAllPositions) {
                return this.positionCompanies
            }

            const MAX_POSITIONS = 2
            const result = []
            let count = 0
            for (const cp of this.positionCompanies) {
                result.push({
                    company: cp.company,
                    positions: [],
                })

                for (const p of cp.positions) {
                    result[result.length - 1].positions.push(p)
                    count += 1
                    if (count >= MAX_POSITIONS) {
                        break
                    }
                }

                if (count >= MAX_POSITIONS) {
                    break
                }
            }

            return result
        },
        allPositionsVisible() {
            let count = 0
            for (const cp of this.visiblePositionCompanies) {
                count += cp.positions.length
            }

            return (count == this.template.positions.length)
        },
    },
    data() {
        return {
            showAllPositions: false,
            activeTab: this.tab,
            activeChangelogId: (this.tab == 'changelogs') ? this.itemId : null,
        }
    },
    methods: {
        close() {
            this.$emit('paymasters_close_sidebar')
        },
        reactivate() {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/hr-templates/${this.template.id}/status`, {status: 'active'}).then(resp => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        inactivate() {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/hr-templates/${this.template.id}/status`, {status: 'inactive'}).then(resp => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>

