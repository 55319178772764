<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4 pt-8 text-center">

                        <div class="icon-huge"><i class="fas fa-check"></i></div>
                        <h1 class="mb-4">You're done!</h1>
    
                        <p><router-link :to="{name: 'index'}" class="btn btn-primary">Go to My Account</router-link></p>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import api from '@/api'

export default {
    data() {
        return {
        }
    },
    methods: {
    },
}
</script>
