<template>
    <div v-show="dataLoaded">
        <div class="flex-page-content flex-container">
            <div class="flex-body">
                <div class="container-fluid">
                    <fast-table
                        :sections="sections"
                        :headers="headers"
                        :data="tableData"
                        v-bind:show-search="false"
                        v-bind:show-more-actions="false"
                        @click="onClick($event)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FastTable from '@/components/FastTable'

export default {
    components: {FastTable, },
    computed: {
        tableData() {
            const tableData = []

            this.tax_forms.forEach((d, idx) => {
                const row = {
                    id: idx,
                    object: d,
                    cells: [
                        d.document_title,
                        d.company ? d.company.name : d.ein_formatted,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            tax_forms: [],
            dataLoaded: false,

            headers: [
                {label: 'Document', classes: '', isSearchable: false},
                {label: 'Company', classes: 'cell-company', isSearchable: false},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tax Documents')
        this.getTaxForms()
    },
    methods: {
        onClick($event) {
            this.$router.push({name: 'employee-tax-form-detail', params: {taxFormId: $event.id}})
        },
        getTaxForms() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/me/tax-forms`).then(resp => {
                this.tax_forms = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
    },
}

</script>
