<template>
    <div v-if="paycheck">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                    <!-- Back navigation -->
                    <div class="border-bottom pt-2 pb-1 mb-4 d-print-none">
                      <span><router-link :to="{name: 'employee-paychecks'}" class="btn btn-link">
                        <i class="far fa-chevron-left"></i> Back
                      </router-link></span>
                    </div>
                    <!-- Paycheck container area -->
                    <div class="container-paystub mx-auto printable-item">
                        <div class="row">
                            <div class="col-12">
                              
                              <div class="row mb-2">
                                <div class="col-sm-8 col-md-6">
                                  <h1 class="mb-2">
                                    Earnings Statement 
                                    <!-- <br><span class="text-thin">
                                        {{ paycheck.check_data.checkDate|date('dddd MM/DD/YYYY') }}
                                    </span> -->
                                  </h1>
                                </div>
                                <div class="col-sm-4 col-md-6 text-sm-right mb-2">
                                  <button type="button" class="btn btn-primary" @click.prevent="$refs.paycheckComponent.printToPDF()">
                                      <i class="far fa-fw fa-file-pdf mr-hf"></i> <span class="btn-text">PDF</span>
                                  </button>
                                </div>
                              </div>
                              
                              <paycheck :paycheck="paycheck" ref="paycheckComponent" />
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import Paycheck from '@/components/Paycheck'

export default {
    components: {Paycheck, },
    data() {
        return {
            paycheck: null,
        }
    },
    mounted() {
        this.getPaycheck()
        this.$store.dispatch('SET_PAGE_TITLE', 'Pay Stub')
    },
    methods: {
        getPaycheck() {
            this.$store.dispatch('START_LOADING')
            api.get(`/me/paychecks/${this.$route.params.paycheckId}`).then(resp => {
                this.paycheck = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
    },
}
</script>
