<template>
    <div>
        <table-page :client="client" :warnings="warnings" :changelogs="changelogs" @warnings_updated="getWarnings" @changelog_date_filter_change="onChangelogDateRangeChange" @changelog_click="onChangelogClick">
            <fast-table
                :sections="sections"
                :headers="headers"
                :data="tableData"
                @click="currentTemplateId = $event.id"
                :use-date-filters="true"
                @date_filter_change="onDateFilter"
            >
                <template slot="table-actions">
                    <button type="button" class="btn btn-primary btn-collapse" @click.prevent="$refs.addModal.open()">
                        <i class="far fa-fw fa-plus"></i> <span class="btn-text">Add Template</span>
                    </button>
                </template>
            </fast-table>

            <hr-template-modal :client="client" ref="addModal" @updated="getTemplates()"/>
        </table-page>

        <sidebar
            :template="currentTemplate"
            :changelogs="currentTemplateChangelogs"
            v-if="currentTemplate"
            ref="sidebar"
            :client="client"
            @updated="onUpdate($event)"
            @close="currentTemplateId = null; sidebarTab = 'main'; currentChangelogId = null;"
            :tab="sidebarTab"
            :item-id="currentChangelogId"
        />
    </div>
</template>

<script>
import FastTable from '@/components/FastTable'
import HrTemplateModal from './components/HRTemplateModal'
import Sidebar from './components/TemplateSidebar'
import TablePage from '@/components/TablePage'
import moment from 'moment'

export default {
    components: {FastTable, HrTemplateModal, Sidebar, TablePage, },
    props: ['client', ],
    computed: {
        currentTemplate() {
            return this.templates.find(t => t.id == this.currentTemplateId)
        },
        currentTemplateChangelogs() {
            if (!this.currentTemplate) {return []}

            return this.changelogs.filter(c => c.hr_template && c.hr_template.id == this.currentTemplateId)
        },
        activeTemplates() {
            return this.templates.filter(t => t.is_active)
        },
        inactiveTemplates() {
            return this.templates.filter(t => !t.is_active)
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.activeTemplates,
                this.inactiveTemplates,
            ]

            subsets.forEach(templates => {
                const sectionData = []

                templates.forEach((t, idx) => {
                    sectionData.push({
                        id: idx,
                        isActive: this.currentTemplateId && t.id == this.currentTemplateId,
                        object: t,
                        cells: [
                            t.name,
                            t.company ? t.company.name : '---',
                            moment(t.updated_on).toDate(),
                            t.updated_by_pretty,
                            t.hr_documents.length,
                            t.positions.length,
                        ],
                    })
                })
                tableData.push(sectionData)
            })
            return tableData
        },
    },
    data() {
        return {
            templates: [],
            dataLoaded: false,
            currentTemplateId: null,
            dateRanges: {},

            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Company', classes: 'cell-company', isSearchable: false, isFilterable: true},
                {label: 'Last Modified', classes: 'cell-date', isSearchable: false, type: 'date'},
                {label: 'Modified By', classes: 'cell-name', isSearchable: true},
                {label: 'Documents', classes: 'cell-medium', isSearchable: false},
                {label: 'Positions', classes: 'cell-medium', isSearchable: false},
            ],
            sections: [
                {title: 'Active Templates', defaultIsClosed: false, id: 'active', hasDateFilter: false},
                {title: 'Inactive Templates', defaultIsClosed: false, id:'inactive', hasDateFilter: true},
            ],
            warnings: null,
            changelogs: null,
            changelogDateRangeStart: null,
            changelogDateRangeEnd: null,
            currentChangelogId: null,
            sidebarTab: 'main',
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Settings / HR Templates')
        this.getTemplates()
        this.getWarnings()
        this.getChangelogs()
    },
    methods: {
        getTemplates() {
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/hr-templates`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.$api.get(url).then(resp => {
                this.templates = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((error) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(error)
            })
        },
        onUpdate(tmpl) {
            const existingTemplate = this.templates.find(t => t.id == tmpl.id)
            if (existingTemplate) {
                Object.assign(existingTemplate, tmpl)
            }
            else {
                this.templates.push(tmpl)
            }

            this.getChangelogs()
            this.getWarnings()
        },
        getWarnings() {
            this.warnings = []  // XXX: This tile does not have a warnings table defined yet
        },
        getChangelogs() {
            let url = `/clients/${this.client.id}/changelogs`
            if (this.changelogDateRangeStart && this.changelogDateRangeEnd) {
                url = `${url}?date-filter.changelogs.start=${encodeURIComponent(this.changelogDateRangeStart)}&date-filter.changelogs.end=${encodeURIComponent(this.changelogDateRangeEnd)}`
            }

            this.$api.get(url).then(resp => {
                this.changelogs = resp.filter(c => c.hr_template)
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getTemplates()
        },
        onChangelogDateRangeChange(range) {
            this.changelogDateRangeStart = range.start
            this.changelogDateRangeEnd = range.end
            this.getChangelogs()
        },
        onChangelogClick(changelog) {
            this.currentTemplateId = changelog.hr_template.id
            this.currentChangelogId = changelog.id
            this.sidebarTab = 'changelogs'
        },
    },
}

</script>
