<template>
    <portal :to="modalContainer">
        <div class="modal show" style="display: block" :class="modalBaseClass" tabindex="-1" role="dialog"  ref="modal" v-if="isOpen">
            <div class="modal-dialog" :class="modalDialogClass" role="document">
                <div class="modal-content" :class="modalHeightClass">
                    <div class="modal-header">
                        <div class="d-flex w-100 flex-wrap">
                            <div class="modal-header-titlebar py-hf">
                                <h3 class="modal-title btnheight-min"><slot name="title"></slot></h3>
                            </div>
                            <button type="button" class="close" aria-label="Close" @click.prevent="close()" v-if="!hideXButton">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="modal-header-buttonbar py-hf">
                                <slot name="headerbuttons"></slot>
                            </div>
                        </div>
                        <div class="w-100">
                            <slot name="subheading">
                            </slot>
                        </div>
                    </div>
                    <div class="modal-body d-flex flex-column" style="background-image: none;">
                        <div class="flex-grow-1 modal-scroll-area" :class="modalBodyInnerClass" ref="scrollArea">
                            <slot></slot>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
import $ from 'jquery'

export default {
    props: {
        modalContainer: {
            type: String,
            default: "globalModal",
        },
        isCloseable: {
            type: Boolean,
            default: true,
        },
        autoshow: {
            type: Boolean,
            default: false,
        },
        fade: {
            type: Boolean,
            default: true,
        },
        size: {
            default: '',
        },
        vCenter: {
            type: Boolean,
            default: false,
        },
        heightMax: {
            type: Boolean,
            default: false,
        },
        hideXButton: {
            type: Boolean,
            default: false,
        },
        bodyInnerClass: {
            default: '',
        },
        options: {
            type: Object,
            default() {
                return {
                    backdrop: false,
                    keyboard: true,
                }
            },
        },
    },
    data() {
        return {
            isOpen: this.autoshow
        }
    },
    watch: {
        isCloseable() {
            this.$store.dispatch('SET_CLOSEABLE', this.isCloseable)
        },
    },
    mounted() {
        $(window).on('resize', this.resize)
    },
    computed: {
        modalBaseClass() {
            const classes = []
            if (this.fade === true) {
                classes.push('fade')
            }

            if (this.autoshow) {
                classes.push('show autoshow')
            }

            return classes.join(' ')
        },
        modalDialogClass() {
            var dialogClasses = ''
            if (this.size) {
                dialogClasses += ' modal-' + this.size
            }
            if (this.vCenter) {
              dialogClasses += ' modal-dialog-centered'
            }
            return dialogClasses
        },
        modalHeightClass() {
            if (this.heightMax) {
                return 'modal-height-max'
            }
            return ''
        },
        modalBodyInnerClass() {
            return this.bodyInnerClass
        },
    },
    methods: {
        open() {
            this.isOpen = true
        },
        close() {
            if (!this.isCloseable) {
                if (!confirm('You have not saved this form. Are you sure you want to close it?')) {
                    return
                }
            }
            this.isOpen = false
            this.$emit('close')
        },
        scrollBottom() {
            this.$nextTick(() => {
                const $scrollArea = $('.modal-scroll-area')
                $scrollArea.parent().scrollTop($scrollArea.height() * 2)
            })
        },
        resize() {
            this.$nextTick(() => {
                $('.modal').modal('handleUpdate')
            })
        },
    },
}
</script>

<style scoped lang="scss">
.modal.autoshow {
    display: block;
}

.modal .modal-content {
    max-height: 90vh;
}
</style>
