<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container mx-auto">

                    <div class="row py-4">
                        <div class="col-md-7 col-lg-5 offset-lg-2 col-xl-4 offset-xl-3 mb-4">
                            <h1 class="mb-4 text-center">Sign In</h1>

                            <form @submit.prevent="onSubmit" novalidate class="mui-form">
                                <form-errors :errors="formErrors"/>

                                <div class="form-row">
                                    <div class="col-12">
                                        <form-input placeholder="Email Address" v-model="formData.username" :errors="errors.username" type="email" autocomplete="email" name="email" />
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="col-12">
                                        <form-input placeholder="Password" v-model="formData.password" :errors="errors.password" type="password" autocomplete="current-password" name="password" />
                                    </div>
                                </div>

                                <div class="my-2">
                                    <button type="submit" class="btn btn-primary btn-block">Continue</button>
                                </div>

                                <div class="text-center">
                                    <router-link :to="{name: 'forgot-password'}" class="btn btn-link">Forgot your password?</router-link>
                                </div>

                            </form>

                            <hr>
                            <div class="text-center mt-2">
                                Don't have an account yet? <router-link :to="{name: 'signup'}" class="btn btn-outline-primary ml-hf">Register</router-link>
                            </div>

                            <br>
                            <div class="text-center mt-2">
                                <a href @click.prevent="showPrivacy()">Privacy Policy</a>
                            </div>
                        </div>

                        <div class="col-md-5 col-lg-5 col-xl-4 mb-4">
                            <div class="hide-in-native">
                                <promo-tile class="promo-tile-tall-md promo-tile-thin mx-auto"></promo-tile>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <privacy-modal ref="privacyModal" />
    </div>
</template>

<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import PrivacyModal from '@/components/Policies/PrivacyModal'
import PromoTile from '@/components/PromoTile'

export default {
    mixins: [FormMixin],
    components: {
        FormInput,
        FormErrors,
        PrivacyModal, 
        PromoTile, 
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Login')
        // reset isInApp - this really should only get set to true
        // in a weird dev situation, but reset it here just in case
        this.$store.dispatch('SET_IS_IN_APP', false)
    },
    data() {
        return {
            formRules: {
                username: {
                    email: true,
                    presence: true,
                },
                password: {
                    presence: true,
                },
            },
            formLabels: {
                username: 'Email Address',
            },
            formData: {
                username: '',
                password: '',
            },
        }
    },
    methods: {
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('LOGIN', this.getFormData()).then((resp) => {
                this.$router.push({name: 'login-validate-email', query: {next: this.$route.query.next || encodeURIComponent('/')}})
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
            })
        },
        showPrivacy() {
            this.$refs.privacyModal.open()
        },
    }
}
</script>

