<template>
    <div class="flex-container flex-body">
        <div>
            <div class="py-1">
                Select the positions that will use this template. (Existing template assignments will be replaced.)
            </div>
            <!-- Tab links -->
            <div class="nav-tabs-container d-md-none" id="">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tabsHrTstep3content1" id="tabsHrTstep3link1" data-toggle="tab" role="tab" aria-controls="tabsHrTstep3content1" aria-selected="true">
                                Available ({{ allPositions.length }})
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tabsHrTstep3content2" id="tabsHrTstep3link2" data-toggle="tab" role="tab" aria-controls="tabsHrTstep3content2" aria-selected="false">
                                Selected ({{ checkedPositions.length }})
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="d-flex flex-grow-1 flex-column">

            <!-- Tabs container -->
            <div class="tab-content tab-content-flex-md row flex-grow-1 mb-2">

                <div class="tab-pane col-12 col-md-7 flex-column active" id="tabsHrTstep3content1" role="tabpanel" aria-labelledby="tabsHrTstep3link1">

                    <!-- Nested vertical flexbox component (left) -->

                    <!-- Standalone container -->
                    <div class="flex-grow-1 d-flex flex-column">

                        <h5 class="d-none d-md-block pt-1">Available <span class="text-regular">({{ allPositions.length }})</span></h5>

                        <!-- Card outer -->
                        <div class="card card-body flex-grow-1 d-flex flex-column p-0 mt-1 mt-md-0">
                            <!-- Sticky-top search bar -->
                            <div class="flex-grow-0 flex-shrink-0 d-flex p-1">
                                <page-search v-model="search" />
                            </div>
                            <!-- Sticky-top column headings -->
                            <div class="flex-grow-0 d-none d-sm-flex px-1 pt-hf align-items-baseline">
                                <div class="form-row flex-grow-1">
                                    <div class="col-12 col-sm-8">
                                        <h5 class="theading mb-hf">Position</h5>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <h5 class="theading mb-hf">Template</h5>
                                    </div>
                                </div>
                            </div>
                            <!-- Scrollable content container -->
                            <div class="flex-grow-1 overflow-scroll-y position-relative">
                                <!-- Inner scrolling content -->
                                <div class="flex-grow-1 w-100 px-1 pb-2 position-absolute">

                                    <!-- Company block -->
                                    <div v-for="cp in positionsByCompany" :key="cp.company.id">
                                        <!-- Company heading -->
                                        <div class="text-small text-muted pt-1 pb-qt border-bottom mb-hf">
                                            <span>{{ cp.company.name }}</span>
                                        </div>

                                        <!-- Position rows -->
                                        <div class="form-row" v-for="p in cp.positions" :key="p.id">
                                            <div class="col-12 col-sm-8">
                                                <div class="py-hf">
                                                    <div class="form-check">
                                                        <label class="form-check-label pl-qt" :for="'available-position-' + p.id">
                                                            <input type="checkbox" class="form-check-input" :id="'available-position-' + p.id" :checked="checkedPositions.indexOf(p.id) >= 0" @input="onInput(p.id)">
                                                            {{ p.terminal_value }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <div class="py-hf pt-3q pl-4 pl-sm-0 unpad-t mt-sm-0 text-small text-700">
                                                    <span class="d-sm-none">Existing Template: </span>
                                                    <span v-if="p.hr_template">{{ p.hr_template.name }}</span>
                                                    <span v-if="!p.hr_template">&mdash;</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <!-- Sticky-bottom toggle bar -->
                            <nav class="flex-grow-0 border-top">
                                <ul class="nav nav-pills nav-justified list-filter-toggle rounded-bottom">
                                    <li class="nav-item">
                                        <a class="nav-link" href :class="{'active': onlyNoTemplate}"  @click.prevent="onlyNoTemplate = true">No template ({{ noTemplatePositions.length }})</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href :class="{'active': !onlyNoTemplate}" @click.prevent="onlyNoTemplate = false">Everything ({{ allPositions.length }})</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <!-- Sticky-bottom 'select all' button -->
                        <div class="flex-grow-0 pt-1">
                            <button class="btn btn-outline-primary btn-block" @click.prevent="selectAll()">
                                Select All ({{ allPositions.length }})
                            </button>
                        </div>
                    </div>

                    <!-- /(left) -->

                </div>

                <div class="tab-pane col-12 col-md-5 flex-column" id="tabsHrTstep3content2" role="tabpanel" aria-labelledby="tabsHrTstep3link2">

                    <!-- Nested vertical flexbox component (right) -->

                    <!-- Standalone container -->
                    <div class="flex-grow-1 d-flex flex-column">

                        <h5 class="d-none d-md-block pt-1">Selected <span class="text-regular">(2)</span></h5>

                        <!-- Card outer -->
                        <div class="card card-body flex-grow-1 d-flex flex-column p-0 mt-1 mt-md-0 bg-150">

                            <!-- Scrollable content container -->
                            <div class="flex-grow-1 d-flex flex-column overflow-scroll-y position-relative">

                                <!-- When empty, use this instead of the scrollable content container -->
                                <div class="px-4 align-self-center my-auto" v-if="!checkedPositions.length">
                                    <div class="text-center text-muted">
                                        <em>Select items under Available to add them here.</em>
                                    </div>
                                </div>

                                <!-- Inner scrolling content -->
                                <div class="flex-grow-1 w-100 px-1 pb-2 position-absolute" v-if="checkedPositions.length">

                                    <!-- Company block -->
                                    <div v-for="cp in selectedPositionsByCompany" :key="cp.company.id">
                                        <!-- Company heading -->
                                        <div class="text-small text-muted pt-1 pb-qt border-bottom mb-hf">
                                            <span>{{ cp.company.name }}</span>
                                        </div>
                                        <!-- Position rows -->
                                        <div class="form-row" v-for="p in cp.positions" :key="p.id">
                                            <div class="col-12">
                                                <div class="py-hf">
                                                    <div class="form-check">
                                                        <label class="form-check-label pl-qt" :for="'selected-position-' + p.id">
                                                            <input type="checkbox" class="form-check-input" :id="'selected-position-' + p.id" checked disabled>
                                                            {{ p.terminal_value }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <!-- /(right) -->
            </div>
        </div>
    </div>
</template>

<script>
import * as JsSearch from 'js-search'

export default {
    props: ['allPositions', 'value', ],
    computed: {
        searchIndex() {
            const searchIndex = new JsSearch.Search('id')
            searchIndex.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
            searchIndex.addIndex('terminal_value')
            searchIndex.addIndex(['company', 'name'])

            searchIndex.addDocuments(this.visiblePositions || [])
            return searchIndex
        },
        selectedPositions() {
            return this.allPositions.filter(p => {return this.checkedPositions.indexOf(p.id) >= 0})
        },
        visiblePositions() {
            if (this.onlyNoTemplate) {
                return this.noTemplatePositions
            }

            return this.allPositions
        },
        searchedPositions() {
            if (!this.search.trim()) {
                return this.visiblePositions
            }
            return this.searchIndex.search(this.search.trim())
        },
        noTemplatePositions() {
            return this.allPositions.filter(p => {return !p.hr_template})
        },
        selectedPositionsByCompany() {
            return this.getPositionsByCompany(this.selectedPositions)
        },

        positionsByCompany() {
            return this.getPositionsByCompany(this.searchedPositions)
        },
    },
    data() {
        return this.makeData()
    },
    methods: {
        selectAll() {
            this.checkedPositions.splice(0, this.checkedPositions.length)
            this.allPositions.forEach(p => {
                this.checkedPositions.push(p.id)
            })
            this.$nextTick(() => {
                this.$emit('input', this.checkedPositions)
            })
        },
        makeData() {
            const data = {
                search: '',
                checkedPositions: [],
                onlyNoTemplate: false,
            }

            this.value.forEach(id => {
                data.checkedPositions.push(id)
            })

            return data
        },
        reset() {
            const data = this.makeData()
            this.search = data.search
            this.checkedPositions = data.checkedPositions
        },
        onInput(id) {
            const idx = this.checkedPositions.indexOf(id)
            if (idx >= 0) {
                this.checkedPositions.splice(idx, 1)
            }
            else {
                this.checkedPositions.push(id)
            }

            this.$nextTick(() => {
                this.$emit('input', this.checkedPositions)
            })
        },
        getPositionsByCompany(positions) {
            const mapping = {}
            const companies = []
            positions.forEach(p => {
                if (!mapping[p.company.id]) {
                    mapping[p.company.id] = []
                    companies.push(p.company)
                }

                mapping[p.company.id].push(p)
            })

            const result = []
            companies.forEach(c => {
                result.push({
                    'company': c,
                    'positions': mapping[c.id]
                })
            })

            return result
        },
    },
}
</script>
