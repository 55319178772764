<script>
const ONBOARDING_ORDER = [
    'onboarding-wotc',
    'onboarding-hr-info',
    'onboarding-address',
    'onboarding-eeo-info',
    'onboarding-form-selection',
    'onboarding-forms',
    'onboarding-direct-deposit',
    'onboarding-complete',
]

export default {
    computed: {
        routePrefix() {
            return this.$route.name.split('-')[0]
        },
        strippedRouteName() {
            return this.$route.name.replace(new RegExp(`^${this.routePrefix}-`), '')
        },
        previousPage() {
            const prev = ONBOARDING_ORDER[ONBOARDING_ORDER.indexOf(this.strippedRouteName) - 1]
            if (!prev) {
                return {name: 'employee-home'}
            }
            if ((prev == 'onboarding-wotc') && (! this.onboardingApplication.has_wotc_step)) {
                return {name: 'employee-home'}
            }
            return {name: `${this.routePrefix}-${prev}`, params: {appId: this.onboardingApplication.id}, query: {prev: 1}}
        },
        nextPage() {
            const next = ONBOARDING_ORDER[ONBOARDING_ORDER.indexOf(this.strippedRouteName) + 1]
            if (!next) {
                let completionRoute = {name: 'employee-home'}
                if (this.routePrefix == 'admin') {
                    completionRoute = {name: 'onboarding', params: {clientId: this.onboardingApplication.company.client}}
                }
                return completionRoute
            }
            return {name: `${this.routePrefix}-${next}`, params: {appId: this.onboardingApplication.id}}
        },
    },
}
</script>
