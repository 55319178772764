<template>
    <sidebar @close="$emit('close')">
        <template slot="title">
            {{ integration.client.name }}
        </template>

        <template slot="header">
            <div class="row mb-1">
                <div class="col">
                    <span class="text-muted">Client {{ integration.client.client_code }}</span>
                </div>
            </div>
        </template>

        <slot>
            <div class="card mb-2">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Integration Info</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.editModal.open()">Edit</button>
                </div>

                <div class="card-body py-1">
                    <div class="mui-textfield">
                        <span class="textfield">{{ integration.get_pos_display }}</span>
                        <label>POS</label>
                    </div>

                    <div class="mui-textfield">
                        <span class="textfield">{{ integration.label }}</span>
                        <label>Label</label>
                    </div>

                    <div class="mui-textfield">
                        <span class="textfield">{{ integration.ref_code }}</span>
                        <label>Reference Code</label>
                    </div>

                    <div class="mui-textfield">
                        <span class="textfield" v-if="integration.apply_to_stores_default">Yes</span><span v-else>No</span>
                        <label>Default Integration</label>
                    </div>
                </div>
            </div>

            <div class="card mb-2">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">POS Connection</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.connectionModal.open()">Edit</button>
                </div>

                <div class="card-body py-1">
                    <div class="mui-textfield" v-if="['lc', 'sicom', 'macromatix'].includes(integration.pos)">
                        <span class="textfield">{{ integration.pos_url }}</span>
                        <label>URL</label>
                    </div>

                    <div class="mui-textfield" v-if="['lc', 'lc_alt', 'sicom', 'macromatix', 'nbo'].includes(integration.pos)">
                        <span class="textfield">{{ integration.pos_username }}</span>
                        <label>Username</label>
                    </div>

                    <div class="mui-textfield" v-if="['lc', 'lc_alt', 'sicom', 'macromatix', 'nbo'].includes(integration.pos)">
                        <span class="textfield">{{ integration.pos_password_masked }}</span>
                        <label>Password</label>
                    </div>

                    <div class="mui-textfield" v-if="['sicom'].includes(integration.pos)">
                        <span class="textfield">{{ integration.sicom_custom_report_id }}</span>
                        <label>SICOM Custom Report ID</label>
                    </div>

                    <div class="mui-textfield" v-if="['qsr'].includes(integration.pos)">
                        <span class="textfield">{{ integration.qsr_client_number }}</span>
                        <label>QSR Client Number</label>
                    </div>
                </div>

                <div v-if="integration.pos_credentials.length > 0">
                    <h5 class="ml-2 mb-0" v-if="integration.pos_credentials">Additional Credentials</h5>

                    <div class="card card-body item-details p-2 m-2" v-for="credential in integration.pos_credentials">
                        <div class="mui-textfield">
                            <span class="textfield"><b>{{ credential.ref_code }}</b></span>
                            <label>Reference Code</label>
                        </div>

                        <div class="mui-textfield" v-if="['lc', 'sicom', 'macromatix'].includes(integration.pos)">
                            <span class="textfield">{{ credential.pos_url }}</span>
                            <label>URL</label>
                        </div>

                        <div class="mui-textfield" v-if="['lc', 'lc_alt', 'sicom', 'macromatix', 'nbo'].includes(integration.pos)">
                            <span class="textfield">{{ credential.pos_username }}</span>
                            <label>Username</label>
                        </div>

                        <div class="mui-textfield" v-if="['lc', 'lc_alt', 'sicom', 'macromatix', 'nbo'].includes(integration.pos)">
                            <span class="textfield">{{ credential.pos_password_masked }}</span>
                            <label>Password</label>
                        </div>
                    </div>
                </div>

                <div v-if="integration.pos_credentials_last_updated_on" class="card-body py-0 mb-2">
                    <small>
                        Updated {{ integration.pos_credentials_last_updated_on|date('M/D/YYYY LT') }} by {{ integration.pos_credentials_last_updated_by.email }}
                    </small>
                </div>
            </div>

            <div class="card mb-2">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Hours Import</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.hoursImportModal.open()">Edit</button>
                </div>

                <div class="card-body py-1">
                    <div class="mui-textfield">
                        <span class="textfield" v-if="integration.has_hours_importer">Yes</span><span v-else>No</span>
                        <label>Enabled</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer">
                        <span class="textfield">{{ integration.pos_default_company_code }}</span>
                        <label>Default company code</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer">
                        <span class="textfield">{{ integration.pos_default_paygroup }}</span>
                        <label>Default paygroup</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer">
                        <span class="textfield">{{ integration.get_matching_method_display }}</span>
                        <label>Matching Method</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer">
                        <span class="textfield" v-if="integration.pos_do_mismatch_check">Yes</span><span v-else>No</span>
                        <label>Check for Name Mismatches</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer">
                        <span class="textfield" v-if="integration.pos_use_payrate_in_hours_import">Yes</span><span v-else>No</span>
                        <label>Use Rate of Pay from POS</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer && ['lc', 'qsr', 'sicom'].includes(integration.pos)">
                        <span class="textfield" v-if="integration.pos_check_floaters">Yes</span><span v-else>No</span>
                        <label>Flag Floater OT</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer && ['lc', 'qsr', 'sicom'].includes(integration.pos)">
                        <span class="textfield" v-if="integration.pos_ignore_salaried_hours">Yes</span><span v-else>No</span>
                        <label>Ignore Salaried Hours</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer && ['qsr'].includes(integration.pos)">
                        <span class="textfield">{{ integration.qsr_mischrs_earning_code }}</span>
                        <label>Misc Hrs Earning Code</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer && ['qsr'].includes(integration.pos)">
                        <span class="textfield">{{ integration.qsr_miscamt_earning_code }}</span>
                        <label>Misc Amt</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer && ['macromatix'].includes(integration.pos)">
                        <span class="textfield">{{ integration.pos_special_company_emp_id_prefix }}</span>
                        <label>Override Company ID Prefix</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer && ['macromatix'].includes(integration.pos)">
                        <span class="textfield">{{ integration.pos_special_company_company_code }}</span>
                        <label>Override Company Code</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer && ['lc_alt'].includes(integration.pos)">
                        <span class="textfield" v-if="integration.pos_only_posted_hours">Yes</span><span v-else>No</span>
                        <label>Only import posted hours</label>
                    </div>

                    <h5 class="mt-2 mb-2" v-if="integration.has_hours_importer">Org Level Mappings</h5>

                    <div class="mui-textfield" v-if="integration.has_hours_importer">
                        <span class="textfield">{{ integration.org_level_1_pos_hours_import_field }}</span>
                        <label>Org Level 1</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer">
                        <span class="textfield">{{ integration.org_level_2_pos_hours_import_field }}</span>
                        <label>Org Level 2</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer">
                        <span class="textfield">{{ integration.org_level_3_pos_hours_import_field }}</span>
                        <label>Org Level 3</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer">
                        <span class="textfield">{{ integration.org_level_4_pos_hours_import_field }}</span>
                        <label>Org Level 4</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.has_hours_importer">
                        <span class="textfield">{{ integration.org_level_5_pos_hours_import_field }}</span>
                        <label>Org Level 5</label>
                    </div>
                </div>
            </div>

            <div class="card mb-2" v-if="['qsr', 'sicom'].includes(integration.pos)">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">New Hires Import</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.newHireImportModal.open()">Edit</button>
                </div>

                <div class="card-body py-1">
                    <div class="mui-textfield">
                        <span class="textfield" v-if="integration.pos_do_new_hires">Yes</span><span v-else>No</span>
                        <label>Enabled</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.pos_do_new_hires">
                        <span class="textfield">{{ integration.get_default_pay_frequency_display }}</span>
                        <label>Default pay frequency</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.pos_do_new_hires">
                        <span class="textfield">{{ integration.default_normal_hours }}</span>
                        <label>Default normal hours</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.pos_do_new_hires">
                        <span class="textfield">{{ integration.payrate_warning_threshold }}</span>
                        <label>Pay rate warning threshold</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.pos_do_new_hires">
                        <span class="textfield" v-if="integration.pos_do_addl_withholding_check">Yes</span><span v-else>No</span>
                        <label>Flag Additional Withholding Elections</label>
                    </div>


                    <h5 class="mt-2 mb-2" v-if="integration.pos_do_new_hires">Org Level Mappings</h5>

                    <div class="mui-textfield" v-if="integration.pos_do_new_hires">
                        <span class="textfield">{{ integration.org_level_1_pos_employee_import_field }}</span>
                        <span class="textfield pl-2" v-if="integration.org_level_1_pos_employee_import_field_default"><b>Default:</b> {{ integration.org_level_1_pos_employee_import_field_default }}</span>
                        <label>Org Level 1</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.pos_do_new_hires">
                        <span class="textfield">{{ integration.org_level_2_pos_employee_import_field }}</span>
                        <span class="textfield pl-2" v-if="integration.org_level_2_pos_employee_import_field_default"><b>Default:</b> {{ integration.org_level_2_pos_employee_import_field_default }}</span>
                        <label>Org Level 2</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.pos_do_new_hires">
                        <span class="textfield">{{ integration.org_level_3_pos_employee_import_field }}</span>
                        <span class="textfield pl-2" v-if="integration.org_level_3_pos_employee_import_field_default"><b>Default:</b> {{ integration.org_level_3_pos_employee_import_field_default }}</span>
                        <label>Org Level 3</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.pos_do_new_hires">
                        <span class="textfield">{{ integration.org_level_4_pos_employee_import_field }}</span>
                        <span class="textfield pl-2" v-if="integration.org_level_4_pos_employee_import_field_default"><b>Default:</b> {{ integration.org_level_4_pos_employee_import_field_default }}</span>
                        <label>Org Level 4</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.pos_do_new_hires">
                        <span class="textfield">{{ integration.org_level_5_pos_employee_import_field }}</span>
                        <span class="textfield pl-2" v-if="integration.org_level_5_pos_employee_import_field_default"><b>Default:</b> {{ integration.org_level_5_pos_employee_import_field_default }}</span>
                        <label>Org Level 5</label>
                    </div>
                </div>
            </div>

            <div class="card mb-2" v-if="['lc', 'lc_alt'].includes(integration.pos)">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">New Hire Sync (to POS)</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.newHirePOSSyncModal.open()">Edit</button>
                </div>

                <div class="card-body py-1">
                    <div class="mui-textfield">
                        <span class="textfield" v-if="integration.pos_new_hire_sync">Yes</span><span v-else>No</span>
                        <label>Enabled</label>
                    </div>

                    <div class="mui-textfield" v-if="integration.pos_new_hire_sync">
                        <span class="textfield">{{ integration.pos_new_hire_default_position }}</span>
                        <label>POS Default Position</label>
                    </div>
                </div>
            </div>

            <pos-edit-modal v-if="integration" ref="editModal" :integration="integration" :client="client" @updated="$emit('updated', $event)" />

            <pos-connection-modal v-if="integration" ref="connectionModal" :integration="integration" :client="client" @updated="$emit('updated', $event)" />

            <pos-hours-import-modal v-if="integration" ref="hoursImportModal" :integration="integration" :client="client" @updated="$emit('updated', $event)" />

            <pos-new-hire-import-modal v-if="integration" ref="newHireImportModal" :integration="integration" :client="client" @updated="$emit('updated', $event)" />

            <pos-new-hire-pos-sync-modal v-if="integration" ref="newHirePOSSyncModal" :integration="integration" :client="client" @updated="$emit('updated', $event)" />

            <button class="btn btn-outline-primary btn-block my-2" @click.prevent="inactivate()" v-if="integration.is_active">Deactivate Integration</button>
            <button class="btn btn-outline-primary btn-block my-2" @click.prevent="reactivate()" v-if="!integration.is_active">Reactivate Integration</button>
        </slot>
    </sidebar>
</template>

<script>
import POSAddEditIntegrationModal from './POSAddEditIntegrationModal'
import POSConnectionModal from './POSConnectionModal'
import POSHoursImportModal from './POSHoursImportModal'
import POSNewHireImportModal from './POSNewHireImportModal'
import POSNewHirePOSSyncModal from './POSNewHirePOSSyncModal'
import Sidebar from '@/components/Sidebar'

export default {
    components: {
        PosEditModal: POSAddEditIntegrationModal,
        PosConnectionModal: POSConnectionModal,
        PosHoursImportModal: POSHoursImportModal,
        PosNewHireImportModal: POSNewHireImportModal,
        PosNewHirePosSyncModal: POSNewHirePOSSyncModal,
        Sidebar,
    },
    props: ['integration', 'client', ],
    methods: {
        reactivate() {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.put(`/admin/clients/${this.client.id}/pos-integrations/${this.integration.id}/status`, {status: 'active'}).then(resp => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.$emit('close')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        inactivate() {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.put(`/admin/clients/${this.client.id}/pos-integrations/${this.integration.id}/status`, {status: 'inactive'}).then(resp => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.$emit('close')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    }
}
</script>
