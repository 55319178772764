<template>
    <div class="flex-page-content flex-container">
        <div class="flex-body body-scroll">
            <div class="container-fluid">
                <div class="container-centered-m py-4">

                    <div v-show="! managerLookupOpen">
                        <h1 class="mb-1 text-center">
                            2-Step Verification
                        </h1>

                        <div class="mb-4 text-center">
                            <span class="text-muted">
                                {{ state.session.email }}
                            </span>
                        </div>

                        <p>
                            This extra step shows it's really you trying to sign in.
                        </p>

                        <div v-if="state.session.login_attempt_verification_method == 'email'" class="pt-2 pb-1 border-top">
                            <h3 class="mb-0">
                                Check your email
                            </h3>
                            <form @submit.prevent="onSubmit" novalidate class="mui-form">
                                <br>
                                <form-errors :errors="formErrors"/>

                                <div class="pt-1">
                                    <p>
                                        We've sent a verification code to <strong>{{ state.session.email }}</strong>.
                                        <br>
                                        Enter the code here:
                                    </p>

                                    <div class="form-row">
                                        <div class="col-6">
                                            <form-input placeholder="Verification Code" v-model="formData.verification_code" :errors="errors.verification_code" type="tel" pattern="[0-9]*" autocomplete="one-time-code" name="code" />
                                        </div>
                                    </div>

                                    <div class="pt-2">
                                        <button type="submit" class="btn btn-primary mr-2 mb-1">Sign In</button>
                                        <span class="btnlike mb-1">
                                            Didn't get the code?&nbsp; <a href @click.prevent="sendEmailCode()">Re-send email</a>
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="accordion" id="mfaAccordion" v-if="state.session.login_attempt_verification_method == 'device'">

                            <!-- Section: Registered Device -->
                            <section class="border-top py-2 mt-3">

                                <!-- Heading -->
                                <div class="py-qt">
                                    <h3 class="mb-0">
                                        <a href="#mfaRegDevice" class="btn-collapse-toggle is-expanded no-decoration unpad-l" data-toggle="collapse" data-target="#mfaRegDevice" role="button" aria-expanded="true" aria-controls="mfaRegDevice" aria-label="Collapse/Expand">
                                            <span class="btn btn-icon">
                                                <i aria-hidden="true" class="fas fa-chevron-right" title="Collapse/Expand"></i>
                                            </span>
                                            <span class="text-dark unpad-l">
                                                Use your registered device
                                            </span>
                                        </a>
                                    </h3>
                                </div>
                                <!-- Body -->
                                <div id="mfaRegDevice" class="collapse show" data-parent="#mfaAccordion">

                                    <div class="pt-1">
                                        <p>
                                            Check your registered device, and click <strong>Allow Sign In</strong> at the prompt.
                                        </p>

                                        <div style="max-width: 8rem; margin: 0 auto; ">
                                            <img class="img-fluid" src="@/assets/img/app-open3.gif">
                                        </div>
                                    </div>

                                </div>
                            </section>

                            <!-- Section: Email Code -->
                            <section class="border-top py-2">
                                <!-- Heading -->
                                <div class="py-qt">
                                    <h3 class="mb-0">
                                        <h3 class="mb-0">
                                            <a href="#mfaEmailCode" class="btn-collapse-toggle is-collapsed no-decoration unpad-l" data-toggle="collapse" data-target="#mfaEmailCode" role="button" aria-expanded="false" aria-controls="mfaEmailCode" aria-label="Collapse/Expand">
                                                <span class="btn btn-icon">
                                                    <i aria-hidden="true" class="fas fa-chevron-right" title="Collapse/Expand"></i>
                                                </span>
                                                <span class="text-dark unpad-l">
                                                    Get a code via email
                                                </span>
                                            </a>
                                        </h3>
                                    </h3>
                                </div>
                                <!-- Body -->
                                <div id="mfaEmailCode" class="collapse" data-parent="#mfaAccordion">

                                    <!-- I'm using a headless accordion here to fake an asynchronous call -->

                                    <div class="accordion" id="mfaEmailCodeSteps">

                                        <div data-parent="#mfaEmailCodeSteps" class="collapse show no-transition" v-if="!emailSent">
                                            <div class="pt-1">
                                                <p>
                                                    We'll send a verification code to <strong>{{ state.session.email }}</strong>.
                                                </p>
                                                <p>
                                                    <a class="btn btn-primary" href role="button" @click.prevent="sendEmailCode()">Send Code</a>
                                                </p>
                                            </div>
                                        </div>

                                        <div data-parent="#mfaEmailCodeSteps" class="collapse show no-transition" v-if="emailSent">
                                            <form @submit.prevent="onSubmit" novalidate class="mui-form">
                                                <form-errors :errors="formErrors"/>

                                                <div class="pt-1">
                                                    <p>
                                                        We've sent a verification code to <strong>{{ state.session.email }}</strong>.
                                                        <br>
                                                        Enter the code here:
                                                    </p>

                                                    <div class="form-row">
                                                        <div class="col-6">
                                                            <form-input placeholder="Verification Code" v-model="formData.verification_code" :errors="errors.verification_code" type="tel" pattern="[0-9]*" />
                                                        </div>
                                                    </div>

                                                    <div class="pt-2">
                                                        <button type="submit" class="btn btn-primary mr-2 mb-1">Sign In</button>
                                                        <span class="btnlike mb-1">
                                                            Didn't get the code?&nbsp; <a href @click.prevent="sendEmailCode()">Re-send email</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                </div>
                            </section>

                        </div>

                        <!-- Button footer -->
                        <div class="border-top pt-1">
                            <div class="row py-2 d-flex align-items-center">
                                <div class="col-12 col-sm-3 text-center text-sm-left">
                                    <router-link :to="{name: 'login'}" class="btn btn-link">Back</router-link>
                                </div>
                                <div class="col-12 col-sm-9 text-center text-sm-right">
                                    <a href class="btn btn-link" @click.prevent="managerLookupOpen = true">I no longer have access to this email address</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <manager-lookup v-if="managerLookupOpen" :is-verified="true" />
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ManagerLookup from "@/components/ManagerLookup"
import api from '@/api'
import bus from '@/bus'

export default {
    mixins: [FormMixin],
    components: {ManagerLookup, },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Login')
        if (!this.$store.state.session) {
            this.$router.push({name: 'login'})
        }

        this.startCheckStatus()
    },
    computed: {
        state() {return this.$store.state}
    },
    data() {
        return {
            checkStatusTask: null,
            checkStatusEnabled: true,
            emailSent: false,
            formRules: {
                verification_code: {
                    presence: {allowEmpty: false},
                },
            },
            formData: {
                verification_code: '',
            },
            managerLookupOpen: false,
        }
    },
    methods: {
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('LOGIN_VALIDATE_EMAIL', this.getFormData()).then((resp) => {
                this.$router.push(decodeURIComponent(this.$route.query.next) || '/')
            }).catch((errors) => {
                this.errors = errors
                // if it's a 400, that means it's an invalid code and could be a typo - 
                // let them fix it; if it's a 404, it means the session is wrong, and they
                // need to restart
                if (errors.__status__ == 404) {
                    this.$router.push({name: 'login'})
                    bus.showError(errors.__all__[0])
                } else {
                    this.formErrors = errors.__all__
                }
            })
        },
        startCheckStatus() {
            if (!this.checkStatusEnabled) {
                return
            }

            if (this.checkStatusTask) {
                return
            }

            if (this.$store.state.session.login_attempt_verification_method != 'device') {
                return
            }

            this.checkStatusTask = setTimeout(() => {
                this.checkStatus()
            }, 1000)
        },
        checkStatus() {
            api.get(`/login/attempt/${this.$store.state.session.login_attempt_challenge_key}`).then(resp => {
                if (resp.status == 'validated') {
                    this.$store.dispatch('LOGIN_VALIDATE_EMAIL', {}).then(() => {
                        this.$router.push(decodeURIComponent(this.$route.query.next) || '/')
                    }).catch(errors => {
                        bus.showError(errors.__all__)
                    })
                }

                if (resp.status == 'pending') {
                    this.checkStatusTask = null
                    this.startCheckStatus()
                }
            }).catch(errors => {
                // TODO: Check errors.__status__
                bus.showError(errors.__all__)
            })
        },
        sendEmailCode() {
            this.checkStatusEnabled = true
            this.$store.dispatch('START_LOADING')
            api.post('/login/email-code', {}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                bus.showInfo('Email code was sent!')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.checkStatusEnabled = false
                this.$store.dispatch('LOGOUT')
                this.$router.push({name: 'login'})
                bus.showError(errors.__all__[0])
            })
            this.emailSent = true
        },
    },
}
</script>

