<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">

                        <div v-show="! managerLookupOpen">
                            <form @submit.prevent="onSubmit" novalidate class="mui-form" v-show="step == 'email'">
                                <h1 class="text-center mb-4">Register New Account</h1>
            
                                <form-errors :errors="formErrors"/>
            
                                <p>Enter the email address that you provided to your employer or account administrator.</p>
            
                                <div class="form-row">
                                    <div class="col">
                                        <form-input placeholder="Email Address" v-model="formData.email" :errors="errors.email" type="email" name="email" autocomplete="email" />
                                    </div>
                                </div>
            
                                <p class="small text-muted">Next, we will ask you to verify this email address.</p>
            
                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <router-link :to="{name: 'login'}" class="btn btn-link">Back</router-link>
                                    </div>
                                    <div class="col text-right">
                                        <button type="submit" class="btn btn-primary">Continue</button>
                                    </div>
                                </div>
                            </form>

                            <div v-show="step == 'is-blocked'">
                                <h1 class="mb-4 text-center">Account Locked</h1>

                                <p class="mb-4">This account is blocked and cannot be accessed.</p>

                                <router-link class="btn btn-primary btn-block" :to="{name: 'login'}">Back to Sign In</router-link>
                            </div>

                            <div v-show="step == 'withdrawn-consent'">
                                <h1 class="mb-4 text-center">Account Deleted</h1>

                                <p class="mb-4">This account has been deleted due to withdrawn acceptance of our Terms of Service. Contact your account administrator if you need to set up a new account.</p>

                                <router-link class="btn btn-primary btn-block" :to="{name: 'login'}">Back to Sign In</router-link>
                            </div>
            
                            <div v-show="step == 'not-in-system'">
                                <h1 class="mb-4 text-center">We don't recognize that account</h1>
            
                                <p class="mb-4">Make sure you're using the email address that you provided to your account administrator. If you still cannot get in, check with your administrator to ensure that your email address is correct.</p>
            
                                <router-link class="btn btn-primary btn-block" :to="{name: 'login'}">Back to Sign In</router-link>
                            </div>
            
                            <div v-show="step == 'existing-user'">
                                <h1 class="mb-4 text-center">You have an account</h1>
            
                                <p class="mb-4">You're already registered and good to go. Enter your email and password on the Sign In screen to proceed.</p>
            
                                <router-link class="btn btn-primary btn-block" :to="{name: 'login'}">Back to Sign In</router-link>
            
                                <a href class="btn btn-link btn-block" @click.prevent="managerLookupOpen = true">I no longer have access to this email address</a>
                            </div>
                        </div>

                        <manager-lookup v-if="managerLookupOpen" :email="formData.email" :is-verified="false" />
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import ManagerLookup from "@/components/ManagerLookup"
import api from '@/api'

export default {
    mixins: [FormMixin],
    components: {
        FormInput,
        FormErrors,
        ManagerLookup,
    },
    data() {
        return {
            formRules: {
                email: {
                    email: true,
                    presence: {allowEmpty: false},
                },
            },
            formLabels: {
                email: 'Email Address',
            },
            formData: this.makeFormData(),
            step: 'email',
            managerLookupOpen: false,
        }
    },
    mounted() {
        this.$store.dispatch('LOGOUT')
    },
    methods: {
        makeFormData() {
            return {
                email: '',
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            return api.post(`/account-status`, {email: this.formData.email}).then((resp) => {

                if (resp.has_user) {
                    if (resp.withdrawn_consent) {
                        this.step = 'withdrawn-consent'
                        return this.$store.dispatch('STOP_LOADING')
                    }

                    if (resp.is_blocked) {
                        this.step = 'is-blocked'
                        return this.$store.dispatch('STOP_LOADING')
                    }

                    this.step = 'existing-user'
                }
                else {
                    if (resp.can_register) {
                        this.$store.dispatch('START_LOADING')
                        api.post(`/signup`, this.formData).then((resp) => {
                            this.$store.dispatch('SET_SESSION', resp)
                            this.$router.push({name: 'signup-validate-email'})
                            this.$store.dispatch('STOP_LOADING')
                        }).catch((errors) => {
                            this.errors = errors
                            this.formErrors = errors.__all__
                            this.onError()
                            this.$store.dispatch('STOP_LOADING')
                        })
                    }
                    else {
                        this.step = 'not-in-system'
                    }
                }

                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },
}
</script>

