import moment from 'moment'

export default {
    makeTodayMoment() {
        return moment().set({
            'hour': 0,
            'minute': 0,
            'second': 0,
            'millisecond': 0,
        })
    },
    makeDateRange(mode, start, end) {
        const range = {mode: mode, start: null, end: null}
        const today = this.makeTodayMoment()

        if (mode == 'last30') {
            range.end = today
            range.start = moment(today).subtract(30, 'day')
        }
        else if (mode == 'last90') {
            range.end = today
            range.start = moment(today).subtract(90, 'day')
        }
        else if (mode == 'last12') {
            range.end = today
            range.start = moment(today).subtract(12, 'month')
        }
        else {
            range.end = moment(end, 'MM/DD/YYYY')
            range.start = moment(start, 'MM/DD/YYYY')
        }

        range.start = range.start.set({
            'hour': 0,
            'minute': 0,
            'second': 0,
            'millisecond': 0,
        })

        range.end = range.end.set({
            'hour': 23,
            'minute': 59,
            'second': 59,
            'millisecond': 999,
        })

        range.start = range.start.utc().format()
        range.end = range.end.utc().format()

        return range
    },
}
