<template>
    <div>
        <div class="flex-page-sidebar">
            <nav class="nav-secondary">
                <div class="dropdown">
                    <a class="btn dropdown-toggle" href role="button" id="navSecondaryMenuBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                        Show: {{ state.pageTitle }}
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navSecondaryMenuBtn">
                      <div v-for="o in navOptions">
                        <router-link :to="{name: o.value}" class="dropdown-item" :class="{'active': $route.name == o.value}">{{ o.text }}</router-link>
                      </div>
                    </div>
                </div>
            </nav>
            <!--
            <div class="d-none d-md-block">
                <ul class="nav flex-column py-2">
                    <li class="nav-item">
                        <router-link :to="{name: 'settings-users', params: {clientId: $route.params.clientId}}" class="nav-link" :class="{'active': $route.name == 'settings-users'}">Security Users</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'settings-hr-documents', params: {clientId: $route.params.clientId}}" class="nav-link" :class="{'active': $route.name == 'settings-hr-documents'}">HR Documents</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'settings-hr-templates', params: {clientId: $route.params.clientId}}" class="nav-link" :class="{'active': $route.name == 'settings-hr-templates'}">HR Templates</router-link>
                    </li>
                </ul>
            </div>
            -->
        </div>

        <router-view class="flex-page-content flex-container view" :client="client"/>
    </div>
</template>

<script>
export default {
    computed: {
        state() {return this.$store.state},
    },
    props: ['client', ],
    data() {
        const navOptions = [
            {'text': 'Security Users', 'value': 'settings-users', 'params': '{clientId: $route.params.clientId}}'},
            {'text': 'HR Documents', 'value': 'settings-hr-documents', 'params': '{clientId: $route.params.clientId}}'},
            {'text': 'HR Templates', 'value': 'settings-hr-templates', 'params': '{clientId: $route.params.clientId}}'},
        ]

        if (!this.$permissions.hasRoles(this.client.id, ['officer', 'payroll-admin'])) {
            navOptions.shift() // Remove the first item from the list.
        }

        return {
            navOptions: navOptions
        }
    },
    mounted() {
        const routes = this.navOptions.map(x => x.value)
        if (routes.indexOf(this.$route.name) < 0) {
            this.$router.replace({name: routes[0], params: {companyId: this.$route.params.clientId}})
        }
    },
}
</script>
