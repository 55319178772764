<template>
    <select ref="select" style="width: 100%" class="form-control" :id="id"></select>
</template>

<script>
import $ from 'jquery'
import 'select2'

export default {
    name: 'Select2',
    data() {
        return {select2: null}
    },
    computed: {
        opts() {
            if (this.options) {
                return this.options.map(o => {
                    return {id: o.value, text: o.text, disabled: o.disabled}
                })
            }

            if (this.optGroups) {
                const result = []
                this.optGroups.forEach(g => {
                    const group = {text: g.label, children: []}
                    g.options.forEach(o => {
                        group.children.push({id: o.value, text: o.text, disabled: o.disabled})
                    })
                    result.push(group)
                })

                return result
            }

            return []
        },
        select2Data() {
            // NB: can't use ... spread operator until it's supported in Edge
            const obj = {}
            Object.assign(obj, this.settings)
            Object.assign(obj, {
                data: this.opts,
                placeholder: this.placeholder,
                allowClear: this.placeholder,
                disabled: this.disabled,
                multiple: this.multiple,
            })
            return obj
        },
    },
    props: {
      id: {type: String, default: ''},
      name: {type: String, default: ''},
      placeholder: {type: String, default: ''},
      multiple: {type: Boolean, default: false},
      options: {type: Array, default: null},
      optGroups: {type: Array, default: null},
      disabled: {type: Boolean, default: false},
      settings: {type: Object, default: () => {}},
      value: null
    },
    watch: {
        optGroups() {
            this.setOptions()
        },
        options() {
            this.setOptions()
        },
        value(val) {
            this.setValue(val)
        }
    },
    methods: {
        setOptions() {
            this.select2.empty()
            this.select2.select2(this.select2Data)
            this.setValue(this.value)
        },
        setValue(val) {
            if (val instanceof Array) {
                this.select2.val([...val])
            } else {
                this.select2.val([val])
            }
            this.select2.trigger('change')
        }
    },
    mounted() {
        this.select2 = $(this.$refs.select).select2(this.select2Data).on('select2:select select2:unselect', ev => {
            this.$emit('change', this.select2.val())
            this.$emit('select', ev['params']['data'])
        })
        this.setValue(this.value)
    },
    beforeDestroy() {
        this.select2.select2('destroy')
    }
}
</script>
