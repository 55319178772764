<template>
    <page>

        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="currentCity = $event"
        >
            <template slot="table-actions">
                <button type="button" class="btn btn-primary btn-collapse-md" @click.prevent="currentCity = null; $refs.addModal.open()">
                    <i class="far fa-fw fa-plus"></i>
                    <span class="btn-text">Add City</span>
                </button>
            </template>
        </fast-table>

        <sidebar :city="currentCity" :all-cities="cities" v-if="currentCity" ref="sidebar" @updated="onUpdate($event)" @close="currentCity = null" />
        <add-modal ref="addModal" :all-cities="cities" @updated="getCities()" />

    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import AddModal from './components/CityModal'
import Sidebar from './components/CitySidebar'

export default {
    components: {FastTable, Page, Sidebar, AddModal, },
    computed: {
        tableData() {
            const tableData = []

            this.cities.forEach((c, idx) => {
                const row = {
                    id: idx,
                    isActive: this.currentCity && c.id == this.currentCity.id,
                    object: c,
                    cells: [
                        c.name,
                        c.state.name,
                        c.reciprocal_agreement_cities.length,
                        c.alternative_names.length,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            cities: [],
            dataLoaded: false,
            currentCity: null,

            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'State', classes: 'cw-5', isSearchable: true},
                {label: 'Reciprocal Cities', classes: 'cw-11', isSearchable: false},
                {label: 'Alternative Names', classes: 'cw-11', isSearchable: false},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tax Engine / Cities')
        this.getCities()
    },
    methods: {
        getCities() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/cities`).then(resp => {
                this.cities = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate(city) {
            const existing = this.cities.find(x => {return x.id == city.id})
            if (existing) {
                Object.assign(existing, city)
            }
        },
    },
}

</script>
