<template>
    <div>
        <div v-if="lockedCompanies.length">
            <!-- Account Alerts (Locked Companies) -->
            <div class="scoreboard-group">
                <!-- Hiding for now, until we have more types of Account Alert
                <h4 class="mb-2">
                    Account Alerts <span class="text-regular">(1)</span>
                </h4>
                -->
                <div class="scoreboard scoreboard--accountalerts box-shadow-tile">
                    <div class="scoreboard-item">
                        <div class="scoreboard-item-main">
                            <span class="scoreboard-item-icon">
                                <i class="fas fa-fw fa-lock"></i>
                            </span>
                            <span class="scoreboard-item-type">
                                Your access to {{lockedCompanies.length}} <span v-if="lockedCompanies.length > 1">companies</span><span v-else>company</span> is locked. Review your Companies list below for details.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="onboardingApplications.length || user.employee_pending_link_companies.length">
            <!-- Next Actions -->
            <div class="scoreboard-group">
                <h4 class="mb-2">
                    Next Actions <span class="text-regular">({{ onboardingApplications.length + user.employee_pending_link_companies.length }})</span>
                </h4>
                <div class="scoreboard scoreboard--nextactions box-shadow-tile">
                    <!-- Onboarding items -->
                    <div class="scoreboard-item" v-for="app in onboardingApplications">
                        <router-link :to="{name: 'employee-onboarding', params: {appId: app.id}}" class="scoreboard-item-main">
                            <span class="scoreboard-item-icon">
                                <i class="fas fa-fw fa-paper-plane"></i>
                            </span>
                            <span class="scoreboard-item-type">
                                <span v-if="!app.digital_consent_given_on">Start Onboarding for </span>
                                <span v-if="app.digital_consent_given_on">Continue Onboarding for </span>
                                {{ app.company.name }}</span>
                            <span class="scoreboard-item-count">
                                <i class="fas fa-fw fa-arrow-right"></i>
                            </span>
                        </router-link>
                    </div>
                    <!-- Linking items -->
                    <div class="scoreboard-item" v-for="company in user.employee_pending_link_companies">
                        <router-link :to="{name: 'employee-link-record', params: {companyId: company.id}}" class="scoreboard-item-main">
                            <span class="scoreboard-item-icon">
                                <i class="fas fa-fw fa-link"></i>
                            </span>
                            <span class="scoreboard-item-type">
                                Link to {{ company.name }}
                            </span>
                            <span class="scoreboard-item-count">
                                <i class="fas fa-fw fa-arrow-right"></i>
                            </span>
                        </router-link>
                    </div>
                </div>
            </div>

            <!-- Onboarding reminder modal -->
            <div class="modal fade" tabindex="-1" role="dialog" ref="onboardingModal">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header d-block pb-0 border-none">
                            <div class="d-flex pb-2">
                                <h3 class="modal-title">New Employee Onboarding</h3>
                            </div>
                        </div>
                        <div class="modal-body">
                            <p>
                                You have Onboarding tasks to complete for {{ onboardingApplications.length }} {{ onboardingApplications.length == 1 ? 'company' : 'companies' }}:
                            </p>
                            <ul class="list-unstyled border-bottom">
                                <li class="py-1 border-top" v-for="app in onboardingApplications" :key="app.id">
                                    <div class="form-row">
                                        <div class="col-12 col-rs-8 d-flex">
                                            <span class="btnlike align-self-center">{{ app.company.name }}</span>
                                        </div>
                                        <div class="col-12 col-rs-4 d-flex justify-content-end">
                                            <router-link :to="{name: 'employee-onboarding', params: {appId: app.id}}"  class="btn btn-block btn-primary align-self-center mb-1 mb-rs-0">
                                                {{ app.digital_consent_given_on ? 'Continue' : 'Start' }}
                                            </router-link>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button-row center>
                                <button type="button" class="btn btn-outline-primary" data-dismiss="modal">I'll do this later</button>
                            </button-row>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import ButtonRow from '@/components/ButtonRow'

export default {
    components: {ButtonRow, },
    props: ['showOnboardingModal', ],
    computed: {
        user() {return this.$store.state.user},
        onboardingApplications() {
            const apps = this.user.pending_onboarding_applications.filter((onApp) => {return ['started', 'logged-in'].indexOf(onApp.status) >= 0})

            apps.sort((a, b) => {
                if (a.progress == b.progress) {
                    return a.id > b.id ? 1 : 1
                }
                return a.progress > b.progress ? 1 : -1
            })

            return apps
        },
        lockedCompanies() {
            const lockedCompanies = this.user.employees.filter((emp) => {return emp.is_locked})
            return lockedCompanies
        }
    },
    mounted() {
        if (this.showOnboardingModal && this.onboardingApplications.length) {
            $(this.$refs.onboardingModal).modal('show')
        }
    },
}

</script>
