<template>
    <div>
        <div class="flex-page-content flex-container body-gray">
            <div class="flex-body body-scroll">

                <div class="container mx-auto">

                    <div class="py-4">
                        <h1 class="mb-2">{{ client.name }}</h1>

                        <employer-scoreboard :client="client" />

                        <div class="row">
                            <div class="col-12 col-lg-9">
                                <!-- Account Features -->
                                <div>
                                    <h4 class="mb-2">
                                        Account Features
                                    </h4>
                                    <div class="form-row pr-lg-3 pr-xl-2">

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, ['officer', 'payroll-admin', 'location-manager', 'store-manager'])">
                                            <router-link class="module-tile" :to="{name: 'onboarding', params: {clientId: client.id}}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-user-check"></i></span>
                                                        <div class="module-tile-label">Onboarding Employees</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, ['officer', 'payroll-admin', 'location-manager', 'store-manager'])">
                                            <router-link class="module-tile" :to="{name: 'employees', params: {clientId: client.id}}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-users"></i></span>
                                                        <div class="module-tile-label">Self-Service Access</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, ['officer', 'payroll-admin', 'location-manager', 'store-manager'])">
                                            <router-link class="module-tile" :to="{name: 'warnings', params: {clientId: client.id}}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-exclamation-triangle"></i></span>
                                                        <div class="module-tile-label">Warnings</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, ['officer', 'payroll-admin', 'location-manager'])">
                                            <router-link class="module-tile" :to="{name: 'hr-documents', params: {clientId: client.id}}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-copy"></i></span>
                                                        <div class="module-tile-label">HR Documents</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <!--
                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3">
                                            <router-link class="module-tile" :to="{name: 'payrolls', params: {clientId: client.id}}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-file-invoice-dollar"></i></span>
                                                        <div class="module-tile-label">Payrolls</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                        -->

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, ['officer', 'payroll-admin', 'location-manager'])">
                                            <router-link class="module-tile" :to="{name: 'paychecks', params: {clientId: client.id}}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-money-check-alt"></i></span>
                                                        <div class="module-tile-label">Pay Stubs</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, ['officer', 'payroll-admin', 'location-manager'])">
                                            <router-link class="module-tile" :to="{name: 'employee-w2s', params: {clientId: client.id}}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-file-invoice-dollar"></i></span>
                                                        <div class="module-tile-label">Employee Tax Documents</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, ['officer', 'payroll-admin'])">
                                            <router-link class="module-tile" :to="{name: 'tax-forms', params: {clientId: client.id}}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-folder"></i></span>
                                                        <div class="module-tile-label">Company Tax Documents</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, ['officer', 'payroll-admin', 'store-manager', 'location-manager'])">
                                            <router-link class="module-tile" :to="{name: 'changelogs', params: {clientId: client.id}}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-history"></i></span>
                                                        <div class="module-tile-label">Change History</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, ['officer', 'payroll-admin', 'location-manager'])">
                                            <a href="#" @click.prevent="goToPOS()" class="module-tile" :class="{'module-tile--inactive module-tile--upgrade': !client.has_pos_integration}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-cogs"></i>
                                                        </span>

                                                        <div class="module-tile-label">Point of Sale Integration</div>
                                                        <div class="module-tile-meta" v-if="!client.has_pos_integration">
                                                            <span>Upgrade</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, ['officer', 'payroll-admin', 'location-manager'])">
                                            <div class="module-tile module-tile--inactive module-tile--comingsoon">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-flag"></i>
                                                        </span>

                                                        <div class="module-tile-label">WOTC</div>
                                                        <div class="module-tile-meta">
                                                            <span>Coming Soon</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, ['officer', 'payroll-admin'])">
                                            <router-link class="module-tile" :to="{name: 'settings', params: {clientId: client.id}}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-tools"></i>
                                                        </span>

                                                        <div class="module-tile-label">Settings</div>

                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3">
                                <div class="pt-2 pt-lg-0 pl-xl-2">
                                    <!-- Companies List -->
                                    <companies-list :companies="client.companies" :is-client="true" />
                                    <!-- Promo Tile -->
                                    <div class="hide-in-native">
                                        <promo-tile class="promo-tile-tall-lg"></promo-tile>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import EmployerScoreboard from '@/components/EmployerScoreboard'
import CompaniesList from '@/components/CompaniesList'
import PromoTile from '@/components/PromoTile'

export default {
    props: ['client'],
    components: {EmployerScoreboard, CompaniesList, PromoTile, },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Home')
        this.$store.dispatch('GET_USER')

        this.$api.post('/me/last-viewed', {client_id: this.$route.params.clientId})
    },
    methods: {
        goToPOS() {
            if (! this.client.has_pos_integration) {
                return
            }

            let pos_app_url = `${this.$delta.POS_APP_URL}/employer/client/${this.client.id}/hours-import/`
            window.location.href = pos_app_url
        }
    }
}
</script>

<style scoped lang="scss">
.card {
    /* width: 15rem; Removing for now, I think this is unneeded. */
}
</style>
