<template>
    <modal ref="modal" :fade="false" :v-center="true" class="modal-pageerror modal-noclose" :modal-container="'specialModal'">
        <template slot="title">
            <div>Your session is about to end</div>
        </template>

        <slot>
            <div>
                <p>
                  You've been inactive for a while. For your security, we'll automatically log you out in approximately
                  {{sessionExpiring}}.
                </p>
                <p>
                    You may choose <b>Continue</b> to stay signed in, or <b>Log Out</b> if you're done.
                </p>
            </div>
        </slot>

        <template slot="footer">
            <button-row center>
                <a href @click.prevent="onRenewSession" class="btn btn-primary">Continue</a>
                <a href @click.prevent="onLogout" class="btn btn-outline-primary">Log Out</a>
            </button-row>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import moment from 'moment'

export default {
    mixins: [ModalMixin, ],
    data() {
        return {
            time_counter: 0,
            timer: null,
        }
    },
    computed: {
        sessionExpiring() {
            // this is just to trigger the recompute, moment will get the current time
            let a = this.time_counter
            return moment(this.$store.state.session_expiry, moment.ISO_8601, true).fromNow(true)
        },
    },
    mounted() {
        this.open()
        // update every 5 seconds - we're really just trying to update the minutes, don't need seconds
        this.timer = setInterval(() => {
            // if the expiration time's passed, show the logged-out modal
            if (moment(this.$store.state.session_expiry, moment.ISO_8601, true).isBefore()) {
                this.$bus.loggedOut()
            }
            this.time_counter += 1
        }, 5000);
    },
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    },
    methods: {
        onRenewSession() {
            this.$store.dispatch('GET_USER')
            this.close()
        },
        onLogout() {
            this.$store.dispatch('LOGOUT')
            this.$router.push({name: 'login'})
        },
    },
}
</script>
