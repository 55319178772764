<template>
    <div>
        <div v-if="!accounts.length" class="row align-items-center mb-hf">
            <div class="col-9">
                <p class="mb-0">
                    No payment accounts added.
                </p>
            </div>
            <div class="col-3 text-right">
                <more-info class="mt-0">
                    Click "Add Account" if you wish to be paid using direct deposit. Otherwise, click "Continue" and you will be paid by check.
                </more-info>
            </div>
        </div>

        <div v-if="accounts.length" class="row align-items-center mb-hf">
            <div class="col-9">
                <h6 class="mb-0">Payment Accounts</h6>
            </div>
            <div class="col-3 text-right">
                <more-info class="mt-0">
                    You may add up to 2 payment accounts for direct deposit. If you delete all payment accounts, you will be paid by check.
                </more-info>
            </div>
        </div>

        <div class="card mb-1" v-for="account in accounts" v-if="accounts.length">
            <div class="card-body p-0">

                <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                        <div class="p-2">
                            <span class="truncate-wrapper truncate-with-last4">
                                <span class="truncate-content" :title="account.bank_name || 'US Bank'">{{ account.bank_name || 'US Bank' }}</span>
                                <span class="truncate-postfix">({{ account.account_number_last_4 }})</span>
                            </span>
                        </div>
                    </div>
                    <div v-bind:class="[accounts.length > 1 ? 'account-split--double' : 'account-split--single', 'text-right']">
                        <div class="px-1 py-2 bg-150 border-left rounded-right">

                            <span v-if="accounts.length > 1 && account.split_type == 'remainder'" class="text-muted d-inline-block mr-2 split-indicator">
                                Remainder
                            </span>

                            <span v-if="accounts.length > 0 && account.split_type == 'percentage'" class="text-muted d-inline-block mr-2 split-indicator">
                                {{ account.percentage }}% per pay
                            </span>

                            <span v-if="accounts.length > 0 && account.split_type == 'fixed'" class="text-muted d-inline-block mr-2 split-indicator">
                                ${{ account.amount }} per pay
                            </span>

                            <div class="dropdown d-inline-block unpad" v-if="!readonly">
                                <a href class="btn btn-icon dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i><span class="sr-only">More Actions</span>
                                </a>
                                <div class="dropdown-menu has-icons dropdown-menu-right">
                                    <!-- <a class="dropdown-item" href><i class="far fa-fw fa-pencil mr-1"></i>Edit Account</a> -->
                                    <a class="dropdown-item" href @click.prevent="onDeleteAccount(account)"><i class="far fa-fw fa-trash-alt mr-1"></i>Delete Account</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card mb-1" v-if="accounts.length < 2 && !readonly">
            <div class="card-body p-0 text-center">
                <a class="d-block p-2" href @click.prevent="openAccountModal()"><i class="far fa-fw fa-plus mr-1"></i>Add Account</a>
            </div>
        </div>

        <div class="mt-2 mb-4" v-if="accounts.length == 2 && !readonly">
            <button class="btn btn-outline-primary" type="button" @click.prevent="swapAccounts()">
                <i class="far fa-fw fa-random mr-1"></i>Swap Account Order
            </button>
        </div>

        <direct-deposit-modal
            :employee="employee"
            :direct-deposit-account="directDepositAccount"
            :url="url"
            @paymasters_direct_deposit_accounts_updated="onAccountsUpdated($event)"
            ref="directDepositModal"
        />
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'

import DirectDepositModal from '@/components/DirectDepositModal'

export default {
    components: {DirectDepositModal, },
    props: ['employee', 'url', 'readonly'],
    computed: {
        accounts() {
            const accounts = this.employee.direct_deposit_accounts.slice()

            return accounts.sort((a, b) => {
                if (a.split_type == 'remainder') {
                    return 1
                }
                if (b.split_type == 'remainder') {
                    return -1
                }
            })
        },
    },
    data() {
        return {
            directDepositAccount: null,
        }
    },
    methods: {
        reset: function() {
            this.errors = {}
            this.formErrors = []
        },
        openAccountModal(directDepositAccount) {
            this.directDepositAccount = directDepositAccount
            this.$refs.directDepositModal.open()
        },
        onAccountsUpdated() {
            this.$emit('paymasters_direct_deposit_accounts_updated')
        },
        onDeleteAccount(account) {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            api.del(`${this.url}/${account.id}`).then(() => {
                this.onAccountsUpdated()
                this.$store.dispatch('STOP_LOADING')
            }).catch((error) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(error)
            })
        },
        swapAccounts() {
            if (this.accounts.length != 2) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.put(this.url, {swap: true}).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('paymasters_direct_deposit_accounts_updated', resp)
            }).catch((errors) => {
                bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },
}
</script>

<style scoped>
.split-indicator {
    width: 6em;
}
</style>
