<template>
    <table-page :client="client" :warnings="warnings" :changelogs="changelogs" @changelog_date_filter_change="onChangelogDateRangeChange" @warnings_date_filter_change="onWarningsDateRangeChange" @warnings_updated="getWarnings">
        <fast-table
            v-if="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="onEmployeeSelected"
            @click_cell="onEmployeeSelected($event.object, $event.columnIdx)"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
        >
            <template slot="table-actions">
                <button type="button" class="btn btn-primary btn-collapse-md" @click.prevent="$refs.addModal.open()">
                    <i class="far fa-fw fa-plus"></i>
                    <span class="btn-text">Add Security User</span>
                </button>
            </template>
        </fast-table>

        <add-modal :client="client" ref="addModal" @updated="onUpdate($event)"/>

        <security-profile-sidebar :security-profile-id="currentProfile.id" :key="currentProfile.id" :tab="tab" v-if="currentProfile && currentProfileEditable" ref="sidebar" :client="client" @updated="onUpdate" @deleted="onDelete" @close="currentProfileId = null" />

        <security-profile-no-access-sidebar :security-profile="currentProfile" :key="currentProfile.id" tab="main" v-if="currentProfile && !currentProfileEditable" ref="sidebar" :client="client" @close="currentProfileId = null" />

    </table-page>
</template>

<script>
import FastTable from '@/components/FastTable'
import AddModal from '@/components/SecurityProfileSidebar/SecurityUserModal'
import SecurityProfileSidebar from '@/components/SecurityProfileSidebar'
import SecurityProfileNoAccessSidebar from '@/components/SecurityProfileSidebar/SecurityProfileNoAccessSidebar'
import TablePage from '@/components/TablePage'

export default {
    components: {FastTable, AddModal, SecurityProfileSidebar, SecurityProfileNoAccessSidebar, TablePage, },
    props: ['client', ],
    computed: {
        pendingUsers() {
            return this.profiles.filter(p => !p.user)  // These don't have users
        },
        activeUsers() {
            return this.profiles.filter((p) => {
                if (!p.user) {  // These must have users + at least one active CSP
                    return false
                }

                let c = 0
                p.company_security_profiles.forEach(csp => {
                    if (csp.role && csp.is_active) {
                        c += 1
                    }
                })

                return c > 0
            })
        },
        inactiveUsers() {
            return this.profiles.filter((p) => {
                if (!p.user) {  // These must have users + no active CSPs
                    return false
                }

                let c = 0
                p.company_security_profiles.forEach(csp => {
                    if (csp.role && csp.is_active) {
                        c += 1
                    }
                })

                return c < 1
            })
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.pendingUsers,
                this.activeUsers,
                this.inactiveUsers,
            ]

            subsets.forEach(profiles => {
                const sectionData = []

                profiles.forEach((p, idx) => {
                    const firstCompanyProfile = p.company_security_profiles[0] || {}
                    const additionalCompanyProfiles = p.company_security_profiles.slice(1)
                    const warnings = this.getSPWarningCount(p)

                    let positionCountPretty = ''
                    if (firstCompanyProfile.id && ['employee-manager', 'store-manager'].indexOf(firstCompanyProfile.role) >= 0) {
                        positionCountPretty = `${firstCompanyProfile.security_profile_positions.length} of ${firstCompanyProfile.company.positions.length}`
                    }


                    const row = {
                        id: idx,
                        isActive: this.currentProfileId && p.id == this.currentProfileId,
                        object: p,
                        cells: [
                            p.full_name_sortable,
                            warnings,
                            firstCompanyProfile.company ? firstCompanyProfile.company.name : '',
                            firstCompanyProfile.is_active,
                            {value: firstCompanyProfile.role_pretty, _meta: {additionalCSSClasses: firstCompanyProfile.is_active ? '' : 'text-line-through'}},
                            positionCountPretty,
                            p.email,
                            p.phone_formatted,
                            p.user ? p.user.last_login : '',
                            p.user ? p.user.date_joined : '',
                        ],
                        subrows: [],
                    }

                    additionalCompanyProfiles.forEach(csp => {
                        const subrow = ['', '']
                        let positionCountPretty = ''
                        if (['employee-manager', 'store-manager'].indexOf(csp.role) >= 0) {
                            positionCountPretty = `${csp.security_profile_positions.length} of ${csp.company.positions.length}`
                        }
                        subrow.push(csp.company.name),
                        subrow.push(csp.is_active),
                        subrow.push({value: csp.role_pretty, _meta: {additionalCSSClasses: csp.is_active ? '' : 'text-line-through'}}),
                        subrow.push(positionCountPretty)
                        subrow.push('')
                        subrow.push('')
                        subrow.push('')
                        subrow.push('')

                        row.subrows.push(subrow)
                    })

                    sectionData.push(row)
                })
                tableData.push(sectionData)
            })
            return tableData
        },
        currentProfile() {
            return this.profiles.find(p => p.id == this.currentProfileId)
        },
        currentProfileEditable() {
            if (!this.currentProfile) {
                return false
            }

            if (this.$store.state.user.is_superuser) {
                return true
            }

            const userSP = this.$permissions.getSecurityProfile(this.client.id)
            if (userSP.id == this.currentProfile.id) {
                return false // Can't edit yourself
            }

            const officerCompanyIds = new Set(this.$permissions.getCompanyIdsByRoles(this.client.id, ['officer']))
            const payrollAdminCompanyIds = new Set(this.$permissions.getCompanyIdsByRoles(this.client.id, ['payroll-admin']))

            this.currentProfile.company_security_profiles.forEach(csp => {
                if (csp.role == 'officer' && officerCompanyIds.has(csp.company.id)) {
                    officerCompanyIds.delete(csp.company.id)
                }
                else if (csp.role == 'payroll-admin' && payrollAdminCompanyIds.has(csp.company.id)) {
                    payrollAdminCompanyIds.delete(csp.company.id)
                }
            })

            return (Array.from(officerCompanyIds).length + Array.from(payrollAdminCompanyIds).length) > 0
        }
    },
    data() {
        return {
            mode: 'main',
            profiles: [],
            dataLoaded: false,
            currentProfileId: null,
            dateRanges: {},

            headers: [
                {label: 'User', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Warnings', classes: 'cell-warnings', isSearchable: false, type: 'warning', isFilterable: true, isClickable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Is Active', classes: 'cell-narrow', isSearchable: false, isFilterable: false, type: 'checkmark'},
                {label: 'Role', classes: 'cell-wide', isSearchable: true, isFilterable: true},
                {label: 'Positions', classes: 'cell-medium', isSearchable: true},
                {label: 'Email', classes: 'cell-email', isSearchable: true},
                {label: 'Phone', classes: 'cell-phone', isSearchable: true},
                {label: 'Last Login', classes: 'cell-date', isSearchable: false, type: 'date'},
                {label: 'Active Since', classes: 'cell-date', isSearchable: false, type: 'date'},
            ],
            sections: [
                {title: 'Users Pending Activation', defaultIsClosed: false, id: 'pending', hasDateFilter: false},
                {title: 'Active Users', defaultIsClosed: false, id: 'active', hasDateFilter: false},
                {title: 'Inactive Users', defaultIsClosed: false, id: 'inactive', hasDateFilter: true},
            ],

            warnings: null,
            changelogs: null,
            changelogDateRangeStart: null,
            changelogDateRangeEnd: null,

            warningsDateRangeStart: null,
            warningsDateRangeEnd: null,

            tab: 'main',
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Settings / Security Users')
        this.getProfiles(false)
        this.getWarnings()
        this.getChangelogs()

        this.$bus.$on('client_data_updated', updates => {
            if (updates.indexOf('security_profiles') >= 0) {
                this.getProfiles(true)
                this.getWarnings()
                this.getChangelogs()
            }
        })
    },
    methods: {
        getProfiles(silent) {
            if (!silent) {
                this.$store.dispatch('START_LOADING')
            }

            let url = `/clients/${this.client.id}/security-profiles`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            let ignore_for_session_expiry = silent
            this.$api.get(url, ignore_for_session_expiry).then((resp) => {
                this.profiles = resp
                this.dataLoaded = true
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }

                if (this.currentProfile) {
                    this.$refs.sidebar.updateSecurityProfile(this.currentProfile)
                }
            }).catch((errors) => {
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate(secProfile) {
            const existingProfile = this.profiles.find((p) => {return secProfile.id == p.id})
            if (existingProfile) {
                Object.assign(existingProfile, secProfile)
            }
            else {
                this.profiles.push(secProfile)
            }
        },
        onDelete() {
            this.profiles = this.profiles.filter(p => p.id != this.currentProfileId)
            this.currentProfileId = null
            this.getProfiles(true)
            this.getWarnings()
            this.getChangelogs()
        },
        getWarnings() {
            let url = `/clients/${this.client.id}/warnings`
            if (this.warningsDateRangeStart && this.warningsDateRangeEnd) {
                url = `${url}?date-filter.resolved.start=${encodeURIComponent(this.warningsDateRangeStart)}&date-filter.resolved.end=${encodeURIComponent(this.warningsDateRangeEnd)}`
            }

            this.$api.get(url).then(resp => {
                this.warnings = resp.warnings.filter(c => c.company_security_profile)
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getChangelogs() {
            let url = `/clients/${this.client.id}/changelogs`
            if (this.changelogDateRangeStart && this.changelogDateRangeEnd) {
                url = `${url}?date-filter.changelogs.start=${encodeURIComponent(this.changelogDateRangeStart)}&date-filter.changelogs.end=${encodeURIComponent(this.changelogDateRangeEnd)}`
            }

            this.$api.get(url).then(resp => {
                this.changelogs = resp.filter(c => c.company_security_profile)
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        setMode(mode) {
            this.mode = mode
            this.$bus.$emit('resize')
        },
        onChangelogDateRangeChange(range) {
            this.changelogDateRangeStart = range.start
            this.changelogDateRangeEnd = range.end
            this.getChangelogs()
        },
        onWarningsDateRangeChange(range) {
            this.warningsDateRangeStart = range.start
            this.warningsDateRangeEnd = range.end
            this.getWarnings()
        },
        onEmployeeSelected(sp, columnIdx) {
            this.tab = 'main'

            const warnings = this.getSPWarningCount(sp)
            if (columnIdx == 1 && warnings) {
                this.tab = 'warnings'
            }

            this.currentProfileId = sp.id
        },
        getSPWarningCount(sp) {
            const cspIds = sp.company_security_profiles.map(csp => csp.id)
            return (this.warnings || []).filter(w => !w.is_resolved && cspIds.indexOf(w.company_security_profile.id) >= 0).length
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getProfiles()
        },
    },
}
</script>
