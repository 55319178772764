<template>
    <sidebar @close="$emit('close')">
        <template slot="title">
            {{ question.name }}
        </template>
        <slot>
            <div class="card mb-2">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Info</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.editModal.open()">Edit</button>
                </div>

                <div class="card-body">
                    <p><strong>Slug: {{ question.slug }}</strong></p>
                    <p v-html="question.html"></p>

                    <ul class="unstyled">
                        <li v-for="option in question.options" :key="option.id">
                            <p><strong>Form: {{ option.yaml_form ? option.yaml_form.name : '--' }}</strong></p>
                            <p><strong>Slug: {{ option.slug }}</strong></p>
                            <p v-html="option.html"></p>
                            <hr />
                        </li>
                    </ul>
                </div>
            </div>

            <edit-modal ref="editModal" :question="question" @updated="$emit('updated', $event)" />

        </slot>
    </sidebar>
</template>

<script>
import EditModal from './QuestionModal'
import Sidebar from '@/components/Sidebar'

export default {
    components: {EditModal, Sidebar, },
    props: ['question', ],
}
</script>

