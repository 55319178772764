<template>
    <page>
        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="currentState = $event"
        />

        <sidebar :state="currentState" :all-states="states" v-if="currentState" ref="sidebar" @updated="onUpdate($event)" @close="currentState = null" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import Sidebar from './components/StateSidebar'

export default {
    components: {FastTable, Sidebar, Page, },
    computed: {
        tableData() {
            const tableData = []

            this.states.forEach((s, idx) => {
                const row = {
                    id: idx,
                    isActive: this.currentState && s.id == this.currentState.id,
                    object: s,
                    cells: [
                        s.name,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            states: [],
            dataLoaded: false,
            currentState: null,

            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tax Engine / States')
        this.getStates()
    },
    methods: {
        getStates() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/states`).then(resp => {
                this.states = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate(state) {
            const existing = this.states.find(s => {return s.id == state.id})
            if (existing) {
                Object.assign(existing, state)
            }
        },
    },
}

</script>
