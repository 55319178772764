
const rowMatchesFilters = (row, filters) => {
    let totalFilterMatches = 0
    filters.forEach(filter => {
        let matchesFilter = false
        const rowValues = new Set()
        const filterValues = new Set(filter.filterValues.map(fv => fv.value))

        if (filter.type == 'managers') {
            const rowManagerIds = new Set(row.object.company_security_profile_ids)  // We might want to provide row.managerIds explicitly vs by convention in row.object.company_security_profile_ids
            rowManagerIds.forEach(managerId => {
                if (filterValues.has(managerId)) {
                    matchesFilter = true
                }
            })
        }
        else if (filter.type == 'warning') {
            const warningVal = row.cells[filter.idx]
            if (warningVal == 'error') {
                // For now, ignoring the "scarlet letter" case
            }
            else if (warningVal == parseInt(warningVal, 10) && warningVal > 0) {
                row.object.warnings.forEach(w => {  // We might want to provide row.warnings explicitly vs by convention in row.object.warnings
                    const val = w.warning_type
                    if (filterValues.has(val)) {
                        matchesFilter = true
                    }
                })
            }
        }
        else if (filter.type == 'checkmark') {
            const val = row.cells[filter.idx]
            if (filterValues.has(val)) {
                matchesFilter = true
            }
        }
        else {
            const val = (row.cells[filter.idx] && row.cells[filter.idx].value) ? row.cells[filter.idx].value : row.cells[filter.idx]
            rowValues.add(val)

            if (row.subrows) {
                row.subrows.forEach(subrow => {
                    rowValues.add(subrow[filter.idx])
                })
            }

            rowValues.forEach(rv => {
                if (filterValues.has(rv)) {
                    matchesFilter = true
                }
            })
        }

        if (matchesFilter) {
            totalFilterMatches += 1
        }
    })

    return totalFilterMatches == filters.length  // True if we match every filter provided
}

export default rowMatchesFilters
