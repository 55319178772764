<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title"><span v-if="integration">Edit</span><span v-else>Add</span> POS Integration</template>

        <slot>
            <form-errors :errors="formErrors"/>

            <div class="form-row pb-2">
                <div class="col">
                    <form-input v-model="formData.pos" :errors="errors.pos" type="select" label="POS" :options="posOptions"/>
                </div>
            </div>

            <div class="form-row pb-2">
                <div class="col">
                    <form-input v-model="formData.label" :errors="errors.label" type="text" :nocaps="true" label="Label" />
                </div>
            </div>

            <div class="form-row pb-2">
                <div class="col">
                    <form-input v-model="formData.ref_code" :errors="errors.ref_code" type="text" :nocaps="true" label="Reference Code" :helper-text="'For referencing in Stores list'" />
                </div>
            </div>

            <div class="form-row pb-2" v-if="integration">
                <div class="col">
                    <form-input label="Default Integration" v-model="formData.apply_to_stores_default" :errors="errors.apply_to_stores_default" type="checkbox" :helper-text="'Apply to all stores in Client if no ref_code is set in Stores list'" />
                </div>
            </div>
        </slot>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin],
    props: ['client', 'integration', ],
    data() {
        const posOptions = [
            {text: 'Altametrics (LC)', value: 'lc_alt'},
            {text: 'CaesarVision', value: 'lc'},
            {text: 'Macromatix', value: 'macromatix'},
            {text: 'QSR', value: 'qsr'},
            {text: 'SICOM (BK)', value: 'sicom'},
            {text: 'NBO (Wingstop)', value: 'nbo'},
        ]

        const rules = {
            pos: {presence: {allowEmpty: false}, },
            ref_code: {presence: {allowEmpty: false}, },
            label: {presence: {allowEmpty: false}, },
        }

        return {
            dataLoaded: false,
            posOptions: posOptions,
            formRules: rules,
            formData: this.makeFormData(),
        }
    },
    mounted() {
        
    },
    methods: {
        makeFormData() {
            let formData = {}
            if (this.integration) {
                formData = {
                    pos: this.integration.pos,
                    ref_code: this.integration.ref_code,
                    label: this.integration.label,
                    apply_to_stores_default: this.apply_to_stores_default,
                }
            } else {
                formData = {
                    pos: null,
                    ref_code: 'default',
                    label: 'Default Integration',
                }
            }

            return formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            if (this.integration) {
                // edit (PUT)
                let url = `/admin/clients/${this.client.id}/pos-integrations/${this.integration.id}/main`
                api.put(url, this.formData).then((resp) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('updated', resp)
                    this.close()
                }).catch((errors) => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    this.$store.dispatch('STOP_LOADING')
                })
            } else {
                // create (POST)
                let url = `/admin/clients/${this.client.id}/pos-integrations`
                api.post(url, this.formData).then((resp) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('updated', resp)
                    this.close()
                }).catch((errors) => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    this.$store.dispatch('STOP_LOADING')
                })
            }
        },
    }
}
</script>
