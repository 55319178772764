<template>
    <div class="form-moreinfo-trigger">
        <span ref="trigger" @click.prevent="onClick" href class="btn btn-icon btn-helpinfo help-info" tabindex="0">
            <i class="far fa-fw fa-info-circle"></i>
            <span class="sr-only">More Info</span>
        </span>
        <div style="display: none;" ref="info"><slot></slot></div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    props: ['title', 'placement'],
    mounted() {
        $(this.$refs.trigger).popover({
            boundary: 'viewport', // Otherwise defaults to scrollParent which sometimes doesn't work.
            content: $(this.$refs.info).html(),
            html: true,
            placement: this.placement || 'right',
            title: this.title || '',
            trigger: 'focus'
        })
    },
    methods: {
        onClick($event) {
            $event.stopPropagation()
        }
    },
}
</script>
