<template>
    <div>
        <div class="flex-page-sidebar">
            <nav class="nav-secondary">
                <div class="dropdown">
                    <a class="btn dropdown-toggle" href role="button" id="navSecondaryMenuBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                        Show: {{ state.pageTitle }}
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navSecondaryMenuBtn">
                        <div v-for="o in navOptions">
                            <router-link :to="{name: o.value}" class="dropdown-item" :class="{'active': $route.name == o.value}">{{ o.text }}</router-link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>

        <router-view class="flex-page-content flex-container view"/>
    </div>
</template>

<script>
export default {
    computed: {
        state() {return this.$store.state},
    },
    props: ['client', ],
    data() {
        return {
            navOptions: [
                {'text': 'States', 'value': 'admin-tax-engine-states'},
                {'text': 'Cities', 'value': 'admin-tax-engine-cities'},
            ]
        }
    },
    mounted() {
        const routes = this.navOptions.map(x => x.value)
        if (routes.indexOf(this.$route.name) < 0) {
            this.$router.replace({name: routes[0]})
        }
    },
}
</script>
