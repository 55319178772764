<template>
    <sidebar @close="$emit('close')">
        <template slot="title">
            {{ state.long_name }}
        </template>
        <slot>
            <div class="card mb-2">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Form Selection Rules</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.editModal.open()">Edit</button>
                </div>

                <div class="card-body py-1">
                    <h4>1. Work &amp; Live in {{ state.name }}</h4>
                    <div v-if="selfRule && selfRule.question" class="mb-2">
                        <strong>Question:</strong> {{ selfRule.question.name }}
                    </div>

                    <div v-if="selfRule && selfRule.yaml_form" class="mb-2">
                        <strong>PDF Form:</strong> {{ selfRule.yaml_form.name }}
                    </div>

                    <div v-if="!selfRule" class="mb-2">
                        <em><i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i> Not defined</em>
                    </div>

                    <h4>2. Work in {{ state.name }} &amp; Live Elsewhere</h4>
                    <p v-if="state.work_state_rules.length < 1">
                        No special case rules are defined for {{ state.name }}.
                    </p>

                    <div class="mb-2" v-for="(rule, $index) in otherStates" :key="rule.id">
                        <strong>State:</strong> {{ otherStateNames[$index] }}
                        <div v-if="rule.question">
                            <strong>Question:</strong> {{ rule.question.name }}
                        </div>

                        <div v-if="rule.yaml_form">
                            <strong>PDF Form:</strong> {{ rule.yaml_form.name }}
                        </div>
                    </div>

                    <h4>3. Default out of State Behavior</h4>
                    <div v-if="defaultRule && defaultRule.question" class="mb-2">
                        <strong>Question:</strong> {{ defaultRule.question.name }}
                    </div>

                    <div v-if="defaultRule && defaultRule.yaml_form" class="mb-2">
                        <strong>PDF Form:</strong> {{ defaultRule.yaml_form.name }}
                    </div>

                    <div v-if="!defaultRule" class="mb-2">
                        <em><i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i> Not defined</em>
                    </div>
                </div>

            </div>

            <edit-modal ref="editModal" :state="state" :all-states="allStates" @updated="$emit('updated', $event)" />
        </slot>
    </sidebar>
</template>

<script>
import EditModal from './StateEditModal'
import Sidebar from '@/components/Sidebar'

export default {
    components: {EditModal, Sidebar, },
    props: ['state', 'allStates', ],
    computed: {
        selfRule() {
            return this.state.work_state_rules.find(r => r.resident_state == this.state.id)
        },
        defaultRule() {
            return this.state.work_state_rules.find(r => !r.resident_state)
        },
        otherStates() {
            return this.state.work_state_rules.filter(r => (r.resident_state && r.resident_state != this.state.id))
        },
        otherStateNames() {
            const result = []
            this.otherStates.forEach(rule => {
                result.push(this.allStates.find(s => s.id == rule.resident_state).name)
            })
            return result
        },
    },
    filters: {
        stateNameById(stateId) {
            return this.allStates.find(s => s.id == stateId)
        },
    },
}
</script>

