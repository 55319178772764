<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title">Withdraw Consent to Terms</template>

        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
              <p>
                <strong class="text-danger">Warning:</strong> Withdrawing your consent will 
                <strong>permanently disable your access</strong> to the GetMyPayStub platform. 
                This action cannot be undone.
              </p>
              
              <p>
                Check the boxes below to confirm that you want to proceed:
              </p>
              
              <hr>
            
              <div class="mui-checkbox mb-2">
                <label>
                  <input type="checkbox" v-model="formData.box1">
                  I understand that by withdrawing my consent, my GetMyPayStub account 
                  will be deactivated forever, across all of my devices.
                </label>
              </div>
              
              <div class="mui-checkbox mb-2">
                <label>
                  <input type="checkbox" v-model="formData.box2">
                  I understand that this applies to ALL of my employers who use the 
                  GetMyPayStub platform.
                </label>
              </div>
              
              <div class="mui-checkbox mb-2">
                <label>
                  <input type="checkbox" v-model="formData.box3">
                  I understand that my employers still retain all of my GetMyPayStub 
                  data, including pay stubs and tax documents.
                </label>
              </div>
            
              <hr>
              
              <p>
                <strong>When you click the "Withdraw My Consent" button below, 
            your account will be deactivated and you will be logged out.</strong>
              </p>
              
              <div class="form-row">
                <div class="col">
                  <button type="button" class="btn btn-block btn-outline-primary" data-dismiss="modal">
                    <span class="btn-text">Cancel</span>
                  </button>
                </div>
                <div class="col">
                  <!-- Disabled by default until all boxes above are checked -->
                  <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()" :disabled="! (formData.box1 && formData.box2 && formData.box3)">Withdraw My Consent</button>
                </div>
              </div>
            </form>
        </slot>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'

export default {
    mixins: [ModalMixin, FormMixin],
    props: ['employee', 'user'],
    data() {
        return {
          formData: {
            box1: false,
            box2: false,
            box3: false,
          }
        }
    },
    methods: {
        onSubmit() {
            this.$store.dispatch('START_LOADING')
            api.post('/me/withdraw-consent', {}).then((data) => {
                this.$store.dispatch('STOP_LOADING')
                this.$store.dispatch('LOGOUT')
                this.$router.push({name: 'account-closed'})
                this.close()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        },
    },
}
</script>
