<template>
    <div>
        <div v-if="warnings.length < 1">
            <div class="row-empty">
                This section is empty.
            </div>
        </div>
        <table class="table css-table table-fixed" v-if="warnings.length">
            <thead>
                <tr>
                    <th class="cw-toggle"></th>
                    <th class="cw-date">Date</th>
                    <th>Warning</th>
                </tr>
            </thead>
            <tbody :key="warning.id" v-for="warning in warnings">
                <tr>
                    <td class="cw-toggle position-relative">
                        <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': openWarnings[warning.id]}" @click.prevent="toggleWarning(warning.id)">
                            <collapse-toggle-button />
                        </a>
                    </td>
                    <td>
                        <span>
                            {{ warning.created_on|date }}
                        </span>
                    </td>
                    <td class="truncate-wrapper">
                        <span class="truncate-content">
                            {{ warning.warning_type_pretty }}
                        </span>
                    </td>
                </tr>
                <tr class="child no-transition" :class="{'collapse': !openWarnings[warning.id]}">
                    <td colspan="3">
                        <div class="card card-body item-details">
                            <p>{{ warning.created_on|date('MM/DD/YYYY') }}</p>
                            <p><b>Status: </b>
                                <span class="" v-if="warning.is_resolvable && warning.is_resolved">Resolved</span>
                                <span class="badge badge-lg badge-danger" v-if="warning.is_resolvable && !warning.is_resolved">Unresolved</span>
                                <span class="badge badge-lg badge-danger" v-if="!warning.is_resolvable && warning.is_permanent">Not resolvable</span>
                                <span class="" v-if="!warning.is_resolvable && !warning.is_permanent">No resolution needed</span>
                            </p>
                            <div class="mb-2" v-if="warning.acknowledgements.length">
                                <table class="table table-flush-x">
                                    <thead>
                                        <tr>
                                            <th>Dismissed By:</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody :key="ack.id" v-for="ack in warning.acknowledgements" v-if="ack.acknowledged_on">
                                        <tr>
                                            <td>{{ ack.security_profile.full_name }}</td>
                                            <td>{{ ack.acknowledged_on|date }}</td>
                                            <td>{{ ack.ip }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p>
                                <strong>More info about "{{ warning.warning_type_pretty }}":</strong>
                            </p>

                            <div>
                                <vue-markdown v-if="warning.warning_info">{{ warning.warning_info }}</vue-markdown>
                            </div>

                            <!-- To prevent extra padding w/empty button row,
                                button-row itself is only displayed if
                                either of the child button conditions is met. -->
                            <button-row  v-if="($store.state.user.acknowledged_warning_ids.indexOf(warning.id) < 0 && !warning.is_resolved) || (!warning.is_resolved && warning.is_force_resolvable && $permissions.hasRolesInCompany(warning.client.id, warning.company.id, ['officer', 'payroll-admin']))">
                                <button v-if="$store.state.user.acknowledged_warning_ids.indexOf(warning.id) < 0 && !warning.is_resolved" :disabled="$store.state.user.is_superuser" type="button" class="btn btn-outline-primary" @click.prevent="dismiss(warning)">
                                    Dismiss
                                </button>
                                <button v-if="!warning.is_resolved && warning.is_force_resolvable && $permissions.hasRolesInCompany(warning.client.id, warning.company.id, ['officer', 'payroll-admin'])" type="button" class="btn btn-outline-primary" @click.prevent="resolve(warning)">
                                    Mark as Resolved
                                </button>
                            </button-row>

                            <p class="text-center" v-if="$store.state.user.acknowledged_warning_ids.indexOf(warning.id) < 0 && !warning.is_resolved && $store.state.user.is_superuser"><small>Superusers cannot dismiss warnings.</small></p>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
import VueMarkdown from 'vue-markdown'
import ButtonRow from '@/components/ButtonRow'

export default {
    props: ['warnings', 'activeWarningId', ],
    components: {VueMarkdown, ButtonRow, },
    data() {
        const data = {
            openWarnings: {},
        }

        if (this.activeWarningId) {
            data.openWarnings[this.activeWarningId] = true
        }

        return data
    },
    methods: {
        toggleWarning(warningId) {
            this.$set(this.openWarnings, warningId, !this.openWarnings[warningId])
        },
        dismiss(warning) {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${warning.client}/warnings/${warning.id}`, {acknowledge: true}).then(resp => {
                this.$store.dispatch('GET_USER').then(() => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('updated', resp)
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors)
            })
        },
        resolve(warning) {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${warning.client}/warnings/${warning.id}`, {resolve: true}).then(resp => {
                this.$store.dispatch('GET_USER').then(() => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('updated', resp)
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors)
            })
        },
    },
}
</script>
