<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title">Change Phone Number</template>

        <slot>
            <h5 class="mb-3">
                Organization: 
                <span class="text-nowrap">{{ sec_profile.client.name }}</span>
            </h5>

            <div v-if="mode == 'phone'">
                <p>Employees may contact you for help with security PIN resets. Please provide a phone number you wish for them to use.</p>
                <form @submit.prevent="onPhoneNumberSubmit" novalidate class="mui-form">
                    <form-errors :errors="formErrors"/>
                    <div class="form-row">
                        <div class="col-6 col-sm-5">
                            <form-input label="Phone Number" v-model="phoneFormData.phone" :errors="errors.phone" type="phone" />
                        </div>
                        <div class="col-4 col-sm-3">
                            <form-input label="Ext. (Optional)" v-model="phoneFormData.extension" :errors="errors.extension" type="integer-string" />
                        </div>
                    </div>
                    <p>
                        Next, we will verify this phone number via text message or an automated voice call.
                    </p>
                    <div class="row mb-2 d-flex align-items-center">
                        <div class="col">
                            <button type="button" class="btn btn-outline-primary" @click.prevent="close">Cancel</button>
                        </div>
                        <div class="col text-right">
                            <button type="submit" class="btn btn-primary">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
            
            <div v-if="mode == 'contact_method'">
                <h3>Verify Your Phone Number</h3>
                <p>
                    We will now send a verification code to the phone number you provided:
                </p>
                <p class="text-large text-muted">
                    {{ phoneFormatted }}
                </p>
                <form @submit.prevent="onContactMethodSubmit" novalidate class="mui-form">
                    <form-errors :errors="formErrors"/>
                    <div class="form-row">
                        <div class="col-12">
                            <form-input label="How do you want to get your code?" v-model="contactMethodFormData.contact_method" type="radio" :options="contactMethodOptions"/>
                        </div>
                    </div>
                    <div class="row mb-2 d-flex align-items-center">
                        <div class="col">
                            <button class="btn btn-outline-primary" @click.prevent="setMode('phone')">Back</button>
                        </div>
                        <div class="col text-right">
                            <button type="submit" class="btn btn-primary">Get Code</button>
                        </div>
                    </div>
                </form>
            </div>

            <div v-if="mode == 'code'">
                <h3>Enter Your Verification Code</h3>
                <form @submit.prevent="onPhoneValidationSubmit" novalidate class="mui-form">
                    <form-errors :errors="formErrors"/>
                    <p v-if="contactMethodFormData.contact_method == 'sms'">
                        Enter the code you received via text message:
                    </p>
                    <p v-if="contactMethodFormData.contact_method == 'call'">
                        Enter the code you received via voice call:
                    </p>
                    <div class="form-row">
                        <div class="col-6">
                            <form-input placeholder="Verification Code" v-model="phoneValidationFormData.verification_code" :errors="errors.verification_code" type="tel" pattern="[0-9]*" />
                        </div>
                    </div>
                    <p class="text-small text-muted">
                        Didn't receive the code? Click Back to try again.
                    </p>
                    <div class="row mb-2 d-flex align-items-center">
                        <div class="col">
                            <button class="btn btn-outline-primary" @click.prevent="setMode('contact_method')">Back</button>
                        </div>
                        <div class="col text-right">
                            <button type="submit" class="btn btn-primary">Get Code</button>
                        </div>
                    </div>
                </form>
            </div>

            <div v-if="mode == 'complete'">
                <div class="">
                    <p>
                        Your phone number was successfully changed.
                    </p>
                    
                    <div class="row mb-2 d-flex align-items-center">
                        <div class="col-12 col-sm-6 col-sm-offset-3">
                            <button type="button" class="btn btn-outline-primary" @click.prevent="close">Done</button>
                        </div>
                    </div>
                </div>
            </div>

        </slot>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'

const contactMethodOptions = [
    {text: 'Text message (SMS)', value: 'sms'},
    {text: 'Automated voice call', value: 'call'},
]

export default {
    mixins: [ModalMixin, FormMixin],
    props: ['sec_profile'],
    data() {
        return {
            mode: 'phone',
            phoneFormData: {
                phone: this.sec_profile.phone_plain,
                extension: this.sec_profile.phone_ext,
            },
            contactMethodFormData: {
                contact_method: '',
            },
            phoneValidationFormData: {
                verification_code: '',
            },
            phoneFormRules: {
                phone: {presence: {allowEmpty: false}},
            },
            contactMethodFormRules: {
                contact_method: {presence: {allowEmpty: false}},
            },
            phoneValidationFormRules: {
                verification_code: {
                    presence: {allowEmpty: false},
                },
            },
        }
    },
    computed: {
        contactMethodOptions() {return contactMethodOptions},
        phoneFormatted() {
            let p = this.phoneFormData.phone
            let val = '(' + p.substring(0, 3) + ') ' + p.substring(3, 6) + '-' + p.substring(6, 10)
            if (this.phoneFormData.extension) {
                val = val + ' x' + this.phoneFormData.extension
            }
            return val
        }
    },
    methods: {
        getFormRules() {
            switch (this.mode) {
                case 'phone':
                    return this.phoneFormRules;
                    break;
                case 'contact_method':
                    return this.contactMethodRules;
                    break;
                case 'code':
                    return this.phoneValidationFormRules;
                    break;
                return {};
            }
        },
        getFormData() {
            switch (this.mode) {
                case 'phone':
                    return this.phoneFormData;
                    break;
                case 'contact_method':
                    return this.contactMethodFormData;
                    break;
                case 'code':
                    return this.phoneValidationFormData;
                    break;
                default:
                    return {};
            }
        },
        setMode(mode) {
            this.mode = mode
        },
        onPhoneNumberSubmit() {
            this.errors = {}
            this.formErrors = []
            if (!this.validate()) {
                return
            }

            this.mode = 'contact_method'
            if (this.phoneFormData.extension) {
                this.contactMethodFormData.contact_method = 'call'
            } else {
                this.contactMethodFormData.contact_method = 'sms'
            }
        },
        onContactMethodSubmit() {
            this.errors = {}
            this.formErrors = []
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            const mergedFormData = {...this.phoneFormData, ...this.contactMethodFormData}
            api.post(`/me/clients/${this.sec_profile.client.id}/update/${this.sec_profile.id}/phone`, mergedFormData).then((resp) => {
                this.mode = 'code'
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        onPhoneValidationSubmit() {
            this.errors = {}
            this.formErrors = []
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/me/clients/${this.sec_profile.client.id}/update/${this.sec_profile.id}/phone-validation`, this.phoneValidationFormData).then((resp) => {
                this.mode = 'complete'
                this.$store.dispatch('STOP_LOADING')
                // update the parent
                this.$emit('updated')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },

}
</script>
