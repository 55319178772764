<template>
    <div>
        <div v-if="!mode">
            <div class="mb-2">
                <a href @click.prevent="close()"><i class="far fa-fw fa-chevron-left mr-hf"></i>Employee Info</a>
            </div>

            <h2 class="text-thin text-muted mb-2">Approval Checklist</h2>

            <p>
                <strong>Complete all items below</strong> to approve this employee. Items can be completed in any order.
            </p>

            <ul class="list-unstyled border-top">
                <li class="px-1 py-2 border-bottom">
                    <a class="todo-item" :class="{'todo-item-completed': employee.hr_info_approved_on}" href @click.prevent="mode = 'hr'"><span class="todo-item-content">HR and Pay Information</span></a>
                </li>
                <li class="px-1 py-2 border-bottom">
                    <a class="todo-item" :class="{'todo-item-completed': allFormsApproved}" href @click.prevent="mode = 'forms'"><span class="todo-item-content">Required Documents</span></a>
                </li>
                <li class="px-1 py-2 border-bottom">
                    <a class="todo-item" :class="{'todo-item-completed': employee.direct_deposit_approved_on}" href @click.prevent="mode = 'direct-deposit'"><span class="todo-item-content">Direct Deposit Information</span></a>
                </li>
            </ul>

            <div class="mt-4">
                <button @click.prevent="approve()" :disabled="!readyForApproval" class="btn btn-block btn-primary">Approve</button>
            </div>
        </div>

        <div v-if="mode == 'hr'">
            <div class="mb-2">
                <a href @click.prevent="mode = ''"><i class="far fa-fw fa-chevron-left mr-hf"></i>Approval Checklist</a>
            </div>

            <h2 class="text-thin text-muted mb-2">HR and Pay Information</h2>
            <employee-details :client="client" :employee="employee" @updated="$emit('updated', $event)" :canEdit="!employee.hr_info_approved_on" @edit="$emit('edit')"/>

            <div class="mt-4">
                <button @click.prevent="onHRFormSubmitted()" class="btn btn-block btn-primary" v-if="!employee.hr_info_approved_on">Accept HR and Pay Info</button>
            </div>
        </div>

        <div v-if="mode == 'forms'">
            <div class="mb-2">
                <a href @click.prevent="mode = ''"><i class="far fa-fw fa-chevron-left mr-hf"></i>Approval Checklist</a>
            </div>

            <h2 class="text-thin text-muted mb-2">Required Documents</h2>
            <p>Complete the {{ totalDocumentCount }} {{ totalDocumentCount == 1 ? 'document' : 'documents' }} below.</p>

            <div class="card doc-item mb-2" v-for="form in forms" :key="form.id">
                <div class="card-body">
                    <div class="todo-item" :class="{'todo-item-completed': form.accepted_on}">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">{{ form.yaml_form.title }}</h6>

                            <div class="doc-action-primary mb-1" v-if="form.yaml_form.form_type != 'i9'">
                                <a v-if="form.accepted_on" :href="`${API_BASE}/clients/${client.id}/pdf-files/${form.id}`" class="btn btn-outline-primary" @click.prevent="setCurrentPDF(form)"><i class="far fa-fw fa-external-link mr-1"></i>View Document</a>
                                <a v-if="!form.accepted_on" :href="`${API_BASE}/clients/${client.id}/pdf-files/${form.id}`" class="btn btn-outline-primary" @click.prevent="setCurrentPDF(form)"><i class="far fa-fw fa-external-link mr-1"></i>Sign Document</a>
                            </div>

                            <div class="doc-action-primary mb-1" v-if="form.yaml_form.form_type == 'i9'">
                                <a href class="btn btn-outline-primary" v-if="!form.accepted_on" @click.prevent="openI9"><i class="far fa-fw fa-pencil mr-1"></i>Complete Document</a>
                                <a v-if="form.accepted_on" :href="`${API_BASE}/clients/${client.id}/pdf-files/${form.id}`" class="btn btn-outline-primary" @click.prevent="setCurrentPDF(form)"><i class="far fa-fw fa-external-link mr-1"></i>View Document</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card doc-item mb-2" v-for="hr_doc in employee.hr_documents" :key="hr_doc.id">
                <div class="card-body">
                    <div class="todo-item" :class="{'todo-item-completed': hr_doc.accepted_on}">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">{{ hr_doc.hr_document.name }}</h6>

                            <div class="doc-action-primary mb-1">
                                <a v-if="hr_doc.accepted_on" href class="btn btn-outline-primary" @click.prevent="setCurrentPDF(hr_doc)"><i class="far fa-fw fa-external-link mr-1"></i>View Document</a>
                                <a v-if="!hr_doc.accepted_on" href class="btn btn-outline-primary" @click.prevent="setCurrentPDF(hr_doc)"><i class="far fa-fw fa-external-link mr-1"></i>Sign Document</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <pdf-modal :url="currentPDF.url" :title="currentPDF.title" v-if="currentPDF" @close="setCurrentPDF(null)" ref="pdfModal">
                <template slot="footer" v-if="(currentForm && !currentForm.accepted_on) || (currentHRDocument && !currentHRDocument.accepted_on)">
                    <button-row stacked>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="$refs.pdfModal.close()">
                            Close
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="signForm()">
                            Sign Document
                        </button>
                    </button-row>
                </template>
            </pdf-modal>
        </div>

        <div v-if="mode == 'i9'">
            <div class="mb-2">
                <a href @click.prevent="mode = 'forms'"><i class="far fa-fw fa-chevron-left mr-hf"></i>Required Documents</a>
            </div>

            <h2 class="text-thin text-muted">I-9 Employment Eligibility Verification</h2>

            <hr class="my-3">

            <div class="completion-message p-4 mt-4 mb-2 text-center rounded">
                <span class="lead">
                    <i class="far fa-fw fa-check-circle mr-1"></i>Section complete &mdash; nice work.
                </span>
            </div>

        </div>
        <!-- /I-9 Container -->



        <!-- Direct Deposit Container -->

        <div v-if="mode == 'direct-deposit'">
            <div class="mb-2">
                <a href @click.prevent="mode = ''"><i class="far fa-fw fa-chevron-left mr-hf"></i>Approval Checklist</a>
            </div>

            <h2 class="text-thin text-muted mb-2">Direct Deposit Information</h2>
            <direct-deposit-form
                :employee="employee"
                :url="`/clients/${client.id}/onboarding-applications/${this.employee.id}/direct-deposit-accounts`"
                @paymasters_direct_deposit_accounts_updated="onAppUpdated($event)"
                :readonly="employee.direct_deposit_approved_on"
            />

            <div class="mt-4">
                <button @click.prevent="acceptDirectDepositInfo()" type="button" class="btn btn-primary btn-block" v-if="!employee.direct_deposit_approved_on">Accept Direct Deposit Info</button>
            </div>
        </div>

        <i9-modal :client="client" :employee="employee" @updated="onAppUpdated($event)" ref="i9Modal" v-if="i9Form && i9ModalOpen" @close="i9ModalOpen = false"/>
        <approval-modal :client="client" :employee="employee" @updated="onAppUpdated($event)" ref="approvalModal"/>
    </div>
</template>

<script>
import JobForm from './JobForm'
import DirectDepositForm from '@/components/DirectDepositForm'
import EmployeeDetails from './EmployeeDetails'
import ButtonRow from '@/components/ButtonRow'
import PdfModal from '@/components/PdfModal'
import I9Modal from './I9Modal'
import ApprovalModal from './ApprovalModal'
import api from '@/api'
import bus from '@/bus'
import moment from 'moment'
import YAML from 'js-yaml'

export default {
    components: {JobForm, DirectDepositForm, EmployeeDetails, ButtonRow, PdfModal, I9Modal, ApprovalModal},
    props: {employee: {required: true}, client: {required: true}},
    computed: {
        acaCategoryOptions() {
            return [
                {text: 'Full Time', value: 'Full Time'},
                {text: 'Variable', value: 'Variable'},
            ]
        },
        API_BASE() {
            return api.API_BASE
        },
        allFormsApproved() {
            const unapprovedForms = this.employee.forms.filter((f) => {
                return !f.accepted_on
            }).length
            const unapprovedDocs = this.employee.hr_documents.filter((f) => {
                return !f.accepted_on
            }).length

            return (unapprovedForms + unapprovedDocs) == 0
        },
        readyForApproval() {
            return (
                this.employee.hr_info_approved_on &&
                this.allFormsApproved &&
                this.employee.direct_deposit_approved_on
            )
        },
        forms() {
            const forms = this.employee.forms
            forms.sort((a, b) => {
                if (a.yaml_form.priority_order == b.yaml_form.priority_order) {
                    return a.id > b.id ? 1 : -1
                }
                return (a.yaml_form.priority_order > b.yaml_form.priority_order) ? 1 : -1
            })
            return forms
        },
        i9Form() {
            return this.employee.forms.find(f => {return f.yaml_form.form_type == 'i9'})
        },
        totalDocumentCount() {
            return this.employee.forms.length + this.employee.hr_documents.length
        },
    },
    data() {
        return {
            mode: '',
            currentPDF: null,
            currentForm: null,
            currentHRDocument: null,
            i9ModalOpen: false,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        onAppUpdated() {
            this.$store.dispatch('START_LOADING')
            return api.get(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}`).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        setCurrentPDF(form) {
            if (!form) {
                this.currentPDF = null
                this.currentForm = null
                this.currentHRDocument = null
                return
            }

            if (form.yaml_form) {
                this.currentForm = form
                this.currentHRDocument = null

                this.currentPDF = {
                    title: this.parseYAML(form.yaml_form.yaml).title,
                    url: {
                        url: `${this.API_BASE}/clients/${this.client.id}/onboarding-applications/${this.employee.id}/forms/${form.id}/pdf`,
                        withCredentials: true,
                    },
                    requires_admin_signature: true,
                }
            }

            if (form.hr_document) {
                this.currentForm = null
                this.currentHRDocument = form

                this.currentPDF = {
                    title: form.hr_document.name,
                    url: {
                        url: `${this.API_BASE}/clients/${this.client.id}/onboarding-applications/${this.employee.id}/hr-documents/${form.id}/pdf`,
                        withCredentials: true,
                    },
                    requires_admin_signature: true,
                }
            }
        },
        signForm() {
            const signatureData = {
                'date': moment().format('MM/DD/YYYY'),
                'datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),
            }

            let url;
            if (this.currentForm) {
                url = `/clients/${this.client.id}/onboarding-applications/${this.employee.id}/forms/${this.currentForm.id}/sign`
            }
            else if (this.currentHRDocument) {
                url = `/clients/${this.client.id}/onboarding-applications/${this.employee.id}/hr-documents/${this.currentHRDocument.id}/sign`
            }

            this.$store.dispatch('START_LOADING')
            api.post(url, signatureData).then(() => {
                this.onAppUpdated().then(() => {
                    this.setCurrentPDF(null)
                    this.$refs.pdfModal.close()

                    if (this.allFormsApproved) {
                        this.mode = ''
                    }
                })
            }).catch((errors) => {
                bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        acceptDirectDepositInfo() {
            this.$store.dispatch('START_LOADING')
            api.post(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}/direct-deposit-info`, {}).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.mode = ''
            }).catch((errors) => {
                bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        onHRFormSubmitted() {
            this.$store.dispatch('START_LOADING')
            api.post(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}/hr-info`, {}).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.mode = ''
            }).catch((errors) => {
                bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        approve() {
            this.$refs.approvalModal.open()
        },
        parseYAML(yaml) {
            return YAML.safeLoad(yaml)
        },
        openI9() {
            this.setCurrentPDF(null)
            this.i9ModalOpen = true
            this.$nextTick(() => {
                this.$refs.i9Modal.open()
            })
        }
    },
    filters: {
        yamlTitle(yaml) {
            return YAML.safeLoad(yaml).title
        },
    },
}
</script>
