<template>
    <modal size="xl" @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">

        <template slot="title">
            Update State: <span class="text-thin text-nowrap">{{ state.name }}</span>
        </template>

        <slot>
            <form class="mui-form container" @submit.prevent="onSubmit()" style="min-height: 20em;">
                <form-errors :errors="formErrors"/>

                <h4>1. Work &amp; Live in {{ state.name }}</h4>
                <div class="form-row">
                    <div class="col-12 col-md-4">
                        <form-input v-model="formData.result" :errors="errors.result" type="select" label="Question/PDF Form" :opt-groups="questionsFormsOptGroup" />
                    </div>
                </div>

                <hr/>

                <h4>2. Work in {{ state.name }} &amp; Live Elsewhere</h4>
                <div class="form-row" v-for="(rule, $index) in formData.other_states">
                    <div class="col-12 col-md-2">
                        <form-input v-model="formData.other_states[$index].state" :errors="(errors.other_states && errors.other_states[$index]) ? errors.other_states[$index].state : []" type="select" label="Resident State" :options="otherStateOptions" />
                    </div>

                    <div class="col-12 col-md-4">
                        <form-input v-model="formData.other_states[$index].result" :errors="(errors.other_states && errors.other_states[$index]) ? errors.other_states[$index].result : []" type="select" label="Question/PDF Form" :opt-groups="questionsFormsOptGroup" />
                    </div>

                    <div class="col col-md-1">
                        <button class="btn btn-outline-primary mt-1" @click.prevent="removeRule(rule)">Remove</button>
                    </div>
                </div>

                <p v-if="formData.other_states.length < 1">
                    No special case rules are defined for {{ state.name }}.
                </p>

                <div class="mb-2">
                    <button class="btn btn-link" @click.prevent="addRule()">+ Add a Rule</button>
                </div>

                <hr/>

                <h4>3. Default out of State Behavior</h4>
                <div class="form-row">
                    <div class="col-12 col-md-4">
                        <form-input v-model="formData.default_result" :errors="errors.default_result" type="select" label="Question/PDF Form" :opt-groups="questionsFormsOptGroup" />
                    </div>
                </div>

            </form>
        </slot>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import {validateForm} from '@/utils'

export default {
    props: ['state', 'allStates', ],
    mixins: [FormMixin, ModalMixin],
    computed: {
        otherStateOptions() {
            const opts = []
            this.allStates.forEach(s => {
                if (s.id != this.state.id) {
                    opts.push({text: s.name, value: s.id})
                }
            })
            return opts
        },
        questionOptions() {
            const opts = []
            this.questions.forEach(q => {
                opts.push({text: q.name, value: q.id})
            })
            return opts
        },
        formOptions() {
            const opts = []
            this.forms.forEach(f => {
                opts.push({text: f.name, value: f.id})
            })
            return opts
        },
        questionsFormsOptGroup() {
            const groups = []
            const qg = {
                label: 'Questions',
                options: []
            }
            this.questions.forEach(q => {
                qg.options.push({text: q.name, value: `question-${q.id}`})
            })
            if (qg.options.length) {
                groups.push(qg)
            }

            const fg = {
                label: 'PDF Forms',
                options: []
            }
            this.forms.forEach(f => {
                fg.options.push({text: f.name, value: `form-${f.id}`})
            })

            if (fg.options.length) {
                groups.push(fg)
            }

            return groups
        },
    },
    watch: {
        state() {
            this.reset()
        }
    },
    data() {
        return {
            formLabels: {
                default_result: 'This field',
                result: 'This field',
            },
            formRules: {
                default_result: {presence: {allowEmpty: false},},
                result: {presence: {allowEmpty: false},},
            },
            formData: this.makeFormData(),
            errors: this.makeFormErrors(),
            forms: [],
            questions: [],
        }
    },
    mounted() {
        this.reset()
    },
    methods: {
        makeFormData() {
            const data = {
                result: null,
                default_result: null,
            }
            data.other_states = []

            this.state.work_state_rules.forEach(r => {
                if (r.resident_state && r.resident_state != this.state.id) {
                    let result = null
                    if (r.yaml_form) {
                        result = `form-${r.yaml_form.id}`
                    }
                    else if (r.question) {
                        result = `question-${r.question.id}`
                    }
                    data.other_states.push({
                        id: r.id,
                        state: r.resident_state,
                        result: result,
                    })
                }
            })

            const selfRule = this.state.work_state_rules.find(r => r.resident_state == this.state.id)
            if (selfRule && selfRule.yaml_form) {
                data.result = `form-${selfRule.yaml_form.id}`
            }
            else if (selfRule && selfRule.question) {
                data.result = `question-${selfRule.question.id}`
            }

            const defaultRule = this.state.work_state_rules.find(r => !r.resident_state)
            if (defaultRule && defaultRule.yaml_form) {
                data.default_result = `form-${defaultRule.yaml_form.id}`
            }
            else if (defaultRule && defaultRule.question) {
                data.default_result = `question-${defaultRule.question.id}`
            }

            return data
        },
        makeFormErrors() {
            return {other_states: []}
        },
        validate() {
            let isValid = FormMixin.methods.validate.call(this, ['result', 'default_result'])
            if (!this.errors.other_states) {
                this.errors.other_states = []
            }

            this.formData.other_states.forEach(r => {
                const rules = {
                    state: {presence: {allowEmpty: false},},
                    result: {presence: {allowEmpty: false},},
                }
                const labels = {
                    result: 'This field',
                }
                const errors = validateForm(r, rules, labels)
                this.errors.other_states.push(errors ? errors : {state: [], result: []})

                if (errors) {
                    isValid = false
                }
            })

            return isValid
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            return api.put(`/admin/tax-engine/states/${this.state.id}`, this.getFormData()).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        reset() {
            FormMixin.methods.reset.call(this)
            this.errors = this.makeFormErrors()
            this.getQuestions()
            this.getPDFForms()
        },
        getQuestions() {
            api.get(`/admin/pdf-forms/questions`).then(resp => {
                this.questions = resp.filter(q => {
                    return q.state && (q.state.id == this.state.id)
                })
            }).catch((errors) => {
                bus.showError(errors.__all__)
            })
        },
        getPDFForms() {
            api.get(`/admin/pdf-forms/forms`).then(resp => {
                this.forms = resp.filter(f => {
                    return f.state && (f.state.id == this.state.id)
                })
            }).catch((errors) => {
                bus.showError(errors.__all__)
            })
        },
        addRule() {
            this.formData.other_states.push({
                id: null,
                state: null,
                result: null,
            })
        },
        removeRule(rule) {
            const idx = this.formData.other_states.indexOf(rule)
            if (idx < 0) {return}

            this.formData.other_states = this.formData.other_states.filter((r, i) =>  i != idx)
        },
    }
}
</script>
