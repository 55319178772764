<template>
    <modal @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable" size="xl" :height-max="true" bodyInnerClass="flex-container flex-body">
        <template slot="title">
            <h3 class="modal-title" v-if="!editMode">Add HR Template: <span class="text-thin text-nowrap">Template Information</span></h3>
            <h3 class="modal-title" v-if="editMode">Edit HR Template: <span class="text-thin text-nowrap">Template Information</span></h3>
        </template>

        <template slot="subheading">
            <div class="mt-hf bg-150 p-1 rounded" v-show="mode != 'name'">
                <span class="text-muted">Company:</span> {{ formData.company }}
            </div>
            <div class="mt-hf bg-150 p-1 rounded" v-show="mode != 'name'">
                <span class="text-muted">Template:</span> {{ formData.name }}
            </div>
        </template>

        <slot>
            <form class="mui-form flex-container flex-body" @submit.prevent="onSubmit()">
                <div v-show="mode == 'name'" class="mt-4">
                    <div class="form-row">
                        <div class="col">
                            <form-input label="Company" v-model="formData.company" :errors="errors.company" type="select" :options="companyOptions"/>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col">
                            <form-input v-model="formData.name" :errors="errors.name" label="Template Name" type="text" />
                        </div>
                    </div>
                </div>

                <div v-show="mode == 'documents'" :class="{'d-flex flex-grow-1 flex-column': mode == 'documents'}">
                    <document-selector ref="docSelector" :all-documents="companyDocuments" v-model="formData.documents" />
                </div>

                <div v-show="mode == 'positions'" :class="{'d-flex flex-grow-1 flex-column': mode == 'positions'}">
                    <position-selector ref="positionSelector" :all-positions="companyPositions" v-model="formData.positions" />
                </div>

                <div v-show="mode == 'confirmation'">
                    <div class="row">
                        <div class="col">
                            <p>This template will include <strong>{{ formData.documents.length }} document{{ formData.documents.length == 1 ? '' : 's' }}</strong>:</p>
                            <ul>
                                <li v-for="doc in selectedDocuments" :key="doc.id">{{ doc.name }}</li>
                            </ul>

                            <p>This template will be assigned to <strong>{{ formData.positions.length }} position{{ formData.positions.length == 1 ? '' : 's' }}</strong>:</p>
                            <ul>
                                <li v-for="pos in selectedPositions" :key="pos.id">{{ pos.terminal_value }}</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </form>
        </slot>

        <template slot="footer">
            <form-errors :errors="formErrors"/>

            <button-row>
                <button v-if="(editMode && (mode == 'documents')) || (mode == 'name')" type="button" class="btn btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button v-else type="button" class="btn btn-outline-primary" @click.prevent="previousStep()">Back</button>

                <button v-if="mode == 'confirmation'" type="submit" class="btn btn-primary" @click.prevent="onSubmit()">Save</button>
                <button v-else type="button" class="btn btn-primary" @click.prevent="nextStep()">Continue</button>
            </button-row>

        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'
import DocumentSelector from './DocumentSelector'
import PositionSelector from './PositionSelector'

export default {
    components: {DocumentSelector, PositionSelector, },
    props: ['client', 'template'],
    mixins: [FormMixin, ModalMixin],
    computed: {
        companyOptions() {
            const options = []
            for (let company of (this.client.companies || [])) {
                options.push({text: company.name, value: company.id})
            }
            return options
        },
        selectedDocuments() {
            return this.documents.filter(d => {return this.formData.documents.indexOf(d.id) >= 0})
        },
        selectedPositions() {
            return this.positions.filter(d => {return this.formData.positions.indexOf(d.id) >= 0})
        },
        companyDocuments() {
            if (!this.formData.company) {
                return []
            }
            return this.documents.filter(d => d.company.id == this.formData.company)
        },
        companyPositions() {
            if (!this.formData.company) {
                return []
            }
            return this.positions.filter(p => p.company.id == this.formData.company)
        },
    },
    data() {
        return {
            formData: this.makeFormData(),
            formRules: {
                company: {presence: {allowEmpty: false}, },
                name: {presence: {allowEmpty: false}, },
            },
            mode: 'name',  // Can be 'name', 'documents', 'positions', 'confirmation'
            editMode: !!this.template,
            documents: [],
            positions: [],
        }
    },
    watch: {
        template() {
            this.reset()
        }
    },
    mounted() {
        this.getDocuments()
        this.getPositions()
    },
    methods: {
        makeFormData() {
            if (this.template) {
                return {
                    company: this.template.company.id,
                    name: this.template.name,
                    documents: this.template.hr_documents.map(d => d.id),
                    positions: this.template.positions.map(p => p.id),
                }
            }

            return {
                company: null,
                name: '',
                documents: [],
                positions: [],
            }
        },
        reset() {
            this.$refs.docSelector.reset()
            this.mode = 'name'
            if (this.editMode) {
                this.mode = 'documents'
            }
            FormMixin.methods.reset.call(this)
        },
        getDocuments() {
            this.$api.get(`/clients/${this.client.id}/hr-documents`).then(docs => {
                this.documents = docs.filter(d => {return d.is_active})
                this.documents.sort((a, b) => {return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1})
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getPositions() {
            this.$api.get(`/clients/${this.client.id}/positions`).then(resp => {
                this.positions = resp
                this.positions.sort((a, b) => {return a.terminal_value.toLowerCase() > b.terminal_value.toLowerCase() ? 1 : -1})
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            const promise = this.template
                    ? this.$api.put(`/clients/${this.client.id}/hr-templates/${this.template.id}`, this.getFormData())
                    : this.$api.post(`/clients/${this.client.id}/hr-templates`, this.getFormData())

            this.$store.dispatch('START_LOADING')
            promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })

        },

        previousStep() {
            const STEPS = {
                'documents': 'name',
                'positions': 'documents',
                'confirmation': 'positions',
            }

            if (STEPS[this.mode]) {
                this.mode = STEPS[this.mode]
            }

            this.$refs.modal.resize()
        },
        nextStep() {
            const STEPS = {
                'name': 'documents',
                'documents': 'positions',
                'positions': 'confirmation',
            }

            if (this.mode == 'name') {
                if (!this.validate()) {
                    return
                }
            }

            if (STEPS[this.mode]) {
                this.mode = STEPS[this.mode]
            }

            this.$refs.modal.resize()
        },
    }
}
</script>
