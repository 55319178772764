<script>
import {validateForm} from '@/utils'
import Router from '@/router'

export default {
    data() {
        return {
            //formRules: {},  // Form validation rules
            formLabels: {},  // Used to customize labels for inputs
            formData: {},  // Holds values from inputs
            errors: {},  // Field errors
            formErrors: [],  // Global form errors

            formSubmitAction: null,  // What VUEX action to take on successful form submit
            formSuccessPath: null,  // If formSubmitAction succeeds, tell the router to go to this page
            isPristine: true,
        }
    },
    watch: {
        formData: {
            handler() {
                this.isPristine = false
            },
            deep: true,
        },
        isPristine() {
            this.$emit('pristine_status', this.isPristine)
        },
        formErrors() {
            this.$emit('form_errors', this.formErrors);
        },
    },
    methods: {
        makeFormData() {
            return {}
        },
        // Used to customize what gets passed to this.formSubmitAction on successful form submit
        getFormData() {
            return this.formData
        },
        validate(fields=[]) {
            this.errors = {}

            const rules = this.getFormRules(fields)
            const labels = this.getFormLabels(fields)
            // Client side validation
            const errors = validateForm(this.getFormData(), rules, labels)
            if (errors) {
                this.errors = errors
                return false
            }

            return true
        },
        getFormLabels(fields) {
            fields = (fields && fields.length) ? fields : Object.keys(this.formLabels)
            const labels = {}
            fields.forEach((field) => {
                if (field in this.formLabels) {
                    labels[field] = this.formLabels[field]
                }
            })

            return labels
        },
        getFormRules(fields) {
            fields = (fields && fields.length) ? fields : Object.keys(this.formRules || {})
            const rules = {}
            fields.forEach((field) => {
                const rule = this.getFormRule(field)
                if (rule) {
                    rules[field] = rule
                }
            })

            return rules
        },
        getFormRule(field) {
            if (field in (this.formRules || {})) {
                return this.formRules[field]
            }

            return null
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }
            if (this.formSubmitAction) {
                // Dispatch the Vuex action with the form data
                return this.$store.dispatch(this.formSubmitAction, this.getFormData()).then((resp) => {
                    if (this.formSuccessPath) {
                        Router.push(this.formSuccessPath)
                    }

                    this.onSuccess()

                    this.reset()
                    return resp
                }).catch((errors) => {
                    // Server returned errors. Show them.
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                })
            }
        },
        onSuccess() {
        },
        onError() {
        },
        reset() {
            this.errors = {}
            this.formErrors = []
            this.formData = this.makeFormData()
            this.$nextTick(() => {
                this.isPristine = true
            })
        },
    }
}
</script>
