<template>
    <div ref="trWrapper" class="tr-wrapper" :class="{
      'active': source.isActive,
      'tr-sectionhead': source.isHeading,
      'tr-head': source.isHeaders,
      'tr-empty': source.isEmpty,
      'tr-content': !source.isHeading && !source.isHeaders && !source.isEmpty
    }">
        <!-- Section Heading -->
        <div class="tr-sectionhead-bar" v-if="source.isHeading">
            <div class="tr-sectionhead-sticky" :style="{'width': stickyWidth + 'px'}">
                <div class="tr-sectionhead-title-wrapper">
                    <a href class="btn btn-collapse-toggle pl-0 d-inline" :class="{'is-expanded': !source.isClosed}" role="button" aria-expanded="true" @click.prevent="toggleSection">
                      <span class="btn btn-icon">
                        <i class="fas fa-minus-circle"></i>
                      </span>
                      <span class="sr-only">Collapse</span>
                      <span class="tr-sectionhead-title">
                        <h5 class="m-0">{{ source.title }} <span class="text-regular">({{ source.count }})</span></h5>
                      </span>
                    </a>
                </div>

                <div class="tr-sectionhead-menu" v-if="source.useDateFilters">
                    <date-filter-trigger :date-filter="source.dateFilter" @click="onDateFilterClick" />
                </div>
            </div>
        </div>
        <!-- Column Headers -->
        <div class="tr" v-if="source.isHeaders && !source.isClosed">
            <div class="td anchor-col col-sortable" @click.prevent="onSort(0)" :class="{'col-sort-asc': (0 == sortColumnIdx && sortIsAsc), 'col-sort-desc': (0 == sortColumnIdx && !sortIsAsc)}">
                <label class="row-checkbox"><input type="checkbox" aria-label="Select Row"></label>
                <div class="cell-content">{{ headers[0].label }} <span class="sort-icon"></span></div>
            </div>

            <div v-for="(header, idx) in headers.slice(1)" @click.prevent="onSort(idx + 1)" class="td col-sortable" :class="[header.classes, (sortColumnIdx == (idx + 1) && sortIsAsc) ? 'col-sort-asc' : '', (sortColumnIdx == (idx + 1) && !sortIsAsc) ? 'col-sort-desc' : '']">
                <div class="cell-content">{{ header.label }} <span class="sort-icon"></span></div>
            </div>
        </div>

        <div class="tr-empty-sticky" v-if="source.isEmpty">
            <em class="text-muted">This section is empty.</em>
        </div>

        <div class="tr" v-if="!source.isHeading && !source.isHeaders && !source.isEmpty" @click.prevent="onClick">
            <div class="td anchor-col">
                <label class="row-checkbox"><input type="checkbox" aria-label="Select Row"></label>
                <cell :cell="source.cells[0]" :header="headers[0]" @click.prevent.stop="onCellClick($event, 0)" />
            </div>

            <div v-for="(header, index) in headers.slice(1)" :class="['td', header.classes]">
                <cell :cell="source.cells[index + 1]" :header="headers[index + 1]" @click.prevent.stop="onCellClick($event, index + 1)" />
            </div>
        </div>

        <div class="tr tr-subrow" v-for="subrow in source.subrows" v-if="source.subrows && !source.isHeading && !source.isHeaders  && !source.isEmpty" @click.prevent="onClick">
            <div class="td anchor-col"><div class="cell-content"></div></div>

            <div v-for="(header, index) in headers.slice(1)" :class="['td', header.classes, {'td-empty': !((subrow[index + 1] && subrow[index + 1]._meta) ? subrow[index + 1].value : subrow[index + 1]) }]">
                <cell :cell="subrow[index + 1]" :header="headers[index + 1]" @click.prevent.stop="onCellClick($event, index + 1)"/>
            </div>
        </div>

    </div>
</template>

<script>
import Cell from './Cell'
import DateFilterTrigger from './DateFilterTrigger'

export default {
    props: ['source', 'headers', 'onclick', 'onSort', 'onToggleSection', 'sortColumnIdx', 'sortIsAsc', 'stickyWidth', 'dateFilterClickHandler'],
    components: {Cell, DateFilterTrigger, },
    methods: {
        onDateFilterClick() {
            this.dateFilterClickHandler(this.source.id)
        },
        onCellClick($event, columnIdx) {
            this.onclick({
                row: this.source,
                columnIdx: columnIdx,
            })
        },
        onClick() {
            this.onclick({
                row: this.source,
                columnIdx: null,
            })
        },
        toggleSection() {
            this.onToggleSection(this.source.id)
        },
    },
}
</script>

