<template>
    <div class="btnlike">
        <button type="button" class="btn-groupaction-sm bg-150 btn-collapse-xs" @click.prevent.stop="onClick($event)">
            <i class="far fa-fw fa-calendar mr-hf"></i>
            <span class="btn-text" v-if="dateFilter.mode == 'last30'">Last 30 days</span>
            <span class="btn-text" v-if="dateFilter.mode == 'last90'">Last 90 days</span>
            <span class="btn-text" v-if="dateFilter.mode == 'last12'">Last 12 months</span>
            <span class="btn-text" v-if="dateFilter.mode == 'custom'">{{ dateFilter.start|date }} - {{ dateFilter.end|date }}</span>
        </button>
    </div>
</template>

<script>
export default {
    props: ['dateFilter'],
    methods: {
        onClick() {
            this.$emit('click')
        },
    },
}
</script>
