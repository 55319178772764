<template>
    <transition name="fade" mode="out-in">
        <router-view class="view" v-if="dataLoaded" ref="stepComponent"
            :onboarding-application="onboardingApplication"
            @paymasters_onboarding_application_updated="getApplication()"
            @updated="onUpdate"
            @next="nextPage"
        />
    </transition>
</template>

<script>
import api from '@/api'
import bus from '@/bus'

export default {
    data() {
        return {
            dataLoaded: false,
            onboardingApplication: null,
        }
    },
    computed: {
        routePrefix() {
            return this.$route.name.split('-')[0]
        },
        strippedRouteName() {
            return this.$route.name.replace(new RegExp(`^${this.routePrefix}-`), '')
        },
    },
    mounted() {
        this.$store.dispatch('GET_USER')
        this.$store.dispatch('SET_PAGE_TITLE', 'New Employee Onboarding')
        this.getApplication()
    },
    methods: {
        nextPage() {
            this.$router.push(this.$refs.stepComponent.nextPage)
        },
        onUpdate(app) {
            if (app) {
                Object.assign(this.onboardingApplication, app)
            }
            else {
                this.getApplication()
            }
        },
        getApplication() {
            this.dataLoaded = false
            const appId = this.$route.params.appId
            this.$store.dispatch('START_LOADING')
            api.get(`/me/companies/${appId}/onboarding-application`).then((resp) => {
                this.onboardingApplication = resp

                let completionRoute = {name: 'employee-home'}
                if (this.routePrefix == 'admin') {
                    completionRoute = {name: 'onboarding', params: {clientId: this.onboardingApplication.company.client}}
                }

                const PAGE_ORDER = [
                    {field: 'employee_completed_on', route: completionRoute},
                    {field: 'direct_deposit_info_given_on', route: {name: `${this.routePrefix}-onboarding-complete`, params: {appId: appId}}},
                    {field: 'forms_completed_on', route: {name: `${this.routePrefix}-onboarding-direct-deposit`, params: {appId: appId}}},
                    {field: 'form_selection_completed_on', route: {name: `${this.routePrefix}-onboarding-forms`, params: {appId: appId}}},
                    {field: 'eeo_info_given_on', route: {name: `${this.routePrefix}-onboarding-form-selection`, params: {appId: appId}}},
                    {field: 'address_given_on', route: {name: `${this.routePrefix}-onboarding-eeo`, params: {appId: appId}}},
                    {field: 'hr_info_given_on', route: {name: `${this.routePrefix}-onboarding-address`, params: {appId: appId}}},
                    {field: 'wotc_answered_on', route: {name: `${this.routePrefix}-onboarding-hr-info`, params: {appId: appId}}},
                ]

                let DEFAULT_ROUTE
                if (this.onboardingApplication.has_wotc_step) {
                    PAGE_ORDER.push( {field: 'digital_consent_given_on', route: {name: `${this.routePrefix}-onboarding-wotc`, params: {appId: appId}}} );
                    DEFAULT_ROUTE = `${this.routePrefix}-onboarding-wotc`
                } else {
                    PAGE_ORDER.push( {field: 'digital_consent_given_on', route: {name: `${this.routePrefix}-onboarding-hr-info`, params: {appId: appId}}} );
                    DEFAULT_ROUTE = `${this.routePrefix}-onboarding-hr-info`
                }

                if (this.$route.name == `${this.routePrefix}-onboarding`) {
                    for (const routeDef of PAGE_ORDER) {
                        if (this.onboardingApplication[routeDef.field]) {
                            return this.$router.replace(routeDef.route)
                        }
                    }
                    this.$router.replace({name: DEFAULT_ROUTE, params: {appId: appId}})
                }

                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                console.error(errors)
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        },
    }
}
</script>
