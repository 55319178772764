<template>
    <div>
        <div class="flex-page-sidebar">
            <nav class="nav-secondary">
                <div class="dropdown">
                    <div class="dropdown-menu" aria-labelledby="navSecondaryMenuBtn">
                        <div v-for="o in navOptions">
                            <router-link :to="{name: o.value}" class="dropdown-item" :class="{'active': $route.name == o.value}">{{ o.text }}</router-link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>

        <router-view class="flex-page-content flex-container view" :client="client" v-if="client" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            client: null,
            navOptions: [
                {'text': 'Companies', 'value': 'admin-client-companies'},
                {'text': 'POS Integrations', 'value': 'admin-client-pos-integrations'},
            ]
        }
    },
    mounted() {
        this.getClient()
        const routes = this.navOptions.map(x => x.value)
        if (routes.indexOf(this.$route.name) < 0) {
            this.$router.replace({name: routes[0]})
        }
    },
    methods: {
        getClient() {
            this.dataLoaded = false
            const clientId = this.$route.params.clientId
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${clientId}`).then((resp) => {
                this.client = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    }
}
</script>
