<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                    <div class="container-centered py-4" v-show="clients && companies">
                        <h1 class="mb-2 text-center">
                            Select an Account
                        </h1>
                        <!-- Search Accounts -->
                        <div class="my-2">
                            <page-search v-model="search" />
                        </div>
                        <!-- Section Heading -->
                        <div class="table-section-heading mb-hf">
                            <div class="row">
                                <span class="col-auto">
                                    <a href class="btn btn-icon btn-collapse-toggle" :class="{'is-expanded': isActiveExpanded}" role="button" @click.prevent="isActiveExpanded = !isActiveExpanded">
                                        <i class="fas fa-minus-circle"></i><span class="sr-only">Collapse</span>
                                    </a>
                                    <span class="col-form-label d-inline-block table-section-title">
                                        <h5 class="m-0">Active <span class="table-section-count">({{ activeCount }})</span></h5>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <!-- Section Content -->
                        <div class="collapse" :class="{'show': isActiveExpanded}">
                            <!-- Admin Tools card -->
                            <router-link :to="{name: 'admin-home'}" class="card mb-hf no-decoration" v-if="state.user && state.user.is_superuser">
                                <div class="card-body">
                                    <div class="form-row py-1">
                                        <div class="col-12 py-hf d-flex align-items-center">
                                            <div class="h1 mb-0 p-0 mr-1 ml-nhf">
                                                <i aria-hidden="true" class="fas fa-fw fa-cog text-500"></i>
                                            </div>
                                            <h4 class="mb-0 p-0">
                                                Admin Tools
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                            <!-- Account card -->
                            <router-link :to="{name: 'employer-home', params: {clientId: client.id}}" class="card mb-hf no-decoration" v-for="client in searchedClients" :key="client.id">
                                <div class="card-body">
                                    <div class="form-row">
                                        <div class="col">
                                            <h4>{{ client.name }}</h4>
                                            <div class="text-500 text-small">
                                                <strong class="text-dark">
                                                    {{ client.client_code }}
                                                </strong>
                                                <span class="mx-qt">|</span>
                                                <span class="text-dark" v-if="client && client.companies">
                                                    {{ client.companies.length }} {{ client.companies.length == 1 ? 'company' : 'companies' }}
                                                </span>
                                            </div>
                                            <div class="text-muted text-small pt-qt" v-if="lastViewedRecord">
                                                <span>Last viewed: </span>
                                                <span v-if="lastViewedRecord.clients && lastViewedRecord.clients[client.id]">{{ lastViewedRecord.clients[client.id]|date('MMM D, YYYY') }}</span>
                                                <span v-if="!lastViewedRecord.clients || !lastViewedRecord.clients[client.id]">Never</span>
                                            </div>
                                        </div>
                                        <div class="col-auto text-right">
                                            <span class="counter counter-warnings float-right mt-nqt" v-if="client.active_warning_count">
                                                <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i>
                                                <span class="counter-label">{{ client.active_warning_count }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="client.locked_companies_count && !client.pending_employer_link" class="card-footer border-0 bg-150 d-flex">
                                    <div class="flex-grow-1 text-dark text-small">
                                        <i aria-hidden="true" class="fa-lock fas fa-fw"></i> 
                                        <span class="text-semibold ml-hf">
                                            {{ client.locked_companies_count }} {{ client.locked_companies_count == 1 ? 'company is' : 'companies are' }} locked
                                        </span>
                                    </div>
                                </div>
                                <div v-if="client.pending_employer_link" class="card-footer border-0 bg-info-light d-flex">
                                    <div class="flex-grow-1 text-dark text-small">
                                        <i aria-hidden="true" class="fa-link fas fa-fw text-info"></i> 
                                        <span class="text-semibold ml-hf">
                                            Set up your access to this account
                                        </span>
                                    </div>
                                    <div class="flex-grow-0 text-primary text-small">
                                        <i class="fas fa-fw fa-arrow-right"></i>
                                    </div>
                                </div>
                            </router-link>
                            <!-- ESS card -->
                            <router-link :to="{name: 'employee-home'}" class="card mb-hf no-decoration" v-if="showESS">
                                <div class="card-body">
                                    <div class="form-row py-1">
                                        <div class="col-12 d-flex flex-column justify-content-center">
                                            <h4 class="">
                                                Employee Self-Service
                                            </h4>
                                            <div class="text-muted text-small" v-if="lastViewedRecord">
                                                <span>Last viewed: </span>
                                                <span v-if="lastViewedRecord.ess">{{ lastViewedRecord.ess|date('MMM D, YYYY') }}</span>
                                                <span v-if="!lastViewedRecord.ess">Never</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as JsSearch from 'js-search'
import Worker from 'worker-loader!@/components/search.worker.js'

export default {
    components: {},
    computed: {
        state() {return this.$store.state},
        companies() {
            // NB: these are returned sorted from the API
            if (!this.$store.state.user) {
                return null
            }
            let result = []
            result = result.concat(this.$store.state.user.employee_companies)
            result = result.concat(this.$store.state.user.employee_onboarding_companies)
            result = result.concat(this.$store.state.user.employee_pending_approval_companies)
            result = result.concat(this.$store.state.user.employee_pending_link_companies)
            return result
        },
        pendingLinkClientIds() {
            let result = []
            this.$store.state.user.employer_pending_link_clients.forEach((c) => {
                result.push(c.id)
            })

            return result
        },
        activeCount() {
            let c = this.clients ? this.clients.length : 0
            if (this.companies && this.companies.length) {
                c += 1
            }
            return c
        },
        searchedClients() {
            if (this.search.trim() && this.foundClientIds) {
                return this.clients.filter(r => this.foundClientIds.indexOf(r.id) >= 0)
            }

            return this.clients
        },
        showESS() {
            // This one basically does or does not match whatever we are calling the ESS link
            if (this.search && this.companies && this.companies.length && this.essSearchIndex) {
                return this.essSearchIndex.search(this.search).length > 0
            }
            return this.companies ? (this.companies.length > 0) : 0
        },
    },
    watch: {
        search() {
            if (this.search.trim()) {
                this.searchWorker.postMessage({
                    action: 'search',
                    search: this.search,
                })
            }
            else {
                this.foundClientIds = null
            }
        },
        clients() {
            this.createSearchIndex()
        },
    },
    data() {
        return {
            hidePageTitle: true,
            clients: null,
            lastViewedRecord: null,
            isActiveExpanded: true,
            searchIndex: null,
            essSearchIndex: null,
            search: '',
            foundClientIds: null,
        }
    },
    created() {
        this.searchWorker = new Worker()
        this.searchWorker.addEventListener('message', (m) => {
            if (m.data.action == 'search') {
                this.foundClientIds = m.data.result[0]
            }
        })
    },
    destroyed() {
        if (this.searchWorker) {
            this.searchWorker.terminate()
            this.searchWorker = null
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', '')
        this.getClients()
    },
    methods: {
        getClients() {
            this.$store.dispatch('START_LOADING')

            this.$store.dispatch('GET_USER').then(() => {
                this.$store.dispatch('START_LOADING')
                this.$api.get('/me/last-viewed').then((data) => {
                    this.lastViewedRecord = data
                })

                // NB: this API returns things sorted in the order we want
                this.$api.get('/clients').then((clients) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.clients = clients
                    this.redirectIfNecessary()

                    // Flatten some of the properties to JsSearch can deal with them
                    this.clients.forEach((c) => {
                        c.company_names = []
                        c.company_eins = []
                        c.company_codes = []
                        c.companies.forEach((comp) => {
                            c.company_names.push(comp.name)
                            c.company_codes.push(comp.company_code)
                        })
                        c.pending_employer_link = this.pendingLinkClientIds.includes(c.id)
                    })

                    this.essSearchIndex = this.createESSSearchIndex()
                    this.createSearchIndex()
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.showError(errors.__all__)
                })
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        redirectIfNecessary() {
            if (this.companies.length) {

                if ((this.clients.length + this.$store.state.user.employer_pending_link_clients.length )< 1) {
                    // If there are no clients, but there are companies, just take them to employee home
                    this.$router.push({name: 'employee-home'})
                    return
                }
            }

            // Disabled for now - see PT #174343821
            // if (this.clients.length == 1) {
            //     if ((this.companies.length + this.$store.state.user.employee_pending_link_companies.length)< 1) {
            //         // If there is only one client, and no companies, just take them to that one client home
            //         const clientId = this.clients[0].id
            //         this.$router.push({name: 'employer-home', params: {clientId: clientId}})
            //         return
            //     }
            // }

            if (!this.companies.length && !this.clients.length) {
                // TODO: throw a 404 or log out?
                return
            }
        },
        createSearchIndex() {
            const rows = []

            if (!this.clients) {
                return
            }

            this.clients.forEach((c) => {
                rows.push({
                    id: c.id,
                    object: c,
                    cells: [
                        c.name,
                        c.client_code,
                        c.company_names,
                        c.company_eins,
                        c.company_codes,
                    ],
                })
            })

            this.searchWorker.postMessage({
                action: 'create',
                headers: [
                    {isSearchable: true},
                    {isSearchable: true},
                    {isSearchable: true},
                    {isSearchable: true},
                    {isSearchable: true},
                ],
                sections: ['main'],
                data: [rows],
            })
        },
        createESSSearchIndex() {
            const searchIndex = new JsSearch.Search('id')
            searchIndex.addIndex('name')
            searchIndex.addDocuments([
                {id: 'ess', 'name': 'ess'},
                {id: 'ESS', 'name': 'Employee Self-Service'},
                {id: 'admin', 'name': 'Admin Tools'},
            ])
            return searchIndex
        },
    },
}
</script>

<style scoped>
h4 {
    text-transform: uppercase;
}
</style>
