<template>
<div>
    <div class="flex-header">
        <div class="page-actions">
            <div class="page-actions-search" v-if="showSearch">
                <a v-if="sections.length > 1" href class="collapse-trigger" :class="{'is-expanded': !allSectionsCollapsed}" @click.prevent="toggleCollapse()">
                    <collapse-toggle-button />
                </a>
                <page-search v-model="search" />
                <!--
                <button type="button" class="ml-1 btn btn-outline-primary" :disabled="refreshing" @click="refresh()">
                    <i class="fas fa-sync" :class="{'fa-spin': refreshing}"></i>
                </button>
                -->
            </div>

            <table-filter :sections="sections" :headers="headers" :data="searchedData" :filteredData="filteredData" @filter="onFilter" :managers="managers" />

            <div class="page-actions-main">
                <slot name="table-actions"></slot>
                <date-filter-trigger v-if="useDateFilters && sections.length == 1" :date-filter="defaultDateFilter" @click="onDateFilterClick('section-' + sections[0].id)" />
                <more-actions-menu ref="moreActionsMenu" v-if="showMoreActions" />
            </div>
        </div>
    </div>

    <div class="flex-body content-main">
        <div class="table-wrapper table-responsive" v-if="rows.length" ref="table">
            <div class="table css-table" tabindex="1" :class="tableClasses" style="height: 100%">

                <virtual-list ref="tableBody"
                    :data-key="'id'"
                    :data-sources="rows"
                    :data-component="rowComponent"

                    :extra-props="extraProps"
                    :keeps="50"
                    :estimate-size="ROW_HEIGHT"

                    style="overflow: auto; height: 100%;"
                    :page-mode="false"

                    class="virtual-table-wrapper"
                    wrap-class="virtual-table"
                    item-class="virtual-table-item"
                />
            </div>
        </div>
    </div>

    <date-filter-modal
        v-if="dateFilterSection"
        :mode="sectionDateFilters['section-' + dateFilterSection.id].mode"
        :start="sectionDateFilters['section-' + dateFilterSection.id].start"
        :end="sectionDateFilters['section-' + dateFilterSection.id].end"
        @change="onDateFilter"
        @close="dateFilterSection = null"
    />
</div>
</template>

<script>
import $ from 'jquery'
import VirtualList from 'vue-virtual-scroll-list'
import Row from './Row'
import SortableMixin from './SortableMixin'
import SearchableMixin from './SearchableMixin'
import rowMatchesFilters from './filter-utils'
import TableFilter from './Filter'
import MoreActionsMenu from '@/components/MoreActionsMenu'
import DateFilterModal from '@/components/DateFilterModal'
import DateUtils from './date-utils'
import DateFilterTrigger from './DateFilterTrigger'

export default {
    props: {
        'sections': Array,
        'tableClasses': String,
        'headers': Array,
        'data': Array,
        'managers': Object,
        'showSearch': {
            type: Boolean, default: true,
        },
        'showMoreActions': {
            type: Boolean, default: true,
        },
        'useDateFilters': {
            type: Boolean, default: false,
        },
    },
    mixins: [SortableMixin, SearchableMixin, ],
    components: {VirtualList, TableFilter, MoreActionsMenu, DateFilterModal, DateFilterTrigger, },
    computed: {
        defaultDateFilter() {
            // This is only used if we have two filters
            if (!this.useDateFilters) {return null}
            if (this.sections.length != 1) {return null}

            return this.sectionDateFilters[Object.keys(this.sectionDateFilters)[0]]
        },
        extraProps() {
            return {
                headers: this.headers,
                onclick: this.onClick,
                dateFilterClickHandler: this.onDateFilterClick,
                onSort: this.onSort,
                onToggleSection: this.onToggleSection,
                sortColumnIdx: this.sortColumnIdx,
                sortIsAsc: this.sortIsAsc,
                stickyWidth: this.stickyWidth,
            }
        },
        rows() {
            if (!this.data) {
                return []
            }

            let rows = []
            this.sections.forEach((section, idx) => {
                let isClosed = false
                const sectionId = 'section-' + section.id

                if (this.sections.length > 1) {
                    isClosed = this.closedSections[sectionId]

                    const hasDateFilter = section.hasDateFilter === false ? false : this.useDateFilters
                    rows.push({
                        isHeading: true,
                        title: section.title,
                        count: this.filteredData[idx].length,
                        isClosed: isClosed,
                        id: sectionId,
                        dateFilter: this.sectionDateFilters[sectionId],
                        useDateFilters: hasDateFilter,
                    })
                }

                if (!isClosed) {
                    const sectionRows = []
                    this.filteredData[idx].forEach(row => {
                        const r = Object.assign({}, row)
                        r.id = 'row-' + row.id
                        sectionRows.push(r)
                    })

                    if (sectionRows.length) {
                        rows.push({
                            isHeaders: true,
                            id: 'section-headers-' + idx,
                        })
                        rows = rows.concat(this.sortRows(sectionRows))
                    }
                    else {
                        rows.push({
                            isEmpty: true,
                            id: `empty-${idx}`,
                        })
                    }
                }
            })

            return rows
        },
        filteredData() {
            if (!this.filters || !this.filters.length) {
                return this.searchedData
            }

            const filteredData = []
            this.searchedData.forEach(section => {
                filteredData.push(
                    section.filter(row => rowMatchesFilters(row, this.filters))
                )
            })

            return filteredData
        },
        allSectionsCollapsed() {
            let sectionsOpen = 0
            Object.keys(this.closedSections).forEach(sId => {
                if (!this.closedSections[sId]) {
                    sectionsOpen++
                }
            })

            return sectionsOpen < 1
        },
    },
    data() {
        const closedSections = {}
        const sectionDateFilters = {}
        this.sections.forEach(section => {
            closedSections['section-' + section.id] = section.defaultIsClosed
            const hasDateFilter = section.hasDateFilter === false ? false : this.useDateFilters
            if (hasDateFilter) {
                sectionDateFilters['section-' + section.id] = DateUtils.makeDateRange('last30', null, null)
            }
        })

        return {
            ROW_HEIGHT: 40,
            CONTAINER_PADDING: 16,
            rowComponent: Row,
            tableHeight: 0,
            closedSections: closedSections,
            stickyWidth: $(this.$refs.table).width() || 0,

            filters: [],
            search: '',
            dateFilterSection: null,
            sectionDateFilters: sectionDateFilters,
        }
    },
    mounted() {
        $(window).on('resize', this.onResize)
        this.$bus.$on('resize', this.onResize)
        this.onResize()
    },
    watch: {
        data() {
            this.onResize()
        },
    },
    methods: {
        onResize() {
            this.$nextTick(() => {
                if (!this.$refs.tableBody) {return}

                const tableScrollOffset = this.$refs.tableBody.getOffset()

                this.stickyWidth = $(this.$refs.table).width() - 17 // Magic number to account for vert scrollbar

                const $t = $(this.$refs.table)
                const offset = $t.offset()
                if (!offset) {
                    return
                }
                this.tableHeight = Math.max(0, $(window).height() - offset.top - this.CONTAINER_PADDING)
                $t.height(this.tableHeight)

                this.resetToOffset(tableScrollOffset)
            })
        },
        onClick(msg) {
            if (msg.columnIdx !== null) {
                if (this.headers[msg.columnIdx].isClickable) {
                    this.$emit('click_cell', {
                        object: msg.row.object,
                        columnIdx: msg.columnIdx,
                    })

                    return
                }
            }

            this.$emit('click', msg.row.object)
        },
        onToggleSection(sectionId) {
            const offset = this.$refs.tableBody ? this.$refs.tableBody.getOffset() : 0
            this.$set(this.closedSections, sectionId, !this.closedSections[sectionId])
            this.resetToOffset(offset)
        },
        toggleCollapse() {
            const offset = this.$refs.tableBody ? this.$refs.tableBody.getOffset() : 0
            let sectionsOpen = 0
            Object.keys(this.closedSections).forEach(sId => {
                if (!this.closedSections[sId]) {
                    sectionsOpen++
                }
            })

            Object.keys(this.closedSections).forEach(sId => {
                this.closedSections[sId] = sectionsOpen ? true : false
            })

            this.resetToOffset(offset)
        },
        resetToOffset(offset) {
            this.$nextTick(() => {
                this.$refs.tableBody.reset()
                this.$nextTick(() => {
                    this.$refs.tableBody.scrollToOffset(offset)
                })
            })
        },
        onFilter(filters) {
            this.filters = filters
        },
        onDateFilterClick(sectionId) {
            this.dateFilterSection = this.sections.find(s => 'section-' + s.id == sectionId)
        },
        onDateFilter(dateFilter) {
            const sectionId = 'section-' + this.dateFilterSection.id
            this.sectionDateFilters[sectionId] = Object.assign({}, dateFilter)

            const eventData = {}
            this.sections.forEach(s => {
                const sectionId = 'section-' + s.id
                eventData[s.id] = this.sectionDateFilters[sectionId]
            })

            this.$emit('date_filter_change', eventData)
        },
    },
}
</script>
