<template>
    <nav class="navbar navbar-expand-sm navbar-light bg-white" :class="{'navbar-showlogo': !state.pageTitle}">
        <div class="navbar-text py-0">
            <h1 class="h3 m-0 text-primary text-regular navbar-expand">
                <router-link class="navbar-brand" :to="logoLinkRoute">
                    <span class="navbar-logo">
                        <img class="navbar-logo-icon" src="@/assets/img/gmps-logo-icon-opt.svg" alt="GetMyPayStub Logo" />
                        <img class="navbar-logo-text" src="@/assets/img/gmps-logo-text-opt.svg" alt="GetMyPayStub Logo" />
                    </span>
                </router-link>
            </h1>
        </div>

        <div class="d-inline-block py-0 mr-auto">
            <h1 class="page-title m-0">{{ state.pageTitle }}</h1>
        </div>

        <ul class="navbar-nav flex-nowrap">
            <li class="nav-item dropdown">
                <a class="nav-link text-dark" href="#" id="navbarMainMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-bars"></i><span class="sr-only">Menu</span>
                </a>
                <div class="dropdown-menu has-icons dropdown-menu-right dropdown-allsize" aria-labelledby="navbarMainMenuLink">
                    <span class="dropdown-item text-550">
                        <i class="far fa-fw fa-user-circle mr-1"></i>
                        {{ state.user.email }}
                    </span>
                    <div class="dropdown-divider"></div>
                    <router-link class="dropdown-item" :to="logoLinkRoute">
                        <i class="far fa-fw fa-home mr-1"></i>
                        Home
                    </router-link>
                    <router-link class="dropdown-item" :to="profileRoute">
                        <i class="far fa-fw fa-user mr-1"></i>
                        Profile &amp; Security
                    </router-link>
                    <router-link class="dropdown-item" :to="{name: 'index'}" v-if="hasMultipleAccounts">
                        <i class="far fa-fw fa-random mr-1"></i>
                        Switch Accounts
                    </router-link>
                    <button v-if="! state.isInApp" class="dropdown-item" @click.prevent="logout()">
                        <i class="far fa-fw fa-sign-out mr-1"></i>
                        Log Out
                    </button>
                    <button v-if="state.isInApp" class="dropdown-item" @click.prevent="logoutApp()">
                        <i class="far fa-fw fa-sign-out mr-1"></i>
                        Log Out
                    </button>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    computed: {
        state() {return this.$store.state},
        activeClient() {
            return this.$store.state.user.admin_clients.find((c) => {return c.id == this.$route.params.clientId})
        },
        logoLinkRoute() {
            switch (this.$route.meta.mode) {
                case 'ess':
                    return {name: 'employee-home'}
                case 'client':
                    return {name: 'employer-home', params: this.$route.params.clientId}
                case 'admin':
                    return {name: 'admin-home'}
            }
            return '/'
        },
        profileRoute() {
            switch (this.$route.meta.mode) {
                case 'ess':
                    return {name: 'profile-ess'}
                case 'client':
                    return {name: 'profile-client', params: this.$route.params.clientId}
                case 'admin':
                    return {name: 'profile-admin'}
            }
            return {name: 'profile'}
        },
        hasMultipleAccounts() {
            if (this.$store.state.user.is_superuser) {
                return true
            }

            const client_ids = new Set()
            this.$store.state.user.security_profiles.forEach((sp) => {
                client_ids.add(sp.client)
            })

            this.$store.state.user.employer_pending_link_clients.forEach((c) => {
                client_ids.add(c.id)
            })

            if (client_ids.size > 1) {
                return true
            }

            let has_ess = (this.$store.state.user.employee_companies.length > 0) || (this.$store.state.user.employee_onboarding_companies.length > 0) || (this.$store.state.user.employee_pending_approval_companies.length > 0) || (this.$store.state.user.employee_pending_link_companies.length > 0)

            if (client_ids.size > 0 && has_ess) {
                return true
            }

            return false
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('LOGOUT')
            this.$router.push({name: 'login'})
        },
        logoutApp() {
            this.$store.dispatch('LOGOUT')
            this.$router.push({name: 'app-logout'})
        },
    },
}
</script>
