<template>
    <table-page :client="client" :warnings="warnings" :changelogs="changelogs" @warnings_updated="getWarnings">
        <fast-table
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :managers="client.company_managers"
            @click="currentEmployee = $event"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
        />

        <employee-sidebar
            :client="client"
            :employee-id="currentEmployee.id"
            v-if="currentEmployee"
            @close="currentEmployee = null"
            tab="misc" section="checks"
            @updated="refresh"
            :key="currentEmployee.id"/>
    </table-page>
</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import TablePage from '@/components/TablePage'

export default {
    props: ['client'],
    components: {FastTable, EmployeeSidebar, TablePage,  },
    computed: {
        active() {
            return this.employees.filter(employee => employee.employment_status == 'Active')
        },
        inactive() {
            return this.employees.filter(employee => employee.employment_status != 'Active')
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.active,
                this.inactive,
            ]

            subsets.forEach(employees => {
                const sectionData = []

                employees.forEach((employee, idx) => {
                    const row = {
                        id: idx,
                        isActive: this.currentEmployee && employee.id == this.currentEmployee.id,
                        object: employee,
                        cells: [
                            employee.full_name_sortable,
                            employee.employee_number,
                            employee.warning_count,
                            employee.company.name,
                            employee.email,
                            employee.ssn_last_4 ? `XXX-XX-${employee.ssn_last_4}` : '---',
                            employee.last_pay_date,
                        ],
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            employees: [],
            dataLoaded: false,
            refreshing: false,
            currentEmployee: null,
            dateRanges: {},

            headers: [
                {label: 'Employee', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Employee Number', classes: 'cell-medium', isSearchable: true},
                {label: 'Warnings', classes: 'cell-warning', isSearchable: false, type: 'warning', isFilterable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Email', classes: 'cell-email', isSearchable: true},
                {label: 'SSN', classes: 'cell-medium', isSearchable: true},
                {label: 'Last Pay Date', classes: 'cell-medium', isSearchable: true, type: 'date'},
            ],
            sections: [
                {title: 'Active Employees', defaultIsClosed: false, id: 'active', hasDateFilters: false},
                {title: 'Inactive Employees', defaultIsClosed: true, id: 'inactive'},
            ],
            warnings: null,
            changelogs: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Pay Stubs')
        this.getData()
        this.getWarnings()
        this.getChangelogs()
    },
    methods: {
        getData(silent) {
            if (!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }

            this.refreshing = true

            let url = `/clients/${this.client.id}/paychecks`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.$api.get(url).then(resp => {
                this.employees = resp
                this.dataLoaded = true
                this.refreshing = false
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
            }).catch((errors) => {
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.refreshing = false
                this.$bus.showError(errors.__all__)
            })
        },
        refresh() {
            if (this.refreshing) {return}
            this.getData(true)
            this.getWarnings()
            this.getChangelogs()
        },
        getWarnings() {
            this.warnings = []  // NB: This tile does not have a cohesive warnings table
        },
        getChangelogs() {
            this.changelogs = []  // NB: This tile does not have a cohesive history table
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getData()
        },
    },
}

</script>
