<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">
                      
                        <h5>{{ onboardingApplication.company.name }}</h5>
                        <h3 class="mb-4">Direct Deposit Information</h3>
        
                        <direct-deposit-form
                            :employee="onboardingApplication"
                            :url="url"
                            @paymasters_direct_deposit_accounts_updated="onAccountsUpdated($event)"
                        />
        
                        <form @submit.prevent="onSubmit">
                            <form-errors :errors="formErrors"/>
        
                            <div class="row mb-2 d-flex align-items-center">
                                <div class="col">
                                    <router-link v-if="previousPage" :to="previousPage">Back</router-link>
                                </div>
        
                                <div class="col text-right">
                                    <button class="btn btn-primary">Continue</button>
                                </div> </div>
                        </form>
                
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import api from '@/api'
import WizardMixin from './WizardMixin'

import DirectDepositForm from '@/components/DirectDepositForm'

export default {
    mixins: [FormMixin, WizardMixin],
    components: {
        FormInput,
        FormErrors,
        DirectDepositForm,
    },
    props: ['onboardingApplication'],
    data() {
        return {
            directDepositAccount: null,
            formRules: {
            },
            formData: {
                action: 'setup-direct-deposit',
            },
            url: `/me/companies/${this.onboardingApplication.id}/onboarding-application/direct-deposit-accounts`
        }
    },
    methods: {
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.patch(`/me/companies/${this.onboardingApplication.id}/onboarding-application`, this.formData).then((resp) => {
                this.$emit('updated', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset() {
            this.errors = {}
            this.formErrors = []
        },
        onAccountsUpdated(app) {
            this.$emit('updated')
        },
    },
}
</script>
