<template>
    <div>
        <!-- Companies Heading -->
        <div class="d-flex">
            <h4 class="flex-grow-1 mb-2">
                Companies <span class="text-regular">({{ companies.length }})</span>
            </h4>
            <div class="flex-grow-0">
                <div class="companies-locked-counter text-right" v-show="Object.keys(lockedCompanyIds).length">
                    <i aria-hidden="true" class="fa-lock fas fa-fw"></i>
                    <span class="pl-hf">{{ Object.keys(lockedCompanyIds).length }}</span>
                </div>
            </div>
        </div>
        <!-- Companies List -->
        <div class="mb-2">
            <ul class="companies-list">
                <li v-for="c in visibleCompanies" v-bind:class="[{ 'companies-list-item--locked': lockedCompanyIds[c.id]}, { 'companies-list-item--unavailable': c.unavailable }, 'companies-list-item']">
                    <!-- Default state -->
                    <span v-if="!lockedCompanyIds[c.id]">
                        {{ c.name }}
                    </span>
                    <!-- Unavailable state -->
                    <!--
                    <div v-if="c.unavailable">
                        <div class="d-flex">
                          <span class="flex-grow-1">
                              {{ c.name }}
                          </span>
                          <span class="flex-grow-0 flex-shrink-0 ml-1">
                              <i aria-hidden="true" class="fa-ban fas fa-fw"></i>
                              <span class="sr-only">Access Removed</span>
                          </span>
                        </div>
                    </div>
                    -->
                    <!-- Locked state -->
                    <a v-if="lockedCompanyIds[c.id]" href @click.prevent="showLockedModal(c)">
                        <div class="d-flex">
                            <span class="flex-grow-1">
                                {{ c.name }}
                            </span>
                            <span class="flex-grow-0 flex-shrink-0 ml-1">
                                <i aria-hidden="true" class="fa-lock fas fa-fw"></i>
                                <span class="sr-only">Access Locked</span>
                            </span>
                        </div>
                        <div class="text-primary-medium">
                            Unlock
                        </div>
                    </a>
                </li>
            </ul>
            <div class="py-1 text-small" v-show="companies.length > companiesToShow">
                <a href v-if="!showAll" @click.prevent="showAll = true">See more</a>
                <a href v-if="showAll" @click.prevent="showAll = false">See fewer</a>
            </div>
        </div>

        <!-- Modals -->
        <locked-modal ref="lockedModal" :client="unlockCompany.client" :company="unlockCompany" v-if="unlockCompany"/>
    </div>
</template>

<script>

import LockedModal from '@/components/LockedModal'

export default {
    props: ['companies', 'isClient'],
    components: {LockedModal, },
    data() {
        return {
            showAll: false,
            unlockCompany: null,
            companiesToShow: 5,
        }
    },
    computed: {
        // Needs to be updated so that it works for both SUs and EEs
        // Also needs to reflect status (locked, unavailable) for each listed company
        lockedCompanyIds() {
            const locked = {}

            if (this.isClient) {
                this.$store.state.user.security_profiles.forEach(sp => {
                    sp.company_security_profiles.forEach(csp => {
                        if (csp.is_locked) {
                            locked[csp.company] = csp.company
                        }
                    })
                })
            } else {
                this.$store.state.user.employees.forEach(e => {
                    if (e.is_locked) {
                        locked[e.company] = e.company
                    }
                })
            }

            const result = {}
            Object.keys(locked).forEach(cId => {
                if (this.companies.find(c => c.id == cId)) {
                    result[cId] = locked[cId]
                }
            })

            return result
        },
        sortedCompanies() {
            return this.companies.sort((a, b) => {
                const aIsLocked = a.id in this.lockedCompanyIds
                const bIsLocked = b.id in this.lockedCompanyIds

                if (aIsLocked != bIsLocked) {
                    return aIsLocked ? -1 : 1
                }

                return (a.name > b.name) ? 1 : -1
            })
        },
        visibleCompanies() {
            if (this.showAll) {
                return this.sortedCompanies
            }

            return this.sortedCompanies.slice(0, this.companiesToShow)
        },
    },
    methods: {
        showLockedModal(c) {
            this.unlockCompany = c
            this.$nextTick(() => {
                this.$refs.lockedModal.open()
            })
        },
    },
}
</script>
