<template>
    <modal size="xl" @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">

        <template slot="title">Edit Voluntary Tax Locations</template>

        <slot>
            <form class="mui-form px-1" @submit.prevent="onSubmit()" style="min-height: 20em;">
                <form-errors :errors="formErrors"/>

                <div class="form-row">
                    <div class="col-6">
                        <h4>States</h4>

                        <div v-for="state in states" :key="state.id">
                            <form-input :label="state.long_name" type="checkbox" v-model="formData.states[state.id]" />
                        </div>
                    </div>

                    <div class="col-6">
                        <h4>Cities</h4>

                        <div v-for="city in cities" :key="city.id">
                            <form-input :label="`${city.name}, ${city.state.name}`" type="checkbox" v-model="formData.cities[city.id]" />
                        </div>
                    </div>

                </div>
            </form>
        </slot>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>

    </modal>
</template>

<script>
import bus from '@/bus'
import api from '@/api'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['company'],
    mixins: [FormMixin, ModalMixin],
    watch: {
        company() {
            this.reset()
        },
    },
    mounted() {
        this.getCities()
        this.getStates()
    },
    data() {
        return {
            cities: [],
            states: [],
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            const states = {}
            const cities = {}

            if (this.company && this.company.id) {
                this.company.voluntary_withholding_locations.forEach(l => {
                    if (l.city) {
                        cities[l.city.id] = true
                    }
                    else if (l.state) {
                        states[l.state.id] = true
                    }
                })
            }

            return {
                states: states,
                cities: cities,
            }
        },
        getFormData() {
            const data = {
                states: [],
                cities: [],
            }

            Object.keys(this.formData.states).forEach(k => {
                if (this.formData.states[k]) {
                    data.states.push(k)
                }
            })

            Object.keys(this.formData.cities).forEach(k => {
                if (this.formData.cities[k]) {
                    data.cities.push(k)
                }
            })

            return data
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            const url = `/clients/${this.$route.params.clientId}/companies/${this.company.id}/tax-locations`
            this.$store.dispatch('START_LOADING')
            return api.put(url, this.getFormData()).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        getCities() {
            api.get(`/admin/tax-engine/cities`).then(resp => {
                this.cities = resp
            }).catch((errors) => {
                bus.showError(errors.__all__)
            })
        },
        getStates() {
            api.get(`/admin/tax-engine/states`).then(resp => {
                this.states = resp
            }).catch((errors) => {
                bus.showError(errors.__all__)
            })
        },
    }
}
</script>
