<template>
    <page>
        <div class="flex-header">
            <div class="page-actions pt-2 pb-1 border-bottom d-flex flex-wrap">
                <form class="mui-form" @submit.prevent="onSubmitSpreadsheet()">
                    <b>Upload company addresses</b>
                    <p>
                        Required fields: ClientCode, CompanyCode, Street1, Street2, City, State, ZipCode
                    </p>
                    <form-input type="file" v-model="data_file" label="Companies Spreadsheet" @upload="onUpload($event)" />
                    <br>
                    <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmitSpreadsheet()">Save</button>
                </form>
            </div>
        </div>

        <fast-table
            v-show="dataLoaded"
            tableClasses="table-anchor--shorttext"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="onClick"
        />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'

export default {
    components: {FastTable, Page, },
    computed: {
        tableData() {
            const tableData = []

            this.clients.forEach((c, idx) => {
                const row = {
                    id: idx,
                    object: c,
                    cells: [
                        c.name,
                        c.client_code,
                        c.num_companies,
                        c.num_pos_integrations > 0,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            clients: [],
            dataLoaded: false,
            file: null,
            data_file: null,
            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Client Code', classes: 'cw-8', isSearchable: true},
                {label: '# of Companies', classes: 'cw-9', isSearchable: false},
                {label: 'POS Integration', classes: '', type: "checkmark",  isSearchable: false, isFilterable: true},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Clients')
        this.getClients()
    },
    methods: {
        getClients() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/clients').then((resp) => {
                this.clients = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate(client) {
            const existing = this.clients.find(s => {return s.id == client.id})
            if (existing) {
                Object.assign(existing, client)
            }
        },
        onClick(client) {
            this.$router.push({name: 'admin-client-companies', params: {clientId: client.id}})
        },
        onUpload(f) {
            this.file = f[0]
            this.data_file = f[0]
        },
        onSubmitSpreadsheet() {
            this.$store.dispatch('START_LOADING')

            let uploadPromise
            if (this.file) {
                uploadPromise = this.$api.upload(`/admin/companies/upload_addresses`, this.file, {fieldName: 'file'}).then(resp => {
                    this.$store.dispatch('STOP_LOADING')
                    this.file = null
                    this.data_file = null
                    this.$bus.showInfo(resp['num_updated'] + ' records updated.')
                    return resp
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.$bus.showError('Error parsing file - check required fields.')
                })
            } else {
                this.$store.dispatch('STOP_LOADING')
                uploadPromise = new Promise((resolve) => {
                    resolve(null)
                })
            }
        },

    },
}

</script>
