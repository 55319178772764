<template>
    <sidebar @close="$emit('close')">
        <template slot="title">
            {{ fullName }}
        </template>

        <template slot="subtitle">
            <span class="counter counter-warnings" v-if="warningCount">
                <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i>
                <span class="counter-label">{{ warningCount }}</span>
            </span>
        </template>

        <template slot="header">
            <!-- Employment Status -->
            <div class="py-qt">
                <span v-if="user.num_active_companies > 0">
                    Active Employee in {{ user.num_active_companies }} <span v-if="user.num_active_companies == 1">Company</span><span v-else="">Companies</span>
                </span>
                <span v-else-if="user.onboarding_applications">
                    Onboarding into {{ user.onboarding_applications.length }} <span v-if="user.onboarding_applications.length == 1">Company</span><span v-else="">Companies</span>
                </span>
                <span v-else>
                    Inactive Employee
                </span>
            </div>

            <unlock-widget v-if="user.id && isLocked" :client="client" :user="user" :is-locked="isLocked" @unlocked="onUpdate" />

            <!-- Tabs -->
            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'main'}" href id="tabLinkMain" role="tab" aria-controls="tabContentMain" :aria-selected="activeTab == 'main'" @click.prevent="activeTab = 'main'">Main</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'warnings'}" href id="tabLinkWarnings" role="tab" aria-controls="tabContentWarnings" :aria-selected="activeTab == 'warnings'" @click.prevent="activeTab = 'warnings'">Warnings</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'access'}" href id="tabLinkUserAccess" role="tab" aria-controls="tabContentUserAccess" :aria-selected="activeTab == 'access'" @click.prevent="activeTab = 'access'">User Access</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'changelogs'}" href id="tabLinkHistory" role="tab" aria-controls="tabContentHistory" aria-selected="activeTab == 'changelogs'" @click.prevent="activeTab = 'changelogs'">History</a>
                        </li>

                        <li class="nav-item" v-if="isAdmin">
                            <a class="nav-link" :class="{active: activeTab == 'admin'}" href id="tabLinkAdmin" role="tab" aria-controls="tabContentAdmin" aria-selected="activeTab == 'admin'" @click.prevent="activeTab = 'admin'">Admin</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot>
            <div class="tab-content">
                <!-- Tab Pane 1 -->
                <div class="tab-pane" :class="{'show active': activeTab == 'main'}" id="tabContentMain" role="tabpanel" aria-labelledby="tabLinkMain">

                    <!-- Companies widget -->
                    <div class="card mb-2">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto">
                                <h4 class="mb-0">Companies</h4>
                            </span>
                            <span v-if="!user.has_user" class="badge badge-lg badge-danger">
                                Not Registered
                            </span>
                        </div>

                        <div class="card-body py-1">
                            <ul class="list-unstyled m-0">
                                <li class="py-1 boxed-li-bottom" v-for="x in companies">
                                    <div class="form-row align-items-baseline pb-1 py-sm-hf">
                                        <div class="col">
                                            <strong>{{ x.company.name }}</strong>
                                            <span v-if="x.is_locked" class="text-danger pl-hf">(LOCKED)</span>
                                        </div>
                                        <div class="col-auto text-right">
                                            <span v-if="user.has_user && !x.is_linked" class="badge badge-lg badge-danger" style="width: 5.5rem;">
                                                Not Linked
                                            </span>
                                            <span v-if="user.has_user && x.is_linked" class="badge badge-lg bg-200 text-muted" style="width: 5.5rem;">
                                                <i class="far fa-check mr-qt"></i> Linked
                                            </span>
                                            <span v-if="!user.has_user">
                                                <span class="badge badge-lg bg-white invisible">
                                                    -
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="mt-nhf mb-1">
                                        <span>{{ x.relationship }}</span>
                                    </div>
                                    <ul class="list-unstyled text-muted text-small pb-1 m-0">
                                        <li class="pb-qt" v-for="job in x.jobs">
                                            {{ job.position.terminal_value }}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'warnings'}" id="tabContentWarnings" role="tabpanel" aria-labelledby="tabLinkWarnings">
                    <section class="table-section">
                        <warnings-header category="Dismissed" :expanded="!dismissedWarningsExpanded" :warning-count="dismissedWarnings.length" @click="dismissedWarningsExpanded = !dismissedWarningsExpanded" />
                        <warnings :warnings="dismissedWarnings" :active-warning-id="activeWarningId" v-if="user" v-show="dismissedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                    <section class="table-section">
                        <warnings-header category="Unresolved" :expanded="!unresolvedWarningsExpanded" :warning-count="unresolvedWarnings.length" @click="unresolvedWarningsExpanded = !unresolvedWarningsExpanded" />
                        <warnings :warnings="unresolvedWarnings" :active-warning-id="activeWarningId" v-if="user" v-show="unresolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                    <section class="table-section">
                        <warnings-header category="Resolved" :expanded="!resolvedWarningsExpanded" :warning-count="resolvedWarnings.length" @click="resolvedWarningsExpanded = !resolvedWarningsExpanded" />
                        <warnings :warnings="resolvedWarnings" :active-warning-id="activeWarningId" v-if="user" v-show="resolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                </div>

                <!-- Tab Pane - User Access -->
                <div class="tab-pane" :class="{'show active': activeTab == 'access'}" id="tabContentUserAccess" role="tabpanel" aria-labelledby="tabLinkUserAccess">
                    <user-access :client="client" :user="user" :email="user.email" :login-attempts="loginAttempts" :is-locked="isLocked" :allow-security-profile-pin-reset="false" :pin-reset-disabled="user.id && user.has_security_profile" @pin_reset="resetPIN()" @send_invite_email="sendESSInvitation(user.email)">
                        <template slot="no-account-message">
                            To invite them to register a Self-Service account, update their email address in payroll first.
                        </template>

                        <template slot="pin-reset-disabled-message">
                            <p class="mb-0 pt-1">
                                This user account has Security User access. To reset their PIN, go to <strong>Settings &gt; Security Users</strong>.
                            </p>
                        </template>
                    </user-access>
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'changelogs'}" id="tabContentHistory" role="tabpanel" aria-labelledby="tabLinkHistory">
                    <change-history :client="client" :changelogs="user.changelogs" :active-changelog-id="activeChangelogId" />
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'admin'}" id="tabContentAdmin" role="tabpanel" aria-labelledby="tabLinkAdmin">
                    <div class="form-row">
                        <div class="col-6">
                            <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="getTaxData()">Get Tax Info</button>
                        </div>
                    </div>

                    <div class="my-2" v-if="taxData">
                        <pre><code>{{ taxData }}</code></pre>
                    </div>

                </div>
            </div>

            <pin-reset-modal :user="user" :client="client" v-if="user.id" ref="pinResetModal" />
        </slot>
    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import PINResetModal from '@/components/PINResetModal'
import UnlockWidget from '@/components/UnlockWidget'
import UserAccess from '@/components/UserAccess'
import Warnings from '@/components/WarningsWidget'
import WarningsHeader from '@/components/WarningsHeaderWidget'
import ChangeHistory from '@/components/ChangeHistory'

export default {
    components: {Sidebar, PinResetModal: PINResetModal, UnlockWidget, UserAccess, Warnings, WarningsHeader, ChangeHistory, },
    props: ['user', 'client', 'tab', 'section', 'itemId', ],
    computed: {
        isAdmin() {
            return this.$store.state.user.is_superuser
        },
        fullName() {
            let fullName = this.user.full_name
            if (!fullName) {
                if (this.user.employees.length) {
                    fullName = this.user.employees[0].full_name ? this.user.employees[0].full_name : ''
                }
                if (this.user.onboarding_applications.length) {
                    fullName = this.user.onboarding_applications[0].full_name ? this.user.onboarding_applications[0].full_name : fullName
                }
            }

            return fullName
        },
        recentLoginAttempts() {
            if (this.showAll) {
                return this.loginAttempts
            }

            return this.loginAttempts.slice(0, 5)
        },
        warningCount() {
            return this.user.warnings.length
        },
        unresolvedWarnings() {
            if (!this.user) {return []}
            return this.user.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return (!isDismissed && !w.is_resolved)
            })
        },
        resolvedWarnings() {
            if (!this.user) {return []}
            return this.user.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return (!isDismissed && w.is_resolved)
            })
        },
        dismissedWarnings() {
            if (!this.user) {return []}
            return this.user.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return isDismissed
            })
        },
        companyMap() {
            const companyMap = {};
            (this.client.companies || []).forEach(c => {
                companyMap[c.id] = c
            })
            return companyMap
        },
        companies() {
            const result = []
            const seenEmp = new Set()
            this.user.employees.forEach(e => {
                if (!seenEmp.has(e.company)) {
                    result.push({
                        'company': this.companyMap[e.company] || {},
                        'relationship': `${e.employment_status} Employee`,
                        'is_locked': e.is_locked,
                        'jobs': e.jobs,
                        'is_linked': (!!e.user),
                    })
                    seenEmp.add(e.company)
                }
            })

            const seenOA = new Set()
            this.user.onboarding_applications.forEach(o => {
                if (!seenOA.has(o.company)) {
                    result.push({
                        'company': this.companyMap[o.company],
                        'relationship': 'Onboarding',
                        'is_locked': o.is_locked,
                        'jobs': o.jobs,
                        'is_linked': (!!o.user),
                    })
                    seenOA.add(o.company)
                }
            })

            return result
        },
        isLocked() {
            let numLocked = 0
            this.user.employees.forEach(e => {
                numLocked += e.is_locked ? 1 : 0
            })

            this.user.onboarding_applications.forEach(o => {
                numLocked += o.is_locked ? 1 : 0
            })

            return numLocked ? true : false
        },
    },
    data() {
        return {
            openCompanyPositions: {},
            loginAttempts: [],
            showAll: false,
            taxData: null,

            activeTab: this.tab,

            activeWarningId: (this.tab == 'warnings') ? this.itemId : null,
            activeChangelogId: (this.tab == 'changelogs') ? this.itemId : null,

            unresolvedWarningsExpanded: true,
            resolvedWarningsExpanded: true,
            dismissedWarningsExpanded: true,
        }
    },
    mounted() {
        this.getLoginAttempts()
    },
    watch: {
        user() {
            this.getLoginAttempts()
            this.taxData = null
        }
    },
    methods: {
        resetPIN() {
            this.$refs.pinResetModal.open()
        },
        unlock() {
            this.$refs.unlockModal.open()
        },
        sendESSInvitation(email) {
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/send-ess-invitation`, {'email': email}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showInfo("Invitation email sent.")
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        getTaxData() {
            const url = `/admin/employees/${this.user.id}/aos-tax-data`
            this.$store.dispatch('START_LOADING')
            this.$api.get(url).then(resp => {
                this.taxData = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getLoginAttempts() {
            if (!this.user.id) {return}

            this.loginAttempts = null
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/users/${this.user.id}/login-history`).then(resp => {
                this.loginAttempts = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/non-sec-users`).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                const user = resp.find(u => u.email == this.user.email)
                this.$emit('updated', user)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })

        },
        openCompanyPosition(cpId) {
            this.$set(this.openCompanyPositions, cpId, !this.openCompanyPositions[cpId])
        },
        onWarningsUpdate() {
            this.onUpdate()
        },
    },
}
</script>
