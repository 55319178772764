<template>
    <modal @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">
        <template slot="title">
            <h3 class="modal-title">Add HR Document: <span class="text-thin text-nowrap">Document Information</span></h3>
        </template>

        <slot>
            <p>Upload a new HR Document. To use it for employee onboarding, add the document to an HR Template.</p>

            <form class="mui-form" @submit.prevent="onSubmit()">
                <form-errors :errors="formErrors"/>

                <div class="form-row">
                    <div class="col">
                        <form-input label="Company" v-model="formData.company" :errors="errors.company" type="select" :options="companyOptions"/>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" label="Document Name" type="text" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input @upload="onFileUpload($event)" :errors="errors.file" label="Upload your document:" helper-text="File extension must be .pdf" type="file" accept=".pdf"/>
                    </div>
                </div>
            </form>
        </slot>

        <template slot="footer">
            <button-row>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">Save Document</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['client'],
    mixins: [FormMixin, ModalMixin],
    computed: {
        companyOptions() {
            const options = []
            for (let company of (this.client.companies || [])) {
                options.push({text: company.name, value: company.id})
            }
            return options
        },
    },
    data() {
        return {
            formData: {
                name: '',
                file: null,
            },
            formRules: {
                name: {presence: {allowEmpty: false}, },
                file: {presence: {allowEmpty: false}, },
            },
        }
    },
    watch: {
        client() {
            this.reset()
        }
    },
    methods: {
        onFileUpload(f) {
            this.formData.file = f[0]
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/hr-documents`, {
                company: this.formData.company,
                name: this.formData.name,
            }).then(resp => {
                return this.$api.upload(`/clients/${this.client.id}/hr-documents/${resp.id}/file`, this.formData.file, {fieldName: 'pdf_file'}).then(resp => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('paymasters_hr_documents_updated', resp)
                    this.close()
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        }
    }
}
</script>
