<template>
    <div class="card mb-2">
        <div class="card-header pr-1 d-flex align-items-center">
            <span class="btnlike mr-auto">
                <h4 class="mb-0">Employee Information</h4>
            </span>

            <button v-if="canEdit" class="btn btn-outline-primary" type="button" @click.prevent="$emit('edit')">Edit</button>
        </div>

        <div class="card-body">
            <div class="mui-textfield">
                <span class="textfield">{{ employee.full_name }}</span>
                <label>Employee Name</label>
            </div>

            <div class="mui-textfield">
                <span class="textfield"><a :href="'mailto:' + encodeURI(employee.email)">{{ employee.email }}</a></span>
                <label>Email Address</label>
            </div>

            <div>
                <div class="mui-textfield">
                    <span class="textfield">{{ employee.date_hired | date }}</span>
                    <label>Start Date</label>
                </div>

                <div class="mui-textfield">
                    <span class="textfield">{{ employee.aca_category }}</span>
                    <label>ACA Employment Category</label>
                </div>

                <div class="mui-textfield" v-if="employee.address_1">
                    <span class="textfield">
                        {{ employee.address_1 }}
                        <br />
                        {{ employee.address_2 }}
                        <br v-if="employee.address_2" />
                        {{ employee.city }}, {{ employee.state }} {{ employee.postal_code }}
                    </span>
                    <label>Address</label>
                </div>

                <div class="mui-textfield" v-if="employee.ssn_last_4">
                    <span class="textfield">XXX-XX-{{ employee.ssn_last_4 }}</span>
                    <label>Social Security Number</label>
                </div>

                <div class="unpad-x">
                  <hr class="unpad-x" />
                </div>

                <h4 class="mt-2 mb-2">{{ client.position_label_pretty }} Information</h4>

                <!-- Position -->
                <div class="mb-1" v-for="job in employee.jobs" :key="job.id">
                    <div class="row align-items-center mb-1">
                        <div class="col-12">
                            <h6 class="mb-0"><!-- {{ job.position.work_location.name }} - -->{{ job.position.terminal_value }}</h6>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-12 col-sm-4">
                            <div class="mui-textfield">
                                <span class="textfield" v-if="job.pay_type == 'S'">Salary</span>
                                <span class="textfield" v-if="job.pay_type == 'H'">Hourly</span>
                                <label>Pay Type</label>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4">
                            <div class="mui-textfield" v-if="job.pay_type == 'H'">
                                <span class="textfield">${{ job.hourly_rate }}</span>
                                <label>Per Hour</label>
                            </div>
                            <div class="mui-textfield" v-if="job.pay_type == 'S'">
                                <span class="textfield">${{ job.salary_rate }}</span>
                                <label>Per Pay Period</label>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4" v-if="job.pay_type == 'S'">
                            <div class="mui-textfield">
                                <span class="textfield">{{ job.default_hours }}</span>
                                <label>Default Hours</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="field-helper-text mb-1">Paid {{ job.position.paygroup.pay_frequency_verbose }}.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Position -->
    </div>
</template>

<script>

export default {
    props: ['employee', 'client', 'canEdit'],
}
</script>
