<template>
    <sidebar @close="$emit('close')">
        <template slot="title">
            {{ form.name }}
        </template>
        <slot>
            <div class="card mb-2">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Info</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.editModal.open()">Edit</button>
                </div>

                <div class="card-body">
                    <div class="mui-form">
                        <div class="mui-textfield">
                            <a class="textfield" :href="form.pdf_file_url" target="_blank">{{ form.pdf_file_real_name }}</a>
                            <label>PDF File</label>
                        </div>

                        <div class="mui-textfield" v-if="form.instructions_pdf_file">
                            <a class="textfield" :href="form.instructions_pdf_file_url" target="_blank">{{ form.instructions_pdf_file_real_name }}</a>
                            <label>PDF File</label>
                        </div>

                        <div class="mui-textfield">
                            <span class="textfield">{{ form.slug }}</span>
                            <label>Slug</label>
                        </div>

                        <div class="mui-textfield">
                            <span class="textfield">{{ form.form_type_label }}</span>
                            <label>Type</label>
                        </div>

                        <div class="mui-textfield">
                            <span class="textfield">{{ form.is_live ? 'Yes' : 'No' }}</span>
                            <label>Live</label>
                        </div>

                        <div class="mui-textfield">
                            <span class="textfield">{{ form.pdf_modified_on|date }}</span>
                            <label>PDF Modified On</label>
                        </div>

                        <div class="mui-textfield">
                            <span class="textfield">{{ form.yaml_modified_on|date }}</span>
                            <label>YAML Modified On</label>
                        </div>
                    </div>
                </div>
            </div>

            <p class="mt-2">You can try out this below. Filling it out will generate a sample PDF.</p>
            <hr />

            <survey ref="survey" :yaml="form.yaml" :knowndata="sampleKnownData" @error="onPDFFormError" :key="`${form.id}-${yamlHash}`" :hidesubmit="true"  :pdf-url="form.instructions_pdf_file_url || form.pdf_file_url" />
            
            <div class="mb-2">
                <button type="button" class="btn btn-primary" @click.prevent="onPDFFormSubmit()">Test PDF</button>
                <button type="button" class="btn btn-outline-primary ml-1" @click.prevent="onTestTaxValues()">Test Tax Values</button>
            </div>

            <div class="py-2 mt-2 border-top" v-if="taxData">
                <dl class="row" v-for="t in taxData">
                    <dt class="col-6">{{ t.field }}</dt>
                    <dd class="col">{{ t.value }}</dd>
                </dl>

                <p v-if="taxData.length < 1">
                    <em>No tax values found.</em>
                </p>

                <div>
                    <hr>
                    <h4>Push to Payroll</h4>
                    <p>
                        To push these values to a test employee in Payroll, enter an address below and click the button - the address must fall in the jurisdiction of this form.
                    </p>
                    <p>
                        <b>Resident Address</b>
                        <br><br>
                        <form-input label="Address" v-model="testAddressFormData.test_address_address1" type="text" />
                        <form-input label="City" v-model="testAddressFormData.test_address_city" type="text" />
                        <form-input label="State" v-model="testAddressFormData.test_address_state" type="text" maxlength="2" />
                        <form-input label="Zip" v-model="testAddressFormData.test_address_zip_code" type="text" />
                    </p>

                    <form-input label="Resident or Work (applies to taxes, not address)" v-model="testFormTaxScope" type="select" :options="taxScopeOptions"/>

                    <form-errors :errors="testFormErrors"/>
                    <div>
                        <alert-box type="success" v-if="testFormSuccessMsg">
                            {{ testFormSuccessMsg }}
                        </alert-box>
                    </div>

                    <button type="button" class="btn btn-primary ml-0" @click.prevent="onPushAddressToPayroll()">Push Address</button>
                    <button type="button" class="btn btn-primary ml-1" @click.prevent="onPushTestValuesToPayroll()">Push Taxes</button>
                    <button type="button" class="btn btn-outline-primary ml-1" @click.prevent="onReadValuesFromPayroll()">Read from Payroll</button>
                    <br><br>
                    <p>
                        <small>
                            Test employee: <b>AND778</b> in company <b>090</b>
                        </small>
                    </p>

                    <div id="payroll-data-comparison" v-if="read_payroll_data.length">
                        <small>
                            <table class="table table-condensed">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Field</th>
                                        <th>Us</th>
                                        <th>Payroll</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="payroll_data_row in read_payroll_data">
                                        <td>
                                            <span v-if="! payroll_data_row.match">
                                                <i class="fas fa-fw fa-exclamation-triangle"></i>
                                            </span>
                                        </td>
                                        <td>{{ payroll_data_row.field }}</td>
                                        <td>{{ payroll_data_row.expected_value }}</td>
                                        <td>{{ payroll_data_row.payroll_value }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </small>
                    </div>

                </div>
            </div>

            <form method="post" target="_blank" ref="pdfPreviewForm" :action="previewActionURL">
                <input type="hidden" name="csrfmiddlewaretoken" :value="pdfPreviewDataCSRFToken" />
                <input type="hidden" name="data" ref="pdfPreviewData" />
            </form>

            <edit-modal ref="editModal" :form="form" @updated="$emit('updated', $event);" />
        </slot>
    </sidebar>
</template>

<script>
import bus from '@/bus'
import api from '@/api'
import EditModal from './FormModal'
import Survey from '@/components/PDFForm/Survey'
import Sidebar from '@/components/Sidebar'
import $ from 'jquery'
import moment from 'moment'
import sha1 from 'js-sha1'

export default {
    components: {EditModal, Survey, Sidebar},
    props: ['form', ],
    computed: {
        pdfPreviewDataCSRFToken() {
            return this.$store.state.session ? this.$store.state.session.csrf_token : ''
        },
        previewActionURL() {
            return `${api.API_BASE}/admin/pdf-forms/forms/${this.form.id}`
        },
        taxData() {
            if (!this.formValues) {
                return null
            }

            let default_tax_values
            default_tax_values = this.$refs.survey.getDefaultTaxValues()

            let data
            data = Object.assign({}, default_tax_values, this.formValues)

            const result = []
            Object.entries(data).forEach(x => {
                if (x[0].match(/^__tax_field__/)) {
                    result.push({
                        field: x[0].substring('__tax_field__'.length),
                        value: x[1],
                    })
                }

                if (x[0].match(/^__local_tax_field__/)) {
                    result.push({
                        field: 'local_' + x[0].substring('__local_tax_field__'.length),
                        value: x[1],
                    })
                }
            })
            return result
        },
        yamlHash() {
            return sha1(this.form.yaml)
        },
        taxScopeOptions() {
            return [
                {text: 'Resident', value: 'resident'},
                {text: 'Work', value: 'work'},
            ]
        },
    },
    watch: {
        form: {
            handler() {
                this.formValues = null
            },
            deep: true,
        }
    },
    data() {
        return {
            formValues: null,
            testFormErrors: null,
            testFormSuccessMsg: '',
            testAddressFormData: {
                test_address_address1: '',
                test_address_city: '',
                test_address_state: '',
                test_address_zip_code: '',
            },
            testFormTaxScope: '',
            read_payroll_data: [],
            sampleKnownData:  {
                'system': {
                    'date': moment().format('MM/DD/YYYY'),
                    'datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),
                },
                'employee': {
                    'first_name': 'Johnny',
                    'middle_initial': 'R',
                    'last_name': 'Cash',
                    'full_name': 'Johnny R. Cash',
                    'address_line_1': '123 Main St',
                    'address_line_2': 'Apartment 777',
                    'city': 'Kingsland',
                    'state': 'AR',
                    'state_long': 'Arkansas',
                    'postcode': '71652',
                    'phone_number': '(530) 555-1234',
                    'ssn': '111-22-3344',
                    'dob': moment(new Date(1932, 1, 25)).format('MM/DD/YYYY'),
                    'date_hired': moment(new Date(2019, 11, 25)).format('MM/DD/YYYY'),
                    'school_district_id': '123',
                    'school_district_name': 'Test Central School District',
                },
                'employer': {
                    'name': 'McDonalds Corp',
                    'fein': '11-2233444',
                    'address_line_1': '675 Queen St',
                    'address_line_2': 'Suite #301',
                    'city': 'Southington',
                    'state': 'CT',
                    'state_long': 'Connecticut',
                    'postcode': '06489',
                    'phone_number': '(530) 555-1234',
                },
            },
        }
    },
    methods: {
        onPDFFormError(error) {
            if (error.message) {
                bus.showError(error.message)
            }
            else {
                bus.showError(error)
            }
        },
        onPDFFormSubmit() {
            let data;
            try {
                data = this.$refs.survey.getFormValues()
            } catch (ex) {
                this.onPDFFormError(ex)
                return
            }

            // We are specifically using jQuery here because if we do it via the normal
            // Vue.js DOM update, we'd have to submit the form in a this.$nextTick
            // callback which would trigger browsers' popup blockers.
            // jQuery updates the value synchronously, so we can update it and submit
            // directly after the button was pressed, which doesn't trigger the blockers.
            $(this.$refs.pdfPreviewData).val(JSON.stringify(data))
            this.$refs.pdfPreviewForm.submit()
        },
        onTestTaxValues() {
            let data;
            try {
                data = this.$refs.survey.getFormValues()
            } catch (ex) {
                this.onPDFFormError(ex)
                return
            }

            this.formValues = data

            this.$nextTick(() => {
                const $body = $(this.$refs.drawerBody)
                $body.scrollTop($body.height())
            })
        },

        onPushAddressToPayroll() {
            this.testFormErrors = null
            this.testFormSuccessMsg = ''

            let testData
            testData = {
                'tax_scope': this.testFormTaxScope,
                'test_address': {
                    'address_1': this.testAddressFormData.test_address_address1,
                    'city': this.testAddressFormData.test_address_city,
                    'state': this.testAddressFormData.test_address_state,
                    'zip_code': this.testAddressFormData.test_address_zip_code,
                },
            }

            let promise
            promise = api.put(`/admin/pdf-forms/forms/${this.form.id}/test-tax-values/push-address`, testData)

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.testFormSuccessMsg = resp['msg']
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.testFormErrors = errors.__all__
            })
        },

        onPushTestValuesToPayroll() {
            this.testFormErrors = null
            this.testFormSuccessMsg = ''

            let testData
            testData = {
                'tax_scope': this.testFormTaxScope,
                'tax_data': this.taxData,
            }

            let promise
            promise = api.put(`/admin/pdf-forms/forms/${this.form.id}/test-tax-values/push-taxes`, testData)

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.testFormSuccessMsg = resp['msg']
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.testFormErrors = errors.__all__
            })
        },

        onReadValuesFromPayroll() {
            this.testFormErrors = null
            this.read_payroll_data = []

            let testData
            testData = {
                'tax_data': this.taxData,
                'tax_scope': this.testFormTaxScope,
                'test_address': {
                    'address_1': this.testAddressFormData.test_address_address1,
                    'city': this.testAddressFormData.test_address_city,
                    'state': this.testAddressFormData.test_address_state,
                    'zip_code': this.testAddressFormData.test_address_zip_code,
                },
            }

            let promise
            promise = api.put(`/admin/pdf-forms/forms/${this.form.id}/test-tax-values/read`, testData)

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.read_payroll_data = resp['payroll_data']
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.testFormErrors = errors.__all__
            })
        },

    },
}
</script>

