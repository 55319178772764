<template>
    <form @submit.prevent="onSubmit()">
        <form-errors :errors="formErrors"/>

        <div class="form-row">
            <div class="col-8 col-sm-6">
                <form-input label="Expected Start Date" v-model="formData.date_hired" :errors="errors.date_hired" type="date" />
            </div>
        </div>

        <div class="form-row">
            <div class="col-8 col-sm-6">
                <form-input label="Pay Type" v-model="formData.pay_type" :errors="errors.pay_type" type="select" :options="payTypeOptions" />
            </div>
        </div>

        <div class="form-group-helpinfo">
            <div class="form-helpinfo-field">
                <form-input label="ACA Employment Category" v-model="formData.aca_category" :errors="errors.aca_category" type="select" :options="acaCategoryOptions" />

                <div class="field-helper-text" v-if="formData.aca_category == 'Full Time'">
                    On average at least 30 hours of service per week.
                </div>

                <div class="field-helper-text" v-if="formData.aca_category == 'Variable'">
                    On average less than 30 hours of service per week.
                </div>

            </div>

            <more-info>If you told this employee that they will be working 40 hours per week or you are paying them a salary, select Full Time for this field. Otherwise, select Variable.</more-info>
        </div>

        <div v-for="(id, $index) in Object.keys(jobForms)" :key="id">
            <div class="row align-items-center">
                <div class="col">
                    <h6 class="mb-1">#{{ $index + 1 }}</h6>
                </div>
                <div class="col text-right">

                    <div class="dropdown d-inline-block" v-if="Object.keys(jobForms).length > 1">
                        <a href="" class="btn btn-icon dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i><span class="sr-only">More Actions</span>
                        </a>
                        <div class="dropdown-menu has-icons dropdown-menu-right">
                            <a class="dropdown-item" href @click.prevent="removeJob(id, $index)"><i class="fas fa-fw fa-times-circle mr-1"></i>Remove Position</a>
                        </div>
                    </div>

                </div>
            </div>

            <job-form
                :client="client"
                :company="selectedCompany"
                :instance="formData.jobs[$index]"
                ref="jobForms"
                @update="onJobUpdate($index, $event)"
                :all-jobs="formData.jobs"
                :pay_type="formData.pay_type"
                :read-only-position="true"
            />

        </div>

        <button v-if="buttonText" type="submit" class="btn btn-block btn-primary">{{ buttonText }}</button>
    </form>
</template>

<script>
import api from '@/api'
import FormMixin from '@/mixins/Form'
import JobForm from './JobForm'

export default {
    mixins: [FormMixin],
    components: {JobForm, },
    props: {employee: {required: true}, client: {required: true}, buttonText: {required: false, default: 'Save'}},
    computed: {
        selectedCompany() {
            return this.client.companies.find((c) => {return c.id == this.employee.company.id})
        },
        payTypeOptions() {
            return [
                {text: 'Salary', value: 'S'},
                {text: 'Hourly', value: 'H'},
            ]
        },
    },
    data() {
        const data = {
            acaCategoryOptions: [
                {text: 'Full Time', value: 'Full Time'},
                {text: 'Variable', value: 'Variable'},
            ],
            formLabels: {
                aca_category: 'ACA Category',
                date_hired: 'Expected Start Date',
            },
            formRules: {
                aca_category: {presence: {allowEmpty: false},},
                date_hired: {presence: {allowEmpty: false}, date: true},
            },
            formData: this.makeFormData(),
            jobForms: {},
        }

        for (let i = 0; i < this.employee.jobs.length; i++) {
            data.jobForms[i] = i
        }

        return data
    },
    methods: {
        makeFormData() {
            const firstJob = this.employee.jobs[0]

            const formData = {
                aca_category: this.employee.aca_category,
                date_hired: this.employee.date_hired,
                pay_type: firstJob.pay_type,
                jobs: [],
            }

            this.employee.jobs.forEach((job) => {
                formData.jobs.push({
                    id: job.id,
                    location: job.position.work_location.id,
                    position: job.position.id,
                    pay_type: job.pay_type,
                    hourly_rate: job.hourly_rate,
                    salary_rate: job.salary_rate,
                    default_hours: job.default_hours,
                })
            })

            return formData
        },
        addJob() {
            const id = Math.max(1, ...Object.keys(this.jobForms)) + 1
            this.$set(this.jobForms, id, id)
        },
        removeJob(id, idx) {
            const jobs = []
            this.formData.jobs = [];
            (this.$refs.jobForms || []).forEach((form, i) => {
                if (i != idx) {
                    jobs.push(form.getFormData())
                }
            })

            this.$set(this.formData, 'jobs', jobs)
            this.$delete(this.jobForms, id)
        },
        onJobUpdate(idx, data) {
            this.$set(this.formData.jobs, idx, data)
        },
        onSubmit() {
            let errorCount = 0;
            (this.$refs.jobForms || []).forEach((form) => {
                if (!form.validate()) {
                    errorCount += 1
                }
            })

            if (!this.validate() || errorCount) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.put(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}`, this.formData).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__

                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            this.errors = {}
            this.formErrors = []
            this.jobForms = {1: 1}
            this.formData = this.makeFormData();

            (this.$refs.jobForms || []).forEach((form) => {
                form.reset()
            })
        },
    }
}
</script>
