<template>
<div class="card mb-2" v-if="loaded">
    <div class="card-header pr-1 d-flex align-items-center">
        <span class="btnlike mr-auto"><h4 class="mb-0">Login History</h4></span>
    </div>

    <div class="card-body py-1">

        <div v-if="!loginAttempts.length > 0">
            <p class="py-1 m-0 text-muted">
                <em>No logins recorded.</em>
            </p>
        </div>
        <div v-if="loginAttempts.length > 0">
            <ul class="list-unstyled m-0">
                <li>
                    <div class="form-row align-items-baseline py-1">
                        <div class="col">
                            <h5 class="theading m-0">Date</h5>
                        </div>
                        <div class="col">
                            <h5 class="theading m-0">IP Address</h5>
                        </div>
                    </div>
                </li>
    
                <li class="boxed-li-bottom" v-for="loginAttempt in recentLoginAttempts" :key="loginAttempt.id">
                    <div class="form-row align-items-baseline py-3q">
                        <div class="col">
                            <span>{{ (loginAttempt.taken_over_on ? loginAttempt.taken_over_on : loginAttempt.login_completed_on)|date }}</span>
                        </div>
                        <div class="col">
                            <span>{{ loginAttempt.taken_over_from ? loginAttempt.taken_over_from : loginAttempt.login_completed_from_ip }} </span>
                        </div>
                    </div>
                </li>
            </ul>

            <div v-if="!showAll && loginAttempts.length > 5">
                <button type="button" class="btn btn-link btn-block" @click.prevent="showAll = true">See all</button>
            </div>
            <div v-if="showAll && loginAttempts.length > 5">
                <button type="button" class="btn btn-link btn-block" @click.prevent="showAll = false">See fewer</button>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    props: ['loginAttempts'],
    computed: {
        sortedLoginAttempts() {
            if (!this.loaded) {return []}

            const result = this.loginAttempts.filter(x => x.login_completed_on)
            result.sort((a, b) => {
                return b.login_completed_on > a.login_completed_on ? 1 : -1
            })

            return result
        },
        recentLoginAttempts() {
            if (this.showAll) {
                return this.sortedLoginAttempts
            }

            return this.sortedLoginAttempts.slice(0, 5)
        },
        loaded() {
            return Array.isArray(this.loginAttempts)
        },
    },
    data() {
        return {
            showAll: false,
        }
    },
}
</script>
