<template>
    <modal @close="$emit('close')" ref="modal" size="lg" :is-closeable="isCloseable" class="modal--filters">
        <template slot="title">Filters</template>

        <slot>
            <!-- Active filters header area -->
            <div>
                <div class="modal-active-filters mb-1">
                    <!-- TODO: Show when currentFilterValues = 0 -->
                    <span class="btnlike text-muted" v-show="activeFilterCount == 0 || onlyQuickFilterIsActive">
                        Select 1 or more filters in these categories:
                    </span>
                    <active-filters-list
                        :in-modal="true"
                        :possible-filters="possibleFilters"
                        :filter-values="currentFilterValues"
                        :quick-filter-on="currentQuickFilterOn"
                        @remove="removeFilter"
                        @clear="resetAll()"
                        v-show="activeFilterCount > 0"
                    />
                </div>
            </div>
            <!-- Filters selection area -->

            <div class="selectfilters-list-wrapper">
                <div class="selectfilters-list accordion" id="selectFiltersAccordion">
                    <section v-for="filter in possibleFilters" :key="filter.id" class="selectfilters-section">
                        <div class="selectfilters-section__header" :id="'category_' + filter.id">
                            <span class="flex-grow-1">
                                <button type="button" :disabled="filter.type == 'warning' && currentQuickFilterOn" class="selectfilters-section__toggle collapse-trigger" :class="{'collapsed': !expandedFilters[filter.id], 'disabled': filter.type == 'warning' && currentQuickFilterOn}" @click.prevent="toggleExpandedFilters(filter.id)" :aria-expanded="expandedFilters[filter.id]" :aria-controls="'categoryBody_' + filter.id">
                                    <collapse-toggle-button :disabled="filter.type == 'warning' && currentQuickFilterOn" />
                                    {{ filter.name }}
                                </button>
                            </span>
                            <span class="flex-grow-0 selectfilters-section__status">
                                <span v-if="currentFilterValues[filter.id].length && (!hasQuickFilter || (hasQuickFilter && !currentQuickFilterOn))" class="selectfilters-section__counter">
                                    {{ currentFilterValues[filter.id].length }}
                                </span>
                                <span v-if="hasQuickFilter && currentQuickFilterOn && filter.type == 'warning'" class="text-muted text-small d-inline-block mr-hf">
                                    <em>Quick filter on</em>
                                </span>

                                <span v-if="hasQuickFilter && filter.type == 'warning'">
                                    <button type="button" :class="{'btn-toggle-active': currentQuickFilterOn}" class="btn btn-icon btn-icon-outline btn-toggle btn-toggle--warnings ml-hf" aria-label="Quick Filter: Warnings" @click.prevent="toggleQuickFilter()">
                                        <i aria-hidden="true" class="fas fa-exclamation-triangle" title="Quick Filter: Warnings"></i>
                                    </button>
                                </span>
                            </span>
                        </div>

                        <div class="selectfilters-section__body collapse" :id="'categoryBody_' + filter.id" :aria-labelledby="'categoryHeader_' + filter.id" data-parent="#selectFiltersAccordion" :class="{'show': expandedFilters[filter.id]}">
                            <ul class="list-unstyled m-0 p-0">
                                <li v-for="filterValue in filter.filterValues" class="selectfilters-section__item" :class="{'is-active': currentFilterValues[filter.id].find(fv => fv.value == filterValue.value)}">
                                    <span class="flex-grow-1">
                                        <form-input type="checkbox" :label="filterValue.label" :value="currentFilterValues[filter.id].find(fv => fv.value == filterValue.value)" @input="toggleFilterValue(filter.id, filterValue, $event)" />
                                    </span>
                                    <span class="flex-grow-0">
                                        <!-- Not currently needed, but here in the structure just in case -->
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </slot>

        <template slot="footer">
            <button-row>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-primary" @click.prevent="applyFilters" :disabled="filtersMatchInitialValues" :class="{'disabled': filtersMatchInitialValues}">
                    {{ initialFilterValuesBlank ? 'Apply Filters' : 'Apply Changes' }}
                </button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import ActiveFiltersList from './ActiveFiltersList'
import deepCompare from 'fast-deep-equal/es6'


export default {
    props: ['possibleFilters', 'filterValues', 'quickFilterOn', ],
    mixins: [ModalMixin],
    components: { ActiveFiltersList, },
    computed: {
        hasQuickFilter() {
            return this.quickFilterOn && (this.possibleFilters.find(f => f.type == 'warning') ? true : false)
        },
        activeFilterCount() {
            return Object.values(this.currentFilterValues).filter(f => f.length > 0).length
        },
        onlyQuickFilterIsActive() {
            if (!this.hasQuickFilter) {return false}
            if (!this.currentQuickFilterOn) {return false}
            if (this.activeFilterCount != 1) {return false}
            const filter = this.possibleFilters.find(f => f.type == 'warning')
            return (this.currentFilterValues[filter.id].length)
        },
        initialFilterValues() {
            const currentFilterValues = {}
            this.possibleFilters.forEach(pf => {
                currentFilterValues[pf.id] = []
                if (pf.id in this.filterValues) {
                    currentFilterValues[pf.id] = JSON.parse(JSON.stringify(this.filterValues[pf.id]))
                }
            })

            return currentFilterValues
        },
        initialFilterValuesBlank() {
            let valuesCount = 0
            Object.keys(this.initialFilterValues).forEach(fId => {
                valuesCount += this.initialFilterValues[fId].length
            })

            return valuesCount == 0
        },
        filtersMatchInitialValues() {
            // true if the current status is the same as the initial filter values, false if something has changed
            return deepCompare(this.initialFilterValues, this.currentFilterValues) && this.quickFilterOn == this.currentQuickFilterOn
        },
    },
    data() {
        const currentFilterValues = {}
        this.possibleFilters.forEach(pf => {
            currentFilterValues[pf.id] = []
            if (pf.id in this.filterValues) {
                currentFilterValues[pf.id] = JSON.parse(JSON.stringify(this.filterValues[pf.id]))
            }
        })

        return {
            currentFilterValues: currentFilterValues,
            currentQuickFilterOn: this.quickFilterOn,
            expandedFilters: {}
        }
    },
    methods: {
        // We need some sort of fix for Bootstrap's accordion behavior, which is bizarrely
        // broken in 1 key way. Section toggles have `.collapsed` by default, since they begin 
        // closed. When I toggle a section open, `.collapsed` gets removed from that toggle (good).
        // If I toggle the same section closed, `.collapsed` gets re-added to that toggle (good).
        // However, if I toggle a DIFFERENT section open – which should close the first section 
        // automatically, because accordion – `.collapsed` does NOT get re-added to the first 
        // section's toggle (bad). This means that after just a few clicks, the open/closed 
        // indicators all get out of sync. 
        // In the example in Bootstrap's docs, everything works fine, so it's probably just 
        // a bug in my implementation. Maybe Bootstrap's code expects there to be 1 expanded
        // section by default (which we don't have)?

        toggleFilterValue(filterId, filterValue, isActive) {
            this.currentFilterValues[filterId] = this.currentFilterValues[filterId].filter(fv => fv.value != filterValue.value)
            if (isActive) {
                this.currentFilterValues[filterId].push(filterValue)
            }
        },
        removeFilter({filterId, filterValue}) {
            this.currentFilterValues[filterId] = this.currentFilterValues[filterId].filter(fv => fv.value != filterValue)
        },
        applyFilters() {
            this.$emit('filter', {
                filterValues: this.currentFilterValues,
                quickFilterOn: this.currentQuickFilterOn
            })
            this.$nextTick(() => {
                this.close()
            })
        },
        toggleQuickFilter() {
            const filter = this.possibleFilters.find(f => f.type == 'warning')

            if (this.currentQuickFilterOn) {
                this.currentFilterValues[filter.id] = []
                this.currentQuickFilterOn = false
            } else {
                this.currentFilterValues[filter.id] = []
                filter.filterValues.forEach(fv => {
                    this.currentFilterValues[filter.id].push(fv)
                })
                this.currentQuickFilterOn = true
                this.$set(this.expandedFilters, filter.id, false)
            }
        },
        resetAll() {
            Object.keys(this.currentFilterValues).forEach(fId => {
                this.currentFilterValues[fId] = []
            })
            this.currentQuickFilterOn = false
        },
        toggleExpandedFilters(filterId) {
            if (!this.expandedFilters[filterId]) {
                Object.keys(this.expandedFilters).forEach(fId => {
                    this.expandedFilters[fId] = false
                })
            }

            this.$set(this.expandedFilters, filterId, !this.expandedFilters[filterId])
        },
    },
}
</script>
