<template>
    <modal size="xl" @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">
        <template slot="title">
            <span class="modal-title" v-if="question">Update Question</span>
            <span class="modal-title" v-if="!question">Add New Question</span>
        </template>

        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <form-errors :errors="formErrors"/>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input :nocaps="true" v-model="formData.slug" :errors="errors.slug" type="text" label="Slug" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.text" :errors="errors.text" type="textarea" label="Text" helper-text="You can use Markdown syntax." />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-6 col-md-3">
                        <form-input v-model="formData.type" :errors="errors.type" type="select" label="Form type" :options="formTypeOptions" />
                    </div>

                    <div class="col-6 col-md-3" v-if="formData.type == 'state'">
                        <form-input v-model="formData.state" :errors="errors.state" type="select" label="State" :options="stateOptions" />
                    </div>
                </div>

                <h4 class="mb-2">Options</h4>

                <div v-for="(id, $index) in Object.keys(optionForms)" :key="id">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="mb-1">Option {{ $index + 1 }}</h6>
                        </div>
                        <div class="col text-right">

                            <div class="dropdown d-inline-block" v-if="Object.keys(optionForms).length > 1">
                                <a href="" class="btn btn-icon dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i><span class="sr-only">More Actions</span>
                                </a>
                                <div class="dropdown-menu has-icons dropdown-menu-right">
                                    <a class="dropdown-item" href @click.prevent="removeOption(id, $index)"><i class="fas fa-fw fa-times-circle mr-1"></i>Remove</a>
                                </div>
                            </div>

                        </div>
                    </div>

                    <option-form :upstream-errors="(errors.option_forms && errors.option_forms[$index]) ? errors.option_forms[$index] : {}" v-model="formData.options[$index]" ref="optionForms" :forms="forms" />
                </div>
                <p class="mb-2">
                    <button type="button" class="btn btn-link" @click.prevent="addOption()"><i class="far fa-plus mr-hf"></i>Add another</button>
                </p>


            </form>
        </slot>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import FormMixin from '@/mixins/Form'
import OptionForm from './OptionForm'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['question', ],
    mixins: [FormMixin, ModalMixin],
    components: {OptionForm, },
    watch: {
        question() {
            this.reset()
        }
    },
    computed: {
        formTypeOptions() {
            return [
                {text: 'State', value: 'state'},
                {text: 'Federal', value: 'federal'},
                // {text: 'City', value: 'city'},
            ]
        },
        stateOptions() {
            const opts = []
            this.states.forEach(s => {
                opts.push({text: s.name, value: s.id})
            })
            return opts
        },
    },
    data() {
        const data = {
            formData: this.makeFormData(),
            formRules: {
                name: {presence: {allowEmpty: false},},
                slug: {presence: {allowEmpty: false},},
                text: {presence: {allowEmpty: false},},
                state: {presence: {allowEmpty: true},},
            },
            states: [],
            optionForms: {},
            forms: [],
        }

        if (this.question) {
            for (let i = 0; i < this.question.options.length; i++) {
                data.optionForms[i] = i
            }
        }
        else {
            data.optionForms = {0: 1}
        }

        return data
    },
    mounted() {
        this.getStates()
        this.getForms()
    },
    methods: {
        makeFormData() {
            if (this.question) {
                const options = []
                this.question.options.forEach((option) => {
                    options.push({
                        id: option.id,
                        slug: option.slug,
                        text: option.text,
                        yaml_form: option.yaml_form ? option.yaml_form.id : null,
                    })
                })

                return {
                    name: this.question.name,
                    slug: this.question.slug,
                    text: this.question.text,
                    type: this.question.type,
                    state: this.question.state ? this.question.state.id : null,
                    options: options,
                }
            } else {
                return {
                    type: 'state',
                    options: [],
                }
            }
        },
        getFormRule(field) {
            if (field == 'state') {
                if (this.formData.type == 'state') {
                    return {presence: {allowEmpty: false}}
                }
                else {
                    return {}
                }
            }

            return FormMixin.methods.getFormRule.call(this, field)
        },
        onSubmit() {
            let errorCount = 0;
            (this.$refs.optionForms || []).forEach((form) => {
                if (!form.validate()) {
                    errorCount += 1
                }
            })
            if (!this.validate() || errorCount) {
                return
            }

            let promise
            if (this.question) {
                promise = api.put(`/admin/pdf-forms/questions/${this.question.id}`, this.getFormData())
            }
            else {
                promise = api.post(`/admin/pdf-forms/questions`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        getStates() {
            api.get(`/admin/tax-engine/states`).then(resp => {
                this.states = resp
            }).catch((errors) => {
                bus.showError(errors.__all__[0])
            })
        },
        getForms() {
            api.get(`/admin/pdf-forms/forms`).then(resp => {
                this.forms = resp
            }).catch((errors) => {
                bus.showError(errors.__all__[0])
            })
        },
        addOption() {
            const id = Math.max(1, ...Object.keys(this.optionForms)) + 1
            this.$set(this.optionForms, id, id)
        },
        removeOption(id, idx) {
            this.formData.options = [];
            (this.$refs.optionForms || []).forEach((form, i) => {
                if (i != idx) {
                    this.formData.options.push(form.getFormData())
                }
            })

            this.$delete(this.optionForms, id)
        },
        reset() {
            (this.$refs.optionForms || []).forEach((form) => {
                form.reset()
            });

            this.$set(this, 'optionForms', {})
            if (this.question) {
                for (let i = 0; i < this.question.options.length; i++) {
                    this.optionForms[i] = i
                }
            }
            else {
                this.optionForms[0] = 1
            }

            FormMixin.methods.reset.call(this)
        },
    },
}
</script>
