<template>
    <aside class="drawer drawer-rowdetail drawer-right collapse no-transition show">

        <div class="drawer-header">
            <div class="drawer-close">
                <button type="button" class="close" aria-label="Close" role="button" aria-expanded="true" @click.prevent="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row">
                <div class="col">
                    <h2 class="drawer-title">
                        <span class="drawer-title-text">{{ city.name }}, {{ city.state.name }}</span>
                    </h2>
                </div>
            </div>
        </div>

        <div class="drawer-body">

            <div class="card mb-2">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Reciprocal Cities</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.editModal.open()">Edit</button>
                </div>

                <div class="card-body py-1">
                    <p v-if="!city.reciprocal_agreement_cities.length">This city has no reciprocal agreements.</p>

                    <ul class="list-unstyled m-0" v-if="city.reciprocal_agreement_cities.length">
                        <li v-for="(s, i) in city.reciprocal_agreement_cities" :key="s.id" :class="{'border-bottom': i < city.reciprocal_agreement_cities.length - 1}">
                            <span>{{ s.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="card mb-2">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Alternative Names</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.editModal.open()">Edit</button>
                </div>

                <div class="card-body py-1">
                    <p v-if="!city.alternative_names.length">This city has no alternative names.</p>

                    <ul class="list-unstyled m-0" v-if="city.alternative_names.length">
                        <li v-for="(n, i) in city.alternative_names" :class="{'border-bottom': i < city.alternative_names.length - 1}">
                            <span>{{ n }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <edit-modal ref="editModal" :city="city" :all-cities="allCities" @updated="$emit('updated', $event); close()" />
    </aside>
</template>

<script>
import bus from '@/bus'
import api from '@/api'
import EditModal from './CityModal'

export default {
    components: {EditModal, },
    props: ['city', 'allCities', ],
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>

