<template>
    <div>
        <table-page :client="client" :warnings="warnings" :changelogs="changelogs" @warnings_updated="getWarnings" @changelog_date_filter_change="onChangelogDateRangeChange" @changelog_click="onChangelogClick">
            <fast-table
                :sections="sections"
                :headers="headers"
                :data="tableData"
                @click="currentDocumentId = $event.id"
                :use-date-filters="true"
                @date_filter_change="onDateFilter"
            >
                <template slot="table-actions">
                    <button type="button" class="btn btn-primary btn-collapse" @click.prevent="$refs.addModal.open()">
                        <i class="far fa-fw fa-plus"></i> <span class="btn-text">Add Document</span>
                    </button>
                </template>
            </fast-table>

            <add-hr-document-modal :client="client" ref="addModal" @paymasters_hr_documents_updated="getDocuments(); getChangelogs()"/>
        </table-page>

        <sidebar
            :document="currentDocument"
            :changelogs="currentDocumentChangelogs"
            v-if="currentDocument"
            ref="sidebar"
            :client="client"
            @updated="getDocuments(); getChangelogs()"
            @close="currentDocumentId = null; sidebarTab = 'main'; currentChangelogId = null;"
            :tab="sidebarTab"
            :item-id="currentChangelogId"
        />
    </div>
</template>

<script>
import FastTable from '@/components/FastTable'
import AddHrDocumentModal from './components/AddHRDocumentModal'
import Sidebar from './components/DocumentSidebar'
import TablePage from '@/components/TablePage'
import moment from 'moment'

export default {
    components: {FastTable, AddHrDocumentModal, Sidebar, TablePage, },
    props: ['client', ],
    computed: {
        currentDocument() {
            return this.documents.find(doc => doc.id == this.currentDocumentId)
        },
        currentDocumentChangelogs() {
            if (!this.currentDocument) {return []}

            return this.changelogs.filter(c => c.hr_document && c.hr_document.id == this.currentDocumentId)
        },
        activeDocuments() {
            return this.documents.filter((doc) => {return doc.is_active})
        },
        inactiveDocuments() {
            return this.documents.filter((doc) => {return !doc.is_active})
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.activeDocuments,
                this.inactiveDocuments,
            ]

            subsets.forEach(docs => {
                const sectionData = []

                docs.forEach((d, idx) => {
                    sectionData.push({
                        id: idx,
                        isActive: this.currentDocumentId && d.id == this.currentDocumentId,
                        object: d,
                        cells: [
                            d.name + ' ' + d.id,
                            d.company ? d.company.name : '---',
                            d.version,
                            moment(d.updated_on).toDate(),
                            d.updated_by_pretty,
                            this.getLastFilename(d.files),
                            d.hr_templates.length,
                        ],
                    })
                })
                tableData.push(sectionData)
            })
            return tableData
        },
    },
    data() {
        return {
            documents: [],
            dataLoaded: false,
            currentDocumentId: null,
            dateRanges: {},

            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Version', classes: 'cell-narrow', isSearchable: false},
                {label: 'Last Modified', classes: 'cell-date', isSearchable: false, type: 'date'},
                {label: 'Modified By', classes: 'cell-name', isSearchable: true},
                {label: 'File Name', classes: 'cell-name', isSearchable: true},
                {label: 'Active Templates', classes: 'cell-medium', isSearchable: false},
            ],
            sections: [
                {title: 'Active Documents', defaultIsClosed: false, id: 'active', hasDateFilter: false},
                {title: 'Inactive Documents', defaultIsClosed: false, id: 'inactive', hasDateFilter: true},
            ],
            warnings: null,
            changelogs: null,
            changelogDateRangeStart: null,
            changelogDateRangeEnd: null,
            currentChangelogId: null,
            sidebarTab: 'main',
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Settings / HR Documents')
        this.getDocuments()
        this.getWarnings()
        this.getChangelogs()
    },
    methods: {
        getDocuments() {
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/hr-documents`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.$api.get(url).then(docs => {
                this.documents = docs
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                if (errors.__status__ == 403) {
                    this.$bus.$emit('no-access')
                }
                else {
                    this.$bus.showError(errors.__all__)
                }
            })
        },
        getLastFilename(files) {
            if (!files.length) {
                return ''
            }
            files = files.slice()  // Make a copy of the array
            files.sort((a, b) => {
                return a.version > b.version ? -1 : 1
            })

            return files[0] ? files[0].filename : ''
        },
        getWarnings() {
            this.warnings = []
        },
        getChangelogs() {
            let url = `/clients/${this.client.id}/changelogs`
            if (this.changelogDateRangeStart && this.changelogDateRangeEnd) {
                url = `${url}?date-filter.changelogs.start=${encodeURIComponent(this.changelogDateRangeStart)}&date-filter.changelogs.end=${encodeURIComponent(this.changelogDateRangeEnd)}`
            }

            this.$api.get(url).then(resp => {
                this.changelogs = resp.filter(c => c.hr_document)
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getDocuments()
        },
        onChangelogDateRangeChange(range) {
            this.changelogDateRangeStart = range.start
            this.changelogDateRangeEnd = range.end
            this.getChangelogs()
        },
        onChangelogClick(changelog) {
            this.currentDocumentId = changelog.hr_document.id
            this.currentChangelogId = changelog.id
            this.sidebarTab = 'changelogs'
        },
    },
}

</script>
