import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import NotFound from '@/pages/NotFound'
import AppDownload from '@/pages/AppDownload'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import AutoLogin from '@/pages/AutoLogin'
import AppLogout from '@/pages/AppLogout'
import Login from '@/pages/Login'
import ForgotPassword from '@/pages/ForgotPassword'
import ForgotPIN from '@/pages/ForgotPIN'
import ResetPIN from '@/pages/ResetPIN'
import NoSystemAccess from '@/pages/NoSystemAccess'
import AccountClosed from '@/pages/AccountClosed'
import LoginValidateEmail from '@/pages/LoginValidateEmail'
import Signup from '@/pages/Signup'
import SignupValidateEmail from '@/pages/SignupValidateEmail'
import SignupDigitalConsent from '@/pages/SignupDigitalConsent'
import SignupSetPassword from '@/pages/SignupSetPassword'
import SignupComplete from '@/pages/SignupComplete'
import SignupNoEmail from '@/pages/SignupNoEmail'
import UserProfile from '@/pages/UserProfile'
import CriticalWarnings from '@/pages/CriticalWarnings'

// Client views
import Client from '@/pages/client/Client'
import EmployerHome from '@/pages/client/EmployerHome'
import Employees from '@/pages/client/employees'
import Onboarding from '@/pages/client/Onboarding'
import Warnings from '@/pages/client/warnings'
import Changelog from '@/pages/client/changelog'
import Paychecks from '@/pages/client/paychecks'
import EmployeeW2s from '@/pages/client/employee-w2s'
import ClientTaxForms from '@/pages/client/tax-forms/TaxForms'
import ClientHRDocuments from '@/pages/client/hr-documents'
import SettingsBase from '@/pages/client/Settings/SettingsBase'
import SettingsUsers from '@/pages/client/Settings/Users'
import SettingsDocuments from '@/pages/client/Settings/Documents'
import SettingsTemplates from '@/pages/client/Settings/Templates'
import ActivateSecurityProfile from '@/pages/client/ActivateSecurityProfile'

// Employee views
import Company from '@/pages/ess/Company'
import EmployeeHome from '@/pages/ess/EmployeeHome'
import EmployeeOnboardingApplication from '@/pages/ess/onboarding/EmployeeOnboardingApplication'
import LinkEmpoyee from '@/pages/ess/LinkEmployee'
import EmployeePaychecks from '@/pages/ess/EmployeePaychecks'
import EmployeePaycheck from '@/pages/ess/EmployeePaycheck'
import EmployeeTaxForms from '@/pages/ess/tax-forms/TaxForms'
import EmployeeTaxFormDetail from '@/pages/ess/tax-forms/TaxFormDetail'
import EmployeeHRDocuments from '@/pages/ess/hr-docs/HRDocuments'

// Employee/onboarding
import OnboardingWOTCQuestions from '@/pages/ess/onboarding/OnboardingWOTCQuestions'
import OnboardingHRInfo from '@/pages/ess/onboarding/OnboardingHRInfo'
import OnboardingAddress from '@/pages/ess/onboarding/OnboardingAddress'
import OnboardingEEOInfo from '@/pages/ess/onboarding/OnboardingEEOInfo'
import OnboardingFormSelection from '@/pages/ess/onboarding/OnboardingFormSelection'
import OnboardingForms from '@/pages/ess/onboarding/OnboardingForms'
import OnboardingDirectDeposit from '@/pages/ess/onboarding/OnboardingDirectDeposit'
import OnboardingComplete from '@/pages/ess/onboarding/OnboardingComplete'

// Admin views

import AdminHome from '@/pages/admin/Index'
import TaxEngineBase from '@/pages/admin/tax-engine/Base'
import TaxEngineStates from '@/pages/admin/tax-engine/States'
import TaxEngineCities from '@/pages/admin/tax-engine/Cities'

import PDFFormsBase from '@/pages/admin/pdf-forms/Base'
import PDFForms from '@/pages/admin/pdf-forms/Forms'
import PDFFormQuestions from '@/pages/admin/pdf-forms/Questions'
import PDFFormSelectionTester from '@/pages/admin/pdf-forms/SelectionTester'
import StateFormsRulesList from '@/pages/admin/pdf-forms/RulesList'
import StateFormsRulesTest from '@/pages/admin/pdf-forms/RulesTest'

import AdminPayrolls from '@/pages/admin/payrolls/Payrolls'
import AdminClients from '@/pages/admin/clients/Clients'
import AdminClientBase from '@/pages/admin/clients/Base'
import AdminClientCompanies from '@/pages/admin/clients/Companies'
import AdminClientPOSIntegrations from '@/pages/admin/clients/POSIntegrations'

import Index from '@/pages/Index.vue'
import $ from 'jquery'

import bus from '@/bus'

Vue.use(VueRouter)

const Router = new VueRouter({
    mode: 'history',
    base: (process.env.NODE_ENV == 'production') ? '/' : '/',
    routes: [
        {name: '404', path: '*', component: NotFound, meta: {requiresAuth: false}},
        {name: 'index', path: '/', component: Index, meta: {requiresAuth: true}},
        {name: 'privacy', path: '/privacy', component: PrivacyPolicy, meta: {requiresAuth: false}},
        {name: 'download-app', path: '/download-app', component: AppDownload, meta: {requiresAuth: false}},

        {name: 'login', path: '/login', component: Login, meta: {requiresAuth: false}},
        {name: 'login-validate-email', path: '/login/validate-email', component: LoginValidateEmail, meta: {requiresAuth: false}},
        {name: 'no-system-access', path: '/no-access', component: NoSystemAccess, meta: {requiresAuth: false}},
        {name: 'account-closed', path: '/account-closed', component: AccountClosed, meta: {requiresAuth: false}},
        {name: 'auto-login', path: '/login/auto/:challengeKey/:secureToken', component: AutoLogin, meta: {requiresAuth: false}},
        {name: 'app-logout', path: '/app-logout', component: AppLogout, meta: {requiresAuth: false}},
        {name: 'forgot-password', path: '/forgot-password', component: ForgotPassword, meta: {requiresAuth: false}},
        {name: 'forgot-pin', path: '/forgot-pin', component: ForgotPIN, meta: {requiresAuth: false}},
        {name: 'reset-pin', path: '/reset-pin/:secret', component: ResetPIN, meta: {requiresAuth: false}},

        {name: 'signup', path: '/signup', component: Signup, meta: {requiresAuth: false}},
        {name: 'signup-validate-email', path: '/signup/validate-email', component: SignupValidateEmail, meta: {requiresAuth: false}},
        {name: 'signup-digital-consent', path: '/signup/digital-consent', component: SignupDigitalConsent, meta: {requiresAuth: false}},
        {name: 'signup-set-password', path: '/signup/set-password', component: SignupSetPassword, meta: {requiresAuth: false}},
        {name: 'signup-complete', path: '/signup/set-complete', component: SignupComplete, meta: {requiresAuth: true}},
        {name: 'signup-no-email', path: '/signup/no-email', component: SignupNoEmail, meta: {requiresAuth: false}},
        {name: 'profile', path: '/profile', component: UserProfile, meta: {requiresAuth: true}},

        {name: 'employee-home', path: '/employee', component: EmployeeHome, meta: {requiresAuth: true, mode: 'ess'}},
        {name: 'employee-paychecks', path: '/employee/paychecks', component: EmployeePaychecks, meta: {requiresAuth: true, mode: 'ess'}},
        {name: 'employee-paycheck', path: '/employee/paychecks/:paycheckId', component: EmployeePaycheck, meta: {requiresAuth: true, mode: 'ess'}},
        {name: 'employee-tax-forms', path: '/employee/tax-forms', component: EmployeeTaxForms, meta: {requiresAuth: true, mode: 'ess'}},
        {name: 'employee-tax-form-detail', path: '/employee/tax-forms/:taxFormId', component: EmployeeTaxFormDetail, meta: {requiresAuth: true, mode: 'ess'}},
        {name: 'employee-hr-docs', path: '/employee/hr-docs', component: EmployeeHRDocuments, meta: {requiresAuth: true, mode: 'ess'}},
        {name: 'profile-ess', path: '/employee/profile', component: UserProfile, meta: {requiresAuth: true, mode: 'ess'}},

        {path: '/employee/onboarding/:appId', component: Company, meta: {requiresAuth: true, mode: 'ess'}, children: [
            {name: 'employee-onboarding', path: '', component: EmployeeOnboardingApplication, meta: {requiresAuth: true, mode: 'ess'}, children: [
                {name: 'employee-onboarding-wotc', path: 'wotc', component: OnboardingWOTCQuestions, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-hr-info', path: 'hr-info', component: OnboardingHRInfo, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-address', path: 'address', component: OnboardingAddress, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-eeo-info', path: 'eeo-info', component: OnboardingEEOInfo, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-form-selection', path: 'form-selection', component: OnboardingFormSelection, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-forms', path: 'forms', component: OnboardingForms, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-direct-deposit', path: 'direct-deposit', component: OnboardingDirectDeposit, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-complete', path: 'complete', component: OnboardingComplete, meta: {requiresAuth: true, mode: 'ess'}},
            ]},
        ]},
        {name: 'employee-link-record', path: '/employee/companies/:companyId/link', component: LinkEmpoyee, meta: {requiresAuth: true, mode: 'ess'}},

        {name: 'link-client', path: '/clients/:clientId/link', component: ActivateSecurityProfile, meta: {requiresAuth: true, mode: 'client'}},
        {path: '/clients/:clientId', component: Client, meta: {requiresAuth: true, mode: 'client'}, children: [
            {name: 'employer-home', path: '', component: EmployerHome, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'employees', path: 'employees', component: Employees, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'onboarding', path: 'onboarding', component: Onboarding, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'warnings', path: 'warnings', component: Warnings, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'changelogs', path: 'change-history', component: Changelog, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'paychecks', path: 'paychecks', component: Paychecks, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'employee-w2s', path: 'employee-w2s', component: EmployeeW2s, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'hr-documents', path: 'hr-documents', component: ClientHRDocuments, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'tax-forms', path: 'tax-forms', component: ClientTaxForms, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'settings', path: 'settings', component: SettingsBase, meta: {requiresAuth: true, mode: 'client'}, children: [
                {name: 'settings-users', path: 'users', component: SettingsUsers, meta: {requiresAuth: true, mode: 'client'}},
                {name: 'settings-hr-documents', path: 'hr-documents', component: SettingsDocuments, meta: {requiresAuth: true, mode: 'client'}},
                {name: 'settings-hr-templates', path: 'hr-templates', component: SettingsTemplates, meta: {requiresAuth: true, mode: 'client'}},
            ]},
            {name: 'profile-client', path: 'profile', component: UserProfile, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'critical-warnings', path: 'critical-warnings', component: CriticalWarnings, meta: {requiresAuth: true, mode: 'client'}},
        ]},

        // Admin pages
        {name: 'admin-home', path: '/admin-tools', component: AdminHome, meta: {requiresAuth: true, mode: 'admin'}},
        {name: 'profile-admin', path: '/admin-tools/profile', component: UserProfile, meta: {requiresAuth: true, mode: 'admin'}},

        {name: 'admin-tax-engine', path: '/admin-tools/tax-engine', component: TaxEngineBase, meta: {requiresAuth: true, mode: 'admin'}, children: [
            {name: 'admin-tax-engine-states', path: 'states', component: TaxEngineStates, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-tax-engine-cities', path: 'cities', component: TaxEngineCities, meta: {requiresAuth: true, mode: 'admin'}},
        ]},

        {name: 'admin-pdf-forms', path: '/admin-tools/pdf-forms', component: PDFFormsBase, meta: {requiresAuth: true, mode: 'admin'}, children: [
            {name: 'admin-pdf-forms-forms', path: 'forms', component: PDFForms, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-pdf-forms-questions', path: 'questions', component: PDFFormQuestions, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-pdf-forms-tester', path: 'tester', component: PDFFormSelectionTester, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-pdf-forms-rules', path: 'rules', component: StateFormsRulesList, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-pdf-forms-rules-test', path: 'rules-test', component: StateFormsRulesTest, meta: {requiresAuth: true, mode: 'admin'}},
        ]},
        {name: 'admin-payrolls', path: '/admin-tools/payrolls', component: AdminPayrolls, meta: {requiresAuth: true, mode: 'admin'}},

        {name: 'admin-clients', path: '/admin-tools/clients', component: AdminClients, meta: {requiresAuth: true, mode: 'admin'}},
        {name: 'admin-client', path: '/admin-tools/clients/:clientId', component: AdminClientBase, meta: {requiresAuth: true, mode: 'admin'}, children: [
            {name: 'admin-client-companies', path: 'companies', component: AdminClientCompanies, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-client-pos-integrations', path: 'integrations', component: AdminClientPOSIntegrations, meta: {requiresAuth: true, mode: 'admin'}},
        ]},


        {path: '/admin/onboarding/:appId', component: Company, meta: {requiresAuth: true, mode: 'admin'}, children: [
            {name: 'admin-onboarding', path: '', component: EmployeeOnboardingApplication, meta: {requiresAuth: true, mode: 'admin'}, children: [
                {name: 'admin-onboarding-wotc', path: 'wotc', component: OnboardingWOTCQuestions, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-hr-info', path: 'hr-info', component: OnboardingHRInfo, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-address', path: 'address', component: OnboardingAddress, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-eeo-info', path: 'eeo-info', component: OnboardingEEOInfo, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-form-selection', path: 'form-selection', component: OnboardingFormSelection, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-forms', path: 'forms', component: OnboardingForms, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-direct-deposit', path: 'direct-deposit', component: OnboardingDirectDeposit, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-complete', path: 'complete', component: OnboardingComplete, meta: {requiresAuth: true, mode: 'admin'}},
            ]},
        ]},
    ],
    scrollBehavior() {
        return {x: 0, y: 0}
    },
})

Router.beforeEach((to, from, next) => {
    store.dispatch('SET_CLOSEABLE', true)
    store.dispatch('STOP_LOADING')
    store.dispatch('SET_PAGE_TITLE', '')
    store.dispatch('CLOSE_LEFT_DRAWER')
    $('body').removeClass('modal-open')
    $('body .modal-backdrop').remove()
    if (to.meta.requiresAuth && !store.state.user) {
        next('/login?next=' + encodeURIComponent(to.path))
    }

    if (global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cordova_iab) {
        global.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({
            command: 'navigate',
            from: {
                fullPath: from.fullPath,
                path: from.path,
                name: from.name,
                params: from.params,
            },
            to: {
                fullPath: to.fullPath,
                path: to.path,
                name: to.name,
                params: to.params,
            },
        }))
    }

    bus.onNavigation()

    next()
})

Router.afterEach((to) => {
    if (to.meta.mode == 'client' && store.state.user && to.params.clientId) {
        bus.subscribeToClientUpdates(to.params.clientId)
    }
    else {
        bus.unsubscribeFromClientUpdates()
    }
})


export default Router
