<template>
    <sidebar @close="$emit('close')">
        <template slot="title">
            {{ document.name }}
        </template>

        <template slot="header">
            <div class="row mb-1">
                <div class="col">
                    <span class="text-muted">
                        Company: {{ document.company.name }}
                    </span>
                </div>
            </div>
            <div class="row mb-1">
                <div class="col">
                    <span class="text-muted">
                        Last updated: {{ document.updated_on|date }}
                    </span>
                </div>
            </div>

            <!-- Tabs -->
            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'main'}" href="#tabContentMain1" id="tabLinkMain1" data-toggle="tab" role="tab" aria-controls="tabContentMain1" :aria-selected="activeTab == 'main'">Main</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'changelogs'}" href="#tabContentHistory1" id="tabLinkHistory1" data-toggle="tab" role="tab" aria-controls="tabContentHistory" :aria-selected="activeTab == 'changelogs'">History</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot>
            <!-- Tabs Content Container -->
            <div class="tab-content">
                <!-- Tab Pane 1 -->
                <div class="tab-pane" :class="{'show active': activeTab == 'main'}" id="tabContentMain1" role="tabpanel" aria-labelledby="tabLinkMain1">

                    <!-- Versions Widget -->
                    <info-widget :collapsible="false">
                        <template slot="title">Versions</template>

                        <template slot="headerbutton">
                            <button type="button" class="btn btn-outline-primary" @click.prevent="$refs.editModal.open()">
                                Upload New Version
                            </button>
                        </template>

                        <slot>
                            <div v-if="!sortedFiles.length">
                                <div class="info-widget-empty p-2">
                                    This HR Document has no uploaded files.
                                </div>
                            </div>
                            <div v-if="sortedFiles.length">
                                <section class="table-section">
                                    <table class="table css-table widget-table table-fixed">
                                        <thead>
                                            <tr>
                                                <th class="cw-toggle"></th>
                                                <th>Filename</th>
                                                <th class="cw-6 text-center">Version</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="file in sortedFiles" :key="file.id">
                                            <tr>
                                                <td class="cw-toggle position-relative">
                                                    <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': shownFileDetails[file.id]}" @click.prevent="toggleFileDetails(file.id)">
                                                        <collapse-toggle-button />
                                                    </a>
                                                </td>
                                                <td class="truncate-wrapper">
                                                    <a href @click.prevent="setCurrentPDF(file)" class="truncate-content">
                                                        <i class="far fa-fw fa-file-pdf mr-hf"></i>
                                                        <span>{{ file.filename }}</span>
                                                    </a>
                                                </td>
                                                <td class="text-center">
                                                    <span>{{ file.version }}</span>
                                                </td>
                                            </tr>
                                            <tr class="child no-transition" v-show="shownFileDetails[file.id]">
                                                <td colspan="3">
                                                    <div class="card card-body item-details">
                                                        <span>
                                                            Uploaded on {{ file.created_on|date('MM/DD/Y hh:mm:ss') }}
                                                        </span><br />
                                                        <span>
                                                            Uploaded by {{ file.created_by_pretty }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
                            </div>
                        </slot>
                    </info-widget>

                    <button-row stacked class="pt-0">
                        <button type="button" class="btn btn-outline-primary" @click.prevent="inactivate()" v-if="document.is_active">
                            Deactivate Document
                        </button>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="reactivate()" v-if="!document.is_active">
                            Reactivate Document
                        </button>
                    </button-row>

                </div>
                <!-- Tab Pane 2 -->
                <div class="tab-pane" :class="{'show active': activeTab == 'changelogs'}" id="tabContentHistory1" role="tabpanel" aria-labelledby="tabLinkHistory1">
                    <change-history :client="client" :changelogs="changelogs" :active-changelog-id="activeChangelogId"/>
                </div>
            </div>

            <edit-hr-document-modal ref="editModal" :document="document" :client="client" @updated="$emit('updated', $event); close()" />

            <pdf-modal :url="currentPDF.url" :title="currentPDF.title" v-if="currentPDF" @close="setCurrentPDF(null)" ref="pdfModal" />

        </slot>
    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import InfoWidget from '@/components/InfoWidget'
import ButtonRow from '@/components/ButtonRow'
import ChangeHistory from '@/components/ChangeHistory'
import EditHrDocumentModal from './EditHRDocumentModal'
import PdfModal from '@/components/PdfModal'

export default {
    components: {Sidebar, InfoWidget, ButtonRow, ChangeHistory, EditHrDocumentModal, PdfModal, },
    props: ['document', 'client', 'changelogs', 'tab', 'section', 'itemId', ],
    computed: {
        state() {return this.$store.state},
        API_BASE() {
            return this.$api.API_BASE
        },
        sortedFiles() {
            return this.sortFiles()
        }
    },
    data() {
        return {
            shownFileDetails: this.makeShownFileDetails(),
            editMode: false,
            currentPDF: null,
            activeTab: this.tab,
            activeChangelogId: (this.tab == 'changelogs') ? this.itemId : null,
        }
    },
    watch: {
        'document.id'() {
            this.shownFileDetails = this.makeShownFileDetails()
        },
    },
    methods: {
        close() {
            this.$emit('paymasters_close_sidebar')
        },
        toggleFileDetails(fileId) {
            this.$set(this.shownFileDetails, fileId, !this.shownFileDetails[fileId])
        },
        reactivate() {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/hr-documents/${this.document.id}/status`, {status: 'active'}).then(resp => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        inactivate() {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/hr-documents/${this.document.id}/status`, {status: 'inactive'}).then(resp => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getChangelogs() {
            this.changelogs = null
            this.$api.get(`/clients/${this.client.id}/hr-documents/${this.document.id}/changelogs`).then(resp => {
                this.changelogs = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        setCurrentPDF(file) {
            if (!file) {
                this.currentPDF = null
                return
            }

            this.currentPDF = {
                title: file.filename,
                url: {
                    url: `${this.API_BASE}/clients/${this.client.id}/hr-documents/${file.hr_document}/file/pdf?file_id=${file.id}`,
                    withCredentials: true,
                }
            }
        },
        sortFiles() {
            const files = this.document.files.slice()  // Make a copy of the array
            files.sort((a, b) => {
                return a.version > b.version ? -1 : 1
            })

            return files
        },
        makeShownFileDetails() {
            const files = this.sortFiles()
            const shownFileDetails = {}
            files.forEach((f, idx) => {
                shownFileDetails[f.id] = (idx == 0)
            })
            return shownFileDetails
        },
    },
}
</script>

