<template>
    <modal size="xl" @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">

    <template slot="title">
        <h3 class="modal-title" v-if="form">Update Form: <span class="text-thin text-nowrap">{{ form.name }}</span></h3>
        <h3 class="modal-title" v-if="!form">Add New Form</h3>
    </template>

    <slot>
    <form class="mui-form" @submit.prevent="onSubmit()">
        <form-errors :errors="formErrors"/>

            <div class="form-row">
                <div class="col">
                    <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" />
                </div>
            </div>

            <div class="form-row" v-if="!form">
                <div class="col">
                    <form-input v-model="formData.slug" :nocaps="true" :errors="errors.slug" type="text" label="Slug" />
                </div>
            </div>

            <div class="form-row">
                <div class="col-8 col-md-4">
                    <form-input v-model="formData.form_type" :errors="errors.form_type" type="select" label="Type" :options="typeOptions"/>
                </div>

                <div class="col-8 col-md-4" v-if="formData.form_type == 'city-w4'">
                    <form-input v-model="formData.city" :errors="errors.city" type="select" label="City" :options="cityOptions" />
                </div>

                <div class="col-8 col-md-4" v-if="(formData.form_type == 'state-w4') || (formData.form_type == 'non-resident') || (formData.form_type == 'exempt-cert')">
                    <form-input v-model="formData.state" :errors="errors.state" type="select" label="State" :options="stateOptions" />
                </div>
            </div>

            <div class="form-row mb-1">
                <div class="col-8">
                    <form-input v-model="formData.is_live" :errors="errors.is_live" type="checkbox" label="Is live" />
                </div>
            </div>

            <div class="form-row mb-4">
                <div class="col-8">
                    <form-input v-model="formData.pdf_file" :errors="errors.pdf_file" type="file" label="PDF File" @upload="onUpload($event, 'pdf_file')" />
                        <small v-if="form && form.pdf_file">Currently: <a class="textfield" :href="form.pdf_file_url" target="_blank">{{ form.pdf_file_real_name }}</a></small>
                </div>
            </div>

            <div class="form-row mb-4">
                <div class="col-8">
                    <form-input v-model="formData.instructions_pdf_file" :errors="errors.instructions_pdf_file" type="file" label="Instructions PDF File" @upload="onUpload($event, 'instructions_pdf_file')" helper-text="Optional. If not provided, the PDF form file will be used for instructions."/>
                        <small v-if="form && form.instructions_pdf_file">Currently: <a class="textfield" :href="form.instructions_pdf_file_url" target="_blank">{{ form.instructions_pdf_file_real_name }}</a></small>
                </div>
            </div>

            <div class="form-row mb-3">
                <div class="col">
                    <form-input v-model="formData.yaml" :errors="errors.yaml" type="textarea" rows="12" label="YAML" extraclasses="yaml-input" @input='validateYAML()' />
                </div>
            </div>


        </form>
    </slot>

    <template slot="footer">
        <div class="form-row">
            <div class="col">
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
            </div>
            <div class="col">
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </div>
        </div>
    </template>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import jsyaml from 'js-yaml'

export default {
    props: ['form', ],
    mixins: [FormMixin, ModalMixin, ],
    watch: {
        form() {
            this.reset()
        },
        'formData.form_type'(n, o) {
            if (o != n) {
                this.formData.city = ''
                this.formData.state = ''
            }
        },
    },
    computed: {
        cityOptions() {
            const opts = []
            this.cities.forEach(c => {
                opts.push({text: `${c.name}, ${c.state.name}`, value: c.id})
            })
            return opts
        },
        stateOptions() {
            const opts = []
            this.states.forEach(s => {
                opts.push({text: s.name, value: s.id})
            })
            return opts
        },
        typeOptions() {
            const opts = []
            this.formTypes.forEach(x => {
                opts.push({text: x[1], value: x[0]})
            })
            return opts
        },
    },
    data() {
        return {
            pdf_file: null,
            uploads: {},
            formData: this.makeFormData(),
            cities: [],
            states: [],
            formTypes: [],
        }
    },
    mounted() {
        this.getCities()
        this.getStates()
        this.getFormTypes()
    },
    methods: {
        reset() {
            FormMixin.methods.reset.call(this)
            this.uploads = {}
        },
        makeFormData() {
            if (this.form) {
                return {
                    name: this.form.name,
                    slug: this.form.slug,
                    yaml: this.form.yaml,
                    city: this.form.city ? this.form.city.id : null,
                    state: this.form.state ? this.form.state.id : null,
                    is_live: this.form.is_live,
                    form_type: this.form.form_type,
                    pdf_file: null,
                }
            } else {
                return {
                }
            }
        },
        onUpload(f, field) {
            if (!f[0]) {
                return
            }

            this.uploads[field] = f[0]
            return
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')

            const uploadPromises = []
            Object.keys(this.uploads).forEach(field => {
                uploadPromises.push(api.upload(`/admin/pdf-forms/file-upload`, this.uploads[field], {fieldName: 'file'}).then(resp => {
                    this.formData[field] = {
                        url: resp.url,
                        filename: resp.filename,
                    }

                    return resp
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    bus.showError(errors.__all__)
                }))
            })
            const allUploadPromises = Promise.all(uploadPromises)

            return allUploadPromises.then(() => {
                let promise

                if (this.form) {
                    promise = api.put(`/admin/pdf-forms/forms/${this.form.id}`, this.getFormData())
                }
                else {
                    promise = api.post(`/admin/pdf-forms/forms`, this.getFormData())
                }

                promise.then(resp => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('updated', resp)
                    this.close()
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                })
            })
        },
        getCities() {
            api.get(`/admin/tax-engine/cities`).then(resp => {
                this.cities = resp
            }).catch((errors) => {
                bus.showError(errors.__all__[0])
            })
        },
        getFormTypes() {
            api.get(`/admin/pdf-forms/forms/types`).then(resp => {
                this.formTypes = resp
            }).catch((errors) => {
                bus.showError(errors.__all__[0])
            })
        },
        getStates() {
            api.get(`/admin/tax-engine/states`).then(resp => {
                this.states = resp
            }).catch((errors) => {
                bus.showError(errors.__all__[0])
            })
        },
        validateYAML() {
            const findQuestions = (tree) => {
                let questions = []
                tree.forEach(q => {
                    questions.push(q)

                    // Add dependent questions
                    questions = questions.concat(findQuestions(q.questions || []))

                    ;(q.options || []).forEach(o => {
                        questions = questions.concat(findQuestions(o.questions || []))
                    })
                })

                return questions
            }

            const KNOWN_TYPES = ['select-one', 'select-many', 'date', 'signature', 'text', 'ssn', 'checkbox', 'currency', 'positive-currency', 'integer', 'positive-integer', 'math', 'calc', 'constant', 'info', 'email']

            delete this.errors.yaml
            try {
                const survey = jsyaml.safeLoad(this.formData.yaml)

                if (!survey) {
                    this.errors.yaml = ['YAML seems to be blank.']
                    return
                }

                if (!survey.title) {
                    this.errors.yaml = ['There is no title!']
                    return
                }

                if (!survey.instructions) {
                    this.errors.yaml = ['There is no instructions text.']
                    return
                }

                if (!(survey.questions instanceof Array) || survey.questions.length < 1) {
                    this.errors.yaml = ['The questions list is missing, empty, or malformed.']
                    return
                }

                const questions = findQuestions(survey.questions)
                const slugs = []

                for (let i = 0; i < questions.length; i++) {
                    let q = questions[i]
                    let plainQ = Object.assign({}, q)

                    delete plainQ.questions
                    delete plainQ.options

                    if (!(q.slug || '').trim()) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` is missing the `slug` attribute.']
                        return
                    }

                    if (!(q.slug + '').match(/^[a-zA-Z][a-zA-Z0-9_]*$/)) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` has a slug of invalid format. Slugs can only contain characters a-z, A-Z, 0-9, and underscore. They cannot start with a number or an underscore.']
                        return
                    }

                    if (q.slug.indexOf('__') >= 0) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` has a slug of invalid format. Slugs must not contain more than one underscore in a row.']
                        return
                    }

                    if (slugs.indexOf(q.slug) >= 0) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` has a slug that is already used by another question.']
                        return
                    }

                    if (!q.type) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` is missing the `type` attribute.']
                        return
                    }

                    if (!q.text && !(q.template || q.source || q.type == 'constant' || q.type == 'signature' || q.type == 'math' || q.type == 'calc')) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` is missing the `text` attribute.']
                        return
                    }

                    if (KNOWN_TYPES.indexOf(q.type) < 0) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` has a type that is not supported. Supported types are: ' + KNOWN_TYPES.join(', ')]
                        return
                    }

                    if ((q.type == 'select-one') || (q.type == 'select-many')) {
                        if (!(q.options || q.preset_options)) {
                            this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` is missing the `options` or `preset_options` attribute.']
                            return
                        }

                        if (q.options && ((!(q.options instanceof Array)) || q.options.length < 1)) {
                            this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` has a malformed `options` attribute.']
                            return
                        }

                        if (q.options) {
                            const optionSlugs = []
                            for (let j = 0; j < q.options.length; j++) {
                                const o = q.options[j]
                                const plainO = Object.assign({}, o)
                                delete plainO.questions

                                if (!(o.slug || '').trim()) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` is missing the `slug` attribute.']
                                    return
                                }

                                if (!(o.slug + '').match(/^[a-zA-Z][a-zA-Z0-9_]*$/)) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` has a slug of invalid format. Slugs can only contain characters a-z, A-Z, 0-9, and underscore. They cannot start with a number or an underscore.']
                                    return
                                }

                                if (o.slug.indexOf('__') >= 0) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` has a slug of invalid format. Slugs must not contain more than one underscore in a row.']
                                    return
                                }

                                if (optionSlugs.indexOf(o.slug) >= 0) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` has a slug that is already used by another option in the same question.']
                                    return
                                }

                                if (!o.text) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` is missing the `text` attribute.']
                                    return
                                }

                                if (o.hasOwnProperty('questions') && (!(o.questions instanceof Array) || o.questions.length < 1)) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` has a malformed or empty `questions` attribute.']
                                    return
                                }

                                optionSlugs.push(o.slug)
                            }
                        }
                    }

                    slugs.push(q.slug)
                }
            }
            catch (ex) {
                this.errors.yaml = ['YAML error: ' + ex.message]
            }

        }
    }
}
</script>

<style lang="scss">
.mui-textfield textarea.yaml-input {
    font-family: monospace;
    padding: 0.5rem;
    border: 1px solid #eee;
}
</style>
