<script>

export default {
    data() {
        const sortStack = []

        this.headers.forEach((h, idx) => {
            if (h.defaultSort) {
                sortStack.push({
                    idx: idx,
                    isDesc: !!h.defaultSortIsDesc,
                })
            }
        })

        if (!sortStack.length) {
            sortStack.push({
                idx: 0,
                isDesc: false,
            })
        }

        return {
            sortStack: sortStack,
        }
    },
    computed: {
        sortColumnIdx() {
            if (this.sortStack.length) {
                return this.sortStack[0].idx
            }

            return -1
        },
        sortIsAsc() {
            if (this.sortStack.length) {
                return !this.sortStack[0].isDesc
            }

            return true
        },
    },
    methods: {
        sortRows(rows) {
            rows.sort((a, b) => {
                for (let sortColumn of this.sortStack) {
                    const orderMultiplier = sortColumn.isDesc ? -1 : 1
                    const valA = a.cells[sortColumn.idx].value ? a.cells[sortColumn.idx].value : a.cells[sortColumn.idx]
                    const valB = b.cells[sortColumn.idx].value ? b.cells[sortColumn.idx].value : b.cells[sortColumn.idx]

                    if (valA != valB) {
                        return valA > valB ? orderMultiplier : -1 * orderMultiplier
                    }
                }

                return 0
            })

            return rows
        },
        onSort(orderColumnIdx) {
            if (this.sortStack.length && this.sortStack[0].idx == orderColumnIdx) {
                // The user clicked on the order column that's already at the top of the sort stack. Just reverse it.
                this.sortStack[0].isDesc = !this.sortStack[0].isDesc
                return
            }

            // Move the new column to the top of the stack
            const tmpStack = this.sortStack.filter(c => (c.idx != orderColumnIdx))
            this.sortStack.splice(0, this.sortStack.length)  // empty the stack in a way that Vue would be able to observe
            this.sortStack.push({idx: orderColumnIdx, isDesc: false})
            tmpStack.forEach(s => this.sortStack.push(s))
        },
    },
}
</script>
