<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">

                        <div v-if="onboardingApplication.status == 'needs-manager-approval'">
                            <div class="pt-8 text-center">
                                <div class="pt-8 text-center">
        
                                    <div class="icon-huge">
                                        <i class="fas fa-check"></i>
                                    </div>
        
                                    <h1 class="mb-4">You're done!</h1>
        
                                    <p class="mt-2">
                                        <router-link class="btn btn-primary" :to="nextPage">Continue to Your Account</router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
        
                        <div v-if="onboardingApplication.status == 'logged-in'">
                            <h5>New Employee Onboarding</h5>
                            <h3>Complete Onboarding</h3>
        
                            <p class="my-4">
                                <strong>You're almost done</strong> &mdash; next we'll check to make sure we have all the necessary information.
                            </p>
        
                            <p class="my-4" v-if="onboardingApplication.wotc_pre_qualified">
                                <strong>We may need additional documentation</strong> to complete your onboarding. We will text you at {{onboardingApplication.phone}} to gather these additional documents.
                            </p>
        
                            <form @submit.prevent="onSubmit">
                                <hr v-if="formErrors.length" />
                                <p v-if="formErrors.length">The following issues were found with your onboarding process. Please go back and fix them before proceeding.</p>
                                <form-errors :errors="formErrors"/>
        
                                <div class="form-row mb-1">
                                    <div class="col">
                                    </div>
                                </div>
        
                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                    <router-link v-if="previousPage" :to="previousPage">Back</router-link>
                                    </div>
        
                                    <div class="col text-right">
                                        <button class="btn btn-primary">Finish</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import WizardMixin from './WizardMixin'
import api from '@/api'

export default {
    mixins: [FormMixin, WizardMixin],
    components: {
        FormInput,
        FormErrors,
    },
    props: ['onboardingApplication'],
    data() {
        return {
            formData: {
                action: 'complete-onboarding',
            },
        }
    },
    methods: {
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.patch(`/me/companies/${this.onboardingApplication.id}/onboarding-application`, this.formData).then(() => {
                this.$emit('paymasters_onboarding_application_updated')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            this.errors = {}
            this.formErrors = []
        },
    },
}
</script>
