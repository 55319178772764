<template>
    <div>
        <alert-box type="error" v-for="error in errors" :key="error">
            {{ error }}
        </alert-box>
    </div>
</template>

<script>

export default {
    props: ['errors'],
}
</script>
