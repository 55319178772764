<template>
    <div class="card mb-2">
        <div class="card-body">
            <form-errors :errors="formErrors"/>

            <div class="form-row">
                <div class="col">
                    <form-input :nocaps="true" v-model="formData.slug" :errors="allErrors.slug" type="text" label="Slug" @input="onInput"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <form-input v-model="formData.text" :errors="allErrors.text" type="textarea" label="Text" helper-text="You can use Markdown syntax." @input="onInput"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <form-input v-model="formData.yaml_form" :errors="allErrors.yaml_form" type="select" label="PDF Form" :options="formOptions" @input="onInput"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'

export default {
    mixins: [FormMixin],
    props: ['value', 'forms', 'upstreamErrors', ],
    computed: {
        allErrors() {
            return Object.assign({}, this.errors, this.upstreamErrors)
        },
        formOptions() {
            const VALID_TYPES = [
                'fed-w4',
                'fed-exempt',
                'state-w4',
                'non-resident',
                'exempt-cert',
            ]

            const opts = []
            this.forms.forEach(f => {
                if (VALID_TYPES.indexOf(f.form_type) >= 0) {
                    opts.push({text: f.name, value: f.id})
                }
            })
            return opts
        },
    },
    data() {
        return {
            formRules: {
                slug: {presence: {allowEmpty: false},},
                text: {presence: {allowEmpty: false},},
                yaml_form: {presence: {allowEmpty: false},},
            },
            formData: this.makeFormData()
        }
    },
    watch: {
        'value.id'() {
            this.reset()
        },
    },
    methods: {
        makeFormData() {
            if (this.value) {
                return {
                    id: this.value.id,
                    text: this.value.text,
                    slug: this.value.slug,
                    yaml_form: this.value.yaml_form,
                }
            }
            else {
                return {
                    id: null,
                    text: '',
                    slug: '',
                    yaml_form: null,
                }
            }
        },
        onInput() {
            this.$emit('input', this.getFormData())
        },
    },
}
</script>
