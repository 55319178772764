<template>
    <div v-if="tax_form">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                
                    <div class="border-bottom pt-2 pb-1">
                      <span><router-link :to="{name: 'employee-tax-forms'}" class="btn btn-link d-print-none">
                        <i class="far fa-chevron-left"></i> Back
                      </router-link></span>
                    </div>
      
                    <div class="row pt-2 pb-1"> 
                      <div class="col-12 col-md mr-auto"> 
                        <h1 class="mb-1">{{ tax_form.document_title }}: 
                          <span class="text-thin">{{ tax_form.document_subtitle }}</span>
                        </h1>
                      </div>
                      <div class="col-12 col-md-auto">
                        <a :href="tax_form.file_url" class="btn btn-primary d-print-none ml-hf">
                          <i class="far fa-fw fa-file-pdf"></i> 
                          <span class="btn-text">Download PDF</span>
                        </a>
                      </div>
                    </div>

                    <section class="mb-2 border-bottom">
                      <w2-detail :tax_form="tax_form" v-if="tax_form" />
                    </section>
                      
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'

import W2Detail from '@/components/W2Detail'

export default {
    components: {W2Detail, },
    data() {
        return {
            tax_form: null,
        }
    },
    mounted() {
        this.getTaxForm()
    },
    methods: {
        getTaxForm() {
            this.$store.dispatch('START_LOADING')
            api.get(`/me/tax-forms/detail/${this.$route.params.taxFormId}`).then(resp => {
                this.tax_form = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
    },
}
</script>
