<template>
    <sidebar @close="$emit('close')" ref="sidebar">
        <template slot="title" v-if="employee">
            {{ employee.full_name }}
        </template>

        <template slot="subtitle" v-if="employee">
            <span class="counter counter-warnings" v-if="warningCount">
                <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i>
                <span class="counter-label">{{ warningCount }}</span>
            </span>
        </template>

        <template slot="header" v-if="employee">
            <div class="row mb-1">
                <div class="col">
                    <span class="text-muted">{{ employee.company.name }}</span>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col">
                    <span class="text-muted" v-if="employeeId">{{ employee.employement_status }} Employee</span>
                    <span class="text-muted" v-if="onboardingApplicationId">Onboarding</span>
                    <span class="text-muted" v-if="employeeId">&middot; #{{ employee.employee_number }}</span>
                </div>
            </div>

            <unlock-widget v-if="employee.user && employee.user.id && employee.is_locked" :client="client" :user="employee.user" :is-locked="employee.is_locked" @unlocked="getData"/>

            <!-- Tabs -->
            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'main'}" href="#tabContentMain" id="tabLinkMain" data-toggle="tab" role="tab" aria-controls="tabContentMain" :aria-selected="activeTab == 'main'">Main</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'misc'}" href="#tabContentMiscHr" id="tabLinkMiscHr" data-toggle="tab" role="tab" aria-controls="tabContentMiscHr" :aria-selected="activeTab == 'main'">Misc HR</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'warnings'}" href="#tabContentWarnings" id="tabLinkWarnings" data-toggle="tab" role="tab" aria-controls="tabContentWarnings" :aria-selected="activeTab == 'warnings'">Warnings</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'access'}" href="#tabUserAccess" id="tabLinkUserAccess" data-toggle="tab" role="tab" aria-controls="tabUserAccess" :aria-selected="activeTab == 'access'">User Access</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'changelogs'}" href="#tabContentHistory" id="tabLinkHistory" data-toggle="tab" role="tab" aria-controls="tabContentHistory" :aria-selected="activeTab == 'changelogs'">History</a>
                        </li>

                        <li class="nav-item" v-if="isAdmin">
                            <a class="nav-link" href="#tabContentAdmin" id="tabLinkAdmin" data-toggle="tab" role="tab" aria-controls="tabContentAdmin" aria-selected="false">Admin</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot v-if="employee">
            <div class="tab-content flex-body">

                <div class="tab-pane" :class="{'show active': activeTab == 'main'}" id="tabContentMain" role="tabpanel" aria-labelledby="tabLinkMain">
                    <div class="card card-header mb-2" v-if="i9CompletedLateWarnings.length">
                        <div class="form-row">
                            <div class="col-1">
                                <i class="text-500 fas fa-fw fa-exclamation-triangle" aria-label="I-9 Completed Late"></i>
                            </div>

                            <div class="col">
                                <p class="mb-1"><strong>I-9 Completed Late</strong></p>
                            </div>
                        </div>

                        <div class="form-row" v-for="w in i9CompletedLateWarnings">
                            <div class="col offset-1">
                                <p class="mb-0">
                                    This employee's Form I-9 was completed late for their start date of {{ w.date|date }}.
                                </p>
                            </div>
                        </div>
                    </div>

                    <employee-details :client="client" :employee="employee" :can-edit="onboardingApplicationId && canEditHRInfo && !employee.hr_info_approved_on" @edit="openEditModal"/>

                    <p class="color-red" v-if="activeOnboardingCount">{{ activeOnboardingCount }} onboarding applications in progress. <router-link :to="{name: 'onboarding', params: {clientId: client.id}}">Go to Onboarding</router-link></p>
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'misc'}" id="tabContentMiscHr" role="tabpanel" aria-labelledby="tabLinkMiscHr">

                    <approval
                        :employee="employee"
                        :client="client"
                        v-if="approvalMode"
                        @close="approvalMode = false"
                        @updated="onUpdate"
                        @edit="openEditModal"
                    />

                    <div v-show="!approvalMode">
                        <p class="text-right text-small">
                            <a href @click.prevent="expandAll()">Expand all</a>
                            <span class="text-muted px-1">|</span>
                            <a href @click.prevent="collapseAll()">Collapse all</a>
                        </p>

                        <!-- Onboarding Widget -->
                        <info-widget :collapsible="true" :is-expanded="onboardingAppsExpanded" @toggle_expand="onboardingAppsExpanded = !onboardingAppsExpanded" v-if="employee">
                            <template slot="title">Onboarding</template>

                            <slot>
                                <!-- Current Onboarding Application(s) display -->
                                <div class="p-2" v-if="onboardingApplicationId && employee">

                                    <p v-if="!employee.approved_on && employee.onboarding_error_message">
                                        <alert-box type="error">
                                            There was a problem with onboarding this employee: {{ employee.onboarding_error_message }}
                                        </alert-box>
                                    </p>

                                    <p v-if="employee.approved_on && !employee.onboarded_on">
                                        <alert-box type="info">
                                            Sending data to payroll system. This may take a few minutes.
                                        </alert-box>
                                    </p>

                                    <alert-box type="info" class="mb-4" v-if="!userHasAccess">
                                        This employee's onboarding is being managed by another user. Contact your Payroll Administrator for more information.
                                    </alert-box>

                                    <div class="m-0">
                                        <div class="text-semibold" v-for="job in employee.jobs" :key="job.id">{{ job.position.terminal_value }}</div>
                                        <span>Start Date: {{ employee.date_hired|date }}</span><br />
                                        <span v-if="employee.status == 'canceled'">Canceled on {{ employee.canceled_on|date }} by {{ employee.canceled_by.email }}.</span>
                                    </div>

                                    <button-row stacked class="mt-1 mb-0" v-if="userHasAccess">
                                        <button class="btn btn-outline-primary" v-if="employee.status == 'started'" @click.prevent="resendActivationEmail()">
                                            Re-Send Invitation
                                        </button>
                                        <button class="btn btn-primary" @click.prevent="approvalMode = true" v-if="((employee.status == 'needs-manager-approval' && !employee.approved_on) || (employee.status == 'hired' && !employee.approved_on)) && !approvalStarted">
                                            Start Approval
                                        </button>
                                        <button class="btn btn-primary" @click.prevent="approvalMode = true" v-if="((employee.status == 'needs-manager-approval' && !employee.approved_on) || (employee.status == 'hired' && !employee.approved_on)) && approvalStarted && !employee.onboarding_error_message">
                                            Continue Approval
                                        </button>
                                        <button class="btn btn-primary" @click.prevent="$refs.approvalModal.open()" v-if="((employee.status == 'needs-manager-approval' && !employee.approved_on) || (employee.status == 'hired' && !employee.approved_on)) && approvalStarted && employee.onboarding_error_message">
                                            Retry Approval
                                        </button>
                                        <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="cancelOnboarding()" v-if="((employee && ['canceled', 'hired'].indexOf(employee.status) < 0) && !employee.approved_on) || (employee.status == 'hired' && !employee.approved_on)">
                                            Cancel Onboarding
                                        </button>
                                    </button-row>
                                </div>

                                <!-- Completed Onboarding Applications display -->
                                <div class="p-0" v-if="employeeId">

                                    <div v-if="onboardingApplications.length">
                                        <section class="table-section">
                                            <div class="table-section-titlebar">
                                                <h5 class="table-section-title">Completed Onboarding Applications <span class="text-regular">({{ onboardingApplications.length }})</span></h5>
                                            </div>
                                            <table class="table css-table widget-table table-fixed">
                                                <thead>
                                                    <tr>
                                                        <th class="cw-toggle"></th>
                                                        <th>{{ client.position_label_pretty }}</th>
                                                        <th class="cw-date text-right">Approved</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-for="app in onboardingApplications" :key="app.id">
                                                    <tr>
                                                        <td class="cw-toggle position-relative">
                                                            <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': expandedOnboardingApplications[app.id]}" @click.prevent="$set(expandedOnboardingApplications, app.id, !expandedOnboardingApplications[app.id])">
                                                                <collapse-toggle-button />
                                                            </a>
                                                        </td>
                                                        <td class="truncate-wrapper">
                                                            <div class="truncate-content" v-for="job in app.jobs" :key="job.id">{{ job.position.terminal_value }}</div>
                                                        </td>
                                                        <td class="text-right">
                                                            <span>{{ app.onboarded_on|date }}</span>
                                                        </td>
                                                    </tr>

                                                    <tr class="child no-transition" v-show="expandedOnboardingApplications[app.id]">
                                                        <td colspan="3">
                                                            <div class="card card-body item-details">
                                                                <section class="item-details-section">
                                                                    <p>
                                                                        <span class="d-block">
                                                                            Approval Date: {{ app.onboarded_on|date }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Approver: {{ app.approved_by ? app.approved_by.email : 'N/A' }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section">
                                                                    <h5 class="theading">
                                                                        Employee Information
                                                                    </h5>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Employee Name:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.full_name }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Email Address:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.email }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Start Date:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.date_hired | date }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            ACA Employment Category:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.aca_category }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Resident Address:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.address_1 }}
                                                                        </span>
                                                                        <span class="d-block" v-if="app.address_2">
                                                                            {{ app.address_2 }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.city }}, {{ app.state }} {{ app.postal_code }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Social Security Number:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            XXX-XX-{{ app.ssn_last_4 }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section">
                                                                    <h5 class="theading">
                                                                        {{ client.position_label_short }} Information
                                                                    </h5>
                                                                    <p v-for="job in app.jobs" :key="job.id">
                                                                        <span class="d-block text-semibold">
                                                                            {{ job.position.terminal_value }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Pay Type: {{ job.pay_type_pretty }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Per Hour: ${{ job.pay_type == 'H' ? job.hourly_rate : job.salary_rate }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section">
                                                                    <h5 class="theading">
                                                                        Equal Employment Opportunity Self-Identification
                                                                    </h5>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Legal Gender:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.gender == 'custom' ? 'Prefer not to say' : app.gender }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Racial/Ethnic Identification:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.ethnic_origin ? app.ethnic_origin : 'Prefer not to say' }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section" v-if="!app.has_wotc_step">
                                                                    <h5 class="theading">
                                                                        WOTC
                                                                    </h5>
                                                                    <p>
                                                                        <span class="d-block" v-if="app.wotc_pre_qualified">
                                                                            This employee <strong>may qualify</strong> for the Work Opportunity Tax Credit. You can check the application progress in HKP.
                                                                        </span>
                                                                        <span class="d-block" v-if="!app.wotc_pre_qualified">
                                                                            This employee <strong>does not qualify</strong> for the Work Opportunity Tax Credit, based on their responses to the pre-screening questionnaire.
                                                                        </span>

                                                                        <a v-if="app.wotc_pdf_file" @click.prevent="showWOTCPDF(app)" :href="`${$api.API_BASE}/clients/${client.id}/onboarding-applications/${app.id}/wotc-pdf`" class="btn btn-outline-primary mt-1">View WOTC Responses</a>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section">
                                                                    <h5 class="theading">
                                                                        Required Documents
                                                                    </h5>
                                                                    <p v-for="form in app.forms" :key="form.id">
                                                                        <span class="d-block text-semibold">
                                                                            {{ form.yaml_form.title }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Completed on {{ form.filled_on|date }}
                                                                        </span>
                                                                        <span class="d-block" v-if="form.employer_signature_data">
                                                                            Signed by {{ form.employer_signature_data.full_name }}
                                                                        </span>
                                                                    </p>
                                                                    <p v-for="doc in app.hr_documents" :key="doc.id">
                                                                        <span class="d-block text-semibold">
                                                                            {{ doc.hr_document.name }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Completed on {{ doc.signed_on|date }}
                                                                        </span>
                                                                        <span class="d-block" v-if="doc.manager_signature_data">
                                                                            Signed by {{ doc.manager_signature_data.full_name }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section">
                                                                    <h5 class="theading">
                                                                        Direct Deposit
                                                                    </h5>
                                                                    <p v-for="acct in app.direct_deposit_accounts" :key="acct.id">
                                                                        <span class="d-block text-semibold">
                                                                            {{ acct.bank_name }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Routing Number: {{ acct.routing_number }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ acct.account_type.toUpperCase() }}
                                                                        </span>
                                                                        <span class="d-block" v-if="acct.split_type == 'percentage' && acct.percentage !== null">
                                                                            {{ acct.percentage }}%
                                                                        </span>
                                                                        <span class="d-block" v-if="acct.split_type == 'remainder'">
                                                                            Remainder
                                                                        </span>
                                                                        <span class="d-block" v-if="acct.split_type == 'fixed'">
                                                                            ${{ acct.amount }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </section>
                                    </div>

                                    <div>
                                        <div class="info-widget-empty p-2" v-if="!onboardingApplications.length">
                                            This employee has no completed onboarding applications.
                                        </div>
                                    </div>

                                </div>
                            </slot>
                        </info-widget>

                        <!-- Managers Widget -->
                        <info-widget :collapsible="true" :is-expanded="managersExpanded" v-if="managers" @toggle_expand="managersExpanded = !managersExpanded">
                            <template slot="title">Managers</template>

                            <slot>

                                <div class="p-2">
                                    <ul class="list-unstyled m-0">
                                        <li v-if="employee && (payrollAdmins.length)">
                                            <h4 class="mb-2">ORGANIZATION</h4>

                                            <ul class="list-unstyled m-0">
                                                <li v-for="manager in payrollAdmins" :key="manager.id">
                                                    <div class="pb-2">
                                                        <h6>{{ manager.full_name }}</h6>
                                                        <span class="text-muted">PAYROLL ADMIN</span><br/>
                                                        <span v-show="manager.phone"><i class="fas fa-phone text-muted"></i> <a :href="'tel:' + manager.phone">{{ manager.phone }}<br /></a></span>
                                                        <i class="fas fa-envelope text-muted"></i> <a :href="'mailto:' + manager.email">{{ manager.email }}</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>

                                        <li v-for="location in workLocations" :key="location.id" class="border-top pt-2" v-if="managersByLocation[location.id]['location-manager'].length + managersByLocation[location.id]['store-manager'].length + managersByLocation[location.id]['employee-manager'].length">
                                            <h4 class="mb-2">{{ location.name }}</h4>
                                            <ul class="list-unstyled m-0">
                                                <li v-for="manager in managersByLocation[location.id]['location-manager']" :key="manager.id">
                                                    <div class="pb-2">
                                                        <h6>{{ manager.full_name }}</h6>
                                                        <span class="text-muted">GENERAL MANAGER</span><br/>
                                                        <span v-show="manager.phone"><i class="fas fa-phone text-muted"></i> <a :href="'tel:' + manager.phone">{{ manager.phone }}<br /></a></span>
                                                        <i class="fas fa-envelope text-muted"></i> <a :href="'mailto:' + manager.email">{{ manager.email }}</a>
                                                    </div>
                                                </li>

                                                <li v-for="manager in managersByLocation[location.id]['store-manager']" :key="manager.id">
                                                    <div class="pb-2">
                                                        <h6>{{ manager.full_name }}</h6>
                                                        <span class="text-muted">DEPARTMENT MANAGER</span><br/>
                                                        <span v-show="manager.phone"><i class="fas fa-phone text-muted"></i> <a :href="'tel:' + manager.phone">{{ manager.phone }}<br /></a></span>
                                                        <i class="fas fa-envelope text-muted"></i> <a :href="'mailto:' + manager.email">{{ manager.email }}</a>
                                                    </div>
                                                </li>

                                                <li v-for="manager in managersByLocation[location.id]['employee-manager']" :key="manager.id">
                                                    <div class="pb-2">
                                                        <h6>{{ manager.full_name }}</h6>
                                                        <span class="text-muted">DEPARTMENT ASSISTANT</span><br/>
                                                        <span v-show="manager.phone"><i class="fas fa-phone text-muted"></i> <a :href="'tel:' + manager.phone">{{ manager.phone }}<br /></a></span>
                                                        <i class="fas fa-envelope text-muted"></i> <a :href="'mailto:' + manager.email">{{ manager.email }}</a>
                                                    </div>
                                                </li>

                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </slot>
                        </info-widget>

                        <!-- HR Documents Widget -->
                        <info-widget :collapsible="true" :is-expanded="docsExpanded" @toggle_expand="docsExpanded = !docsExpanded">
                            <template slot="title">HR Documents</template>

                            <template slot="headerbutton" v-if="employeeId">
                                <button class="btn btn-outline-primary" type="button" @click.prevent="openDocumentUploadModal">Add</button>
                            </template>

                            <slot>
                                <div v-if="docs.length">
                                    <section class="table-section">
                                        <!-- TODO: Implement active/inactive sections;
                                              replace :class statement below with a real condition -->
                                        <!--
                                        <a href class="table-section-titlebar collapse-trigger" role="button" :class="{'is-expanded': 1 == 1}" @click.prevent="">
                                            <span class="widget-toggle-wrapper">
                                                <collapse-toggle-button />
                                            </span>
                                            <h5 class="table-section-title">Active Documents <span class="text-regular">(1)</span></h5>
                                        </a>
                                        -->
                                        <table class="table css-table widget-table table-fixed">
                                            <thead>
                                                <tr>
                                                    <th class="cw-toggle"></th>
                                                    <th>Document</th>
                                                    <!-- th class="cw-6 text-center">Version</th -->
                                                    <th class="cw-date text-right">Completed</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="doc in visibleDocs" :key="doc.id" :class="{'active-item': doc.id == `employee-doc-${activeHrDocumentId}`}" ref="docs">
                                                <tr>
                                                    <td class="cw-toggle position-relative">
                                                        <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': expandedDocuments[doc.id]}" @click.prevent="$set(expandedDocuments, doc.id, !expandedDocuments[doc.id])">
                                                            <collapse-toggle-button />
                                                        </a>
                                                    </td>
                                                    <td class="truncate-wrapper">
                                                        <a href @click.prevent="setCurrentPDF(doc)" class="paycheck truncate-content">{{ doc.name }}</a>
                                                    </td>
                                                    <!-- td class="text-center d-none d-rs-table-cell">
                                                        <span>{{ doc.version }}</span>
                                                    </td -->
                                                    <td class="text-right">
                                                        <span>
                                                            {{ doc.manager_date_signed|date }}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr class="child no-transition" v-show="expandedDocuments[doc.id]">
                                                    <td colspan="3">
                                                        <div class="card card-body bg-150 m-0 py-1" v-if="doc.source_pretty == 'Import'">
                                                            <div class="text-small text-muted">
                                                                Imported document
                                                            </div>
                                                        </div>
                                                        <div class="card card-body bg-150 m-0 py-1" v-else>
                                                            <div class="text-small text-muted">
                                                                <span>Version: {{ doc.version }} <br /></span>
                                                                <span>Completed by Employee: {{ doc.date_signed|date }} <br /></span>
                                                                <span>Approved by Manager: {{ doc.manager_date_signed|date }} <br /></span>
                                                                <span>Approved/Signed By: {{ doc.manager_name }} <br /></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <!-- TODO: Implement active/inactive sections -->
                                    <!--
                                    <section class="table-section">
                                        <!-- TODO: replace :class statement with a real condition --
                                        <a href class="table-section-titlebar collapse-trigger" role="button" :class="{'is-expanded': 1 == 1}" @click.prevent="">
                                            <span class="widget-toggle-wrapper">
                                                <collapse-toggle-button />
                                            </span>
                                            <h5 class="table-section-title">Inactive Documents <span class="text-regular">(0)</span></h5>
                                        </a>
                                        <div class="row-empty">
                                            This section is empty.
                                        </div>
                                    </section>
                                    -->
                                    <!-- TODO: These buttons will go away when Active/Inactive sections are implemented -->
                                    <div v-if="!showAllDocs && employee && docs.length > 5">
                                        <button type="button" class="btn btn-link btn-block" @click.prevent="showAllDocs = true">See all</button>
                                    </div>
                                    <div v-if="showAllDocs && employee && docs.length > 5">
                                        <button type="button" class="btn btn-link btn-block" @click.prevent="showAllDocs = false">See fewer</button>
                                    </div>
                                </div>
                                <div v-if="!docs.length">
                                    <div class="info-widget-empty p-2">
                                        This employee has no HR documents.
                                    </div>
                                </div>
                            </slot>
                        </info-widget>

                        <!-- Check History Widget -->
                        <info-widget :collapsible="true" :is-expanded="checksExpanded" @toggle_expand="checksExpanded = !checksExpanded" v-if="employee">
                            <template slot="title">
                                Check History
                            </template>
                            <slot>
                                <div v-if="employee && checks.length">
                                    <ul class="list-unstyled m-0">
                                        <li>
                                            <div class="form-row align-items-baseline py-1">
                                                <div class="col"><h5 class="m-0">Check #</h5></div>
                                                <div class="col"><h5 class="m-0">Check Date</h5></div>
                                                <div class="col"><h5 class="m-0">Net Pay</h5></div>
                                                <div class="col"><h5 class="m-0">Status</h5></div>
                                            </div>
                                        </li>

                                        <li v-for="(check, i) in visibleChecks" :key="check.id" :class="{'border-bottom': i < visibleChecks.length - 1, 'active-item': check.id == activeCheckId}">
                                            <div class="form-row align-items-baseline py-3q">
                                                <div class="col">
                                                    <a href @click.prevent="onCheckClicked(check)" class="paycheck">{{ check.check_number || '[Unknown]' }}</a>
                                                </div>

                                                <div class="col">
                                                    <span>{{ check.check_date|date }}</span>
                                                </div>

                                                <div class="col">
                                                    <span>${{ check.net_pay }}</span>
                                                </div>

                                                <div class="col cap">
                                                    <span>{{ check.check_type }}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div v-if="!showAllChecks && employee && checks.length > 5">
                                        <button type="button" class="btn btn-link btn-block" @click.prevent="showAllChecks = true">See all</button>
                                    </div>
                                    <div v-if="showAllChecks && employee && checks.length > 5">
                                        <button type="button" class="btn btn-link btn-block" @click.prevent="showAllChecks = false">See fewer</button>
                                    </div>
                                </div>
                                <div v-if="!checks.length">
                                    <div class="info-widget-empty p-2">
                                        This employee has no check history.
                                    </div>
                                </div>
                            </slot>
                        </info-widget>

                        <!-- Tax Documents Widget -->
                        <info-widget :collapsible="true" :is-expanded="taxDocsExpanded" @toggle_expand="taxDocsExpanded = !taxDocsExpanded" v-if="employee">
                            <template slot="title">
                                Tax Documents
                            </template>
                            <slot>
                                <div v-if="employee && taxForms.length">
                                    <ul class="list-unstyled m-0" v-if="employee && taxForms.length">
                                        <li>
                                            <div class="form-row align-items-baseline py-1">
                                                <div class="col"><h5 class="m-0">Document</h5></div>
                                                <div class="col"><h5 class="m-0">Year</h5></div>
                                            </div>
                                        </li>

                                        <li v-for="(doc, i) in visibleTaxForms" :key="doc.id" :class="{'border-bottom': i < visibleTaxForms.length - 1, 'active-item': doc.id == activeTaxDocumentId}">
                                            <div class="form-row align-items-baseline py-3q">
                                                <div class="col">
                                                    <a href @click.prevent="onW2Clicked(doc)"><span>{{ doc.document_title }}</span></a>
                                                </div>

                                                <div class="col">
                                                    <span>{{ doc.tax_year }}</span>
                                                </div>
        
                                            </div>
                                        </li>
                                    </ul>
                                    <div v-if="!showAllTaxForms && employee && taxForms.length > 5">
                                        <button type="button" class="btn btn-link btn-block" @click.prevent="showAllTaxForms = true">See all</button>
                                    </div>
                                    <div v-if="showAllTaxForms && employee && taxForms.length > 5">
                                        <button type="button" class="btn btn-link btn-block" @click.prevent="showAllTaxForms = false">See fewer</button>
                                    </div>
                                </div>
                                <div v-if="!taxForms.length">
                                    <div class="info-widget-empty p-2">
                                        This employee has no tax documents.
                                    </div>
                                </div>
                            </slot>
                        </info-widget>
                    </div>

                </div>

                <!-- WARNINGS Tab Pane -->
                <div class="tab-pane" :class="{'show active': activeTab == 'warnings'}" id="tabContentWarnings" role="tabpanel" aria-labelledby="tabLinkWarnings">
                    <section class="table-section">
                        <warnings-header category="Dismissed" :expanded="!dismissedWarningsExpanded" :warning-count="dismissedWarnings.length" @click="dismissedWarningsExpanded = !dismissedWarningsExpanded" />
                        <warnings :warnings="dismissedWarnings" :active-warning-id="activeWarningId" v-if="employee" v-show="dismissedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                    <section class="table-section">
                        <warnings-header category="Unresolved" :expanded="!unresolvedWarningsExpanded" :warning-count="unresolvedWarnings.length" @click="unresolvedWarningsExpanded = !unresolvedWarningsExpanded" />
                        <warnings :warnings="unresolvedWarnings" :active-warning-id="activeWarningId" v-if="employee" v-show="unresolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                    <section class="table-section">
                        <warnings-header category="Resolved" :expanded="!resolvedWarningsExpanded" :warning-count="resolvedWarnings.length" @click="resolvedWarningsExpanded = !resolvedWarningsExpanded" />
                        <warnings :warnings="resolvedWarnings" :active-warning-id="activeWarningId" v-if="employee" v-show="resolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                </div>

                <!-- USER ACCESS Tab Pane -->
                <div class="tab-pane" :class="{'show active': activeTab == 'access'}" id="tabUserAccess" role="tabpanel" aria-labelledby="tabLinkUserAccess">
                    <user-access :client="client" :user="employee.user" :email="employee.email" :login-attempts="loginAttempts" :is-locked="employee.is_locked" :allow-security-profile-pin-reset="false" @pin_reset="resetPIN()" @send_invite_email="resendActivationEmail(user.email)" v-if="employee">
                        <template slot="pin-reset-disabled-message">
                            <p class="mb-0 pt-1">
                                This user account has Security User access. To reset their PIN, go to <strong>Settings &gt; Security Users</strong>.
                            </p>
                        </template>
                    </user-access>

                    <pin-reset-modal :user="employee.user" :client="client" v-if="employee.user && employee.user.id" ref="pinResetModal" />
                </div>

                <!-- CHANGE HISTORY Tab Pane -->
                <div class="tab-pane" :class="{'show active': activeTab == 'changelogs'}" id="tabContentHistory" role="tabpanel" aria-labelledby="tabLinkHistory">
                    <change-history :client="client" :changelogs="changelogs" :active-changelog-id="activeChangelogId" />
                </div>

                <div class="tab-pane" id="tabContentAdmin" role="tabpanel" aria-labelledby="tabLinkAdmin" v-if="employee && onboardingApplicationId">
                    <div class="tab-pane pb-2" role="tabpanel">

                        <div class="form-row mb-3">
                            <div class="col-6">
                                <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminOnboarding()">Onboard as Employee</button>

                                <!--
                                <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('wotc')">WOTC</button>
                                <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('hr-info')">HR Info</button>
                                <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('address')">Address</button>
                                <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('eeo-info')">EEO Information</button>
                                <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('forms')">Forms</button>
                                <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('direct-deposit')">Direct Deposit</button>
                                -->
                            </div>

                            <div class="col-6">
                                <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="getAOSData()">View Payroll Data</button>
                            </div>
                        </div>

                        <div class="my-2" v-if="aosData">
                            <pre><code>{{ aosData }}</code></pre>
                        </div>

                        <admin-onboarding-modal
                            ref="adminOnboardingModal"
                            :employee="employee"
                            :client="client"
                            :repeat-step="adminOnboardingRepeatStep"
                            @updated="$emit('updated', $event)"
                            @close="adminOnboardingRepeatStep = null"
                        />
                    </div>
                </div>
            </div>

            <paycheck-modal :client="client" :paycheck="currentCheck" v-if="currentCheck" ref="paycheckModal" @close="currentCheck = null" />
            <w2-modal :client="client" :w2detail="currentW2" v-if="currentW2" ref="W2Modal" @close="currentW2 = null" />
            <pdf-modal :url="currentPDF.url" :title="currentPDF.title" v-if="currentPDF" @close="currentPDF = null" ref="pdfModal" />
            <document-upload-modal :client="client" :employee="employee" @updated="onUpdate" ref="documentUploadModal" v-if="documentUploadModalOpen" @close="documentUploadModalOpen = false" />
            <approval-modal :client="client" :employee="employee" @updated="onUpdate" ref="approvalModal" />
            <edit-modal ref="editModal" :employee="employee" :client="client" @updated="onUpdate" v-if="canEditHRInfo && !employee.hr_info_approved_on && editModalOpened" @close="editModalOpened = false" />
        </slot>

    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import InfoWidget from '@/components/InfoWidget'
import ButtonRow from '@/components/ButtonRow'
import EmployeeDetails from './EmployeeDetails'
import ChangeHistory from '@/components/ChangeHistory'
import Warnings from '@/components/WarningsWidget'
import WarningsHeader from '@/components/WarningsHeaderWidget'
import UserAccess from '@/components/UserAccess'
import PINResetModal from '@/components/PINResetModal'
import UnlockWidget from '@/components/UnlockWidget'
import PaycheckModal from '@/components/PaycheckModal'
import W2Modal from '@/components/W2Modal'
import PdfModal from '@/components/PdfModal'
import DocumentUploadModal from './DocumentUploadModal'
import Approval from './Approval'
import ApprovalModal from './ApprovalModal'
import EditModal from './EditEmployeeModal'
import AdminOnboardingModal from './AdminOnboardingModal'

const sortManagers = (managers) => {
    managers.sort((a, b) => {
        if (a.full_name == b.full_name) {
            return a.id > b.id ? 1 : -1
        }
        return a.full_name > b.full_name ? 1 : -1
    })
}

const sortByNameAndId = (objects, nameField) => {
    objects.sort((a, b) => {
        if (a[nameField] == b[nameField]) {
            return a.id > b.id ? 1 : -1
        }
        return a[nameField] > b[nameField] ? 1 : -1
    })
}

export default {
    components: {Sidebar, InfoWidget, ButtonRow, ChangeHistory, UserAccess, PinResetModal: PINResetModal, UnlockWidget, EmployeeDetails, PaycheckModal, W2Modal, PdfModal, DocumentUploadModal, Warnings, WarningsHeader, Approval, ApprovalModal, EditModal, AdminOnboardingModal, },
    props: ['employeeId', 'onboardingApplicationId', 'client', 'tab', 'section', 'itemId', ],
    computed: {
        isAdmin() {
            return this.$store.state.user.is_superuser
        },
        warningCount() {
            if (!this.employee) {return 0}
            return this.employee.warnings.filter((w) => {return !w.resolved_on && w.is_resolvable}).length
        },
        visibleChecks() {
            if (!this.showAllChecks) {
                return this.checks.slice(0, 5)
            }

            return this.checks
        },
        visibleDocs() {
            if (!this.showAllDocs) {
                return this.docs.slice(0, 5)
            }

            return this.docs
        },
        visibleTaxForms() {
            if (!this.showAllTaxForms) {
                return this.taxForms.slice(0, 5)
            }

            return this.taxForms
        },
        payrollAdmins() {
            if (!this.employee) {return []}

            const managers = (this.managers['payroll-admin'] || []).map(csp => csp.security_profile)
            sortManagers(managers)
            return managers
        },
        workLocations() {
            if (!this.employee) {return []}

            const locations = {}
            this.employee.jobs.forEach(j => {
                locations[j.position.work_location.id] = j.position.work_location
            })
            const result = []
            Object.keys(locations).forEach(id => {
                result.push(locations[id])
            })
            sortByNameAndId(result, 'name')
            return result
        },
        managersByLocation() {
            const result = {}

            this.workLocations.forEach(w => {
                result[w.id] = {
                    'location-manager': [],
                    'store-manager': [],
                    'employee-manager': [],
                }
            })

            Object.keys(this.managers).forEach(role => {
                if (['location-manager', 'store-manager', 'employee-manager'].indexOf(role) < 0) {return}

                this.managers[role].forEach(csp => {
                    let workLocationId;
                    if (csp.role == 'location-manager') {
                        csp.work_location_security_profiles.forEach(wlsp => {
                            workLocationId = wlsp.work_location.id
                            if (!result[workLocationId]) {return}

                            if (!result[workLocationId][csp.role].find(x => x.id == csp.security_profile.id)) {
                                result[workLocationId][csp.role].push(csp.security_profile)
                            }
                        })
                    }
                    else {
                        csp.security_profile_positions.forEach(spp => {
                            workLocationId = spp.position.work_location.id
                            if (!result[workLocationId]) {return}

                            if (!result[workLocationId][csp.role].find(x => x.id == csp.security_profile.id)) {
                                result[workLocationId][csp.role].push(csp.security_profile)
                            }
                        })

                    }

                })
            })

            return result
        },

        docs() {
            const result = []

            if (this.employeeId && this.employee) {
                this.employeeDocuments.forEach(d => {
                    let dateSigned = ''
                    if (d.onboarding_application_hr_document) {
                        dateSigned = d.signature_data ? d.signature_data.date : ''
                    }
                    else if (d.onboarding_application_pdf_form) {
                        dateSigned = d.signature_data ? d.signature_data.signature.date : ''
                    }

                    result.push({
                        id: `employee-doc-${d.id}`,
                        url: `${this.$api.API_BASE}/clients/${this.client.id}/employees/${this.employee.id}/documents/${d.id}/pdf`,
                        name: d.name,
                        version: d.version,
                        date_signed: dateSigned,
                        manager_date_signed: d.employer_signature_data ? d.employer_signature_data.date : '',
                        manager_name: d.employer_signature_data ? d.employer_signature_data.full_name : '',
                        source_pretty: d.source_pretty,
                    })
                })
            }
            else if (this.onboardingApplicationId && this.employee) {
                this.employee.hr_documents.forEach(d => {
                    result.push({
                        id: `hr-doc-${d.id}`,
                        url: `${this.$api.API_BASE}/clients/${this.client.id}/onboarding-applications/${this.employee.id}/hr-documents/${d.id}/pdf`,
                        name: d.hr_document.name,
                        version: d.hr_document.version,
                        date_signed: d.signature_data ? d.signature_data.date : '',
                        manager_date_signed: d.manager_signature_data ? d.manager_signature_data.date : '',
                        manager_name: d.manager_signature_data ? d.manager_signature_data.full_name : '',
                        source_pretty: 'Onboarding',
                    })
                })

                this.employee.forms.forEach(d => {
                    result.push({
                        id: `form-${d.id}`,
                        url: `${this.$api.API_BASE}/clients/${this.client.id}/onboarding-applications/${this.employee.id}/forms/${d.id}/pdf`,
                        name: d.yaml_form.title,
                        version: 1,
                        date_signed: (d.signature_data && d.signature_data.signature) ? d.signature_data.signature.date : '',
                        manager_date_signed: d.employer_signature_data ? d.employer_signature_data.date : '',
                        manager_name: d.employer_signature_data ? d.employer_signature_data.full_name : '',
                        source_pretty: 'Onboarding',
                    })
                })
            }

            return result.filter(d => (d.source_pretty != 'Onboarding') || d.manager_date_signed)
        },
        unresolvedWarnings() {
            if (!this.employee) {return []}
            return this.employee.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return (!isDismissed && !w.is_resolved)
            })
        },
        resolvedWarnings() {
            if (!this.employee) {return []}
            return this.employee.warnings.filter(w => {
                return w.is_resolved
            })
        },
        dismissedWarnings() {
            if (!this.employee) {return []}
            return this.employee.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return isDismissed && !w.is_resolved
            })
        },

        /* These have to do with dealing with onboarding */
        approvalStarted() {
            if (!this.onboardingApplicationId) {return false}

            if (this.employee.hr_info_approved_on) {return true}
            if (this.employee.i9_approved_on) {return true}
            if (this.employee.direct_deposit_approved_on) {return true}
            const formsApproved = this.employee.forms.filter((f) => {
                return f.accepted_on
            }).length

            if (formsApproved > 0) {return true}
            return false
        },
        canEditHRInfo() {
            if (!this.onboardingApplicationId) {return false}

            return !(this.employee.status == 'hired' || this.employee.status == 'canceled')
        },
        workLocationIds() {
            if (!this.onboardingApplicationId) {return []}

            return Array.from(new Set(this.employee.jobs.map(j => j.position.work_location.id)))
        },
        positionIds() {
            if (!this.onboardingApplicationId) {return []}

            return Array.from(new Set(this.employee.jobs.map(j => j.position.id)))
        },
        userHasAccess() {
            if (!this.onboardingApplicationId) {return false}

            return this.$permissions.hasAccess(
                this.employee.company.client,
                this.employee.company.id,
                this.workLocationIds,
                this.positionIds,
            )
        },
        i9CompletedLateWarnings() {
            const result = []
            const warnings = this.employee.warnings.filter(w => w.warning_type == 'i9-completed-late')
            warnings.forEach(w => {
                const o = this.onboardingApplications.find(o => o.id == w.onboarding_application)
                const i9 = o.forms.find(f => f.form_designation == 'i9')
                result.push({
                    warning: w,
                    onboardingApplication: o,
                    i9: i9,
                    date: i9.signature_data.manager_signature.date
                })
            })
            return result
        },
    },
    mounted() {
        this.getData()
    },
    data() {
        const activeHrDocumentId = (this.section == 'hr-documents') ? this.itemId : null
        const expandedDocuments = {}
        if (activeHrDocumentId) {
            expandedDocuments[`employee-doc-${activeHrDocumentId}`] = true
        }

        return {
            employee: null,
            approvalMode: false,
            changelogs: [],
            editModalOpened: false,
            adminOnboardingRepeatStep: null,
            aosData: null,

            activeOnboardingCount: 0,
            onboardingApplications: [],
            managers: {},
            employeeDocuments: [],
            taxForms: [],
            loginAttempts: [],
            checks: [],

            currentCheck: null,
            currentW2: null,
            currentPDF: null,
            showAllChecks: Boolean(this.activeCheckId),
            showAllDocs: Boolean(this.activeHrDocumentId),
            showAllTaxForms: Boolean(this.activeTaxDocumentId),
            expandedDocuments: expandedDocuments,
            expandedOnboardingApplications: {},
            documentUploadModalOpen: false,

            activeTab: this.tab,

            onboardingAppsExpanded: this.section == 'onboarding' || this.tab != 'misc',
            managersExpanded: this.section == 'managers' || this.tab != 'misc',
            docsExpanded: this.section == 'hr-documents' || this.tab != 'misc',
            checksExpanded: this.section == 'checks' || this.tab != 'misc',
            taxDocsExpanded: this.section == 'tax-documents' || this.tab != 'misc',

            activeCheckId: (this.section == 'checks') ? this.itemId : null,
            activeHrDocumentId: activeHrDocumentId,
            activeTaxDocumentId:(this.section == 'tax-documents') ? this.itemId : null,

            activeWarningId: (this.tab == 'warnings') ? this.itemId : null,
            activeChangelogId: (this.tab == 'changelogs') ? this.itemId : null,

            unresolvedWarningsExpanded: true,
            resolvedWarningsExpanded: true,
            dismissedWarningsExpanded: true,
        }
    },
    methods: {
        getData() {
            if (this.employeeId) {
                return this.getEmployee()
            }
            else if (this.onboardingApplicationId) {
                return this.getOnboardingApplication()
            }
        },
        getEmployee() {
            this.employee = null
            return this.$api.get(`/clients/${this.client.id}/employees/${this.employeeId}/misc-hr-info`).then(resp => {
                this.employee = resp.employee
                this.activeOnboardingCount = resp.active_onboarding_count
                this.managers = resp.managers
                this.employeeDocuments = resp.docs
                this.taxForms = resp.tax_forms
                this.loginAttempts = resp.login_attempts
                this.checks = resp.checks
                this.changelogs = resp.changelogs
                this.onboardingApplications = resp.onboarding_applications

                /* XXX: need to figure out how to make this scroll correctly
                this.$nextTick(() => {
                    let offset = 0
                    if (this.activeHrDocumentId) {
                        const idx = this.docs.findIndex(d => `${d.id}` == `${this.activeHrDocumentId}`)
                        offset = this.$refs.docs[idx].getBoundingClientRect().top
                    }
                    this.$refs.sidebar.scrollTop(offset)
                })
                */
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
        getOnboardingApplication() {
            this.employee = null

            return this.$api.get(`/clients/${this.client.id}/onboarding-applications/${this.onboardingApplicationId}/misc-hr-info`).then(resp => {
                this.employee = resp.onboarding_application
                this.activeOnboardingCount = 0  // XXX: is this correct?
                this.managers = resp.managers
                this.taxForms = []
                this.loginAttempts = resp.login_attempts
                this.checks = []
                this.changelogs = resp.changelogs

            }).catch(errors => { this.$bus.showError(errors.__all__)
            })
        },
        resetPIN() {
            this.$refs.pinResetModal.open()
        },
        resendActivationEmail() {
            if (!this.onboardingApplicationId) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}/invitation`, {}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showInfo(`Email sent to ${this.employee.email}.`)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        cancelOnboarding() {
            if (!this.onboardingApplicationId) {return}

            if (confirm('Are you sure?')) {
                this.$store.dispatch('START_LOADING')
                this.$api.patch(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}`, {status: 'canceled'}).then((resp) => {
                    this.$store.dispatch('STOP_LOADING')
                    Object.assign(this.employee, resp)
                    this.$emit('updated', resp)
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.showError(errors.error)
                })
            }
        },
        onCheckClicked(check) {
            this.currentCheck = check
            this.$nextTick(() => {
                this.$refs.paycheckModal.open()
            })
        },
        onW2Clicked(w2_detail) {
            this.currentW2 = w2_detail
            this.$nextTick(() => {
                this.$refs.W2Modal.open()
            })
        },
        setCurrentPDF(doc) {
            this.currentPDF = {
                title: doc.name,
                url: {
                    url: doc.url,
                    withCredentials: true,
                },
            }

            this.$nextTick(() => {
                this.$refs.pdfModal.open()
            })
        },
        expandAll() {
            this.onboardingAppsExpanded = true
            this.managersExpanded = true
            this.docsExpanded = true
            this.checksExpanded = true
            this.taxDocsExpanded = true
        },
        collapseAll() {
            this.onboardingAppsExpanded = false
            this.managersExpanded = false
            this.docsExpanded = false
            this.checksExpanded = false
            this.taxDocsExpanded = false
        },
        onUpdate(emp) {
            if (this.approvalMode && (this.employee.status != emp.status) && (emp.status == 'hired')) {
                this.approvalMode = false
            }

            Object.assign(this.employee, emp)
            this.$emit('updated', this.employee)
        },
        onWarningsUpdate() {
            this.getData().then(() => {
                this.$emit('updated', this.employee)
            })
        },
        updateEmployee(emp) {
            // Similar to onUpdate() but does not emit an event. This is used by the parent component to overwrite this.employee if an out of band update comes in

            if (this.approvalMode && (this.employee.status != emp.status) && (emp.status == 'hired')) {
                this.approvalMode = false
            }

            Object.assign(this.employee, emp)
        },
        openDocumentUploadModal() {
            this.documentUploadModalOpen = true
            this.$nextTick(() => {
                this.$refs.documentUploadModal.open()
            })
        },
        openEditModal() {
            this.editModalOpened = true
            this.$nextTick(() => {
                this.$refs.editModal.open()
            })
        },
        getAOSData() {
            const url = `/admin/onboarding-applications/${this.employee.id}/aos-data`
            this.$store.dispatch('START_LOADING')
            this.$api.get(url).then(resp => {
                this.aosData = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        adminOnboarding() {
            const url = `/admin/onboarding-applications/${this.employee.id}/reset`
            this.$store.dispatch('START_LOADING')
            this.$api.post(url, {}).then(() => {
                return this.$router.push({name: 'admin-onboarding', params: {appId: this.employee.id}})
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        adminRepeatOnboardingStep(step) {
            this.adminOnboardingRepeatStep = step
            this.$refs.adminOnboardingModal.open()
        },
        showWOTCPDF(app) {
            const doc = {
                name: 'WOTC',
                url: `${this.$api.API_BASE}/clients/${this.client.id}/onboarding-applications/${app.id}/wotc-pdf`
            }

            this.setCurrentPDF(doc)
        },
    },
}
</script>

<style scoped>
    .active-item {
        background-color: #DCEEF6;
    }
</style>
