import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import jQuery from 'jquery'
import api from './api'
import moment from 'moment'
import * as Cookies from 'tiny-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    plugins: [createPersistedState({
        key: process.env.LOCAL_STORAGE_KEY,
        paths: ['session', 'session_expiry', 'user', 'isInApp'],
    })],
    state: {
        loading: false,
        pageTitle: '',
        session: null,
        session_expiry: null,
        user: null,
        isCloseable: true,
        isInApp: false,
        appVersionClient: null,
        appVersionServer: null,
        appVersionStaleSince: null,
    },
    actions: {
        SET_CLOSEABLE: ({commit}, isCloseable) => {
            commit('SET_CLOSEABLE', isCloseable)
        },
        SET_PAGE_TITLE: ({commit}, title) => {
            commit('SET_PAGE_TITLE', title)
        },
        CLOSE_LEFT_DRAWER: ({commit}) => {
            commit('CLOSE_LEFT_DRAWER')
        },
        START_LOADING: ({commit}) => {
            commit('START_LOADING')
        },
        STOP_LOADING: ({commit}) => {
            commit('STOP_LOADING')
        },
        SET_SESSION: ({commit}, sessionData) => {
            commit('SET_SESSION', sessionData)
        },
        SET_SESSION_EXPIRY: ({commit}, expiryDate) => {
            commit('SET_SESSION_EXPIRY', expiryDate)
        },
        SET_APP_VERSION: ({commit}, appVersion) => {
            commit('SET_APP_VERSION', appVersion)
        },
        SET_IS_IN_APP: ({commit}, in_app_status) => {
            commit('SET_IS_IN_APP', in_app_status)
        },
        LOGIN: ({commit, dispatch}, formData) => {
            dispatch('START_LOADING')
            return api.post('/login', formData).then((resp) => {
                commit('SET_SESSION', resp)
                dispatch('STOP_LOADING')
            }).catch((error) => {
                dispatch('STOP_LOADING')
                throw error
            })
        },
        LOGIN_VALIDATE_EMAIL: ({commit, dispatch}, formData) => {
            dispatch('START_LOADING')
            return api.post('/login/complete', formData).then((resp) => {
                commit('SET_SESSION', resp)
                return dispatch('GET_USER').then(() => {
                    dispatch('STOP_LOADING')
                }).catch(err => {
                    dispatch('STOP_LOADING')
                    throw err
                })
            }).catch((error) => {
                dispatch('STOP_LOADING')
                throw error
            })
        },
        LOGOUT: ({commit}) => {
            commit('LOGOUT')
        },
        GET_USER: ({commit}) => {
            return api.get('/me').then((resp) => {
                commit('SET_USER', resp)
            }).catch((error) => {
                throw error
            })
        },
    },
    mutations: {
        SET_CLOSEABLE: (state, isCloseable) => {
            state.isCloseable = isCloseable
        },
        START_LOADING: (state) => {
            state.loading = true
        },
        STOP_LOADING: (state) => {
            state.loading = false
        },
        SET_PAGE_TITLE: (state, title) => {
            state.pageTitle = title
        },
        CLOSE_LEFT_DRAWER: () => {
            jQuery('#drawerLeft').removeClass('show')
        },
        SET_SESSION: (state, session) => {
            state.session = Object.assign({}, session)
        },
        SET_SESSION_EXPIRY: (state, session_expiry) => {
            state.session_expiry = session_expiry
        },
        SET_APP_VERSION: (state, appVersion) => {
            // update the client version the first time, server every time
            if (! state.appVersionClient) {
                state.appVersionClient = appVersion
            }
            state.appVersionServer = appVersion
            // if the app version is stale, remember when it went stale
            if (state.appVersionClient != state.appVersionServer) {
                state.appVersionStaleSince = moment()
                console.log("App version detected stale at " + state.appVersionStaleSince)
            }
        },
        SET_IS_IN_APP: (state, in_app_status) => {
            state.isInApp = in_app_status
        },
        LOGOUT: (state) => {
            Object.keys(Cookies.getAll()).forEach((key) => {
                Cookies.remove(key)
            })
            state.session = null
            state.session_expiry = null
            state.user = null
            state.loading = false
            state.pageTitle = ''
        },
        SET_USER: (state, user) => {
            state.user = user
        },
    },
})
