<script>

import api from '@/api'
import bus from '@/bus'
import jQuery from 'jquery'
import ArrayBufferToString from 'arraybuffer-to-string'

import { saveAs } from 'file-saver'

export default {
    methods: {
        printToPDF() {
            let h = this.createPrintableHTML(this.$refs.printable.innerHTML)

            let css_includes = []
            let css_rules = []
            for (let style of document.styleSheets) {
                if (style.href) {
                    // NB: non-GetMyPayStub items will be filtered out and need to be
                    // included on the server (i.e. Google Fonts, FontAwesome, etc.)
                    css_includes.push(style.href)
                } else {
                    for (let css_rule of style.cssRules) {
                        css_rules.push(css_rule.cssText)
                    }
                }
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/print-pdf`, {
                html: h,
                css_includes: css_includes,
                css_rules: css_rules,
            }).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                if (this.$store.state.isInApp) {
                    if (global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cordova_iab) {
                        global.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({
                            command: 'print',
                            content: btoa(ArrayBufferToString(resp, 'binary'))
                        }))
                    }
                } else {
                    // TODO: make filename customizable
                    saveAs(new Blob([resp], {type: "application/pdf"}), `download.pdf`)
                }
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },

        createPrintableHTML(html) {
          return `
                <div class="container-fluid">
                    <div class="printable-item">
                        ${html}
                    </div>
                </div>
            `
        }
    },
}
</script>
