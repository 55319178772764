<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body">
                <div class="container-fluid">

                    <fast-table
                        :sections="sections"
                        :headers="headers"
                        :data="tableData"
                         v-if="displayed_paychecks"
                         v-bind:show-search="false"
                         v-bind:show-more-actions="false"
                         @click="onClick"
                    >

                        <template slot="table-actions">
                            <div class="dropdown">
                                <a href="" class="btn btn-icon-outline text-700 dropdown-toggle" role="button" id="menuYears" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="far fa-fw fa-calendar"></i> <span class="btn-text">{{ selected_year }}</span>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="menuYears">
                                    <a class="dropdown-item" @click.prevent="selectYear(year)" v-for="year in paycheck_years">
                                        {{ year }}
                                    </a>
                                </div>
                            </div>

                        </template>

                    </fast-table>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FastTable from '@/components/FastTable'

export default {
    components: {FastTable, },
    computed: {
        tableData() {
            const tableData = []

            if (! this.displayed_paychecks) {
                return [tableData]
            }

            this.displayed_paychecks.forEach((p, idx) => {
                const row = {
                    id: idx,
                    object: p,
                    cells: [
                        p.check_date,
                        p.company.name,
                        p.check_number,
                        '$' + p.net_pay
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
        displayed_paychecks() {
            return this.paychecks.filter(el => el.check_year == this.selected_year);
        },
    },
    data() {
        return {
            paychecks: [],
            paycheck_years: [],
            selected_year: '',

            headers: [
                {label: 'Check Date', classes: '', isSearchable: false, type: 'date', defaultSort: true, defaultSortIsDesc: true},
                {label: 'Company', classes: 'cell-company', isSearchable: false},
                {label: 'Check Number', classes: 'cell-company', isSearchable: false},
                {label: 'Net Pay', classes: 'cell-company', isSearchable: false},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.getPaychecks()
        this.$store.dispatch('SET_PAGE_TITLE', 'Pay Stubs')
    },
    methods: {
        selectYear(year) {
            this.selected_year = year
        },
        onClick($event) {
            this.$router.push({name: 'employee-paycheck', params: {paycheckId: $event.id}})
        },
        getPaychecks() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/me/paychecks').then(resp => {
                this.paychecks = resp['paychecks']
                this.paycheck_years = resp['paycheck_years']
                this.selected_year = this.paycheck_years[0]
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
    },
}
</script>
