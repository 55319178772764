<template>
    <div>
        <fast-table
            v-if="changelogs !== null"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="onClick"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
        >
        </fast-table>

        <employee-sidebar
            v-if="!disableSidebar && currentChangelog && (currentChangelog.employee || currentChangelog.onboarding_application)"
            :employee-id="currentChangelog.employee ? currentChangelog.employee.id : null"
            :onboarding-application-id="currentChangelog.onboarding_application ? currentChangelog.onboarding_application.id : null"
            tab="changelogs" :item-id="currentChangelog.id"
            :client="client"
            @close="currentChangelog = null"
            :key="currentChangelog ? `e-${currentChangelog.id}` : ''"
        />

        <security-profile-sidebar
            v-if="!disableSidebar && currentChangelog && currentChangelog.company_security_profile"
            :active-changelog-id="currentChangelog.id"
            :security-profile-id="currentChangelog.company_security_profile.security_profile.id"
            tab="changelogs" :item-id="currentChangelog.id"
            :client="client"
            @close="currentChangelog = null"
            :key="currentChangelog ? `sp-${currentChangelog.id}` : ''"
        />
    </div>
</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import SecurityProfileSidebar from '@/components/SecurityProfileSidebar'

export default {
    props: ['client', 'changelogs', 'disableSidebar'],
    components: {FastTable, EmployeeSidebar, SecurityProfileSidebar, },
    computed: {
        tableData() {
            const tableData = []

            this.changelogs.forEach((c, idx) => {
                const row = {
                    id: idx,
                    isActive: this.currentChangelog && c.id == this.currentChangelog.id,
                    object: c,
                    cells: [
                        c.item_changed,
                        c.parent_company ? c.parent_company.name : '--',
                        c.change_target,
                        c.created_on,
                        c.created_by.email,
                        c.employee_email,
                        c.employee_ssn_last_4 ? `XXX-XX-${c.employee_ssn_last_4}` : '',
                        c.manager_count,
                        c.created_from_ip,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            currentChangelog: null,

            headers: [
                {label: 'Item Changed', classes: '', isSearchable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Changed Data', classes: 'cell-medium', isSearchable: false, isFilterable: true},
                {label: 'Date', classes: 'cell-date', isSearchable: false, defaultSort: true, defaultSortIsDesc: true, type: 'date'},
                {label: 'Changed By', classes: 'cell-email', isSearchable: true},
                {label: 'Employee Email', classes: 'cell-email', isSearchable: true},
                {label: 'Employee SSN', classes: 'cell-medium', isSearchable: true},
                {label: 'Manager', classes: 'cell-narrow', isSearchable: false},
                {label: 'IP Address', classes: 'cell-ip-address', isSearchable: true},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    methods: {
        onDateFilter(dateRanges) {
            const dateRange = dateRanges[Object.keys(dateRanges)[0]]
            this.$emit('date_filter_change', dateRange)
        },
        onClick($event) {
            this.currentChangelog = $event
            this.$emit('changelog_click', $event)
        },
    },
}

</script>
