<template>
    <div v-show="dataLoaded">
        <div class="flex-page-content flex-container">
            <div class="flex-body">
                <div class="container-fluid">

                        <fast-table
                            :sections="sections"
                            :headers="headers"
                            :data="tableData"
                            v-bind:show-search="false"
                            v-bind:show-more-actions="false"
                            @click="onClick($event)"
                        />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FastTable from '@/components/FastTable'

export default {
    components: {FastTable, },
    computed: {
        tableData() {
            const tableData = []

            this.hr_docs.forEach((d, idx) => {
                const row = {
                    id: idx,
                    object: d,
                    cells: [
                        d.name,
                        d.employee.company.name,
                        d.date_added,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            hr_docs: [],
            dataLoaded: false,

            headers: [
                {label: 'Document', classes: '', isSearchable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true},
                {label: 'Date Added', classes: 'cell-date', isSearchable: true, type: 'date'},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'HR Documents')
        this.getHRDocs()
    },
    methods: {
        getHRDocs() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/me/hr-docs`).then(resp => {
                this.hr_docs = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onClick(doc) {
            window.open(doc.employee_file_url)
        },
    },
}

</script>
