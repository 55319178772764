import 'babel-polyfill'
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import {PMDateFilters, PMBus, PMAPI, PMPermissions, PMConstants} from '@/plugins'
import PortalVue from 'portal-vue'

import MoreInfo from '@/components/MoreInfo'
import CollapseToggleButton from '@/components/CollapseToggleButton'
import AlertBox from '@/components/AlertBox'
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import PageSearch from '@/components/PageSearch'

import 'jquery'
import 'popper.js'
import 'bootstrap/dist/js/bootstrap.js'
import 'muicss/dist/js/mui.min.js'
import 'toastr/toastr.scss'
import 'select2/dist/css/select2.min.css'

import '@/assets/fonts/roboto/roboto.css'
import '@/assets/font-awesome/css/all.css'

import '@/assets/scss/style.scss'
import '@/assets/scss/local.scss'

Vue.config.productionTip = false

Vue.use(PMDateFilters)
Vue.use(PMAPI)
Vue.use(PMBus)
Vue.use(PMPermissions)
Vue.use(PMConstants)
Vue.use(PortalVue)

Vue.component('MoreInfo', MoreInfo)
Vue.component('CollapseToggleButton', CollapseToggleButton)
Vue.component('AlertBox', AlertBox)
Vue.component('FormInput', FormInput)
Vue.component('FormErrors', FormErrors)
Vue.component('PageSearch', PageSearch)

import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import { Integrations } from "@sentry/tracing"

if (process.env.VUE_APP_RAVEN_JS_DSN) {
    Sentry.init({
        dsn: process.env.VUE_APP_RAVEN_JS_DSN,
        integrations: [
            new Integrations.BrowserTracing(),
            new VueIntegration({
                Vue,
                attachProps: true,
                logError: true,
                tracing: true,
                tracingOptions: {
                    trackComponents: true,
                },
            }),
        ],
    })
}

new Vue({
    render: h => h(App),
    router: router,
    store: store,
}).$mount('#app')
