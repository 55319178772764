<template>
    <page>
        <div class="nav-tabs-container pt-1" id="tabsPageNav">
            <div class="nav-tabs-scroll-helper">
                <ul class="nav nav-tabs">

                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': mode == 'main'}" href id="tabLink1" role="tab" aria-controls="tabContent1" :aria-selected="mode == 'main'" @click.prevent="setMode('main')">
                            Main
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': mode == 'warnings'}" href id="tabLink2" role="tab" aria-controls="tabContent2" :aria-selected="mode == 'warnings'" @click.prevent="setMode('warnings')">
                            Warnings
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': mode == 'changelogs'}" href id="tabLink3" role="tab" aria-controls="tabContent3" :aria-selected="mode == 'changelogs'" @click.prevent="setMode('changelogs')">
                            Change History
                        </a>
                    </li>

                </ul>
            </div>
        </div>

        <div class="flex-body flex-container tab-content">
            <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'main'}" id="tabContent1" role="tabpanel" aria-labelledby="tabLink1">
                <slot></slot>
            </div>

            <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'warnings'}" id="tabContent2" role="tabpanel" aria-labelledby="tabLink2">
                <warnings-table :client="client" :warnings="warnings" @updated="$emit('warnings_updated', $event)" @date_filter_change="$emit('warnings_date_filter_change', $event)" @warning_click="$emit('warning_click', $event)"  :disable-sidebar="disableWarningSidebar"/>
            </div>

            <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'changelogs'}" id="tabContent3" role="tabpanel" aria-labelledby="tabLink3">
                <changelog-table :client="client" :changelogs="changelogs" @date_filter_change="$emit('changelog_date_filter_change', $event)" @changelog_click="$emit('changelog_click', $event)" :disable-sidebar="disableChangelogSidebar" />
            </div>
        </div>

    </page>
</template>

<script>
import Page from './Page'
import WarningsTable from '@/components/WarningsTable'
import ChangelogTable from '@/components/ChangelogTable'

export default {
    components: {Page, WarningsTable, ChangelogTable, },
    props: ['client', 'warnings', 'changelogs', 'disableChangelogSidebar', 'disableWarningSidebar', ],
    data() {
        return {
            mode: 'main',
        }
    },
    methods: {
        setMode(mode) {
            this.mode = mode
            this.$bus.$emit('resize')
        },
    }
}
</script>
