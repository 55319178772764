<template>
    <div>
        <table-page
            :client="client"
            :warnings="ourWarnings"
            :changelogs="ourChangelogs"
            @changelog_date_filter_change="onChangelogDateRangeChange"
            @warnings_date_filter_change="onWarningsDateRangeChange"
            @warnings_updated="getWarnings"
            @changelog_click="onChangelogClick"
            @warning_click="onWarningClick"
            :disable-changelog-sidebar="true"
            :disable-warning-sidebar="true">

            <fast-table
                :sections="sections"
                :headers="headers"
                :data="tableData"
                :managers="client.company_managers"
                @click="onClick"
                @click_cell="onClick($event.object, $event.columnIdx)"
                :use-date-filters="true"
                @date_filter_change="onDateFilter"
            />

        </table-page>

        <sidebar
            v-if="currentUser"
            :user="currentUser"
            :client="client"
            @close="currentUserKey = null"
            @updated="onUpdate"
            :key="currentUserKey"
            :tab="tab"
            :itemId="{'main': null, 'warnings': activeWarningId, 'changelogs': activeChangelogId}[this.tab]"
        />
    </div>
</template>

<script>
import Sidebar from './components/Sidebar'
import FastTable from '@/components/FastTable'
import TablePage from '@/components/TablePage'

export default {
    components: {FastTable, Sidebar, TablePage, },
    props: ['client'],
    computed: {
        activeUsers() {
            return this.users.filter(u => u.is_active)
        },
        inactiveUsers() {
            return this.users.filter(u => !u.is_active)
        },
        currentUser() {
            return this.users.find(u => u.key == this.currentUserKey)
        },
        ourWarnings() {
            // This is a filtered list of warnings. It is meant to ensure that the Warnings table on this page
            // only shows warnings about the users in the Main tab

            let result = []
            this.users.forEach(u => {
                result = result.concat(this.findWarningsForUser(u))
            })
            return result
        },
        ourChangelogs() {
            // This is a filtered list of changelogs. It is meant to ensure that the History table on this page
            // only shows changelogs about the users in the Main tab

            let result = []
            this.users.forEach(u => {
                result = result.concat(this.findChangelogsForUser(u))
            })
            return result
        },
        tableData() {
            const tableData = []

            const subsets = [
                this.activeUsers,
                this.inactiveUsers,
            ]

            subsets.forEach(users => {
                const sectionData = []

                users.forEach(u => {
                    const companyIds = new Set()

                    u.employees.forEach(e => {companyIds.add(e.company_id)})
                    u.onboarding_applications.forEach(o => {companyIds.add(o.company_id)})
                    const companyCount = companyIds.size

                    let ssnLast4 = ''
                    if (u.employees.length) {
                        ssnLast4 = u.employees[0].ssn_last_4 ? u.employees[0].ssn_last_4 : ''
                    }
                    if (u.onboarding_applications.length) {
                        ssnLast4 = u.onboarding_applications[0].ssn_last_4 ? u.onboarding_applications[0].ssn_last_4 : ssnLast4
                    }

                    let fullName = u.full_name_sortable
                    if (!fullName) {
                        if (u.employees.length) {
                            fullName = u.employees[0].full_name_sortable ? u.employees[0].full_name_sortable : ''
                        }
                        if (u.onboarding_applications.length) {
                            fullName = u.onboarding_applications[0].full_name_sortable ? u.onboarding_applications[0].full_name_sortable : fullName
                        }
                    }

                    const lockedCompanyIds = new Set()
                    const unlinkedCompanyIds = new Set()
                    let last_pay_date = ''

                    u.employees.forEach(e => {
                        if (e.is_locked) {
                            lockedCompanyIds.add(e.company_id)
                        }
                        if (! e.user) {
                            unlinkedCompanyIds.add(e.company_id)
                        }
                        if (e.last_pay_date && e.last_pay_date > last_pay_date) {
                            last_pay_date = e.last_pay_date
                        }
                    })

                    u.onboarding_applications.forEach(o => {
                        if (o.is_locked) {
                            lockedCompanyIds.add(o.company_id)
                        }
                        // NB: this is OK - if someone just hasn't started Onboarding, we don't consider that a problem
                        // if (! o.user) {
                        //     unlinkedCompanyIds.add(o.company_id)
                        // }
                    })

                    // Locked > Unlinked > OK (if Locked, takes precedence over Unlinked)
                    let status = 'OK'
                    if (! u.has_user) {
                        status = 'Not Registered'
                    } else {
                        if (unlinkedCompanyIds.size > 0) {
                            if (unlinkedCompanyIds.size > 1) {
                                status = unlinkedCompanyIds.size + ' Unlinked Companies'
                            } else {
                                status = unlinkedCompanyIds.size + ' Unlinked Company'
                            }
                        }
                        if (lockedCompanyIds.size > 0) {
                            if (lockedCompanyIds.size > 1) {
                                status = lockedCompanyIds.size + ' Locked Companies'
                            } else {
                                status = lockedCompanyIds.size + ' Locked Company'
                            }
                        }
                    }

                    const row = {
                        id: u.key,
                        isActive: this.currentUser && u.key == this.currentUserKey,
                        object: u,
                        cells: [
                            fullName,
                            this.getWarningCount(u),
                            companyCount,
                            status,
                            u.last_login ? u.last_login : '',
                            u.email,
                            ssnLast4 ? 'XXX-XX-' + ssnLast4 : '',
                            last_pay_date
                        ],
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            users: [],
            dataLoaded: false,
            currentUserKey: null,
            dateRanges: {},

            headers: [
                {label: 'Employee', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Warnings', classes: 'cell-warning', isFilterable: true, isSearchable: false, type: 'warning', isClickable: true},
                {label: 'Companies', classes: 'cell-medium', isSearchable: false},
                {label: 'Account Status', classes: 'cell-medium', isFilterable: true, isSearchable: true},
                {label: 'Last Login', classes: 'cell-date', isSearchable: true, type: 'date'},
                {label: 'Email', classes: 'cell-email', isSearchable: true},
                {label: 'SSN', classes: 'cell-wide', isSearchable: true},
                {label: 'Last Paid', classes: 'cell-date', isSearchable: false, type: 'date'},
            ],
            sections: [
                {title: 'Active Employees', defaultIsClosed: false, id: 'active', hasDateFilter: false},
                {title: 'Inactive Employees', defaultIsClosed: true, id: 'inactive', hasDateFilter: true},
            ],
            warnings: null,
            changelogs: null,

            changelogDateRangeStart: null,
            changelogDateRangeEnd: null,

            warningsDateRangeStart: null,
            warningsDateRangeEnd: null,

            tab: 'main',
            activeWarningId: null,
            activeChangelogId: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Self-Service Access')
        this.getUsers()
        this.getWarnings()
        this.getChangelogs()

        this.$bus.$on('client_data_updated', updates => {
            if ((updates.indexOf('onboarding_applications') >= 0) || (updates.indexOf('employees') >= 0)) {
                this.getUsers(true)
                this.getWarnings()
                this.getChangelogs()
            }
        })
    },
    methods: {
        getUsers(silent) {
            if (!silent) {
                this.$store.dispatch('START_LOADING')
            }

            let url = `/clients/${this.client.id}/non-sec-users`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.$api.get(url).then(resp => {
                this.users = resp
                this.updateUserWarningsAndChangelogs()

                if (!silent) {
                    this.dataLoaded = true
                    this.$store.dispatch('STOP_LOADING')
                }
            }).catch((errors) => {
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate(user) {
            Object.assign(this.users.find(u => u.email == user.email), user)
            this.updateUserWarningsAndChangelogs()
        },
        getWarnings() {
            let url = `/clients/${this.client.id}/warnings`
            if (this.warningsDateRangeStart && this.warningsDateRangeEnd) {
                url = `${url}?date-filter.resolved.start=${encodeURIComponent(this.warningsDateRangeStart)}&date-filter.resolved.end=${encodeURIComponent(this.warningsDateRangeEnd)}`
            }

            this.$api.get(url).then(resp => {
                this.warnings = resp.warnings.filter(w => {
                    if (w.employee || w.onboarding_application) {
                        if (['bounced-email', 'consent-withdrawn', 'pin-reset'].indexOf(w.warning_type) >= 0) {
                            return true
                        }
                    }
                })

            this.updateUserWarningsAndChangelogs()
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getChangelogs() {
            let url = `/clients/${this.client.id}/changelogs`
            if (this.changelogDateRangeStart && this.changelogDateRangeEnd) {
                url = `${url}?date-filter.changelogs.start=${encodeURIComponent(this.changelogDateRangeStart)}&date-filter.changelogs.end=${encodeURIComponent(this.changelogDateRangeEnd)}`
            }

            this.$api.get(url).then(resp => {
                this.changelogs = resp.filter(c => c.onboarding_application || c.employee)
                this.updateUserWarningsAndChangelogs()
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        findItemsForUser(u, items) {
            const employeeIds = new Set()
            const onboardingApplicationIds = new Set()

            u.employees.forEach(e => {
                employeeIds.add(e.id)
            })

            u.onboarding_applications.forEach(o => {
                onboardingApplicationIds.add(o.id)
            })

            let result = []
            if (items) {
                result = result.concat(items.filter(x => {
                    if (x.employee) {
                        return employeeIds.has(x.employee.id)
                    }
                    else if (x.onboarding_application) {
                        return onboardingApplicationIds.has(x.onboarding_application.id)
                    }
                }))
            }

            return result
        },
        findChangelogsForUser(u) {
            return this.findItemsForUser(u, this.changelogs)
        },
        findWarningsForUser(u) {
            return this.findItemsForUser(u, this.warnings)
        },
        getWarningCount(u) {
            let warnings = this.findWarningsForUser(u)
            warnings = warnings.filter(w => !(w.is_resolved))
            return warnings.length
        },
        updateUserWarningsAndChangelogs() {
            // This method combines this.users with this.warnings and this.changelogs so that all
            // those values are accessible from each user object
            if (this.users && this.warnings) {
                this.users.forEach(u => {
                    u.warnings = this.findWarningsForUser(u)
                })
            }

            if (this.users && this.changelogs) {
                this.users.forEach(u => {
                    u.changelogs = this.findChangelogsForUser(u)
                })
            }
        },
        onClick(u, columnIdx) {
            this.activeWarningId = null
            this.activeChangelogId = null
            this.tab = 'main'

            if (columnIdx == 1 && this.getWarningCount(u)) {
                this.tab = 'warnings'
            }

            this.currentUserKey = u.key
        },
        onChangelogDateRangeChange(range) {
            this.changelogDateRangeStart = range.start
            this.changelogDateRangeEnd = range.end
            this.getChangelogs()
        },
        onWarningsDateRangeChange(range) {
            this.warningsDateRangeStart = range.start
            this.warningsDateRangeEnd = range.end
            this.getWarnings()
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getUsers()
        },
        onChangelogClick(changelog) {
            this.activeWarningId = null
            this.activeChangelogId = null
            this.currentUserKey = null
            this.tab = 'main'

            const u = this.users.find(u => {
                if (changelog.employee) {
                    if (u.employees.find(e => e.id == changelog.employee.id)) {
                        return true
                    }
                }
                else if (changelog.onboarding_application) {
                    if (u.onboarding_applications.find(e => e.id == changelog.onboarding_application.id)) {
                        return true
                    }
                }
                return false
            })

            if (u) {
                this.tab = 'changelogs'
                this.activeChangelogId = changelog.id
                this.currentUserKey = u.key
            }
        },
        onWarningClick(warning) {
            this.activeWarningId = null
            this.activeChangelogId = null
            this.currentUserKey = null
            this.tab = 'main'

            const u = this.users.find(u => {
                if (warning.employee) {
                    if (u.employees.find(e => e.id == warning.employee.id)) {
                        return true
                    }
                }
                else if (warning.onboarding_application) {
                    if (u.onboarding_applications.find(e => e.id == warning.onboarding_application.id)) {
                        return true
                    }
                }
                return false
            })

            if (u) {
                this.tab = 'warnings'
                this.activeWarningId = warning.id
                this.currentUserKey = u.key
            }
        },
    },
}
</script>

