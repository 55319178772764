<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title">Add Payment Account</template>

        <slot>
            <form-errors :errors="formErrors"/>

            <div class="form-row">
                <div class="col-12 d-flex flex-row">
                    <div class="flex-grow-1 mr-1">
                        <form-input v-model="formData.routing_number" :errors="errors.routing_number" type="integer-string" label="Routing Number" maxlength="9"/>
                        <!-- <div class="field-helper-text">FIRST NATIONAL BANK OF NEW YORK </div> -->
                    </div>
                    <more-info>
                        <p class="mb-1">
                            Your bank's Routing Number is the first set of numbers on the bottom of your check. It is 9 digits long.
                        </p>
                        <p class="mb-0">
                            Your Account Number is the second set of numbers on the bottom of your check. It can vary in length.
                        </p>
                    </more-info>
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <div class="form-group-helpinfo">
                        <div class="form-helpinfo-field">
                            <form-input v-model="formData.account_number" :errors="errors.account_number" type="text" label="Account Number" maxlength="17"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-row pb-2">
                <div class="col">
                    <form-input v-model="formData.account_type" :errors="errors.account_type" type="select" label="Account Type" :options="accountTypeOptions"/>
                </div>
            </div>

            <div v-if="!isFirstAccount">
                <h5 class="mb-2">Deposit Portion</h5>

                <div class="form-row pb-8">
                    <div class="col-6">
                        <form-input v-model="formData.split_type" :errors="errors.split_type" type="select" label="Portion (Per Pay)"  :options="splitTypeOptions" />
                    </div>

                    <div class="col-5">
                        <form-input v-if="formData.split_type == 'fixed'" label="Amount" v-model="formData.amount" :errors="errors.amount" type="currency" prefix="$" />
                            <form-input v-if="formData.split_type == 'percentage'" label="Per Pay Amount" v-model="formData.percentage" :errors="errors.percentage" type="integer" maxlength="2" prefix="%" />
                    </div>

                    <div class="col-1">
                        <more-info>
                           <p class="mb-1">
                              Decide what portion of each paycheck will be deposited into this account (for example, 25% or $100). The remainder will be deposited into the other payment account.
                           </p>
                           <p class="mb-0">
                              You can swap the order of the 2 accounts after completing this form, if desired.
                           </p>
                        </more-info>
                    </div>
                </div>
            </div>
        </slot>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save Account</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin],
    props: ['employee', 'directDepositAccount', 'url', ],
    watch: {
        directDepositAccount() {
            this.reset()
        },
        'formData.split_type'() {
            this.formData.amount = ''
            this.formData.percentage = ''
            if (this.errors.amount) {delete this.errors.amount}
            if (this.errors.percentage) {delete this.errors.percentage}
        },
    },
    computed: {
        accountTypeOptions() {
            return [
                {text: 'Checking', value: 'checking'},
                {text: 'Savings', value: 'savings'},
            ]
        },
        splitTypeOptions() {
            return [
                {text: 'Fixed Amount ($)', value: 'fixed'},
                {text: 'Percentage (%)', value: 'percentage'},
            ]
        },
        isFirstAccount() {
            const thisId = this.directDepositAccount ? this.directDepositAccount.id : null
            const otherAccountCount = this.employee.direct_deposit_accounts.filter((a) => {
                return a.id != thisId
            }).length

            if (otherAccountCount < 1) {
                return true
            }
            return false
        },
    },
    data() {
        const rules = {
            routing_number: {presence: {allowEmpty: false}, aba_routing_number: true},
            account_number: {presence: {allowEmpty: false}, aba_account_number: true},
            account_type: {presence: {allowEmpty: false}, },
        }

        if (!this.isFirstAccount) {
            rules.split_type = {presence: {allowEmpty: false}, }
        }

        return {
            formRules: rules,
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            if (this.directDepositAccount) {
                return {
                    routing_number: this.directDepositAccount.routing_number,
                    account_number: this.directDepositAccount.account_number,
                    account_type: this.directDepositAccount.account_type,
                    split_type: this.directDepositAccount.split_type,
                    amount: this.directDepositAccount.amount,
                    percentage: this.directDepositAccount.percentage,
                }
            }
            else {
                const data = {
                    routing_number: '',
                    account_number: '',
                    account_type: '',
                    split_type: 'remainder',
                    amount: '',
                    percentage: '',
                }

                if (this.employee.direct_deposit_accounts.length) {
                    data.split_type = 'percentage'
                }

                return data
            }
        },
        onSubmit() {
            if (this.isFirstAccount) {
                this.formData.split_type = 'remainder'
            }

            if (this.formData.split_type == 'percentage') {
                this.formRules.percentage = {presence: {allowEmpty: false}, integer: {min: 1, max: 99}}
                if (this.formRules.amount) {
                    delete this.formRules.amount
                }
            }

            if (this.formData.split_type == 'fixed') {
                this.formRules.amount = {presence: {allowEmpty: false}, currency: true}
                if (this.formRules.percentage) {
                    delete this.formRules.percentage
                }
            }

            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(this.url, this.formData).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('paymasters_direct_deposit_accounts_updated', resp)
                this.close()
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    }
}
</script>
