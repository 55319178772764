<template>
    <sidebar @close="$emit('close')" v-if="securityProfile">
        <template slot="title" v-if="securityProfile">
            {{ securityProfile.full_name }}
        </template>

        <template slot="header" v-if="securityProfile">
            <div class="mb-1">
                <div class="d-flex flex-wrap">
                    <div class="py-qt text-nowrap">
                        <span class="text-muted" v-if="isActive && !securityProfile.user">Pending Activation</span>
                        <span class="text-muted" v-if="isActive && securityProfile.user">Active</span>
                        <span class="text-muted" v-if="!isActive">Inactive</span>
                    </div>
                </div>
            </div>

            <unlock-widget v-if="securityProfile.user && securityProfile.user.id && isLocked" :client="client" :user="securityProfile.user" :is-locked="isLocked" @unlocked="refresh" />

            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'main'}" href="#tabSUContentMain" id="tabSULinkMain" data-toggle="tab" role="tab" aria-controls="tabSUContentMain" :aria-selected="activeTab == 'main'">Main</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'warnings'}" href="#tabSUContentWarnings" id="tabSULinkWarnings" data-toggle="tab" role="tab" aria-controls="tabSUContentWarnings" :aria-selected="activeTab == 'warnings'">Warnings</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'access'}" href="#tabSUUserAccess" id="tabLinkUserAccess" data-toggle="tab" role="tab" aria-controls="tabSUUserAccess" :aria-selected="activeTab == 'access'">User Access</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'changelogs'}" href="#tabSUChangeHistory" id="tabSULinkHistory" data-toggle="tab" role="tab" aria-controls="tabSUChangeHistory" :aria-selected="activeTab == 'changelogs'">History</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot v-if="securityProfile">
            <div class="tab-content">
                <div class="tab-pane" :class="{'show active': activeTab == 'main'}" id="tabSUContentMain" role="tabpanel" aria-labelledby="tabSULinkMain">

                    <div class="card mb-2">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto"><h4 class="mb-0">User Information</h4></span>
                        </div>
                        <div class="card-body py-1">

                            <div class="mui-textfield">
                                <span class="textfield">{{ securityProfile.first_name }} {{ securityProfile.last_name }}</span>
                                <label>Name</label>
                            </div>

                            <div class="mui-textfield">
                                <span class="textfield"><a :href="'mailto:' + encodeURI(securityProfile.email)">{{ securityProfile.email }}</a></span>
                                <label>Email Address</label>
                            </div>

                            <div class="mui-textfield">
                                <span class="textfield">{{ securityProfile.phone_formatted }}</span>
                                <label>Phone Number</label>
                                <p class="mt-1"><small>This phone number is shown to employees when they need to contact a manager to reset their PIN.</small></p>
                            </div>

                            <div class="form-row mt-3">
                                <div class="col" v-if="isActive && !securityProfile.user">
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="resendInvitation()">Re-send Invitation</button>
                                </div>

                                <div class="col" v-if="isActive && securityProfile.user && canManage">
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="deactivate()">Deactivate User</button>
                                </div>

                                <div class="col" v-if="isActive && !securityProfile.user && canManage">
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="deleteSecurityProfile()">Delete User</button>
                                </div>

                                <div class="col" v-if="!isActive && canManage">
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="reactivate()">Reactivate User</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="card mb-2">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto"><h4 class="mb-0">Security Roles</h4></span>
                            <button class="btn btn-outline-primary" @click.prevent="$refs.editModal.open()">Edit</button>
                        </div>
                        <div class="card-body py-1">
                            <ul class="list-unstyled m-0">
                                <li :class="{'border-top': idx > 0}" v-for="(cp, idx) in securityProfile.company_security_profiles">
                                    <div class="form-row align-items-baseline pb-1 py-sm-hf">
                                        <div class="col-auto px-0">
                                            <a href class="btn btn-icon btn-collapse-toggle" role="button" :class="{'is-expanded': openCompanyPositions[cp.id], 'collapsed': !openCompanyPositions[cp.id]}" @click.prevent="openCompanyPosition(cp.id)">
                                                <i class="fas fa-minus-circle"></i><span class="sr-only">Collapse</span>
                                            </a>
                                        </div>
                                        <div class="col">
                                            <div class="form-row">
                                                <div class="col-12 col-sm">
                                                    <span>{{ cp.company.name }}</span>
                                                </div>
                                                <div class="col-12 col-sm-5">
                                                    <span class="text-bold" :class="{'text-line-through': !cp.is_active}">{{ cp.role_pretty }}</span>
                                                    <span class="text-bold text-warning" v-if="cp.is_locked"> LOCKED</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="child no-transition" :class="{'collapse': !openCompanyPositions[cp.id]}">
                                        <p v-if="['location-manager', 'store-manager'].indexOf(cp.role) < 0">All positions.</p>

                                        <div v-if="['location-manager'].indexOf(cp.role) >= 0">
                                            <p v-if="cp.work_location_security_profiles.length < 1">No work locations.</p>
                                            <ul class="list-unstyled text-muted text-small pl-4 pb-1 m-0" v-if="cp.work_location_security_profiles.length">
                                                <li class="pl-hf pb-qt" v-for="wlsp in cp.work_location_security_profiles">{{ wlsp.work_location.name }}</li>
                                            </ul>
                                        </div>

                                        <div v-if="['store-manager'].indexOf(cp.role) >= 0">
                                            <p v-if="cp.security_profile_positions.length < 1">No positions.</p>
                                            <ul class="list-unstyled text-muted text-small pl-4 pb-1 m-0" v-if="cp.security_profile_positions.length">
                                                <li class="pl-hf pb-qt" v-for="spp in cp.security_profile_positions">{{ spp.position.terminal_value }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'warnings'}" id="tabSUContentWarnings" role="tabpanel" aria-labelledby="tabSULinkWarnings">
                    <section class="table-section">
                        <warnings-header category="Dismissed" :expanded="!dismissedWarningsExpanded" v-if="warnings" :warning-count="dismissedWarnings.length" @click="dismissedWarningsExpanded = !dismissedWarningsExpanded" />
                        <warnings :warnings="dismissedWarnings" :active-warning-id="activeWarningId" v-if="warnings" v-show="dismissedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>

                    <section class="table-section">
                        <warnings-header category="Unresolved" :expanded="!unresolvedWarningsExpanded" v-if="warnings" :warning-count="unresolvedWarnings.length" @click="unresolvedWarningsExpanded = !unresolvedWarningsExpanded" />
                        <warnings :warnings="unresolvedWarnings" :active-warning-id="activeWarningId" v-if="warnings" v-show="unresolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                    <section class="table-section">
                        <warnings-header category="Resolved" :expanded="!resolvedWarningsExpanded" v-if="warnings" :warning-count="resolvedWarnings.length" @click="resolvedWarningsExpanded = !resolvedWarningsExpanded" />
                        <warnings :warnings="resolvedWarnings" :active-warning-id="activeWarningId" v-if="warnings" v-show="resolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>

                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'access'}" id="tabSUUserAccess" role="tabpanel" aria-labelledby="tabLinkUserAccess">
                    <user-access
                        :client="client"
                        :user="securityProfile.user"
                        :email="securityProfile.email"
                        :login-attempts="loginAttempts"
                        :allow-security-profile-pin-reset="true"
                        @pin_reset="resetPIN()"
                    />
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'changelogs'}" id="tabSUChangeHistory" role="tabpanel" aria-labelledby="tabSULinkHistory">
                    <change-history :client="client" :changelogs="changelogs" :active-changelog-id="activeChangelogId" />
                </div>
            </div>
        </slot>

        <edit-modal ref="editModal" :security-profile="securityProfile" :client="client" @updated="onUpdate" />
        <pin-reset-modal :user="securityProfile.user" :client="client" v-if="securityProfile.user" ref="pinResetModal" />
    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import ChangeHistory from '@/components/ChangeHistory'
import EditModal from './SecurityUserModal'
import PinResetModal from '@/components/PINResetModal'
import UnlockWidget from '@/components/UnlockWidget'
import UserAccess from '@/components/UserAccess'
import Warnings from '@/components/WarningsWidget'
import WarningsHeader from '@/components/WarningsHeaderWidget'

export default {
    props: ['securityProfileId', 'client', 'tab', 'section', 'itemId', ],
    components: {Sidebar, ChangeHistory, EditModal, PinResetModal, UnlockWidget, UserAccess, Warnings, WarningsHeader, },
    computed: {
        isLocked() {
            return this.securityProfile.company_security_profiles.filter(csp => csp.is_locked).length > 0
        },
        isActive() {
            return this.securityProfile.company_security_profiles.filter(csp => csp.is_active).length > 0
        },
        unresolvedWarnings() {
            if (!this.warnings) {return []}
            return this.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return (!isDismissed && !w.is_resolved)
            })
        },
        resolvedWarnings() {
            if (!this.warnings) {return []}
            return this.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return (!isDismissed && w.is_resolved)
            })
        },
        dismissedWarnings() {
            if (!this.warnings) {return []}
            return this.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return isDismissed
            })
        },
        canManage() {
            if (!this.securityProfile) {
                return false
            }

            let canManageCount = 0
            this.securityProfile.company_security_profiles.forEach(csp => {
                if (!this.$permissions.hasRolesInCompany(this.client.id, csp.company.id, ['payroll-admin', 'officer'])) {
                    return
                }

                if (csp) {
                    let requiredRoles = ['payroll-admin', 'officer']
                    if (csp.role == 'payroll-admin') {
                        requiredRoles = ['officer']
                    }
                    else if (csp.role == 'officer') {
                        requiredRoles = []
                    }

                    if (!this.$permissions.hasRolesInCompany(this.client.id, csp.company.id, requiredRoles)) {
                        return
                    }
                }

                canManageCount += 1
            })

            return canManageCount == this.securityProfile.company_security_profiles.length
        },
    },
    data() {
        return {
            securityProfile: null,
            changelogs: null,
            warnings: null,
            openChangelogs: {},
            changeHistoryLoaded: false,
            openCompanyPositions: {},
            loginAttempts: [],

            activeTab: this.tab,

            activeWarningId: (this.tab == 'warnings') ? this.itemId : null,
            activeChangelogId: (this.tab == 'changelogs') ? this.itemId : null,

            unresolvedWarningsExpanded: true,
            resolvedWarningsExpanded: true,
            dismissedWarningsExpanded: true,
        }
    },
    mounted() {
        this.getSecurityProfile()
        this.getChangelogs()
        this.getWarnings()
    },
    methods: {
        openCompanyPosition(cpId) {
            this.$set(this.openCompanyPositions, cpId, !this.openCompanyPositions[cpId])
        },
        getSecurityProfile() {
            this.securityProfile = null
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/security-profiles/${this.securityProfileId}`).then(resp => {
                this.securityProfile = resp
                this.$store.dispatch('STOP_LOADING')
                this.getLoginAttempts()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getChangelogs() {
            this.changelogs = null
            this.$api.get(`/clients/${this.client.id}/security-profiles/${this.securityProfileId}/changelogs`).then(resp => {
                this.changelogs = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getWarnings() {
            this.changelogs = null
            this.$api.get(`/clients/${this.client.id}/security-profiles/${this.securityProfileId}/warnings`).then(resp => {
                this.warnings = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getLoginAttempts() {
            this.loginAttempts = null
            if (this.securityProfile.user) {
                this.$api.get(`/clients/${this.client.id}/users/${this.securityProfile.user.id}/login-history`).then(resp => {
                    this.loginAttempts = resp
                }).catch((errors) => {
                    this.$bus.showError(errors.__all__)
                })
            }
        },
        reset() {
            this.getSecurityProfiles()
            this.getChangelogs()
            this.getWarnings()
            this.openCompanyPositions = {}
        },
        resetPIN() {
            this.$refs.pinResetModal.open()
        },
        deleteSecurityProfile() {
            if (!confirm('Are you sure you want to delete this security user invitation?')) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.del(`/clients/${this.client.id}/security-profiles/${this.securityProfile.id}`).then(() => {
                this.$emit('deleted', {})
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        deactivate() {
            if (!confirm('Are you sure you want to deactivate this security user?')) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.patch(`/clients/${this.client.id}/security-profiles/${this.securityProfile.id}`, {is_active: false}).then(resp => {
                this.getChangelogs()
                this.getWarnings()
                Object.assign(this.securityProfile, resp)
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reactivate() {
            this.$refs.editModal.open()
        },
        resendInvitation() {
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/security-profiles/${this.securityProfile.id}/invitation`, {}).then(resp => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showInfo(`Email sent to ${this.securityProfile.email}.`)
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        refresh() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/security-profiles/${this.securityProfile.id}`).then(resp => {
                Object.assign(this.securityProfile, resp)
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        onUpdate(data) {
            this.getChangelogs()
            this.getWarnings()
            Object.assign(this.securityProfile, data)
            this.$emit('updated', data)
        },
        updateSecurityProfile(sp) {
            // Similar to onUpdate() but does not emit an event. This is used by the parent component to overwrite this.securityProfile if an out of band update comes in
            Object.assign(this.securityProfile, sp)
        },
        onWarningsUpdate() {
            this.refresh()
        },
    },
}
</script>

