<template>
    <div>
        <div class="flex-page-content flex-container body-gray">
            <div class="flex-body body-scroll">
              
                <div class="container mx-auto">
          
                    <div class="py-4">
                        <div class="row">
                            <div class="col-12">
            
                                <div class="form-row">
            
                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-clients'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-building"></i></span>
                                                    <div class="module-tile-label">Clients</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
            
                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-tax-engine'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-money-bill"></i></span>
                                                    <div class="module-tile-label">Tax Engine</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
            
                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-pdf-forms'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-file-pdf"></i></span>
                                                    <div class="module-tile-label">PDF/YAML Forms</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
            
                                    <div class="col-12 col-ss-6 col-sm-4 col-lg-3">
                                        <router-link class="module-tile" :to="{name: 'admin-payrolls'}">
                                            <div class="module-tile-inner">
                                                <div class="module-tile-content">
                                                    <span class="module-tile-icon"><i class="fal fa-fw fa-file-invoice-dollar"></i></span>
                                                    <div class="module-tile-label">Payrolls</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                </div>
        
                            </div>
                        </div>
                    </div>
            
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Admin Tools')
    },
}
</script>
