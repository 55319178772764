<template>
    <table-page :client="client" :warnings="warnings" :changelogs="changelogs" @warnings_updated="getWarnings">
        <fast-table
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :managers="client.company_managers"
            @click="currentDocument = $event"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
        />

        <employee-sidebar
            :client="client"
            :employee-id="currentDocument.employee.id"
            v-if="currentDocument"
            @close="currentDocument = null"
            tab="misc" section="hr-documents" :item-id="currentDocument.id"
            @updated="refresh"
            :key="currentDocument.id"/>
    </table-page>
</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import TablePage from '@/components/TablePage'
import MoreActionsMenu from '@/components/MoreActionsMenu'

export default {
    props: ['client'],
    components: {FastTable, EmployeeSidebar, TablePage, MoreActionsMenu, },
    computed: {
        active() {
            return this.documents.filter((d) => {
                return (d.employee.employment_status == 'Active')
            })
        },
        inactive() {
            return this.documents.filter((d) => {
                return (d.employee.employment_status != 'Active')
            })
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.active,
                this.inactive,
            ]

            subsets.forEach(docs => {
                const sectionData = []

                docs.forEach((d, idx) => {
                    const row = {
                        id: idx,
                        isActive: this.currentDocument && d.id == this.currentDocument.id,
                        object: d,
                        cells: [
                            d.employee.full_name_sortable,
                            d.employee.employee_number,
                            d.employee.warning_count,
                            d.employee.company.name,
                            d.name,
                            d.version,
                            d.source_pretty,
                            d.created_on,
                            d.employer_signature_data.full_name,
                            d.employee.last_pay_date,
                            d.employee.email,
                            d.employee.ssn_last_4 ? `XXX-XX-${d.employee.ssn_last_4}` : '---',
                        ],
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            documents: [],
            dataLoaded: false,
            refreshing: false,
            currentDocument: null,
            dateRanges: {},

            headers: [
                {label: 'Employee', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Employee Number', classes: 'cell-medium', isSearchable: true},
                {label: 'Warnings', classes: 'cell-warning', isSearchable: false, type: 'warning', isFilterable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Name', classes: 'cell-medium', isSearchable: true, isFilterable: true},
                {label: 'Version', classes: 'cell-medium', isSearchable: false},
                {label: 'Source', classes: 'cell-medium', isSearchable: false, isFilterable: true},
                {label: 'Effective On', classes: 'cell-medium', isSearchable: false, type: 'date'},
                {label: 'Signed/Approved By', classes: 'cell-medium', isSearchable: false},
                {label: 'Last Pay Date', classes: 'cell-medium', isSearchable: false},
                {label: 'Email', classes: 'cell-email', isSearchable: true},
                {label: 'SSN', classes: 'cell-medium', isSearchable: true},
            ],
            sections: [
                {title: 'Active Employees', defaultIsClosed: false, id: 'active', hasDateFilter: false},
                {title: 'Inactive Employees', defaultIsClosed: true, id: 'inactive'},
            ],
            warnings: null,
            changelogs: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'HR Documents')
        this.getData()
        this.getWarnings()
        this.getChangelogs()
    },
    methods: {
        getData(silent) {
            if (!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }

            this.refreshing = true

            let url = `/clients/${this.client.id}/employee-hr-documents`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.$api.get(url).then(resp => {
                resp.forEach(d => {
                    d.company_security_profile_ids = d.employee.company_security_profile_ids
                })
                this.documents = resp
                this.dataLoaded = true
                this.refreshing = false
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
            }).catch((errors) => {
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.refreshing = false
                this.$bus.showError(errors.__all__)
            })
        },
        refresh() {
            if (this.refreshing) {return}
            this.getData(true)
            this.getWarnings()
            this.getChangelogs()
        },
        getWarnings() {
            this.warnings = null
            this.$api.get(`/clients/${this.client.id}/warnings`).then(resp => {
                this.warnings = resp.warnings.filter(w => {
                    if (w.employee) {
                        if (['i9-completed-late'].indexOf(w.warning_type) >= 0) {
                            return true
                        }
                    }
                })
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getChangelogs() {
            this.changelogs = []  // NB: This tile does not have a cohesive history table
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getData()
        },
    },
}

</script>
