<template>
    <!-- Warnings container -->
    <div v-if="dataLoaded">
        <!-- Errors -->
        <div v-if="dataLoaded && errors.length" class="scoreboard-group">
            <alert-box type="error" class="mb-0">
                <p class="mb-1">Failed to load warnings:</p>
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
            </alert-box>
        </div>

        <!-- Warnings -->
        <div v-if="warningTypes.length > 0" class="scoreboard-group">
            <h4 class="mb-2">
                <!-- TODO: Is the total count below correct?? -->
                Warnings to Review <span class="text-regular">({{ warnings.length }})</span>
            </h4>
            <div class="scoreboard scoreboard--warnings box-shadow-tile accordion" id="scoreboard_warnings">
                <div v-for="warningType in warningTypes" :key="warningType.type" class="scoreboard-item" v-if="dataLoaded && errors.length < 1">
                    <div class="scoreboard-item-main" data-toggle="collapse" :data-target="'#warnings-table-' + warningType.type" role="button" aria-expanded="false" :aria-controls="'warnings-table-' + warningType.type">
                        <span class="scoreboard-item-icon">
                            <span class="counter counter-warnings">
                                <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i>
                                <span class="counter-label">{{ warningsByType[warningType.type].length }}</span>
                            </span>
                        </span>
                        <span class="scoreboard-item-type">
                            {{ warningType.label }}
                        </span>
                        <span class="scoreboard-item-count">
                            <more-info>
                                <vue-markdown>{{ warningType.warning_info }}</vue-markdown>
                            </more-info>
                        </span>
                    </div>
                    <!-- Warning Detail table -->
                    <div class="scoreboard-item-detail collapse" :id="'warnings-table-' + warningType.type" data-parent="#scoreboard_warnings">
                        <div class="px-2 pb-1 pt-0 pt-md-1">
                            <table class="table table-sm table-flush-x table-rearrange-sm">
                                <thead>
                                    <tr>
                                        <th class="cell-date col-sortable col-sort-desc">
                                            Date
                                            <span class="sort-icon"></span>
                                        </th>
                                        <th class="cell-name col-sortable">
                                            <span v-if="warningsByType[warningType.type][0].employee">Employee</span>
                                            <span v-if="!warningsByType[warningType.type][0].employee && warningsByType[warningType.type][0].onboarding_application">Employee</span>
                                            <span v-if="warningsByType[warningType.type][0].company_security_profile">Security User</span>
                                            <span class="sort-icon"></span>
                                        </th>
                                        <th class="col-sortable">
                                            Company
                                            <span class="sort-icon"></span>
                                        </th>
                                        <th class="col-sortable text-md-center" v-if="warningsByType[warningType.type][0].is_resolvable">
                                            Resolved
                                            <span class="sort-icon"></span>
                                        </th>
                                        <th class="cell-actions text-md-right">
                                            <span class="sr-only">Actions</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="warning in warningsByType[warningType.type]" :key="warning.id">
                                        <td class="cell-date">
                                            <span class="btnlike-sm-alt">
                                                {{ warning.created_on|date }}
                                            </span>
                                        </td>
                                        <td class="cell-name">
                                            <span class="btnlike-sm-alt text-semibold" v-if="warning.employee">
                                                {{ warning.employee.full_name }}
                                            </span>
                                            <span class="btnlike-sm-alt text-semibold" v-if="!warning.employee && warning.onboarding_application">
                                                {{ warning.onboarding_application.full_name }}
                                            </span>
                                            <span class="btnlike-sm-alt text-semibold" v-if="warning.company_security_profile">
                                                {{ warning.company_security_profile.security_profile.full_name }}
                                            </span>
                                            <!-- Not a link (for now)
                                            <router-link class="btnlike-sm-alt text-semibold" v-if="warning.employee" :to="{name: '', params: {}}">{{ warning.employee.full_name }}</router-link>
                                            <router-link class="btnlike-sm-alt text-semibold" v-if="warning.onboarding_application" :to="{name: '', params: {}}">{{ warning.onboarding_application.full_name }}</router-link>
                                            -->
                                        </td>
                                        <td>
                                            <span class="btnlike-sm-alt">
                                                {{ warning.company.name }}
                                            </span>
                                        </td>
                                        <td class="text-md-center" v-if="warning.is_resolvable">
                                            <span class="btnlike-sm-alt text-muted" v-if="warning.resolved_on">
                                                <i class="far fa-fw fa-check"></i>
                                                <span class="d-md-none">Resolved</span>
                                            </span>

                                            <span class="date-value-overdue btnlike-sm-alt mt-0" v-if="!warning.resolved_on">
                                                No<span class="d-md-none">t Resolved</span>
                                            </span>
                                        </td>
                                        <td class="cell-actions text-md-right">
                                            <button type="button" class="btn btn-sm btn-outline-primary my-hf my-md-0" @click.prevent="dismiss(warning)">Dismiss</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
    props: ['client', ],
    components: {VueMarkdown, },
    computed: {
        user() {return this.$store.state.user},
        warningTypes() {
            const types = []
            const seen = new Set()
            this.warnings.forEach(w => {
                if (seen.has(w.warning_type)) {
                    return
                }
                seen.add(w.warning_type)
                types.push({
                    type: w.warning_type,
                    label: w.warning_type_pretty,
                    warning_info: w.warning_info,
                })
            })

            return types
        },
        warningsByType() {
            const result = {}
            this.warningTypes.forEach(t => {
                result[t.type] = []
            })

            this.warnings.forEach(w => {
                result[w.warning_type].push(w)
            })

            this.warningTypes.forEach(t => {
                result[t.type].sort((a, b) => {
                    return a.created_on > b.created_on ? 1 : -1
                })
            })

            return result
        },
    },
    data() {
        return {
            warnings: [],
            errors: [],
            dataLoaded: true,
        }
    },
    mounted() {
        this.getWarnings()
    },
    methods: {
        getWarnings() {
            const url = this.client ? `/clients/${this.client.id}/warnings/active` : '/warnings/active'

            return this.$api.get(url).then(resp => {
                this.warnings = resp
                this.dataLoaded = true
            }).catch(errors => {
                this.errors = errors
                this.dataLoaded = true
            })
        },
        dismiss(warning) {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${warning.client}/warnings/${warning.id}`, {acknowledge: true}).then(resp => {
                this.getWarnings().then(() => {
                    this.$store.dispatch('STOP_LOADING')
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors[0])
            })
        },
    },
}

</script>
