<template>
    <modal @close="$refs.form.reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">
        <template slot="title">Update Employee</template>

        <slot>
            <div>
                <hr-form ref="form" :employee="employee" :client="client" @updated="onApplicationsUpdated($event)" button-text="" />
            </div>
        </slot>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit">Update Employee</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import HrForm from './HRForm'

export default {
    mixins: [ModalMixin],
    components: {HrForm, },
    props: ['client', 'employee', ],
    watch: {
        client() {
            this.$refs.form.reset()
        }
    },
    methods: {
        onSubmit(evt) {
            this.$refs.form.onSubmit(evt)
        },
        onApplicationsUpdated(app) {
            this.$emit('updated', app)
            this.close()
        },
    }
}
</script>
