<template>
    <div v-if="client.id">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered-m py-4">

                        <h5 class="mb-3">
                            Activate Account Access:
                            <span class="text-nowrap">{{ client.name }}</span>
                        </h5>

                        <div v-if="mode == 'invalid'">
                            <h3>Cannot Find Your Record</h3>
                            <p>
                                We could not find a record to link your account. Please start over.
                            </p>
                            <p class="mt-2">
                                <router-link class="btn btn-link" :to="{name: 'index'}">Go Back</router-link>
                            </p>

                        </div>

                        <div v-if="mode == 'phone'">
                            <h3>Enter Your Phone Number</h3>
                            <p>Employees may contact you for help with security PIN resets. Please provide a phone number you wish for them to use.</p>
                            <form @submit.prevent="onPhoneNumberSubmit" novalidate class="mui-form">
                                <form-errors :errors="formErrors"/>
                                <div class="form-row">
                                    <div class="col-6 col-sm-5">
                                        <form-input label="Phone Number" v-model="phoneFormData.phone" :errors="errors.phone" type="phone" />
                                    </div>
                                    <div class="col-4 col-sm-3">
                                        <form-input label="Ext. (Optional)" v-model="phoneFormData.extension" :errors="errors.extension" type="integer-string" />
                                    </div>
                                </div>
                                <p>
                                    Next, we will verify this phone number via text message or an automated voice call.
                                </p>
                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <router-link class="btn btn-outline-primary" :to="{name: 'index'}">Exit Setup</router-link>
                                    </div>
                                    <div class="col text-right">
                                        <button type="submit" class="btn btn-primary">Continue</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-if="mode == 'contact_method'">
                            <h3>Verify Your Phone Number</h3>
                            <p>
                                We will now send a verification code to the phone number you provided:
                            </p>
                            <p class="text-large text-muted">
                                {{ phoneFormatted }}
                            </p>
                            <form @submit.prevent="onContactMethodSubmit" novalidate class="mui-form">
                                <form-errors :errors="formErrors"/>
                                <div class="form-row">
                                    <div class="col-12">
                                        <form-input label="How do you want to get your code?" v-model="contactMethodFormData.contact_method" type="radio" :options="contactMethodOptions"/>
                                    </div>
                                </div>
                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <button class="btn btn-outline-primary" @click.prevent="setMode('phone')">Back</button>
                                    </div>
                                    <div class="col text-right">
                                        <button type="submit" class="btn btn-primary">Get Code</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-if="mode == 'code'">
                            <h3>Enter Your Verification Code</h3>
                            <form @submit.prevent="onPhoneValidationSubmit" novalidate class="mui-form">
                                <form-errors :errors="formErrors"/>
                                <p v-if="contactMethodFormData.contact_method == 'sms'">
                                    Enter the code you received via text message:
                                </p>
                                <p v-if="contactMethodFormData.contact_method == 'call'">
                                    Enter the code you received via voice call:
                                </p>
                                <div class="form-row">
                                    <div class="col-6">
                                        <form-input placeholder="Verification Code" v-model="phoneValidationFormData.verification_code" :errors="errors.verification_code" type="tel" pattern="[0-9]*" />
                                    </div>
                                </div>
                                <p class="text-small text-muted">
                                    Didn't receive the code? Click Back to try again.
                                </p>
                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <button class="btn btn-outline-primary" @click.prevent="setMode('contact_method')">Back</button>
                                    </div>
                                    <div class="col text-right">
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-if="mode == 'pin'">
                            <h3>Create a Secret PIN</h3>

                            <form @submit.prevent="onPINSubmit" novalidate class="mui-form">
                                <form-errors :errors="formErrors"/>

                                <p>If you forget your password, you'll use this PIN to reset it.</p>

                                <div class="form-row">
                                    <div class="col-6 col-md-4">
                                        <form-input placeholder="Enter a PIN" v-model="pinFormData.pin" :errors="errors.pin" type="tel" pattern="[0-9]*" maxlength="6" />
                                    </div>
                                    <div class="col-12 field-helper-text">
                                        <span class="password-strength-message">PIN must be a number with 4 to 6 digits.</span>
                                    </div>
                                </div>

                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <button class="btn btn-outline-primary" @click.prevent="setMode('phone')">Back</button>
                                    </div>
                                    <div class="col text-right">
                                        <button type="submit" class="btn btn-primary">Continue</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-if="mode == 'recovery-word'">
                            <h3>Create a recovery word</h3>

                            <form @submit.prevent="onRecoveryWordSubmit" novalidate class="mui-form">
                                <form-errors :errors="formErrors"/>

                                <p>If you lose your account info and cannot log in, your account administrator can help you recover it. Before resetting your PIN, you will be asked to say this secret recovery word.</p>

                                <div class="form-row">
                                    <div class="col">
                                        <form-input placeholder="Enter a recovery word" v-model="recoveryWordFormData.recovery_word" :errors="errors.recovery_word" type="text"/>
                                    </div>
                                    <div class="col-12 field-helper-text">
                                        <span class="password-strength-message">This can be any word. You won't be asked to spell it, only to say it aloud.</span>
                                    </div>
                                </div>

                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <button class="btn btn-outline-primary" @click.prevent="setMode('pin')">Back</button>
                                    </div>
                                    <div class="col text-right">
                                        <button type="submit" class="btn btn-primary">Continue</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-if="mode == 'complete'">
                            <div class="">
                                <h1 class="mb-1">Setup Complete <i class="fas fa-check text-primary ml-hf"></i></h1>

                                <p class="mt-1">
                                    You now have access to management features for this account.
                                </p>

                                <p class="mt-8">
                                    <router-link class="btn btn-primary" :to="{name: 'employer-home', params: {clientId: client.id}}">Continue to Account Home</router-link>
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'

import api from '@/api'
import bus from '@/bus'

const contactMethodOptions = [
    {text: 'Text message (SMS)', value: 'sms'},
    {text: 'Automated voice call', value: 'call'},
]

export default {
    mixins: [FormMixin],
    computed: {
        contactMethodOptions() {return contactMethodOptions},
        phoneFormatted() {
            let p = this.phoneFormData.phone
            let val = '(' + p.substring(0, 3) + ') ' + p.substring(3, 6) + '-' + p.substring(6, 10)
            if (this.phoneFormData.extension) {
                val = val + ' x' + this.phoneFormData.extension
            }
            return val
        }
    },
    mounted() {
        this.$store.dispatch('GET_USER')
        this.getClient()
    },
    data() {
        return {
            client: {},
            mode: '',
            phoneFormData: {
                phone: '',
                extension: '',
            },
            contactMethodFormData: {
                contact_method: '',
            },
            phoneValidationFormData: {
                verification_code: '',
            },

            pinFormData: {
                pin: '',
            },
            recoveryWordFormData: {
                recovery_word: '',
            },

            phoneFormRules: {
                phone: {presence: {allowEmpty: false}},
            },
            pinFormRules: {
                pin: {
                    presence: {allowEmpty: false},
                    length: {
                        minimum: 4,
                        maximum: 6,
                    }
                },
            },
            recoveryWordFormRules: {
                recovery_word: {
                    presence: {allowEmpty: false},
                    length: {
                        minimum: 6,
                        maximum: 128,
                    }
                },
            },
            contactMethodFormRules: {
                contact_method: {presence: {allowEmpty: false}},
            },
            phoneValidationFormRules: {
                verification_code: {
                    presence: {allowEmpty: false},
                },
            },
        }
    },
    methods: {
        getFormRules() {
            switch (this.mode) {
                case 'phone':
                    return this.phoneFormRules;
                case 'contact_method':
                    return this.contactMethodRules;
                case 'pin':
                    return this.pinFormRules;
                case 'recovery-word':
                    return this.recoveryWordFormRules;
                case 'code':
                    return this.phoneValidationFormRules;
                default:
                    return {}
            }
        },
        getFormData() {
            switch (this.mode) {
                case 'phone':
                    return this.phoneFormData;
                case 'contact_method':
                    return this.contactMethodFormData;
                case 'pin':
                    return this.pinFormData;
                case 'recovery-word':
                    return this.recoveryWordFormData;
                case 'code':
                    return this.phoneValidationFormData;
                default:
                    return {}
            }
        },
        setMode(mode) {
            this.mode = mode
        },
        onPhoneNumberSubmit() {
            this.errors = {}
            this.formErrors = []
            if (!this.validate()) {
                return
            }

            this.mode = 'contact_method'
            if (this.phoneFormData.extension) {
                this.contactMethodFormData.contact_method = 'call'
            } else {
                this.contactMethodFormData.contact_method = 'sms'
            }
        },
        onContactMethodSubmit() {
            this.errors = {}
            this.formErrors = []
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            const mergedFormData = {...this.phoneFormData, ...this.contactMethodFormData}
            api.post(`/me/clients/${this.client.id}/link/phone`, mergedFormData).then((resp) => {
                this.mode = 'code'
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        onPhoneValidationSubmit() {
            this.errors = {}
            this.formErrors = []
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/me/clients/${this.client.id}/link/phone-validation`, this.phoneValidationFormData).then((resp) => {
                if (resp.requires_pin) {
                    this.mode = 'pin'
                }
                else {
                    this.mode = 'complete'
                }
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        onPINSubmit() {
            this.errors = {}
            this.formErrors = []
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/me/clients/${this.client.id}/link/set-pin`, this.pinFormData).then(() => {
                this.mode = 'recovery-word'
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        onRecoveryWordSubmit() {
            this.errors = {}
            this.formErrors = []
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/me/clients/${this.client.id}/link/set-recovery-word`, this.recoveryWordFormData).then(()=> {
                this.mode = 'complete'
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        getClient() {
            this.$store.dispatch('START_LOADING')
            api.get(`/me/clients/${this.$route.params.clientId}/link`).then((resp) => {
                this.client = resp.client
                this.mode = 'phone'
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                bus.showError(errors['__all__'][0])
                this.$store.dispatch('STOP_LOADING')
            })
        }
    },
}
</script>
