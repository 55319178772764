<template>
    <aside class="drawer drawer-rowdetail drawer-right collapse no-transition show">

        <div class="drawer-header">
            <div class="drawer-close">
                <button type="button" class="close" aria-label="Close" role="button" aria-expanded="true" @click.prevent="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row">
                <div class="col">
                    <h2 class="drawer-title">
                        <span class="drawer-title-text">
                            <slot name="title"></slot>
                        </span>
                        <slot name="subtitle"></slot>
                    </h2>
                </div>
            </div>

            <slot name="header"></slot>
        </div>

        <div class="drawer-body flex-container" ref="body">
            <slot></slot>
        </div>
    </aside>
</template>

<script>
import $ from 'jquery'

export default {
    methods: {
        close() {
            this.$emit('close')
        },
        scrollTop(offset) {
            this.$nextTick(() => {
                $(this.$refs.body).scrollTop(offset)
            })
        },
    },
}
</script>

