<template>
    <div>
        <div>
          
          <div class="row">
            <!-- Left column -->
            <div class="col-12 col-md-4">
              
              <div class="pt-2 border-top">
              
              <div class="row">
                <div class="col-12">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">a</strong>
                      Employee's social security number
                    </label>
                    <div class="mui-value-readonly">
                      {{ tax_form.html_form_data.box_a }}
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">b</strong>
                      Employer identification number (EIN)
                    </label>
                    <div class="mui-value-readonly">
                      {{ tax_form.html_form_data.box_b }}
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">c</strong>
                      Employer's name, address and ZIP code
                    </label>
                    <div class="mui-value-readonly">
                      <div>{{ tax_form.html_form_data.box_c_employer_name }}</div>
                      <div>{{ tax_form.html_form_data.box_c_delivery_address }}</div>
                      <div>
                        {{ tax_form.html_form_data.box_c_city }}, {{ tax_form.html_form_data.box_c_state }} {{ tax_form.html_form_data.box_c_zip_code }}
                      </div>
                      <div>
                        {{ tax_form.html_form_data.box_c_foreign_state_province }}
                        {{ tax_form.html_form_data.box_c_foreign_postal_code }}
                        {{ tax_form.html_form_data.box_c_country_code }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">d</strong>
                      Control number
                    </label>
                    <div class="mui-value-readonly">
                        {{ tax_form.html_form_data.box_d }}
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">e</strong>
                      Employee's name
                    </label>
                    <div class="mui-value-readonly">
                      {{ tax_form.html_form_data.box_e_first_name }}
                      {{ tax_form.html_form_data.box_e_middle_initial }}
                      {{ tax_form.html_form_data.box_e_last_name }}
                      {{ tax_form.html_form_data.box_e_suffix }}
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">f</strong>
                      Employee's address and ZIP code
                    </label>
                    <div class="mui-value-readonly">
                      <div>{{ tax_form.html_form_data.box_f_delivery_address }}</div>
                      <div>
                        {{ tax_form.html_form_data.box_f_city }}, {{ tax_form.html_form_data.box_f_state }} {{ tax_form.html_form_data.box_f_zip_code }}
                      </div>
                      <div>
                        {{ tax_form.html_form_data.box_f_foreign_state_province }}
                        {{ tax_form.html_form_data.box_f_foreign_postal_code }}
                        {{ tax_form.html_form_data.box_f_country_code }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>
              
            </div>
            <!-- Right column -->
            <div class="col-12 col-md-8">
              
              <div class="pt-2 border-top">
              
              <!-- Inner 2-column rows-->
              
              <div class="row">
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">1</strong>
                      Wages, tips, other compensation
                    </label>
                    <div class="mui-value-readonly">
                      <span v-if="tax_form.html_form_data.box_1">$</span>{{ tax_form.html_form_data.box_1 }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">2</strong>
                      Federal income tax withheld
                    </label>
                    <div class="mui-value-readonly">
                      <span v-if="tax_form.html_form_data.box_2">$</span>{{ tax_form.html_form_data.box_2 }}
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">3</strong>
                      Social security wages
                    </label>
                    <div class="mui-value-readonly">
                      <span v-if="tax_form.html_form_data.box_3">$</span>{{ tax_form.html_form_data.box_3 }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">4</strong>
                      Social security tax withheld
                    </label>
                    <div class="mui-value-readonly">
                      <span v-if="tax_form.html_form_data.box_4">$</span>{{ tax_form.html_form_data.box_4 }}
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">5</strong>
                      Medicare wages and tips
                    </label>
                    <div class="mui-value-readonly">
                      <span v-if="tax_form.html_form_data.box_5">$</span>{{ tax_form.html_form_data.box_5 }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">6</strong>
                      Medicare tax withheld
                    </label>
                    <div class="mui-value-readonly">
                      <span v-if="tax_form.html_form_data.box_6">$</span>{{ tax_form.html_form_data.box_6 }}
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">7</strong>
                      Social security tips
                    </label>
                    <div class="mui-value-readonly">
                      <span v-if="tax_form.html_form_data.box_7">$</span>{{ tax_form.html_form_data.box_7 }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">8</strong>
                      Allocated tips
                    </label>
                    <div class="mui-value-readonly">
                      <span v-if="tax_form.html_form_data.box_8">$</span>{{ tax_form.html_form_data.box_8 }}
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">9</strong>
                    </label>
                    <div class="mui-value-readonly">
                      &mdash;
                    </div>
                  </div>
                </div>
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">10</strong>
                      Dependent care benefits
                    </label>
                    <div class="mui-value-readonly">
                      <span v-if="tax_form.html_form_data.box_10">$</span>{{ tax_form.html_form_data.box_10 }}
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">11</strong>
                      Nonqualified plans
                    </label>
                    <div class="mui-value-readonly">
                      <span v-if="tax_form.html_form_data.box_11">$</span>{{ tax_form.html_form_data.box_11 }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">12</strong>
                    </label>
                    <div class="mui-value-readonly">
                        <div v-for="box_12 in tax_form.html_form_data.box_12">
                            {{ box_12.code }} - ${{ box_12.data }}
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">13</strong>
                    </label>
                    <div class="mui-value-readonly">
                      <div>
                        <i class="far fa-fw" v-bind:class="{ 'fa-check-square': tax_form.html_form_data.box_13_a, 'fa-square': !tax_form.html_form_data.box_13_a }"></i> Statutory employee
                      </div>
                      <div>
                        <i class="far fa-fw" v-bind:class="{ 'fa-check-square': tax_form.html_form_data.box_13_b, 'fa-square': !tax_form.html_form_data.box_13_b }"></i> Retirement plan
                      </div>
                      <div>
                        <i class="far fa-fw" v-bind:class="{ 'fa-check-square': tax_form.html_form_data.box_13_c, 'fa-square': !tax_form.html_form_data.box_13_c }"></i> Third-party sick pay
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-rs-6">
                  <div class="mui-textfield mui-textfield-readonly">
                    <label>
                      <strong class="mr-hf">14</strong>
                      Other
                    </label>
                    <div class="mui-value-readonly">
                      <div v-for="box_14 in tax_form.html_form_data.box_14">
                          {{ box_14.code }} - ${{ box_14.data }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>
              
            </div>
          </div>
          
          <div class="row" v-for="rs_rec in tax_form.html_form_data.rs_recs">
            <div class="col-12">
              
              <div class="d-flex flex-wrap pt-2 border-top">
                <!-- Boxes 15-17 -->
                <div class="d-flex flex-wrap mr-1">
                  <div class="w-15r mr-1">
                    <div class="mui-textfield mui-textfield-readonly">
                      <label>
                        <strong class="mr-hf">15</strong>
                        State - Employer's state ID number
                      </label>
                      <div class="mui-value-readonly">
                        {{ rs_rec.RS_N_box_15_state }}
                        <span v-if="rs_rec.RS_N_box_15_state_id">
                            -
                        </span>
                        {{ rs_rec.RS_N_box_15_state_id }}
                      </div>
                    </div>
                  </div>
                  <div class="w-10r mr-1">
                    <div class="mui-textfield mui-textfield-readonly">
                      <label>
                        <strong class="mr-hf">16</strong>
                        State wages, tips, etc.
                      </label>
                      <div class="mui-value-readonly">
                        <span v-if="rs_rec.RS_N_box_16">$</span>{{ rs_rec.RS_N_box_16 }}
                      </div>
                    </div>
                  </div>
                  <div class="w-10r mr-1">
                    <div class="mui-textfield mui-textfield-readonly">
                      <label>
                        <strong class="mr-hf">17</strong>
                        State income tax
                      </label>
                      <div class="mui-value-readonly">
                        <span v-if="rs_rec.RS_N_box_17">$</span>{{ rs_rec.RS_N_box_17 }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Boxes 18-20 -->
                <div class="d-flex flex-wrap mr-1">
                  <div class="w-10r mr-1">
                    <div class="mui-textfield mui-textfield-readonly">
                      <label>
                        <strong class="mr-hf">18</strong>
                        Local wages, tips, etc.
                      </label>
                      <div class="mui-value-readonly">
                        <span v-if="rs_rec.RS_N_box_18">$</span>{{ rs_rec.RS_N_box_18 }}
                      </div>
                    </div>
                  </div>
                  <div class="w-10r mr-1">
                    <div class="mui-textfield mui-textfield-readonly">
                      <label>
                        <strong class="mr-hf">19</strong>
                        Local income tax
                      </label>
                      <div class="mui-value-readonly">
                        <span v-if="rs_rec.RS_N_box_19">$</span>{{ rs_rec.RS_N_box_19 }}
                      </div>
                    </div>
                  </div>
                  <div class="w-10r mr-1">
                    <div class="mui-textfield mui-textfield-readonly">
                      <label>
                        <strong class="mr-hf">20</strong>
                        Locality name
                      </label>
                      <div class="mui-value-readonly">
                        {{ rs_rec.RS_N_box_20 }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          
        </div>

    </div>
</template>

<script>
export default {
    props: ['tax_form', ],
}
</script>
