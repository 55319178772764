<template>
    <modal @close="reset(); $emit('close')" ref="modal" :is-closeable="isPristine" size="xl">
        <template #title>I-9 Employment Eligibility Verification</template>
        <slot>
            <div v-show="mode == 'docs'" class="container">
                <div class="i9-pdf-container" v-if="pdf && pdf.src">
                    <pdf :src="pdf.src" :page="1" class="border-bottom w-100" />
                </div>

                <div class="bg-150 my-2">
                    <h3>
                        <div class="font-weight-normal">
                            USCIS Form I-9
                        </div>
                        <div>
                            Employment Eligibility Verification
                        </div>
                    </h3>
                    <p class="small mb-0">
                    Department of Homeland Security<br>
                    U.S. Citizenship and Immigration Services
                    </p>
                </div>

                <h6>Section 2. Employer or Authorized Representative Review and Verification</h6>

                <p class="small">
                <em>(Employers or their authorized representative must complete and 
                    sign Section 2 within 3 business days of the employee's first day of 
                    employment. You must physically examine one document from List A OR 
                    a combination of one document from List B and one document from List 
                    C as listed on the "Lists of Acceptable Documents.")</em>
                </p>

                <div class="form-row">
                    <div class="col-12 col-lg-2">
                        <strong>Employee Info from Section 1</strong>
                    </div>

                    <div class="col-12 col-lg-3">
                        <form-input :disabled="true" type="text" label="Last Name (Family Name)" :value="i9Form.form_data.last_name" />
                    </div>

                    <div class="col-12 col-lg-3">
                        <form-input :disabled="true" type="text" label="First Name (Given Name)" :value="i9Form.form_data.first_name" />
                    </div>

                    <div class="col-12 col-lg-1">
                        <form-input :disabled="true" type="text" label="M.I." :value="i9Form.form_data.middle_initial" />
                    </div>

                    <div class="col-12 col-lg-3">
                        <form-input :disabled="true" type="text" label="Citizenship/Immigration Status" :value="immigrationStatusNumber" />
                    </div>
                </div>

                <form class="mui-form my-4">
                    <div class="form-row">
                        <div class="col-12 col-lg-4">
                            <div class="text-center">
                                <h3>List A</h3>
                                <h5>Identity and Employment Authorization</h5>
                            </div>

                            <i9-doc :documents="documents.listA" :immigration-status="immigrationStatus" :errors="errors.listA" v-model="formData.docA" ref="i9DocA">
                                <template #document-title-help>
                                    <p>If the employee presented documentation from <a target="_blank" href="https://www.uscis.gov/i-9-central/acceptable-documents/list-documents/form-i-9-acceptable-documents">List A</a>, select the document or receipt presented from the drop-down list provided. If the employee presented a List A document that consists of a combination of documents, select the first document from the drop-down list provided. The other documents in the combination should be entered in the separate areas provided.</p>

                                    <p>All documents must be unexpired. Ensure that each document is an unexpired, original (no photocopies, except for certified copies of birth certificates) document. Certain employees may present an expired employment authorization document, which may be considered unexpired, if the employee's employment authorization has been extended by regulation or a Federal Register Notice. Refer to the instructions for more information.</p>

                                    <p>The documents below the dotted line in the drop-down are unacceptable for the citizenship or immigration status the employee selected in Section 1. If you receive an error message after selecting one of these documents, ensure that you selected the correct number in the Citizenship/Immigration Status field that correlates to the citizen or immigration status box the employee checked in Section 1. If this area was automatically populated, have the employee review the information he or she entered on the printed and signed copy of Section 1, make any required corrections to Section 1, then initial and date those corrections. You may then correct the number in the Employee info field, which should provide the correct list of acceptable documents.</p>

                                    <p>If the employee did not present a List A combination document, the second and third Document Title fields will contain N/A.</p>

                                    <p>An entry in this column allows all fields in the List B and C columns to contain N/A. If you did this in error, select a document from the drop-down list provided in List B. All fields in the List A column will then contain N/A, and you will then be able to complete the List B and List C columns.</p>
                                </template>

                                <template #issuing-authority-help>
                                    <p>Select from the drop-down list the issuing authority of the List A document or receipt you entered in the first Document Title field.</p>

                                    <p>The issuing authority is the entity that issued the document.</p>

                                    <p>If the employee presented a List A document that consists of a combination of documents, use the second and third Issuing Authority fields as necessary.</p>

                                    <p>If the issuing authority provided in the drop-down list does not match the issuing authority shown on the document presented, ensure that you made the correct selection in the Document Title field.</p>
                                </template>

                                <template #document-number-help>
                                    <p>Enter the document number, if any, exactly as it appears on the document you entered in the First Document Title field.</p>

                                    <p>If you chose a receipt in the Document Title field, you should enter the receipt number, if any, exactly as it appears on the receipt.</p>

                                    <p>If the document does not contain a number, enter N/A.</p>

                                    <p>If the employee presented a combination of documents, use the second and third Document Number fields as necessary.</p>
                                </template>

                                <template #exp-help>
                                    <p>Enter the expiration date, if any, of the document or receipt you entered in the first Document Title field. A document is not acceptable if it has already expired. An unexpired document includes a document where the expiration date shown on the face of the document has been automatically extended, such as for individuals with Temporary Protected Status.</p>

                                    <p>If the document uses text rather than a date to indicate when it expires, enter the text shown on the document, such as &quot;D/S&quot; (which means, &quot;duration of status&quot;). If the document does not contain an expiration date, enter N/A. If the employee presented a combination of documents, use the second and third Expiration Date fields as necessary. For a receipt, enter the expiration date of the receipt validity period.</p>

                                    <ol>
                                        <li>For a receipt showing that the employee has applied to replace a document that was lost, stolen or damaged, enter the date that is 90 days from the first day of work for pay.</li>
                                        <li>For a receipt that is the arrival portion of Form I-94/I-94A containing a temporary I-551 stamp and a photograph of the individual, enter the expiration date of the stamp or, if there is no expiration date, within one year from the date of admission.</li>
                                        <li>For a receipt that is the departure portion of Form I-94/I-94A with a refugee admission stamp, enter a date that is 90 days from the first day of work for pay.</li>
                                    </ol>
                                </template>
                            </i9-doc>
                        </div>

                        <div class="col-12 col-lg-4">
                            <div class="text-center">
                                <h3>List B</h3>
                                <h5>Identity</h5>
                            </div>

                            <i9-doc :documents="documents.listB" :immigration-status="immigrationStatus" :errors="errors.listB" v-model="formData.docB" ref="i9DocB">
                                <template #document-title-help>
                                    <p>If the employee presented an acceptable document or an acceptable receipt for a <a target="_blank" href="https://www.uscis.gov/i-9-central/acceptable-documents/list-documents/form-i-9-acceptable-documents?topic_id=1&t=b">List B</a> document, select the document or receipt presented from the drop-down list provided. All documents must be unexpired. If you make an entry in this column, you must also make an entry in the List C column.</p>

                                    <p>If an employer participates in E-Verify and the employee presents a List B document, the List B document must include a photograph.</p>

                                    <p>If a parent or legal guardian attests either to the identity of an employee who is a <a target="_blank" href="https://www.uscis.gov/i-9-central/acceptable-documents/acceptable-documents">minor</a> (individual under age 18) who cannot present an identity document or to the identity of certain <a target="_blank" href="https://www.uscis.gov/i-9-central/acceptable-documents/acceptable-documents">employees with disabilities</a> in Section 1, select either &quot;minor under age 18&quot; (for a minor) or &quot;special placement&quot; (for an employee with disabilities) from the drop-down provided. Refer to the instructions and the Handbook for Employers: Guidance for Completing Form I-9 (M-274) for more guidance on minors and certain persons with disabilities.</p>

                                    <p>The documents below the dotted line in the drop-down are unacceptable for the citizenship or immigration status the employee selected in Section 1. If you receive an error message after selecting one of these documents, ensure that you selected the correct number in the Citizenship/Immigration Status field that correlates to the citizen or immigration status box the employee checked in Section 1. If this area was automatically populated, have the employee review the information he or she entered on the printed and signed copy of Section 1, make any required corrections to Section 1, then initial and date those corrections. You may then correct the number in the Employee info field, which should provide the correct list of acceptable documents.</p>

                                    <p>An entry in this column allows all fields in the List A column to contain N/A. If you did this in error, select a document in the List A drop-down. This field column and the List C column will then contain N/A. You may then complete the List A column.</p>
                                </template>

                                <template #issuing-authority-help>
                                    <p>Select from the drop-down list the issuing authority of the document or receipt you entered in the Document Title field in this column. The issuing authority is the entity that issued the document or receipt.</p>

                                    <p>If you selected a document or receipt issued by a state in the Document Title field, select the state or territory that issued the document or receipt from the drop-down list provided.</p>

                                    <p>If no drop-down is provided for a particular selection, enter the issuing authority exactly as it appears on the document or receipt.</p>

                                    <p>If the issuing authority provided in the drop-down list does not match the issuing authority shown on the document or receipt presented, ensure that you made the correct selection in the Document Title field.</p>
                                </template>

                                <template #document-number-help>
                                    <p>Enter the document number, if any, exactly as it appears on the document you entered in the Document Title field. If the document has no number, enter N/A.</p>

                                    <p>If you chose a receipt in the Document Title field, you should enter the receipt number, if any, exactly as it appears on the receipt.</p>
                                </template>

                                <template #exp-help>
                                    <p>Enter the expiration date, if any, of the document or receipt you entered above. The document is not acceptable if it is already expired. If the document or receipt has no expiration date, enter N/A.</p>

                                    <p>However, for a receipt showing that the employee has applied to replace a document that was lost stolen or damaged, enter the expiration date of the receipt validity period which is 90 days from the first day of work for pay.</p>
                                </template>
                            </i9-doc>
                        </div>

                        <div class="col-12 col-lg-4">
                            <div class="text-center">
                                <h3>List C</h3>
                                <h5>Employment Authorization</h5>
                            </div>

                            <i9-doc :documents="documents.listC" :immigration-status="immigrationStatus" :errors="errors.listC" v-model="formData.docC" ref="i9DocC">
                                <template #document-title-help>
                                    <p>If the employee presented an acceptable document from <a target="_blank" href="https://www.uscis.gov/i-9-central/acceptable-documents/list-documents/form-i-9-acceptable-documents?topic_id=1&t=c">List C</a> or an acceptable receipt for a List C document, select the document or receipt presented from the drop-down list provided. </p>

                                    <p>All documents must be unexpired. An unexpired document includes a document where the expiration date shown on the face of the document has been extended, such as conditional residents who show an extended conditional resident card with a Form I-797 as a List C document.</p>

                                    <p>If you chose DHS-issued employment authorized or Receipt DHS-issued employment authorization from the drop-down list provided in the Document Title field, this field will contain the words ""List C #7."" after which you should enter the title of the document. Abbreviations are acceptable.</p>

                                    <p>If you make an entry in this column, you must also make an entry in the List B column, if you have not already done so.</p>

                                    <p>An entry in this column allows all fields in the List A column to contain N/A. If you did this in error, select N/A from the drop-down list provided. You will then be able to select a document in a different column field.</p>

                                    <p>The documents below the dotted line in the drop-down do not match the citizenship or immigration status the employee selected in Section 1. If you receive an error message after selecting one of these documents, ensure that you selected the correct number in the Citizenship/Immigration Status field that correlates to the citizen or immigration status box the employee selected in Section 1 as noted on the employee's printed and signed copy of Section 1. If this area was automatically populated, have the employee review the information he or she entered on the printed and signed copy of Section 1, make any required corrections to Section 1, then initial and date those corrections. You may then correct the number in the Employee Info field, which should provide the correct list of acceptable documents.</p>

                                    <p>An entry in this column allows all fields in the List A column to contain N/A. If you did this in error, select a document in the List A drop-down. This column and the List B column will then contain N/A. You may then complete the List A column.</p>
                                </template>

                                <template #issuing-authority-help>
                                    <p>Select from the drop-down list the issuing authority of the document or receipt you entered in the Document Title field in this column. The issuing authority is the entity that issued the document or receipt.</p>

                                    <p>If an issuing authority drop-down list is not provided, enter the issuing authority exactly as it appears on the document or receipt you entered in the Document Title field in this column.</p>

                                    <p>If the issuing authority provided in this field does not match the issuing authority shown on the document or receipt presented, ensure that you made the correct selection in the Document Title field in this column.</p>
                                </template>

                                <template #document-number-help>
                                    <p>Enter the document number in this field as found on the document you entered in the Document Title field in this column. If the document does not contain a number, select N/A.</p>

                                    <p>If you choose a receipt in the Document Title field in this column, you should enter the receipt number, if any, exactly as it appears on the receipt.</p>
                                </template>

                                <template #exp-help>
                                    <p>Enter the expiration date, if any, of the document or receipt you selected above. If the document or receipt has no expiration date, enter N/A. The document is not acceptable if it has already expired, unless USCIS has extended the expiration date on the document.</p>

                                    <p>For instance, if a conditional resident presents an expired Form I-551 in combination with a Form-797 extending his or her conditional resident status with the employee's expired Form I-551, enter the future expiration date as indicated on the Form I-797.</p>

                                    <p>For a receipt, enter the expiration date of the receipt validity period.</p>

                                    <ol>
                                        <li>For a receipt showing that the employee has applied to replace a document that was lost, stolen or damaged, enter the date that is 90 days from the first day of work for pay.</li>
                                        <li>For a receipt that is the arrival portion of Form I-94/I-94A containing a temporary I-551 stamp and a photograph of the individual, enter the expiration date of the stamp or, if there is no expiration date, within one year from the date of admission.</li>
                                        <li>For a receipt that is the departure portion of Form I-94/I-94A with a refugee admission stamp, enter a date that is 90 days from the first day of work for pay.</li>
                                    </ol>
                                </template>
                            </i9-doc>
                        </div>

                    </div>

                    <h5>Additional Information</h5>
                    <div class="form-row">
                        <div class="col-12 col-lg-6">
                            <form-input type="textarea" label="Additional Information" v-model="formData.additional_info">
                                <template #popover-helper>
                                    <p>Use this space to notate any additional information required to From I-9, such as:</p>

                                    <ul>
                                        <li>Employment authorization extensions for Temporary Protected Status beneficiaries, F-1 OPT STEM students, CAP-GAP, H-1B and H2-A employees continuing employment with the same employer or changing employers, and other nonimmigrant categories that may receive extensions of stay.</li>
                                        <li>Additional document(s) that certain nonimmigrant employees may present that cannot be entered in the drop-downs provided.</li>
                                        <li>Discrepancies that E-Verify employers must notate when participating in the IMAGE program.</li>
                                        <li>Employee termination dates and form retention dates.</li>
                                        <li>E-Verify case number, which may also be entered in the margin or attached as a separate sheet per E-Verify requirements and your chosen business process.</li>
                                        <li>Any other comments or notations necessary for the employer's business process.</li>
                                    </ul>

                                    <p>You may leave this field blank if the employee's circumstances do not require additional notations.</p>
                                </template>
                            </form-input>
                        </div>
                        <div class="col-12 col-lg-6">
                            <form-input type="date" label="The employee's first day of employment" placeholder="mm/dd/yyyy" v-model="formData.date_hired" help-text="(See instructions for exemptions)" :errors="errors.date_hired">
                                <template #popover-helper>
                                    <p>Enter the employee's first day of employment as a 2-digit month, 2-digit day and 4-digit year (mm/dd/yyyy).</p>

                                    <p>Recruiters and recruiters for a fee do not enter the employee's first day of employment</p>
                                </template>
                            </form-input>
                        </div>
                    </div>


                    <div class="form-row">
                        <div class="col-12 col-md-6">
                            <form-input type="text" label="Title of Employer or Authorized Representative" v-model="formData.manager_title" :errors="errors.manager_title">
                                <template #popover-helper>
                                    <p>Enter the title, position or role of the person who physically examines the employee's original document(s), completes, and signs Section 2.</p>
                                </template>
                            </form-input>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-12 col-md-6">
                            <form-input type="text" label="Last Name of Employer or Authorized Representative" v-model="formData.manager_last_name" :errors="errors.manager_last_name">
                                <template #popover-helper>
                                    <p>Enter the full legal last name of the person who physically examines the employee's original documents, completes and signs Section 2.</p>

                                    <p>Last name refers to family name or surname. If the person has two last names or a hyphenated last name, include both names.</p>
                                </template>
                            </form-input>
                        </div>

                        <div class="col-12 col-md-6">
                            <form-input type="text" label="First Name of Employer or Authorized Representative" v-model="formData.manager_first_name" :errors="errors.manager_first_name">
                                <template #popover-helper>
                                    <p>Enter the full legal first name of the person who physically examines the employee's original documents, completes and signs Section 2.</p>

                                    <p>First name refers to the given name</p>
                                </template>
                            </form-input>
                        </div>

                        <div class="col-12 col-md-6">
                            <form-input type="text" label="Employer's Business or Organization Name" v-model="formData.employer_name" :errors="errors.employer_name">
                                <template #popover-helper>
                                    <p>Enter the name of the employer's business or organization.</p>
                                </template>
                            </form-input>
                        </div>

                        <div class="col-12 col-md-8">
                            <form-input type="text" label="Employer's Business or Organization Address (Street Number and Name)" v-model="formData.employer_address_line_1" :errors="errors.employer_address_line_1">
                                <template #popover-helper>
                                    <p>Enter an actual, physical address of the employer. If your company has multiple locations, use the most appropriate address that identifies the location of the employer. Do not provide a P.O. Box address.</p>
                                </template>
                            </form-input>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-12 col-md-6">
                            <form-input type="text" label="City or Town" v-model="formData.employer_city" :errors="errors.employer_city">
                                <template #popover-helper>
                                    <p>Enter the city or town for the Employer's Business or Organization Address. If the location is not in a city or town, you may enter the name of the village, county, township, reservation, etc. that applies.</p>
                                </template>
                            </form-input>
                        </div>

                        <div class="col-12 col-md-3">
                            <form-input type="select" label="State" v-model="formData.employer_state" :options="stateOptions" :errors="errors.employer_state">
                                <template #popover-helper>
                                    <p>Select the state where the Employer's Business or Organization Address is located from the drop-down list provided. You may also type the first letter of the abbreviation and use the down arrow to select your state or territory.</p>
                                </template>
                            </form-input>
                        </div>

                        <div class="col-12 col-md-2">
                            <form-input type="text" label="ZIP Code" maxlength="5" v-model="formData.employer_postcode" :errors="errors.employer_postcode">
                                <template #popover-helper>
                                    <p>Enter the 5-digit ZIP code for the Employer's Business or Organization Address.</p>
                                </template>
                            </form-input>
                        </div>

                    </div>
                </form>
            </div>

            <div v-if="mode == 'signature'" class="container">
                <div>
                    <pdf v-if="pdf.numPages" :src="pdf.src" v-for="i in pdf.numPages" :key="i" :page="i" class="border-bottom w-100"/>
                </div>

            </div>

        </slot>

        <template #footer>
            <div class="modal-footer">
                <form class="mui-form" v-if="mode == 'signature'">
                    <h5>Digital Signature</h5>

                    <p>
                    Certification: I attest, under penalty of perjury, that (1) I have
                    examined the document(s) presented by the above-named employee, (2)
                    the above-listed document(s) appear to be genuine and to relate to
                    the employee named, and (3) to the best of my knowledge the employee
                    is authorized to work in the United States.
                    </p>

                    <div class="form-group form-check">
                        <form-input v-model="formData.agree" :errors="errors.agree" label="I have read and agree to the terms of this document." type="checkbox" />
                    </div>
                </form>

                <form-errors :errors="formErrors"/>
                <button-row>
                    <button type="button" class="btn btn-outline-primary" v-if="mode == 'docs'" @click.prevent="close()">
                        Close
                    </button>
                    <button type="button" class="btn btn-outline-primary" v-if="mode != 'docs'" @click.prevent="previousStep()">
                        Back
                    </button>
                    <button type="button" class="btn btn-primary" v-if="mode == 'signature'" :disabled="!formData.agree" @click.prevent="nextStep()">
                        Continue
                    </button>
                    <button type="button" class="btn btn-primary" v-if="mode != 'signature'" @click.prevent="nextStep()">
                        Continue
                    </button>
                </button-row>
            </div>
        </template>
    </modal>

<!-- /Modal: Select Documents -->
</template>

<script>
import $ from 'jquery'
import api from '@/api'
import bus from '@/bus'
import moment from 'moment'
import pdf from 'vue-pdf'
import I9Doc from './I9Doc'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import countries from '@/countries'
import states from '@/states'

const allStates = []
states.forEach(s => {
    allStates.push(s.name)
})

const allCountries = []
countries.forEach(c => {
    allCountries.push(c.name)
})

const canadianProvinces = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'Newfoundland and Labrador',
    'Nova Scotia',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Northwest Territories',
    'Nunavut',
    'Yukon',
]

export default {
    components: {I9Doc, pdf, },
    mixins: [FormMixin, ModalMixin, ],
    props: ['client', 'employee', ],
    mounted() {
        $(this.$refs.modal).on('hidden.bs.modal', () => {
            this.reset()
        })

        this.getPDF()
    },
    computed: {
        i9Form() {
            return this.employee.forms.find(f => {return f.yaml_form.form_type == 'i9'})
        },
        stateOptions() {
            const opts = []
            states.forEach(s => {
                opts.push({text: s.name.toUpperCase(), value: s.abbreviation})
            })
            return opts
        },
        immigrationStatusNumber() {
            return [
                'us_citizen',
                'us_national',
                'us_permanent_resident',
                'authorized_alien',
            ].indexOf(this.immigrationStatus) + 1
        },
        immigrationStatus() {
            if (this.i9Form.form_data.designation__us_citizen) {
                return 'us_citizen'
            }
            if (this.i9Form.form_data.designation__us_national) {
                return 'us_national'
            }
            if (this.i9Form.form_data.designation__us_permanent_resident) {
                return 'us_permanent_resident'
            }
            if (this.i9Form.form_data.designation__authorized_alien) {
                return 'authorized_alien'
            }
        },
    },
    watch: {
        'formData.docA.slug'(_new, old) {
            if (!this.formData.docA.slug) {return}

            if (this.formData.docA.slug == 'list_a_na') {
                if (this.formData.docB.slug == 'list_b_na') {
                    this.formData.docB.slug = ''
                    this.$refs.i9DocB.reset()
                }

                if (this.formData.docC.slug == 'list_c_na') {
                    this.formData.docC.slug = ''
                    this.$refs.i9DocC.reset()
                }
            }
            else {
                this.formData.docB.slug = 'list_b_na'
                this.formData.docC.slug = 'list_c_na'

                this.$refs.i9DocB.reset()
                this.$refs.i9DocC.reset()

                if (old == 'list_a_na') {
                    this.$refs.i9DocA.reset()
                }
            }
        },
        'formData.docB.slug'() {
            if (this.formData.docB.slug == 'list_b_na') {
                if (this.formData.docA.slug == 'list_a_na') {
                    this.formData.docA.slug = ''
                    this.$refs.i9DocA.reset()
                }

                if (this.formData.docC.slug != 'list_c_na') {
                    this.formData.docC.slug = 'list_c_na'
                    this.$refs.i9DocC.reset()
                }
            }
            else {
                this.formData.docA.slug = 'list_a_na'
                this.$refs.i9DocA.reset()
                if (this.formData.docC.slug == 'list_c_na') {
                    this.formData.docC.slug = ''
                    this.$refs.i9DocC.reset()
                }
            }
        },
        'formData.docC.slug'() {
            if (this.formData.docC.slug == 'list_c_na') {
                if (this.formData.docA.slug == 'list_a_na') {
                    this.formData.docA.slug = ''
                    this.$refs.i9DocA.reset()
                }

                if (this.formData.docB.slug != 'list_b_na') {
                    this.formData.docB.slug = 'list_b_na'
                    this.$refs.i9DocB.reset()
                }
            }
            else {
                this.formData.docA.slug = 'list_a_na'
                this.$refs.i9DocA.reset()
                if (this.formData.docB.slug == 'list_b_na') {
                    this.formData.docB.slug = ''
                    this.$refs.i9DocB.reset()
                }
            }
        },
    },
    data() {
        return {
            pdf: null,
            formData: this.makeFormData(),
            formRules: {
                date_hired: {presence: {allowEmpty: false}, date: true},
                manager_title: {presence: {allowEmpty: false}},
                manager_last_name: {presence: {allowEmpty: false}},
                manager_first_name: {presence: {allowEmpty: false}},
                employer_name: {presence: {allowEmpty: false}},
                employer_address_line_1: {presence: {allowEmpty: false}},
                employer_city: {presence: {allowEmpty: false}},
                employer_state: {presence: {allowEmpty: false}},
                employer_postcode: {presence: {allowEmpty: false}},
            },
            formLabels: {
                date_hired: 'Hire Date',
                employer_address_line_1: 'Address',
                manager_title: 'Title',
                manager_last_name: 'Last Name',
                manager_first_name: 'First Name',
                employer_city: 'City',
                employer_state: 'State',
                employer_postcode: 'ZIP Code',
            },
            errors: this.makeErrors(),
            mode: 'docs',
            documents: {
                listA: [
                    {
                        slug: 'list_a_na',
                        name: 'N/A',
                        issuers: ['N/A'],
                        exp_is_na: true,
                        number_is_na: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: 'us_passport',
                        name: 'U.S. Passport',
                        immigration_statuses: ['us_citizen', 'us_national'],
                        issuers: [
                            'U.S. Department of State',
                        ],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {
                            pattern: "^[A-Za-z0-9]*$",
                            message: "can only contain letters and numbers"
                        }},
                        documentNumberInputOptions: {min_length: 6, maxlength: 9,},
                    },
                    {
                        slug: 'us_passport_card',
                        name: 'U.S. Passport Card',
                        immigration_statuses: ['us_citizen', 'us_national'],
                        issuers: [
                            'U.S. Department of State',
                        ],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {
                            pattern: "^[A-Za-z0-9]*$",
                            message: "can only contain letters and numbers"
                        }},
                        documentNumberInputOptions: {min_length: 6, maxlength: 9,},
                    },
                    {
                        slug: 'permanent_resident_card',
                        name: 'Perm. Resident Card (Form I-551)',
                        immigration_statuses: ['us_permanent_resident'],
                        issuers: [
                            'U.S. Citizenship and Immigration Services',
                            'DOJ Immigration and Naturalization Service',
                        ],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {
                            pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                            message: "must start with three letters followed by 10 digits. E.g.: QLC3856718663"
                        }},
                        documentNumberInputOptions: {min_length: 13, maxlength: 13,},
                    },
                    {
                        slug: 'alien_reg_receipt_card',
                        name: 'Alien Reg. Receipt Card (Form I-551)',
                        immigration_statuses: ['us_permanent_resident'],
                        issuers: [
                            'DOJ Immigration and Naturalization Service',
                        ],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {
                            pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                            message: "must start with three letters followed by 10 digits. E.g.: QLC3856718663"
                        }},
                        documentNumberInputOptions: {min_length: 13, maxlength: 13,},
                    },
                    {
                        slug: 'foreign_passport_with_temp_i551_stamp',
                        name: 'Foreign Passport with Temp. I-551 Stamp',
                        immigration_statuses: ['us_permanent_resident'],
                        issuers: allCountries,
                        documentNumberRules: {presence: {allowEmpty: false}, format: {
                            pattern: "^[A-Za-z0-9]*$",
                            message: "must contain only letters and numbers"
                        }},
                        documentNumberInputOptions: {min_length: 6, maxlength: 12,},
                        secondary_documents: [
                            {
                                slug: 'temp_i551_stamp',
                                name: 'Temporary I-551 Stamp',
                                number_is_na: true,
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'DOJ Immigration and Naturalization Service',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: {
                                    pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                                    message: "must start with three letters followed by 10 digits. E.g.: QLC3856718663"
                                }},
                                documentNumberInputOptions: {min_length: 13, maxlength: 13,},
                            }
                        ],
                    },
                    {
                        slug: 'foreign_passport_with_temp_i551_mriv',
                        name: 'Foreign Passport with Temp. I-551 MRIV',
                        immigration_statuses: ['us_permanent_resident'],
                        issuers: allCountries,
                        documentNumberRules: {presence: {allowEmpty: false}, format: {
                            pattern: "^[A-Za-z0-9]*$",
                            message: "must contain only letters and numbers"
                        }},
                        documentNumberInputOptions: {min_length: 6, maxlength: 12,},
                        secondary_documents: [
                            {
                                slug: 'temp_i551_mriv',
                                name: 'Machine-readable immigrant visa (MRIV)',
                                issuers: [
                                    'U.S. Department of State',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: { // I-551 rules
                                    pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                                    message: "must start with three letters followed by 10 digits. E.g.: QLC3856718663"
                                }},
                                documentNumberInputOptions: {min_length: 13, maxlength: 13,},
                            }
                        ],
                    },
                    {
                        slug: 'receipt_form_i94_i551_stamp_photo',
                        name: 'Receipt Form I-94/I-94A w/I-551 stamp, photo',
                        issuers: ['U.S. Department of Homeland Security'],
                        immigration_statuses: ['us_permanent_resident'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {  // I-94 rules
                            pattern: "^[0-9A-Za-z]*$",
                            message: "can only contain letters and numbers."
                        }},
                        documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                    },
                    {
                        slug: 'receipt_replacement_perm_res_card_i551',
                        name: 'Receipt Replacement Perm. Res. Card (Form I-551)',
                        issuers: ['U.S. Citizenship and Immigration Services'],
                        immigration_statuses: ['us_permanent_resident'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: { // I-551 rules
                            pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                            message: "must start with three letters followed by 10 digits. E.g.: QLC3856718663"
                        }},
                        documentNumberInputOptions: {min_length: 13, maxlength: 13,},
                    },

                    {
                        slug: 'employment_auth_doc_i766',
                        name: 'Employment Auth. Document (Form I-766)',
                        issuers: ['U.S. Citizenship and Immigration Services'],
                        immigration_statuses: ['authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {  // I-766 rules
                            pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                            message: "must start with three letters followed by 10 digits. E.g.: MSC0902850001"
                        }},
                        documentNumberInputOptions: {min_length: 13, maxlength: 13,},
                    },

                    {
                        slug: 'foreign_passport_work_auth_nonimmigrant',
                        name: 'Foreign Passport, work-authorized nonimmigrant',
                        issuers: allCountries,
                        immigration_statuses: ['authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {
                            pattern: "^[A-Za-z0-9]*$",
                            message: "must contain only letters and numbers"
                        }},
                        documentNumberInputOptions: {min_length: 6, maxlength: 12,},
                        secondary_documents: [
                            {
                                slug: 'foreign_passport_work_auth_nonimmigrant_i94',
                                name: 'Form I-94/I-94A',
                                documentNumberRules: {presence: {allowEmpty: false}, format: {
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "must contain only letters and numbers"
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                            },
                            {
                                slug: 'foreign_passport_work_auth_nonimmigrant_receipt_i94',
                                name: 'Receipt Form I-94/I-94A',
                                documentNumberRules: {presence: {allowEmpty: false}, format: {
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "must contain only letters and numbers"
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                            },
                        ],
                        tertiary_documents: [
                            {
                                slug: 'foreign_passport_work_auth_nonimmigrant_tertiary_na',
                                name: 'N/A',
                                issuers: ['N/A'],
                                exp_is_na: true,
                                number_is_na: true,
                            },
                            {
                                slug: 'foreign_passport_work_auth_nonimmigrant_form_i20',
                                name: 'Form I-20',
                                issuers: [
                                    'U.S. Immigration and Customs Enforcement',
                                    'US DOJ INS',
                                ],
                                // XXX: Which rules should apply here?
                            },
                            {
                                slug: 'foreign_passport_work_auth_nonimmigrant_form_ds2019',
                                name: 'Form DS-2019',
                                issuers: ['U.S. Department of State'],
                                // XXX: Which rules should apply here?
                            },
                        ],
                    },

                    {
                        slug: 'fsm_passport_with_form_i94',
                        name: 'FSM Passport with Form I-94',
                        issuers: ['Federated States of Micronesia'],
                        immigration_statuses: ['authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {
                            pattern: "^[A-Za-z0-9]*$",
                            message: "must contain only letters and numbers"
                        }},
                        documentNumberInputOptions: {min_length: 6, maxlength: 12,},
                        secondary_documents: [
                            {
                                slug: 'fsm_passport_with_form_i94_i94',
                                name: 'Form I-94/I-94A',
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: {
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "must contain only letters and numbers"
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                            },
                            {
                                slug: 'fsm_passport_with_form_i94_receipt_i94',
                                name: 'Receipt Form I-94/I-94A',
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: {
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "must contain only letters and numbers"
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                            },
                        ],
                    },

                    {
                        slug: 'rmi_passport_with_form_i94',
                        name: 'RMI Passport with Form I-94',
                        issuers: ['Republic of the Marshall Islands'],
                        immigration_statuses: ['authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {
                            pattern: "^[A-Za-z0-9]*$",
                            message: "must contain only letters and numbers"
                        }},
                        documentNumberInputOptions: {min_length: 6, maxlength: 12,},
                        secondary_documents: [
                            {
                                slug: 'rmi_passport_with_form_i94_i94',
                                name: 'Form I-94/I-94A',
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: {
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "must contain only letters and numbers"
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                            },
                            {
                                slug: 'rmi_passport_with_form_i94_receipt_i94',
                                name: 'Receipt Form I-94/I-94A',
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: {
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "must contain only letters and numbers"
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                            },
                        ],
                    },
                    {
                        slug: 'receipt_replacement_ead_form_i766',
                        name: 'Receipt Replacement EAD (Form I-766)',
                        issuers: ['U.S. Citizenship and Immigration Services'],
                        immigration_statuses: ['authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {
                            pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                            message: "must start with three letters followed by 10 digits. E.g.: MSC0902850001"
                        }},
                        documentNumberInputOptions: {min_length: 13, maxlength: 13,},
                    },

                    {
                        slug: 'receipt_replacement_form_i94_with_refugee_stamp',
                        name: 'Receipt Form I-94/I-94A w/refugee stamp (or RE class of admission)',
                        issuers: ['U.S. Department of Homeland Security'],
                        immigration_statuses: ['authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {
                            pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                            message: "must start with three letters followed by 10 digits. E.g.: MSC0902850001"
                        }},
                        documentNumberInputOptions: {min_length: 13, maxlength: 13,},
                    },

                    {
                        slug: 'receipt_foreign_passport_work_auth_nonimmigrant',
                        name: 'Receipt Foreign Passport, work-authorized nonimmigrant',
                        issuers: allCountries,
                        immigration_statuses: ['authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {  // Foreign passport rules
                            pattern: "^[A-Za-z0-9]*$",
                            message: "must contain only letters and numbers"
                        }},
                        documentNumberInputOptions: {min_length: 6, maxlength: 12,},
                        secondary_documents: [
                            {
                                slug: 'foreign_passport_work_auth_nonimmigrant_i94',
                                name: 'Form I-94/I-94A',
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: {  // I-94 rules
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "can only contain letters and numbers."
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                            },
                            {
                                slug: 'foreign_passport_work_auth_nonimmigrant_receipt_i94',
                                name: 'Receipt Form I-94/I-94A',
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: {  // I-94 rules
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "can only contain letters and numbers."
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                            },
                        ],
                        tertiary_documents: [
                            {
                                slug: 'foreign_passport_work_auth_nonimmigrant_tertiary_na',
                                name: 'N/A',
                                issuers: ['N/A'],
                                exp_is_na: true,
                                number_is_na: true,
                            },
                            {
                                slug: 'foreign_passport_work_auth_nonimmigrant_form_i20',
                                name: 'Form I-20',
                                issuers: [
                                    'U.S. Immigration and Customs Enforcement',
                                    'US DOJ INS',
                                ],
                                // XXX: What rules should apply here?
                            },
                            {
                                slug: 'foreign_passport_work_auth_nonimmigrant_form_ds2019',
                                name: 'Form DS-2019',
                                issuers: ['U.S. Department of State'],
                                // XXX: What rules should apply here?
                            },
                        ],
                    },

                    {
                        slug: 'receipt_fsm_passport_with_form_i94',
                        name: 'Receipt FSM Passport with Form I-94',
                        issuers: ['Federated States of Micronesia'],
                        immigration_statuses: ['authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {  // Foreign passport rules
                            pattern: "^[A-Za-z0-9]*$",
                            message: "must contain only letters and numbers"
                        }},
                        documentNumberInputOptions: {min_length: 6, maxlength: 12,},
                        secondary_documents: [
                            {
                                slug: 'receipt_fsm_passport_with_form_i94_i94',
                                name: 'Form I-94/I-94A',
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: {  // I-94 rules
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "can only contain letters and numbers."
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                            },
                            {
                                slug: 'receipt_fsm_passport_with_form_i94_receipt_i94',
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: {  // I-94 rules
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "can only contain letters and numbers."
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                            },
                        ],
                        tertiary_documents: [
                            {
                                slug: 'receipt_fsm_passport_with_form_i94_na',
                                name: 'N/A',
                                issuers: ['N/A'],
                                exp_is_na: true,
                                number_is_na: true,
                            },
                            {
                                slug: 'receipt_fsm_passport_with_form_i94_i20',
                                name: 'Form I-20',
                                issuers: [
                                    'U.S. Immigration and Customs Enforcement',
                                    'US DOJ INS',
                                ],
                                // XXX: What rules should apply here?
                            },
                            {
                                slug: 'receipt_fsm_passport_with_form_i94_ds2019',
                                name: 'Form DS-2019',
                                issuers: ['U.S. Department of State'],
                                // XXX: What rules should apply here?
                            },
                        ],
                    },

                    {
                        slug: 'receipt_rmi_passport_with_form_i94',
                        name: 'Receipt RMI Passport with Form I-94',
                        issuers: ['Republic of the Marshall Islands'],
                        immigration_statuses: ['authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {  // Foreign passport rules
                            pattern: "^[A-Za-z0-9]*$",
                            message: "must contain only letters and numbers"
                        }},
                        secondary_documents: [
                            {
                                slug: 'receipt_rmi_passport_with_form_i94_i94',
                                name: 'Form I-94/I-94A',
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: {  // I-94 rules
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "can only contain letters and numbers."
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                            },
                            {
                                slug: 'receipt_rmi_passport_with_form_i94_receipt_i94',
                                name: 'Receipt Form I-94/I-94A',
                                issuers: [
                                    'U.S. Citizenship and Immigration Services',
                                    'U.S. Customs and Border Protection',
                                ],
                                documentNumberRules: {presence: {allowEmpty: false}, format: {  // I-94 rules
                                    pattern: "^[0-9A-Za-z]*$",
                                    message: "can only contain letters and numbers."
                                }},
                                documentNumberInputOptions: {min_length: 11, maxlength: 11,},
                            },
                        ],
                        tertiary_documents: [
                            {
                                slug: 'receipt_rmi_passport_with_form_i94_na',
                                name: 'N/A',
                                issuers: ['N/A'],
                                exp_is_na: true,
                                number_is_na: true,
                            },
                            {
                                slug: 'receipt_rmi_passport_with_form_i94_form_i20',
                                name: 'Form I-20',
                                issuers: [
                                    'U.S. Immigration and Customs Enforcement',
                                    'US DOJ INS',
                                ],
                                // XXX: What rules should apply here?
                            },
                            {
                                slug: 'receipt_rmi_passport_with_form_i94_form_ds2019',
                                name: 'Form DS-2019',
                                issuers: ['U.S. Department of State'],
                                // XXX: What rules should apply here?
                            },
                        ],
                    },
                ],
                listB: [
                    {
                        slug: 'list_b_na',
                        name: 'N/A',
                        issuers: ['N/A'],
                        exp_is_na: true,
                        number_is_na: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: "drivers_license_issued_by_stateterritory",
                        name: "Driver's license issued by state/territory",
                        issuers: allStates,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {  // State ID rules
                            pattern: "^[a-zA-Z0-9*-]*$",
                            message: "can only contain letters, numbers, *, and -."
                        }},
                        documentNumberInputOptions: {min_length: 1, maxlength: 17,},
                    },

                    {
                        slug: 'id_card_issued_by_stategterritory',
                        name: 'ID card issued by state/territory',
                        issuers: allStates,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, format: {  // State ID rules
                            pattern: "^[a-zA-Z0-9*-]*$",
                            message: "can only contain letters, numbers, *, and -."
                        }},
                        documentNumberInputOptions: {min_length: 1, maxlength: 17,},
                    },
                    {
                        slug: 'government_id',
                        name: 'Government ID',
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: 'school_id',
                        name: 'School ID',
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: 'voter_registration_card',
                        name: 'Voter registration card',
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: 'us_military_card',
                        name: 'U.S. Military card',
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: 'us_military_draft_record',
                        name: 'U.S. Military draft record',
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `military_dependents_id_card`,
                        name: `Military dependent's ID card`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `uscg_merchant_mariner_card`,
                        name: `USCG Merchant Mariner card`,
                        issuers: ['U.S. Coast Guard'],
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `native_american_tribal_document`,
                        name: `Native American tribal document`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `canadian_drivers_license`,
                        name: `Canadian driver's license`,
                        issuers: canadianProvinces,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `school_record`,
                        name: `School record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `report_card`,
                        name: `Report card (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `clinic_record`,
                        name: `Clinic record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `doctor_record`,
                        name: `Doctor record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `hospital_record`,
                        name: `Hospital record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },

                    {
                        slug: `day_care_record`,
                        name: `Day-care record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `nursery_school_record`,
                        name: `Nursery School record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `special_placement`,
                        name: `Special Placement`,
                        exp_is_na: true,
                        number_is_na: true,
                        issuers: ['N/A'],
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `individual_under_age_18`,
                        name: `Individual under Age 18`,
                        exp_is_na: true,
                        number_is_na: true,
                        issuers: ['N/A'],
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },

                    {
                        slug: "receipt_replacement_driver_s_license",
                        name: "Receipt Replacement Driver's license",
                        issuers: allStates,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: 'receipt_replacement_id_card',
                        name: 'Receipt Replacement ID card',
                        issuers: allStates,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: 'receipt_replacement_government_id',
                        name: 'Receipt Replacement Government ID',
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },

                    {
                        slug: 'receipt_replacement_school_id',
                        name: 'Receipt Replacement School ID',
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: 'receipt_replacement_voter_reg_card',
                        name: 'Receipt Replacement Voter Reg. card',
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: 'receipt_replacement_us_military_card',
                        name: 'Receipt Replacement U.S. Military card',
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: 'receipt_replacement_us_military_draft_record',
                        name: 'Receipt Replacement U.S. Military Draft record',
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replacement_military_dep_card`,
                        name: `Receipt Replacement Military Dep. card`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replacement_merchant_mariner_card`,
                        name: `Receipt Replacement Merchant Mariner Card`,
                        issuers: ['U.S. Coast Guard'],
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replacement_native_american_tribal_doc`,
                        name: `Receipt Replacement Native American Tribal Doc`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replacement_canadian_dl`,
                        name: `Receipt Replacement Canadian DL`,
                        issuers: canadianProvinces,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replacement_school_record`,
                        name: `Receipt Replacement School record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replacement_report_card`,
                        name: `Receipt Replacement Report card (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replacement_clinic_record`,
                        name: `Receipt Replacement Clinic record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replacement_doctor_record`,
                        name: `Receipt Replacement Doctor record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replacement_hospital_record`,
                        name: `Receipt Replacement Hospital record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replacement_day_care_record`,
                        name: `Receipt Replacement Day-care record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replacement_nursery_school_record`,
                        name: `Receipt Replacement Nursery School record (under 18)`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                ],
                listC: [
                    {
                        slug: 'list_c_na',
                        name: 'N/A',
                        issuers: ['N/A'],
                        exp_is_na: true,
                        number_is_na: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `social_security_card`,
                        name: `Social Security Card (Unrestricted)`,
                        issuers: [
                            'Social Security Administration',
                            'U.S. Department of Health and Human Services',
                            'Social Security Board',
                            'Department of Health, Education and Welfare',
                        ],
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                        documentNumberRules: {presence: {allowEmpty: false}, ssn: {specialAllowedValues: ['n/a', 'N/A', 'N/a', 'n/A'], }},
                        documentNumberInputOptions: {maxlength: 11, },
                    },
                    {
                        slug: `form_fs_545`,
                        name: `Form FS-545`,
                        issuers: ['U.S. Department of State',],
                        exp_is_na: true,
                        immigration_statuses: ['us_citizen', 'us_national'],
                    },
                    {
                        slug: `form_ds_1350`,
                        name: `Form DS-1350`,
                        issuers: ['U.S. Department of State',],
                        exp_is_na: true,
                        immigration_statuses: ['us_citizen', 'us_national'],
                    },
                    {
                        slug: `form_fs_240`,
                        name: `Form FS-240`,
                        issuers: ['U.S. Department of State',],
                        exp_is_na: true,
                        immigration_statuses: ['us_citizen', 'us_national'],
                    },
                    {
                        slug: `us_birth_certificate`,
                        name: `U.S. Birth Certificate`,
                        enter_issuer: true,
                        exp_is_na: true,
                        immigration_statuses: ['us_citizen', 'us_national'],
                    },
                    {
                        slug: `native_american_tribal_document`,
                        name: `Native American tribal document`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `form_i_197`,
                        name: `Form I-197`,
                        issuers: ['DOJ Immigration and Naturalization Service'],
                        immigration_statuses: ['us_citizen', 'us_national'],
                    },
                    {
                        slug: `form_i_179`,
                        name: `Form I-179`,
                        issuers: ['DOJ Immigration and Naturalization Service'],
                        immigration_statuses: ['us_citizen', 'us_national'],
                    },
                    {
                        slug: `employment_auth_document_dhs`,
                        name: `Employment auth. document (DHS) List C #7`,
                        issuers: ['DHS'],
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },

                    {
                        slug: `receipt_replace_unrestricted_ss_card`,
                        name: `Receipt Replace. Unrestricted SS Card`,
                        issuers: ['Social Security Administration'],
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },

                    {
                        slug: `receipt_replacement_birth_certificate`,
                        name: `Receipt Replacement Birth Certificate`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national'],
                    },
                    {
                        slug: `receipt_replace_native_american_tribal_doc`,
                        name: `Receipt Replace. Native American Tribal Doc`,
                        enter_issuer: true,
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                    {
                        slug: `receipt_replace_employment_auth_doc`,
                        name: `Receipt Replace. Employment Auth. Doc. (DHS) List C #7 Receipt`,
                        issuers: ['DHS'],
                        immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
                    },
                ],
            },
        }
    },
    methods: {
        makeErrors() {
            return {
                listA: {
                    secondaryDocument: {},
                    tertiaryDocument: {},
                },
                listB: {},
                listC: {},
            }
        },
        makeFormData() {
            return {
                agree: false,
                docA: {
                    slug: '',
                },
                docB: {
                    slug: '',
                },
                docC: {
                    slug: '',
                },

                date_hired: '',
                manager_title: '',
                manager_first_name: '',
                manager_last_name: '',
                employer_name: '',
                employer_address_line_1: '',
                employer_city: '',
                employer_state: '',
                employer_postcode: '',
            }
        },
        validate() {
            const basicFormValidation = FormMixin.methods.validate.call(this)

            if (
                (this.formData.docA.slug && this.formData.docA.slug == 'list_a_na') &&
                (this.formData.docB.slug && this.formData.docB.slug == 'list_b_na') &&
                (this.formData.docC.slug && this.formData.docC.slug == 'list_c_na')
            ) {
                    this.formErrors.push('You must specify a document from List A or documents from list B and C.')
                    return
            }

            const valid = []
            if (this.formData.docA.slug && this.formData.docA.slug != 'list_a_na') {
                valid.push(this.$refs.i9DocA.validate())
            }
            else if ((this.formData.docB.slug && this.formData.docB.slug != 'list_b_na') && (this.formData.docC.slug && this.formData.docC.slug != 'list_c_na')) {
                valid.push(this.$refs.i9DocB.validate())
                valid.push(this.$refs.i9DocC.validate())
            }
            else {
                valid.push(this.$refs.i9DocA.validate())
                valid.push(this.$refs.i9DocB.validate())
                valid.push(this.$refs.i9DocC.validate())
            }

            if (valid.filter(v => !v).length < 1) {
                return basicFormValidation
            }

            return false
        },
        onSubmit() {
            this.$store.dispatch('START_LOADING')
            const formData = {
                form_data: this.getFormData(),
                signatures: this.getSignatures(),
            }

            api.post(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}/i9?sign=1`, formData).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.isPristine = true
                this.$nextTick(() => {
                    this.close(true)
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
        reset() {
            FormMixin.methods.reset.call(this)
            this.$set(this, 'formData', this.makeFormData())
            this.errors = this.makeErrors()
            this.mode = 'docs'
            this.isPristine = true
            this.$store.dispatch('SET_CLOSEABLE', true)
        },
        getFormData() {
            const data = {
                doc_a_title: this.formData.docA.name,
                doc_a_issuer: this.formData.docA.issuer,
                doc_a_number: this.formData.docA.number,
                doc_a_exp: this.formData.docA.exp,

                doc_b_title: this.formData.docB.name,
                doc_b_issuer: this.formData.docB.issuer,
                doc_b_number: this.formData.docB.number,
                doc_b_exp: this.formData.docB.exp,

                doc_c_title: this.formData.docC.name,
                doc_c_issuer: this.formData.docC.issuer,
                doc_c_number: this.formData.docC.number,
                doc_c_exp: this.formData.docC.exp,

                doc_a_secondary_title: 'N/A',
                doc_a_secondary_issuer: 'N/A',
                doc_a_secondary_number: 'N/A',
                doc_a_secondary_exp: 'N/A',

                doc_a_tertiary_title: 'N/A',
                doc_a_tertiary_issuer: 'N/A',
                doc_a_tertiary_number: 'N/A',
                doc_a_tertiary_exp: 'N/A',

                'manager_date': moment().format('MM/DD/YYYY'),
                'manager_datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),

                additional_info: this.formData.additional_info,
                date_hired: this.formData.date_hired,
                manager_title: this.formData.manager_title,
                manager_last_name: this.formData.manager_last_name,
                manager_first_name: this.formData.manager_first_name,
                employer_name: this.formData.employer_name,
                employer_address_line_1: this.formData.employer_address_line_1,
                employer_city: this.formData.employer_city,
                employer_state: this.formData.employer_state,
                employer_postcode: this.formData.employer_postcode,
            }

            if (this.formData.docA.secondaryDocument && this.formData.docA.secondaryDocument.slug) {
                data.doc_a_secondary_title = this.formData.docA.secondaryDocument.name
                data.doc_a_secondary_issuer = this.formData.docA.secondaryDocument.issuer
                data.doc_a_secondary_number = this.formData.docA.secondaryDocument.number
                data.doc_a_secondary_exp = this.formData.docA.secondaryDocument.exp
            }

            if (this.formData.docA.tertiaryDocument && this.formData.docA.tertiaryDocument.slug) {
                data.doc_a_tertiary_title = this.formData.docA.tertiaryDocument.name
                data.doc_a_tertiary_issuer = this.formData.docA.tertiaryDocument.issuer
                data.doc_a_tertiary_number = this.formData.docA.tertiaryDocument.number
                data.doc_a_tertiary_exp = this.formData.docA.tertiaryDocument.exp
            }

            return data
        },
        getSignatures() {
            const formData = this.getFormData()
            return {
                'manager_signature': {
                    'first_name': formData.manager_first_name,
                    'last_name': formData.manager_last_name,
                    'full_name': `${formData.manager_first_name} ${formData.manager_last_name}`,
                    'date': formData['manager_date'],
                },
            }
        },
        previousStep() {
            if (this.mode == 'signature') {
                this.mode = 'docs'
                return
            }
        },
        getPDF() {
            const url = `/clients/${this.client.id}/onboarding-applications/${this.employee.id}/forms/${this.i9Form.id}/pdf`

            this.$store.dispatch('START_LOADING')
            return api.get(url).then(resp => {
                const pdfBlob = new Uint8Array(resp)
                pdf.createLoadingTask(pdfBlob).promise.then((pdf) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.pdf = {
                        numPages: pdf.numPages,
                        src: pdfBlob,
                    }
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
        nextStep() {
            if (this.mode == 'signature') {
                return this.onSubmit()
            }

            if (this.mode == 'docs') {
                this.errors = {}
                this.formErrors = []

                if (!this.validate()) {
                    if (this.formErrors.length < 1 && Object.keys(this.errors).length > 0) {
                        this.formErrors.push('Please check that you filled out all the required fields and try again.')
                    }

                    return
                }

                const formData = {
                    form_data: this.getFormData(),
                }
                this.$store.dispatch('START_LOADING')
                api.post(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}/i9`, formData).then(() => {
                    this.getPDF().then(() => {
                        this.mode = 'signature'
                    })
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    bus.showError(errors.__all__)
                    this.formErrors = errors.__all__
                    this.onError()
                })
            }
        },
    }
}
</script>
