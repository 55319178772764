<template>
    <aside class="drawer drawer-rowdetail drawer-right collapse no-transition show" v-show="dataLoaded">

        <div class="drawer-header">
            <div class="drawer-close">
                <button type="button" class="close" aria-label="Close" role="button" aria-expanded="true" @click.prevent="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row">
                <div class="col">
                    <h2 class="drawer-title">
                        <span class="drawer-title-text">{{ company.name }} </span>
                    </h2>
                </div>
            </div>
        </div>

        <div class="drawer-body">

            <div class="card mb-2">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Properties</h4>
                    </span>
                </div>

                <div class="card-body p-1">
                    <strong>EIN:</strong> {{ company.EIN }}<br />
                    <strong>Name:</strong> {{ company.name }}<br />
                    <strong>Known As:</strong> {{ company.known_as }}<br />
                    <strong>Address:</strong> {{ company.address_1 }}{{ company.address_2 ? ' , ' + company.address_2 : '' }}, {{ company.city }}, {{ company.state }} {{ company.postal_code }}<br />
                </div>
            </div>

            <div class="card mb-2" v-if="dataLoaded">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Work Locations</h4>
                    </span>
                </div>

                <div class="card-body py-1">
                    <p v-if="!details.work_locations.length">This company has no work locations.</p>

                    <ul class="list-unstyled m-0" v-if="details.work_locations.length">
                        <li v-for="(w, i) in details.work_locations" :key="w.id" :class="{'border-bottom': i < details.work_locations.length - 1}" class="py-1">
                            <strong>{{ w.name }}</strong><br/>
                            {{w.id }} {{ w.address_1 }}{{ w.address_2 ? ` ${w.address_2}, ` : '' }}{{ w.city }}, {{ w.state }} {{ w.postal_code }}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="card mb-2" v-if="dataLoaded">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Voluntary Tax Locations</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.editTaxLocationsModal.open()">Edit</button>
                </div>

                <div class="card-body py-1">
                    <p v-if="!withholdingLocations.length">This company has no voluntary withholding locations.</p>

                    <ul class="list-unstyled m-0" v-if="withholdingLocations.length">
                        <li v-for="(w, i) in withholdingLocations" :key="w.id" :class="{'border-bottom': i < withholdingLocations.length - 1}">
                            <strong v-if="w.city">{{ w.city.name }}, {{ w.city.state.name }}</strong>
                            <strong v-if="w.state">{{ w.state.long_name }}</strong>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <edit-tax-locations-modal ref="editTaxLocationsModal" :company="details" @updated="onVoluntaryWithholdingLocationsUpdate" />
    </aside>
</template>

<script>
import EditTaxLocationsModal from './EditTaxLocationsModal'
import api from '@/api'
import bus from '@/bus'

export default {
    components: {EditTaxLocationsModal, },
    props: ['company', ],
    computed: {
        withholdingLocations() {
            const locations = this.details.voluntary_withholding_locations
            locations.sort((a, b) => {
                if (a.city && !b.city) {return 1}
                if (!a.city && b.city) {return -1}

                if (a.city && b.city) {
                    return a.city.name > b.city.name ? 1 : -1
                }

                if (a.state && !b.state) {return 1}
                if (!a.state && b.state) {return -1}

                if (a.state && b.state) {
                    return a.state.long_name > b.state.long_name ? 1 : -1
                }


                return a.id > b.id ? 1 : -1
            })

            return locations
        },
    },
    mounted() {
        this.getCompany()
    },
    watch: {
        'company.id'() {
            this.getCompany()
        },
    },
    data() {
        return {
            dataLoaded: false,
            details: {},
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        getCompany() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            api.get(`/clients/${this.$route.params.clientId}/companies/${this.company.id}/details`).then((resp) => {
                this.details = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
        onVoluntaryWithholdingLocationsUpdate(locations) {
            this.details.voluntary_withholding_locations = locations
            this.$emit('updated', this.details)
        },
    },
}
</script>

