<script>
import Modal from '@/components/Modal'
import ButtonRow from '@/components/ButtonRow'
import $ from 'jquery'

export default {
    components: {Modal, ButtonRow},
    data() {
        return {
            isCloseable: true,
        }
    },
    watch: {
        isCloseable() {
            this.$store.dispatch('SET_CLOSEABLE', this.isCloseable)
        },
    },
    methods: {
        open() {
            this.$refs.modal.open()
            if (this.reset) {
                this.reset()
            }
        },
        close(forceClose = false) {
            if (forceClose) {
                this.isCloseable = true
            }

            this.$nextTick(() => {
                if (this.$refs.modal) {
                    this.$refs.modal.close()
                }
                this.$nextTick(() => {
                    $('body').removeClass('modal-open')
                    $('body .modal-backdrop').remove()
                })
            })
        },
        scrollBottom() {
            this.$refs.modal.scrollBottom()
        },
    },
}
</script>
