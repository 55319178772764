<template>
<div>
    <section class="table-section">
        <div v-if="loaded && !changelogs.length">
            <div class="row-empty py-0">
                This object has no change history.
            </div>
        </div>
        <div v-if="loaded && changelogs.length">
            <table class="table css-table table-fixed">
                <thead>
                    <tr>
                        <th class="cw-toggle"></th>
                        <th class="cw-date">Date</th>
                        <th class="cw-6">Change</th>
                        <th>Changed By</th>
                    </tr>
                </thead>
                <tbody :key="changelog.id" v-for="changelog in sortedChangelogs" v-if="changelog.log_level > 10">
                    <tr>
                        <td class="cw-toggle position-relative">
                            <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': !openChangelogs[changelog.id]}" @click.prevent="toggleChangelog(changelog.id)">
                                <collapse-toggle-button />
                            </a>
                        </td>
                        <td>
                            <span>
                                {{ changelog.created_on|date }}
                            </span>
                        </td>
                        <td class="truncate-wrapper">
                            <span class="truncate-content">
                                {{ changelog.change_type }}
                            </span>
                        </td>
                        <td class="truncate-wrapper">
                            <span class="truncate-content">
                                {{ changelog.created_by.email }}
                            </span>
                        </td>
                    </tr>
                    <tr class="child no-transition" :class="{'collapse': !openChangelogs[changelog.id]}">
                        <td colspan="4">
                            <changeset :client="client" :changelog="changelog" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</div>
</template>

<script>
import Changeset from '@/components/Changeset'

export default {
    props: ['changelogs', 'activeChangelogId', 'client', ],
    components: {Changeset},
    computed: {
        loaded() {
            return Array.isArray(this.changelogs)
        },
        sortedChangelogs() {
            const result = this.changelogs.slice()
            result.sort((a, b) => {
                return a.created_on > b.created_on ? -1 : 1
            })

            return result
        },
    },
    data() {
        const data = {
            openChangelogs: {},
        }

        if (this.activeChangelogId) {
            data.openChangelogs[this.activeChangelogId] = true
        }

        return data
    },
    methods: {
        toggleChangelog(changelogId) {
            this.$set(this.openChangelogs, changelogId, !this.openChangelogs[changelogId])
        },
    },
}
</script>
