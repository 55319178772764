import moment from 'moment'
import bus from '@/bus'
import api from '@/api'
import store from '@/store'


export const PMDateFilters = {
    install(Vue) {
        Vue.filter('fromNow', (value) => {
            return value ? moment(value).fromNow() : ''
        })

        Vue.filter('fromNowGranular', (value) => {
            if (!value) {
                return ''
            }

            const diff = moment().diff(moment(value), 'seconds')
            if (diff == 1) {
                return '1 second ago'
            }
            return `${diff} seconds ago`
        })

        Vue.filter('date', (value, format='MM/DD/Y') => {
            // for now at least, try to guess the date format -
            // it'll either be YYYY-MM-DD or MM/DD/YYYY
            if (moment.isMoment(value)) {
                return moment(value).format(format)
            }
            else if (value instanceof Date) {
                return moment(value).format(format)
            }
            else if (value instanceof String || typeof(value) == 'string') {
                let inputformat = 'MM/DD/YYYY'
                if (value && value.indexOf('-') >= 0) {
                    inputformat = 'YYYY-MM-DD'
                    if (value && value.indexOf('T') >= 0) {
                        inputformat = 'YYYY-MM-DD\THH\:mm\:s\.SSS\Z'
                    }
                }

                return value ? moment(value, inputformat).format(format) : ''
            }

            return ''
        })
    }
}

export const PMBus = {
    install(Vue) {
        Vue.prototype.$bus = bus
    }
}

export const PMAPI = {
    install(Vue) {
        Vue.prototype.$api = api
    }
}

export const PMPermissions = {
    install(Vue) {
        Vue.prototype.$permissions = {
            getSecurityProfile(clientId) {
                return store.state.user.security_profiles.find(sp => sp.client == clientId)
            },
            getCompanyIdsByRoles(clientId, roles) {
                if (store.state.user.is_superuser) {
                    return this.client.companies.map(c => c.id)
                }

                const companySecurityProfiles = this.getSecurityProfile(clientId).company_security_profiles
                return companySecurityProfiles.filter(csp => roles.indexOf(csp.role) >= 0).map(csp => csp.company)
            },
            hasRoles(clientId, roles) {
                if (store.state.user.is_superuser) {
                    return true
                }

                const companySecurityProfiles = store.state.user.security_profiles.find(sp => sp.client == clientId).company_security_profiles
                if (companySecurityProfiles.filter(csp => roles.indexOf(csp.role) >= 0).length) {
                    return true
                }

                return false
            },
            hasRolesInCompany(clientId, companyId, roles) {
                if (store.state.user.is_superuser) {
                    return true
                }

                const companySecurityProfiles = store.state.user.security_profiles.find(sp => sp.client == clientId).company_security_profiles
                const csp = companySecurityProfiles.find(c => c.company == companyId)
                if (csp && roles.indexOf(csp.role) >= 0) {
                    return true
                }

                return false
            },
            isSuperuser() {
                return store.state.user.is_superuser
            },
            hasAccess(clientId, companyId, workLocationIds, positionIds) {
                if (store.state.user.is_superuser) {
                    return true
                }

                else if (this.hasRolesInCompany(clientId, companyId, ['officer', 'payroll-admin'])) {
                    return true
                }

                else if (workLocationIds.length && this.hasRolesInCompany(clientId, companyId, ['location-manager'])) {
                    const companySecurityProfiles = store.state.user.security_profiles.find(sp => sp.client == clientId).company_security_profiles
                    const csp = companySecurityProfiles.find(c => c.company == companyId)
                    const userWorkLocationIds = csp.work_location_security_profiles.map(wlsp => wlsp.work_location)
                    if (workLocationIds.every(wId => userWorkLocationIds.includes(wId))) {
                        return true
                    }
                }

                else if (positionIds.length && this.hasRolesInCompany(clientId, companyId, ['store-manager', 'employee-manager'])) {
                    const companySecurityProfiles = store.state.user.security_profiles.find(sp => sp.client == clientId).company_security_profiles
                    const csp = companySecurityProfiles.find(c => c.company == companyId)
                    const userPositionIds = csp.security_profile_positions.map(spp => spp.position)

                    if (positionIds.every(pId => userPositionIds.includes(pId))) {
                        return true
                    }
                }

                return false
            },
        }
    }
}


export const PMConstants = {
    install(Vue) {
        Vue.prototype.$delta = {
            POS_APP_URL: process.env.VUE_APP_POS_APP_URL || 'https://pos.getmypaystub.com'
        }
    }
}

