<template>
    <modal size="xl" @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">
        <template slot="title">
            <h3 class="modal-title">
                {{ securityProfile ? 'Edit' : 'Add' }} Security User:
                <span class="text-thin text-nowrap" v-if="step == 'user-info'">User Information</span>
                <span class="text-thin text-nowrap" v-if="step == 'companies'">Company Roles</span>
                <span class="text-thin text-nowrap" v-if="step == 'role'">Role and Access</span>
            </h3>
        </template>

        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()" v-if="step == 'user-info'">

                <div class="form-row">
                    <div class="col-12 col-sm-6">
                        <form-input v-model="formData.first_name" :errors="errors.first_name" placeholder="First Name" type="text" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-12 col-sm-6">
                        <form-input v-model="formData.last_name" :errors="errors.last_name" placeholder="Last Name" type="text" />
                    </div>
                </div>

                <div class="form-row" v-if="!securityProfile">
                    <div class="col-12 col-sm-6">
                        <form-input v-model="formData.email" :errors="errors.email" placeholder="Email Address" type="email" />
                    </div>
                </div>
            </form>

            <div v-if="step == 'companies'">
                <div class="p-1 unpad-x bg-150 rounded mb-2">
                    <div><span class="text-muted">User:</span> {{ formData.first_name }} {{ formData.last_name }}</div>
                </div>
                <p>Manage this user's role and access for each company in this account.</p>

                <ul class="list-unstyled">
                    <li class="d-none d-md-block">
                        <div class="d-flex align-items-center p-1 list-action-item list-action-header">
                            <div class="row flex-grow-1">
                                <div class="col-12 col-md-6"><h5>Company</h5></div>
                                <div class="col-12 col-md-6"><h5>Role</h5></div>
                            </div>
                        </div>
                    </li>

                    <li class="border-bottom" v-for="cr in companyRoles">
                        <div class="d-flex align-items-center p-1 no-decoration" v-if="!cr.isEditable">
                            <div class="row flex-grow-1">
                                <div class="col-12 col-md-6">{{ cr.name }}</div>
                                <div class="col-12 col-md-6"><span class="text-dark">{{ cr.role }}</span></div>
                            </div>
                        </div>

                        <a href class="d-flex align-items-center p-1 list-action-item no-decoration" v-if="cr.isEditable" @click.prevent="selectCompany(cr.company_id)">
                            <div class="row flex-grow-1">
                                <div class="col-12 col-md-6">{{ cr.name }}</div>
                                <div class="col-12 col-md-6"><span class="text-dark">{{ cr.role }}</span></div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

            <div v-if="step == 'role'">
                <div>
                    <div class="p-1 unpad-x bg-150 rounded mb-2">
                        <div><span class="text-muted">User:</span> {{ formData.first_name }} {{ formData.last_name }}</div>
                        <div><span class="text-muted">Company:</span> {{ currentCompany.name }}</div>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-12 col-lg-6">
                        <h5>Security Role</h5>

                        <ul class="list-unstyled m-0">

                            <li class="p-1 border-bottom" v-for="role in currentCompanyRoleOptions" :class="{'bg-hilite-light': formData.roles[currentCompany.id] == role.value}">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="radio" name="roles" v-model="formData.roles[currentCompany.id]" :value="role.value">
                                        <div class="text-bold">{{ role.label }}</div>
                                        <div class="text-small">{{ role.desc }}</div>
                                    </label>
                                </div>
                            </li>

                        </ul>
                    </div>

                   <div class="col-12 col-lg-6">
                        <h5>Access</h5>
                        <p class="mb-1">This user will be able to access:
                            <strong v-if="!formData.roles[currentCompany.id]">no positions in this company.</strong>

                            <strong v-if="['payroll-admin', 'officer'].indexOf(formData.roles[currentCompany.id]) >= 0">all positions in this company.</strong>
                        </p>

                        <div class="card card-body p-1" v-if="['store-manager'].indexOf(formData.roles[currentCompany.id]) >= 0">
                            <page-search v-model="search" />

                            <div class="pl-0 pt-hf">
                                <div class="py-hf" v-for="p in searchCompanyPositions" :key="p.id">
                                    <div class="form-check">
                                        <label class="form-check-label pl-qt" :for="`role-checkbox-input-${p.id}`">
                                          <input type="checkbox" class="form-check-input" :id="`role-checkbox-input-${p.id}`" v-model="formData.positions[currentCompany.id][p.id]">
                                          {{ p.terminal_value }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card card-body p-1" v-if="['location-manager'].indexOf(formData.roles[currentCompany.id]) >= 0">
                            <page-search v-model="search" />

                            <div class="pl-0 pt-hf">
                                <div class="py-hf" v-for="l in searchCompanyLocations" :key="l.id">
                                    <div class="form-check">
                                        <label class="form-check-label pl-qt" :for="`role-checkbox-input-${l.id}`">
                                            <input type="checkbox" class="form-check-input" :id="`role-checkbox-input-${l.id}`" v-model="formData.work_locations[currentCompany.id][l.id]">
                                            {{ l.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </slot>

        <template slot="footer">
            <form-errors :errors="formErrors"/>

            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">{{ step == 'companies' ? 'Save' : 'Continue' }}</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import * as JsSearch from 'js-search'

export default {
    props: ['client', 'securityProfile'],
    mixins: [FormMixin, ModalMixin],
    computed: {
        roleCount() {
            let count = 0
            this.client.companies.forEach(c => {
                if (this.formData.roles[c.id]) {
                    count += 1
                }
            })

            return count
        },
        companyRoles() {
            const companies = []
            this.client.companies.forEach(c => {
                if (!this.$permissions.hasRolesInCompany(this.client.id, c.id, ['payroll-admin', 'officer'])) {
                    return
                }

                const isEditable = this.canEditRole(c.id)

                let roleLabel;
                if (isEditable) {
                    const role = this.formData.roles[c.id] || '';
                    const rolePretty = this.roleOptions.find(r => r.value == role).label
                    roleLabel = role ? rolePretty : 'None'
                }
                else {
                    const csp = this.securityProfile.company_security_profiles.find(csp => csp.company.id == c.id)
                    const rolePretty = this.roleOptions.find(r => r.value == csp.role).label
                    roleLabel = csp.role ? rolePretty : 'None'
                }

                const comp = {
                    company_id: c.id,
                    name: c.name,
                    role: roleLabel,
                    isEditable: isEditable,
                }

                companies.push(comp)
            })

            return companies
        },
        currentCompanyPositions() {
            if (!this.currentCompany || !this.currentCompany.id) {
                return []
            }

            return this.clientPositions.filter(p => {
                return p.company.id == this.currentCompany.id
            })
        },
        currentCompanyLocations() {
            if (!this.currentCompany || !this.currentCompany.id) {
                return []
            }
            return this.currentCompany.work_locations
        },
        searchCompanyLocations() {
            if (this.search && this.locationSearchIndex) {
                return this.locationSearchIndex.search(this.search)
            }
            return this.currentCompanyLocations
        },
        searchCompanyPositions() {
            if (this.search && this.searchIndex) {
                return this.searchIndex.search(this.search)
            }
            return this.currentCompanyPositions
        },
        roleOptions() {
            return [
                {value: '', label: 'None', desc: 'No security privileges.'},
                {value: 'store-manager', label: 'Department Manager', desc: 'View and edit HR details for one or more positions.'},
                {value: 'location-manager', label: 'General Manager', desc: 'View HR and tax documents and view and edit HR details at one or more work locations.'},
                {value: 'payroll-admin', label: 'Payroll Administrator', desc: 'View and edit HR details for all positions and manage certain company settings.'},
                {value: 'officer', label: 'Officer', desc: 'View and edit HR details for all positions and manage all company settings.'},
            ]
        },
        currentCompanyRoleOptions() {
            if (!this.currentCompany) {return []}
            const result = []
            this.roleOptions.forEach(r => {
                if (['', 'store-manager', 'location-manager'].indexOf(r.value) >= 0) {
                    result.push(r)
                }
                else {
                    if (r.value == 'payroll-admin') {
                        if (this.$permissions.hasRolesInCompany(this.client.id, this.currentCompany.id, ['officer'])) {
                            result.push(r)
                        }
                    }
                    if (r.value == 'officer') {
                        if (this.$permissions.isSuperuser()) {
                            result.push(r)
                        }
                    }
                }
            })

            return result
        },
    },
    data() {
        return {
            step: this.securityProfile ? 'companies' : 'user-info',
            search: '',
            searchIndex: null,
            formData: this.makeFormData(),
            formRules: {
                first_name: {presence: {allowEmpty: false}, },
                last_name: {presence: {allowEmpty: false}, },
                email: {presence: {allowEmpty: false}, email: true},
            },
            currentCompany: null,
            clientPositions: [],
        }
    },
    mounted() {
        this.getClientPositions()
    },
    methods: {
        makeFormData() {
            const data = {
                first_name: '',
                last_name: '',
                email: '',
                roles: {},
                positions: {},
                work_locations: {},
            }
            this.client.companies.forEach(c => {
                if (!this.canEditRole(c.id)) {
                    return
                }

                data.positions[c.id] = {}
                data.work_locations[c.id] = {}
                data.roles[c.id] = ''
            })

            if (this.securityProfile) {
                data.first_name = this.securityProfile.first_name
                data.last_name = this.securityProfile.last_name
                data.email = this.securityProfile.email

                this.securityProfile.company_security_profiles.forEach(csp => {
                    if (!this.canEditRole(csp.company.id)) {
                        return
                    }

                    if (csp.is_active) {
                        data.roles[csp.company.id] = csp.role
                    }

                    csp.security_profile_positions.forEach(cpp => {
                        data.positions[csp.company.id][cpp.position.id] = true
                    })

                    csp.work_location_security_profiles.forEach(w => {
                        data.work_locations[csp.company.id][w.work_location.id] = true
                    })
                })
            }

            return data
        },
        getFormData() {
            const data = JSON.parse(JSON.stringify(this.formData))

            const positions = {}
            Object.keys(data.roles).forEach(cId => {
                if (data.roles[cId] && ['store-manager'].indexOf(data.roles[cId]) >= 0) {
                    positions[cId] = []
                    Object.keys(data.positions[cId]).forEach(pId => {
                        if (data.positions[cId][pId]) {
                            positions[cId].push(pId)
                        }
                    })
                }
            })
            data.positions = positions

            const workLocations = {}
            Object.keys(data.roles).forEach(cId => {
                if (data.roles[cId] && ['location-manager'].indexOf(data.roles[cId]) >= 0) {
                    workLocations[cId] = []
                    Object.keys(data.work_locations[cId]).forEach(lId => {
                        if (data.work_locations[cId][lId]) {
                            workLocations[cId].push(lId)
                        }
                    })
                }
            })
            data.work_locations = workLocations

            return data
        },
        onSubmit() {
            this.formErrors = []
            if (this.step == 'user-info') {
                if (!this.validate(['first_name', 'last_name', 'email'])) {
                    return
                }

                this.$store.dispatch('START_LOADING')
                let validate_email_promise = this.$api.post(`/clients/${this.client.id}/security-profiles/validate-email`, {'email': this.formData.email})

                validate_email_promise.then(() => {
                    this.$store.dispatch('STOP_LOADING')
                    this.step = 'companies'
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                })

                return
            }

            if (this.step == 'role') {
                this.step = 'companies'
                this.search = ''
                return
            }

            this.$store.dispatch('START_LOADING')
            let promise
            if (this.securityProfile) {
                promise = this.$api.put(`/clients/${this.client.id}/security-profiles/${this.securityProfile.id}`, this.getFormData())
            }
            else {
                promise = this.$api.post(`/clients/${this.client.id}/security-profiles`, this.getFormData())
            }

            promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        getClientPositions() {
            this.clientPositions = []
            this.$api.get(`/clients/${this.client.id}/positions`).then(resp => {
                this.clientPositions = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        selectCompany(cId) {
            this.currentCompany = this.client.companies.find(c => c.id == cId)
            this.step = 'role'
            this.searchIndex = this.createSearchIndex()
        },
        reset() {
            FormMixin.methods.reset.call(this)
            this.step = this.securityProfile ? 'companies' : 'user-info'
            this.currentCompany = null
            this.getClientPositions()
        },
        createSearchIndex() {
            const searchIndex = new JsSearch.Search('id')
            searchIndex.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
            searchIndex.addIndex('terminal_value')

            searchIndex.addDocuments(this.currentCompanyPositions || [])
            return searchIndex
        },
        canEditRole(companyId) {
            // Returns true if the current user can edit the role for companyid with the given `this.securityProfile`
            if (!this.$permissions.hasRolesInCompany(this.client.id, companyId, ['payroll-admin', 'officer'])) {
                return false
            }

            if (!this.securityProfile) {
                return true
            }

            const csp = this.securityProfile.company_security_profiles.find(csp => csp.company.id == companyId)
            if (csp) {
                let requiredRoles = ['payroll-admin', 'officer']
                if (csp.role == 'payroll-admin') {
                    requiredRoles = ['officer']
                }
                else if (csp.role == 'officer') {
                    requiredRoles = []
                }

                if (!this.$permissions.hasRolesInCompany(this.client.id, companyId, requiredRoles)) {
                    return false
                }
            }

            return true
        },
    },
}
</script>

<style>
.custom-control.custom-checkbox .custom-control-label::before {
    left: -1.5rem;
}

.custom-control.custom-checkbox {
    padding-left: 2rem;
}
</style>
