<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered-s py-4">

                        <div v-show="! managerLookupOpen">
                            <form @submit.prevent="onSubmit" novalidate class="mui-form" v-show="step == 'email'">
                                <h1 class="mb-4 text-center">Forgot Password</h1>

                                <form-errors :errors="formErrors"/>

                                <div class="form-row">
                                    <div class="col-12">
                                        <form-input label="Enter your email address:" placeholder="Email Address" v-model="formData.email" :errors="errors.email" type="email" autocomplete="email" name="email" />
                                    </div>
                                </div>

                                <div class="my-2">
                                    <button type="submit" class="btn btn-primary btn-block">Continue</button>
                                </div>

                                <p class="text-center">
                                    <router-link class="btn btn-link" :to="{name: 'login'}">Back</router-link>
                                </p>
                            </form>

                            <div v-show="step == 'is-blocked'">
                                <h1 class="mb-4 text-center">Account Locked</h1>

                                <p>This account is blocked and cannot be accessed.</p>

                                <router-link class="btn btn-primary btn-block" :to="{name: 'login'}">Back to Sign In</router-link>
                            </div>

                            <div v-show="step == 'withdrawn-consent'">
                                <h1 class="mb-4 text-center">Account Deleted</h1>

                                <p>This account has been deleted due to withdrawn acceptance of our Terms of Service. Contact your account administrator if you need to set up a new account.</p>

                                <router-link class="btn btn-primary btn-block" :to="{name: 'login'}">Back to Sign In</router-link>
                            </div>

                            <div v-show="step == 'not-in-system'">
                                <h1 class="mb-4 text-center">We don't recognize that account</h1>
            
                                <p>Make sure you're using the email address that you provided to your employer. If you still cannot get in, check with your employer to ensure that your email address is correct.</p>
            
                                <router-link class="btn btn-primary btn-block" :to="{name: 'login'}">Back to Sign In</router-link>
                            </div>
            
                            <div v-show="step == 'no-user'">
                                <h1 class="mb-4 text-center">Register your new account</h1>
            
                                <p>You don't have a password yet - it looks like this is the first time you're using this account. In a few quick steps you'll be registered and ready to go.</p>
            
                                <router-link class="btn btn-primary btn-block" :to="{name: 'signup'}">Continue</router-link>
            
                                <router-link class="btn btn-link btn-block" :to="{name: 'no-system-access'}">I no longer have access to this email address</router-link>

                            </div>

                            <form @submit.prevent="onSubmit" novalidate class="mui-form" v-show="step == 'validate-email'">
                                <h1 class="text-center mb-4">Verify Your Email Address</h1>
            
                                <form-errors :errors="formErrors"/>
            
                                <p>We've sent an email to <strong>{{ formData.email }}</strong>, containing a verification code.</p>
            
                                <p>Please enter the code here:</p>
            
                                <div class="form-row">
                                    <div class="col-6">
                                        <form-input placeholder="Verification Code" v-model="formData.verification_code" :errors="errors.verification_code" type="tel" pattern="[0-9]*" name="code" autocomplete="one-time-code" />
                                    </div>
                                </div>
            
                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <router-link :to="{name: 'login'}" class="btn btn-link">Back to Sign In</router-link>
                                    </div>
                                    <div class="col text-right">
                                        <button type="submit" class="btn btn-primary">Continue</button>
                                    </div>
                                </div>

                                <hr>

                                <div class="mt-2 text-center">
                                    <a href class="btn btn-link" @click.prevent="managerLookupOpen = true">Don't have access to this email?</a>
                                </div>
                            </form>

                            <!-- Validate SSN -->
                            <form @submit.prevent="onSubmit" novalidate class="mui-form" v-show="step == 'validate-ssn'">
                                <form-errors :errors="formErrors"/>

                                <p>Enter your SSN to verify your identity.</p>

                                <div class="form-row">
                                    <div class="col">
                                        <form-input placeholder="Enter your SSN" v-model="formData.ssn" :errors="errors.ssn" type="ssn" />
                                    </div>
                                </div>

                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col text-center">
                                        <button type="submit" class="btn btn-primary">Continue</button>
                                    </div>
                                </div>
                            </form>

                            <!-- Validate PIN -->
                            <form @submit.prevent="onSubmit" novalidate class="mui-form" v-show="step == 'validate-pin'">
                                <form-errors :errors="formErrors"/>

                                <p>Enter the PIN you chose when you first signed up.</p>

                                <div class="form-row">
                                    <div class="col">
                                        <form-input placeholder="Enter a PIN" v-model="formData.pin" :errors="errors.pin" type="tel" pattern="[0-9]*" maxlength="6" />
                                    </div>
                                </div>
            
                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col text-center">
                                        <button type="submit" class="btn btn-primary">Continue</button>
                                    </div>
                                </div>
            
                                <div class="mt-2 text-center">
                                    <router-link class="btn btn-link btn-block" :to="{name: 'forgot-pin'}">Forgot PIN?</router-link>
                                </div>
                            </form>

                            <form @submit.prevent="onSubmit" novalidate class="mui-form" v-show="step == 'set-password'">
                                <form-errors :errors="formErrors"/>

                                <p>Create a secure password for your account.</p>

                                <div class="form-row">
                                    <div class="col">
                                        <form-input placeholder="Enter a password" v-model="formData.password" :errors="errors.password" type="password" />
                                    </div>
                                </div>

                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col text-center">
                                        <button type="submit" class="btn btn-primary">Continue</button>
                                    </div>
                                </div>
                            </form>

                            <div v-show="step == 'done'">
                                <h1 class="mb-4 text-center">Password reset successful</h1>

                                <p>You are all set. You can now log in.</p>

                                <router-link class="btn btn-primary btn-block" :to="{name: 'login'}">Sign In</router-link>
                            </div>
                        </div>

                        <manager-lookup v-if="managerLookupOpen" :email="formData.email" :is-verified="false" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import ManagerLookup from "@/components/ManagerLookup"

export default {
    mixins: [FormMixin],
    components: {
        FormInput,
        FormErrors,
        ManagerLookup,
    },
    mounted() { this.$store.dispatch('SET_PAGE_TITLE', 'Forgot Password')
    },
    data() {
        return {
            step: 'email',
            formRules: {
                email: {
                    email: true,
                    presence: {allowBlank: false},
                },
                verification_code: {
                    presence: {allowEmpty: false},
                },
                ssn: {
                    ssn: true,
                },
                pin: {
                    presence: {allowEmpty: false},
                    length: {
                        minimum: 4,
                        maximum: 6,
                    }
                },
                password: {
                    presence: {allowEmpty: false},
                    length: {
                        minimum: 8,
                        maximum: 128,
                    }
                },
            },
            formData: {
                email: '',
            },
            managerLookupOpen: false,
        }
    },
    methods: {
        onSubmit() {
            let fields = []
            if (this.step == 'email') {
                fields = ['email']
            }
            if (this.step == 'validate-email') {
                fields = ['verification_code']
            }
            if (this.step == 'validate-pin') {
                fields = ['pin']
            }
            if (this.step == 'validate-ssn') {
                fields = ['ssn']
            }
            if (this.step == 'set-password') {
                fields = ['password']
            }

            if (!this.validate(fields)) {
                return
            }

            this.errors = {}
            this.formErrors = []
            if (this.step == 'email') {
                this.$store.dispatch('START_LOADING')
                return this.$api.post(`/account-status`, {email: this.formData.email}).then((resp) => {

                    if (resp.has_user) {
                        if (resp.withdrawn_consent) {
                            this.step = 'withdrawn-consent'
                            return this.$store.dispatch('STOP_LOADING')
                        }

                        if (resp.is_blocked) {
                            this.step = 'is-blocked'
                            return this.$store.dispatch('STOP_LOADING')
                        }

                        return this.$api.post(`/forgot-password/send-code`, {email: this.formData.email}).then((resp) => {
                            this.step = 'validate-email'
                            this.$store.dispatch('SET_SESSION', resp)
                            this.$store.dispatch('STOP_LOADING')
                        }).catch((errors) => {
                            this.errors = errors
                            this.formErrors = errors.__all__
                            this.onError()
                            this.$store.dispatch('STOP_LOADING')
                        })
                    }
                    else {
                        if (resp.can_register) {
                            this.step = 'no-user'
                        }
                        else {
                            this.step = 'not-in-system'
                        }
                    }

                    this.$store.dispatch('STOP_LOADING')
                }).catch((errors) => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    this.$store.dispatch('STOP_LOADING')
                })
            }

            if (this.step == 'validate-email') {
                this.$store.dispatch('START_LOADING')
                return this.$api.post(`/forgot-password/validate-email`, {verification_code: this.formData.verification_code}).then((resp) => {
                    if (resp.validation_method == 'pin') {
                        this.step = 'validate-pin'
                    }
                    else if (resp.validation_method == 'ssn') {
                        this.step = 'validate-ssn'
                    }
                    else if (resp.validation_method === 'na') {
                        this.step = 'set-password'
                    }
                    else {
                        this.formErrors.push('Server error occurred. Please try again later.')
                    }

                    this.$store.dispatch('STOP_LOADING')
                }).catch((errors) => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    this.$store.dispatch('STOP_LOADING')
                })
            }

            if (this.step == 'validate-ssn') {
                this.$store.dispatch('START_LOADING')
                return this.$api.post(`/forgot-password/validate-ssn`, {ssn: this.formData.ssn}).then((resp) => {
                    this.step = 'set-password'
                    this.$store.dispatch('STOP_LOADING')
                }).catch((errors) => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    this.$store.dispatch('STOP_LOADING')
                })
            }

            if (this.step == 'validate-pin') {
                this.$store.dispatch('START_LOADING')
                return this.$api.post(`/forgot-password/validate-pin`, {pin: this.formData.pin}).then((resp) => {
                    this.step = 'set-password'
                    this.$store.dispatch('STOP_LOADING')
                }).catch((errors) => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    this.$store.dispatch('STOP_LOADING')
                })
            }

            if (this.step == 'set-password') {
                this.$store.dispatch('START_LOADING')
                return this.$api.post(`/forgot-password/set-password`, {password: this.formData.password}).then((resp) => {
                    this.step = 'done'
                    this.$store.dispatch('STOP_LOADING')
                }).catch((errors) => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    this.$store.dispatch('STOP_LOADING')
                })
            }

        },
    }
}
</script>

