<template>
    <div class="button-row-container" :class="buttonRowClasses">
        <!-- Optionally include form errors just above buttons -->
        <slot name="errors"></slot>
        <!-- Actual buttons go here; generally use 1 or 2 -->
        <div class="button-row">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 'Center':
        //    For single-button situations only.
        //    By default, a single button will stretch the entire row width.
        //    Add 'center' property to an instance when you want that single
        //    button to be narrower & sit in the middle instead.
        //    Can be combined with 'inline'.
        center: {
            type: Boolean,
            default: false,
        },
        // 'Inline':
        //    Buttons in <button-row> are block by default, meaning that they stretch 
        //    to fill their entire row. Add 'inline' property to an instance
        //    when you want the buttons to switch to inline-block (width based on 
        //    the button label, flush with left/right edges) on larger screens 
        //    ('rs' and wider).Generally we do this on pages but avoid it in modals.
        inline: {
            type: Boolean,
            default: false,
        },
        // 'Stacked':
        //    Add 'stacked' property to an instance to deal with long button labels.
        //    Only has an effect when there are 2+ buttons in the row.
        //    Buttons get stacked vertically on small screens, then
        //    transition to normal side-by-side layout
        //    on larger screens ('rs' breakpoint).
        stacked: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonRowClasses() {
            let buttonRowClasses = ''
            if (this.inline) {
                buttonRowClasses += ' button-row--inline'
            }
            if (this.center) {
                buttonRowClasses += ' button-row--center'
            }
            if (this.stacked) {
                buttonRowClasses += ' button-row--stacked'
            }
            return buttonRowClasses
        },
    },
}
</script>
