<template>
    <modal @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">
        <template slot="title">
            <h3 class="modal-title">Update HR Document: <span class="text-thin text-nowrap">{{ document.name }}</span></h3>
        </template>

        <slot>
            <p>Upload a new version of this HR Document. The new version will be used going forward.</p>

            <form class="mui-form" @submit.prevent="onSubmit()">
                <form-errors :errors="formErrors"/>

                <div class="form-row">
                    <div class="col">
                        <form-input @upload="onFileUpload($event)" :errors="errors.file" label="Upload your document:" helper-text="File extension must be .pdf" type="file" accept=".pdf"/>
                    </div>
                </div>
            </form>
        </slot>

        <template slot="footer">
            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">
                    Upload and Save
                </button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import $ from 'jquery'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['client', 'document', ],
    mixins: [FormMixin, ModalMixin],
    data() {
        return {
            formData: {
                file: null,
            },
            formRules: {
                file: {presence: {allowEmpty: false}, },
            },
        }
    },
    watch: {
        client() {
            this.reset()
        }
    },
    mounted() {
        $(this.$refs.modal).on('hidden.bs.modal', () => {
            this.reset()
        })
    },
    methods: {
        onFileUpload(f) {
            this.formData.file = f[0]
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            return this.$api.upload(`/clients/${this.client.id}/hr-documents/${this.document.id}/file`, this.formData.file, {fieldName: 'pdf_file'}).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        }
    }
}
</script>
