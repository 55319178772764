<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered py-4">
                      
                        <h1 class="text-center mb-4">
                            Profile &amp; Security
                        </h1>
                        
                        <!-- My Account widget -->
                        <div class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">
                                        My Account
                                    </h4>
                                </span>
                            </div>
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="mui-textfield">
                                        <span class="textfield">{{ state.user.email }}</span>
                                        <label>Email Address</label>
                                      </div>
                                    </div>
                                </div>
                                <div class="form-row mb-2">
                                    <button class="btn btn-outline-primary" @click.prevent="showChangePassword()">
                                      Change Password...
                                    </button>
                                </div>
                                <alert-box type="info" class="mb-0">
                                    If you need to change the email address associated with
                                    your account, please contact your account administrator.
                                </alert-box>
                            </div>
                        </div>
                        
                        <!-- My Devices widget -->
                        <div class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">
                                      My Devices
                                    </h4>
                                </span>
                            </div>
                            <div class="card-body" v-if="authenticator_device">
                                <p class="">
                                    You have an authorized security device:
                                </p>
                                <div class="card card-body bg-200 mb-1">
                                    <div class="row">
                                        <div class="col-12 col-sm-6">
                                            <div class="btnlike">
                                                <strong>{{ authenticator_device }}</strong>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 text-sm-right">
                                            <button class="btn btn-outline-primary" @click.prevent="deauthorizeDevice()">Deauthorize Device</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
          
                            <div class="card-body" v-if="! authenticator_device">
                                <p class="mb-0">
                                    You do not have an authorized security device.
                                    <!-- TODO: prompt to get app -->
                                </p>
                            </div>
                        </div>
                        
                        <!-- Security User Access widget -->
                        <div v-if="security_profiles.length > 0" class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                              <span class="btnlike mr-auto">
                                <h4 class="mb-0">
                                  My Security User Access
                                </h4>
                              </span>
                            </div>
                            <div class="card-body">
                                <p>
                                    You have Security User access at the organization<span v-if="security_profiles.length > 1">s</span> below. 
                                    Employees can contact you to reset their account PIN, using the 
                                    phone number<span v-if="security_profiles.length > 1">s</span> listed below.
                                </p>
                                <div class="pt-sm-1">
                                    <ul class="list-unstyled m-0">
                                        <!-- Header row -->
                                        <li class="d-none d-sm-block">
                                            <div class="form-row align-items-baseline pb-1">
                                                <div class="col-12 col-sm-4 col-md-5">
                                                    <h5 class="theading m-0">Organization</h5>
                                                </div>
                                                <div class="col-12 col-sm-4">
                                                    <h5 class="theading m-0">Your Contact Number</h5>
                                                </div>
                                                <div class="col-12 col-sm-4 col-md-3 text-sm-right">
                                                    <h5 class="theading m-0 sr-only">Actions</h5>
                                                </div>
                                            </div>
                                        </li>
                                        <!-- Content row -->
                                        <li v-for="sec_profile in security_profiles" class="boxed-li-bottom">
                                            <div class="form-row align-items-baseline py-1">
                                                <div class="col-12 col-sm-4 col-md-5">
                                                    <span class="text-semibold">
                                                        {{ sec_profile.client.name }}
                                                    </span>
                                                </div>
                                                <div class="col-12 col-sm-4">
                                                    <span>
                                                        {{ sec_profile.phone_formatted }}
                                                    </span>
                                                </div>
                                                <div class="col-12 col-sm-4 col-md-3 text-sm-right py-hf py-sm-0">
                                                    <button class="btn btn-sm btn-outline-primary" @click.prevent="showChangePhone(sec_profile)">Edit Phone Number</button>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Recent Logins widget -->
                        <div class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">
                                        Recent Logins
                                    </h4>
                                </span>
                            </div>
                            <div class="card-body">
                                <ul class="list-unstyled m-0">
                                    <!-- Header row -->
                                    <li class="d-none d-sm-block">
                                        <div class="form-row align-items-baseline pb-1">
                                            <div class="col-12 col-sm-5">
                                                <h5 class="theading m-0">Date &amp; Time</h5>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <h5 class="theading m-0">Device</h5>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                <h5 class="theading m-0">IP Address</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- Content row -->
                                    <li class="boxed-li-bottom" v-for="login_detail in recent_logins">
                                        <div class="form-row align-items-baseline py-1">
                                            <div class="col-12 col-sm-5">
                                                <span>
                                                    <!-- 09/15/2019 02:03 PM (EST) -->
                                                    {{ formatDateTime(login_detail.login_completed_on) }}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <span>
                                                    {{ login_detail.device_string }}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                <span>
                                                    {{ login_detail.login_completed_from_ip }}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- Active Sessions widget (Hidden for now) -->
                        <div class="card mb-2" v-show="false">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">
                                        Active Sessions
                                    </h4>
                                </span>
                            </div>
                            <div class="card-body">
                                <ul class="list-unstyled m-0">
                                    <!-- Header row -->
                                    <li class="d-none d-sm-block">
                                        <div class="form-row align-items-baseline pb-1">
                                            <div class="col-12 col-sm-5">
                                                <h5 class="theading m-0">Expires</h5>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <h5 class="theading m-0">Device</h5>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                <h5 class="theading m-0">IP Address</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- Content row -->
                                    <li class="boxed-li-bottom" v-for="session_detail in active_sessions">
                                        <div class="form-row align-items-baseline py-1">
                                            <div class="col-12 col-sm-5">
                                                <span>
                                                    <!-- 09/15/2019 02:03 PM (EST) -->
                                                    {{ formatDateTime(session_detail.expire_date) }}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <span>
                                                    {{ session_detail.device }}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                <span>
                                                    {{ session_detail.ip }}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- Terms & Privacy widget -->
                        <div class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">
                                        Terms &amp; Privacy
                                    </h4>
                                </span>
                            </div>
                            <div class="card-body">
                                <p class="mb-2">
                                    By using GetMyPayStub, you consent to our Terms and Conditions.
                                    You may withdraw this consent at any time. <strong class="text-semibold text-danger">
                                    Withdrawing your consent will permanently disable your access to GetMyPayStub.</strong>
                                </p>
                                <p class="mb-2">
                                    <button class="btn btn-outline-primary" @click.prevent="showWithdrawConsent()">
                                        Withdraw Consent &amp; Leave GetMyPayStub
                                    </button>
                                </p>
                                <hr>
                                <p class="mb-1">
                                    View: <a href @click.prevent="showTerms()">Terms and Conditions</a> &nbsp;|&nbsp;
                                    <a href @click.prevent="showPrivacy()">Privacy Policy</a>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <terms-modal ref="termsModal" />
        <privacy-modal ref="privacyModal" />
        <withdraw-consent-modal ref="withdrawConsentModal" />
        <change-password-modal ref="changePasswordModal" />
        <change-phone-modal :sec_profile="currentSecProfile" ref="changePhoneModal" v-if="currentSecProfile" @close="currentSecProfile=null" @updated="getUserProfileInfo" />
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import moment from 'moment'

import WithdrawConsentModal from './components/WithdrawConsentModal'
import ChangePasswordModal from './components/ChangePasswordModal'
import ChangePhoneModal from './components/ChangePhoneModal'
import TermsModal from '@/components/Policies/TermsModal'
import PrivacyModal from '@/components/Policies/PrivacyModal'

export default {
    components: {WithdrawConsentModal, ChangePasswordModal, ChangePhoneModal, TermsModal, PrivacyModal, },
    computed: {
        state() {return this.$store.state}
    },
    data() {
      return {
          recent_logins: [],
          active_sessions: [],
          authenticator_device: null,
          security_profiles: [],
          currentSecProfile: null,
      }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Profile & Security')
        this.getUserProfileInfo()
    },
    methods: {
        showTerms() {
            this.$refs.termsModal.open()
        },
        showPrivacy() {
            this.$refs.privacyModal.open()
        },
        showWithdrawConsent() {
            this.$refs.withdrawConsentModal.open()
        },
        showChangePassword() {
            this.$refs.changePasswordModal.open()
        },
        showChangePhone(sec_profile) {
            this.currentSecProfile = sec_profile
            this.$nextTick(() => {
                this.$refs.changePhoneModal.open()
            })
        },
        formatDateTime(val) {
            return moment(val).format('MM/DD/YYYY hh:mm A')
        },
        getUserProfileInfo() {
            this.$store.dispatch('START_LOADING')

            this.$store.dispatch('GET_USER').then(() => {
                api.get('/me/profile-info').then((data) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.recent_logins = data['recent_logins']
                    this.active_sessions = data['active_sessions']
                    this.authenticator_device = data['authenticator_device']
                    this.security_profiles = data['security_profiles']
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    bus.showError(errors.__all__)
                })
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        },
        deauthorizeDevice() {
          if (! confirm('De-authorizing your device will turn off 1-button verification on that device.\n\n' + 'The next time you log in on any device, we will confirm your identity via email.\n\n' + 'Do you want to proceed?')) {
            return;
          }

          this.$store.dispatch('START_LOADING')
          api.post('/authenticator-device/deauthorize', {}).then((data) => {
              this.$store.dispatch('STOP_LOADING')
              this.authenticator_device = ''
              bus.showInfo('Security device cleared.')
          }).catch((errors) => {
              this.$store.dispatch('STOP_LOADING')
              bus.showError(errors.__all__)
          })
        },
    }
}
</script>

