<template>
    <div class="card mb-1">
        <div class="card-body">
            <form-errors :errors="formErrors"/>

            <div class="form-row" v-if="['lc'].includes(integration.pos)">
                <div class="col">
                    <form-input label="POS URL" v-model="formData.pos_url" :errors="errors.pos_url" :nocaps="true" type="text" @input="onInput" />
                </div>
            </div>

            <div class="form-row" v-if="['lc', 'lc_alt'].includes(integration.pos)">
                <div class="col">
                    <form-input label="POS Username" v-model="formData.pos_username" :errors="errors.pos_username" :nocaps="true" type="text" @input="onInput" />
                </div>
            </div>

            <div class="form-row" v-if="['lc', 'lc_alt'].includes(integration.pos)">
                <div class="col">
                    <form-input label="POS Password" v-model="formData.pos_password" :errors="errors.pos_password" :nocaps="true" type="text" @input="onInput" />
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <form-input v-model="formData.ref_code" :errors="errors.ref_code" type="text" :nocaps="true" label="Reference Code" @input="onInput" />
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'

export default {
    mixins: [FormMixin],
    props: ['client', 'integration', 'instance'],
    data() {
        return {
            formData: this.makeFormData()
        }
    },
    watch: {
        instance() {
            this.reset()
        },
    },
    methods: {
        getFormRules(fields) {
            let rules = {}

            rules['ref_code'] = {presence: {allowEmpty: false}, }

            if (['lc'].includes(this.integration.pos)) {
                rules['pos_url'] = {presence: {allowEmpty: false}, }
            }

            if (['lc', 'lc_alt'].includes(this.integration.pos)) {
                rules['pos_username'] = {presence: {allowEmpty: false}, }
                rules['pos_password'] = {presence: {allowEmpty: false}, }
            }

            return rules
        },
        makeFormData() {
            if (this.instance) {
                return {
                    id: this.instance.id,
                    pos_url: this.instance.pos_url,
                    pos_username: this.instance.pos_username,
                    pos_password: this.instance.pos_password,
                    ref_code: this.instance.ref_code,
                }
            }
            else {
                return {
                    pos_url: '',
                    pos_username: '',
                    pos_password: '',
                    ref_code: '',
                }
            }
        },
        getFormData() {
            return this.formData
        },
        onInput() {
            this.$nextTick(() => {
                this.$emit('update', this.getFormData())
            })
        },
    },
}
</script>
