<template>
    <page>
        <changelog-table :client="client" :changelogs="changelogs" @date_filter_change="onDateRangeChange" />
    </page>
</template>

<script>
import ChangelogTable from '@/components/ChangelogTable'
import Page from '@/components/Page'

export default {
    props: ['client', ],
    components: {ChangelogTable, Page, },
    data() {
        return {
            changelogs: [],
            dateRangeStart: null,
            dateRangeEnd: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Change History')
        this.getChangelogs()
    },
    methods: {
        getChangelogs() {
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/changelogs`
            if (this.dateRangeStart && this.dateRangeEnd) {
                url = `${url}?date-filter.changelogs.start=${encodeURIComponent(this.dateRangeStart)}&date-filter.changelogs.end=${encodeURIComponent(this.dateRangeEnd)}`
            }

            this.$api.get(url).then(resp => {
                this.changelogs = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onDateRangeChange(range) {
            this.dateRangeStart = range.start
            this.dateRangeEnd = range.end
            this.getChangelogs()
        },
    },
}

</script>
